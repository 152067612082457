import { useEffect, useState } from "react";
import {TextField } from '@material-ui/core';
import "../style.css"
import { connect } from 'react-redux';
import DomainRoundedIcon from '@material-ui/icons/DomainRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import actions from '../duck/actions';

const CommunityInput = (props) => {
    const [val,setVal] = useState(props.community)
    const [valid,setValid] = useState(false)

    useEffect(()=>{
      if(props.community===""){
        setValid(false)
      }else{
        setValid(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
      setVal(props.community)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.community])

    const handleInputChange = (value) => {
      if(val.length<255){
          setValid(true)
          setVal(value)
          props.setCommunity(value)
      }
    }

    return(
        <div className="inputContainer">
        <div className="iconContainerNew">
          <DomainRoundedIcon className="icon"/>
        </div>
        <TextField
        value={val}
        label="Gmina"
        variant="outlined"
        type="text"
        className="bigInput"
        inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:"#0750b5"}}}
        onChange={(e)=>handleInputChange(e.target.value)}
        
        />
        <div className="iconContainer">
        {val===""?<HelpOutlineIcon className="icon" style={{color:"grey"}}/>:valid===true?<CheckCircleIcon className="icon" style={{color:"#43a047"}}/>:<CancelOutlinedIcon className="icon" style={{color:"red"}}/>}
        </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
  return{
    setCommunityId : (obj) => dispatch(actions.setCommunityId(obj)),
    setCommunity : (obj) => dispatch(actions.setCommunity(obj)),
  }
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        community: state.newOrder.customerData.community
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(CommunityInput)