import { Grid } from "@mui/material"
import CommentInput from "./CommentInput"
import ConfirmButton from "./ConfirmButton"
import RangePicker from "./RangePicker"
import { RoleService } from "../../RoleService"
import AdminUserSelect from "./AdminUserSelect"
import AdminStatusSelect from "./AdminStatusSelect"
import AdminConfirmButton from "./AdminConfirmButton"
import ResponseInfo from "./ResponseInfo"



const NewReqestForm = () => {
    
    const itemStyle={
        paddingTop:15,
        paddingBottom:15
    }
    const roleService = new RoleService()
    return(
        <>
        <Grid container>
            {
                roleService.hasOneOfRoles(["ADMIN","BOSS","LEAVES_ADMIN"])&&
                <Grid style={itemStyle} item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <AdminUserSelect/>
                </Grid>
            }
            <Grid style={itemStyle} item xs={12} sm={12} md={10} lg={8} xl={8}>
                <RangePicker/>
            </Grid>
            <Grid style={itemStyle} item xs={12} sm={12} md={10} lg={8} xl={8}>
                <CommentInput/>
            </Grid>
            {
                roleService.hasOneOfRoles(["ADMIN","BOSS","LEAVES_ADMIN"])&&
                <Grid style={itemStyle} item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <AdminStatusSelect/>
                </Grid>
            }
            <ResponseInfo/>
            {
                roleService.hasOneOfRoles(["ADMIN","BOSS","LEAVES_ADMIN"])===true?
                <Grid style={itemStyle} item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <AdminConfirmButton/>
                </Grid>
                :
                <Grid style={itemStyle} item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <ConfirmButton/>
                </Grid>
            }
        </Grid>
        </>
    )
}

export default NewReqestForm