import { combineReducers } from 'redux'
import servicesReducer from './newOrder/duck'
import logReducer from "./login/duck"
import filtersReducer from "./departments/duck"
import leavesReducer from './leave/duck/reducer'

const rootReducer = combineReducers({
    newOrder: servicesReducer,
    log: logReducer,
    filters: filtersReducer,
    leaves:leavesReducer
})
export default rootReducer