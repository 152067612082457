import SimpleInfoVierwer from "./SimpleInfoViewer"



const HistorySwitch = (props) =>{

    const {activity} = props

    console.log(activity)

    switch(activity.historyInfoId){
        case 1:
            return activity.historyInfoId
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 28:
        case 29:
        case 30:
        case 31:
        case 32:
        case 33:
        case 34:
        case 35:
        case 36:
        case 37:
        case 38:
        case 39:
        case 40:
        case 41:
        case 42:
        case 43:
        case 44:
        case 45:
        case 46:
        case 47:
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 54:
        case 55:
            return <SimpleInfoVierwer activity={activity}/>
        default:
            return ":)"
    }
}

export default (HistorySwitch)