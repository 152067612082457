import SaveIcon from '@material-ui/icons/Save';
import { useEffect, useState } from "react";
import { IconButton, TextField } from '@material-ui/core';
import "./style.css"
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { serverUrl } from '../config/serverConfig';
import MapRoundedIcon from '@material-ui/icons/MapRounded';

const PlotNumberInput = (props) => {
    const {plotNumber} = props
    const {orderId} = props
    const [valBase,setValBase] = useState(plotNumber)
    const [val,setVal] = useState(plotNumber)
    const { enqueueSnackbar } = useSnackbar()
    const token='Bearer '.concat(props.jwt)


    useEffect(()=>{
        setValBase(plotNumber)
        setVal(plotNumber)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props])

    async function change(plotNumber,orderId) {
        await fetch(`${serverUrl}/orders/${orderId}/plot-number?plotNumber=${plotNumber}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie edytowano numer działki",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Numer działki nie został edytowany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Numer działki nie został edytowany",{variant: 'error',autoHideDuration: 2000})
        })
      }

    const handleInputChange = (value) => {
        if(val.length<255){
            setVal(value)
        }
    }

    const handleSave = () => {
        if(val.length<255&&val.length>=1){
            change(encodeURIComponent(val.trim()),orderId)
        }else{
            enqueueSnackbar("Numer działki musi mieć od 1 do 255 znaków",{variant: 'warning',autoHideDuration: 4000})
        }
    }

    return(
        <>
        <div className="iconContainer">
        <MapRoundedIcon className="icon"/>
        </div>
        <TextField
        value={val}
        label="Numer działki"
        variant="outlined"
        type="text"
        className="bigInput"
        inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:"#0750b5"}}}
        onChange={(e)=>handleInputChange(e.target.value)}
        />
        <IconButton disabled={val===valBase} className="saveButton" onClick={()=>{handleSave()}}>
            <SaveIcon className="saveIcon" style={{color:val===valBase?"grey":"green"}}/>
        </IconButton>
        </>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
  return{
      jwt:state.log.jwt,
  }
}
export default connect(mapStateToProps,null)(PlotNumberInput)