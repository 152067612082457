import types from './types';
//sanitary-installations
const addFilterSanit = item => ({
    type: types.ADD_FILTER_SANIT, item
})

const addGlobalFilterSanit = item => ({
    type: types.ADD_GLOBAL_FILTER_SANIT, item
})

const deleteFilterSanit = item => ({
    type: types.DELETE_FILTER_SANIT, item
})

const resetFiltersSanit = () => ({
    type: types.RESET_FILTERS_SANIT
})

const addSortedColumnSanit = item => ({
    type: types.ADD_SORTED_COLUMN_SANIT, item
})

const setPageSanit = item => ({
    type: types.SET_PAGE_SANIT, item
})

const setOnPageSanit = item => ({
    type: types.SET_ON_PAGE_SANIT, item
})


//typical projects
const addFilterTyp = item => ({
    type: types.ADD_FILTER_TYP, item
})

const addGlobalFilterTyp = item => ({
    type: types.ADD_GLOBAL_FILTER_TYP, item
})

const deleteFilterTyp = item => ({
    type: types.DELETE_FILTER_TYP, item
})

const resetFiltersTyp = () => ({
    type: types.RESET_FILTERS_TYP
})

const addSortedColumnTyp = item => ({
    type: types.ADD_SORTED_COLUMN_TYP, item
})

const setPageTyp = item => ({
    type: types.SET_PAGE_TYP, item
})

const setOnPageTyp = item => ({
    type: types.SET_ON_PAGE_TYP, item
})


//individual projects
const addFilterInd = item => ({
    type: types.ADD_FILTER_IND, item
})

const addGlobalFilterInd = item => ({
    type: types.ADD_GLOBAL_FILTER_IND, item
})

const deleteFilterInd = item => ({
    type: types.DELETE_FILTER_IND, item
})

const resetFiltersInd = () => ({
    type: types.RESET_FILTERS_IND
})

const addSortedColumnInd = item => ({
    type: types.ADD_SORTED_COLUMN_IND, item
})

const setPageInd = item => ({
    type: types.SET_PAGE_IND, item
})

const setOnPageInd = item => ({
    type: types.SET_ON_PAGE_IND, item
})


//geodesy
const addFilterGeodesy = item => ({
    type: types.ADD_FILTER_GEODESY, item
})

const addGlobalFilterGeodesy = item => ({
    type: types.ADD_GLOBAL_FILTER_GEODESY, item
})

const deleteFilterGeodesy = item => ({
    type: types.DELETE_FILTER_GEODESY, item
})

const resetFiltersGeodesy = () => ({
    type: types.RESET_FILTERS_GEODESY
})

const addSortedColumnGeodesy = item => ({
    type: types.ADD_SORTED_COLUMN_GEODESY, item
})

const setPageGeodesy = item => ({
    type: types.SET_PAGE_GEODESY, item
})

const setOnPageGeodesy = item => ({
    type: types.SET_ON_PAGE_GEODESY, item
})


//geology
const addFilterGeology = item => ({
    type: types.ADD_FILTER_GEOLOGY, item
})

const addGlobalFilterGeology = item => ({
    type: types.ADD_GLOBAL_FILTER_GEOLOGY, item
})

const deleteFilterGeology = item => ({
    type: types.DELETE_FILTER_GEOLOGY, item
})

const resetFiltersGeology = () => ({
    type: types.RESET_FILTERS_GEOLOGY
})

const addSortedColumnGeology = item => ({
    type: types.ADD_SORTED_COLUMN_GEOLOGY, item
})

const setPageGeology = item => ({
    type: types.SET_PAGE_GEOLOGY, item
})

const setOnPageGeology = item => ({
    type: types.SET_ON_PAGE_GEOLOGY, item
})


//conditions
const addFilterCond = item => ({
    type: types.ADD_FILTER_COND, item
})

const addGlobalFilterCond = item => ({
    type: types.ADD_GLOBAL_FILTER_COND, item
})

const deleteFilterCond = item => ({
    type: types.DELETE_FILTER_COND, item
})

const resetFiltersCond = () => ({
    type: types.RESET_FILTERS_COND
})

const addSortedColumnCond = item => ({
    type: types.ADD_SORTED_COLUMN_COND, item
})

const setPageCond = item => ({
    type: types.SET_PAGE_COND, item
})

const setOnPageCond = item => ({
    type: types.SET_ON_PAGE_COND, item
})

//allOrdersTable
const addFilterAllOrders = item => ({
    type: types.ADD_FILTER_ALL_ORDERS, item
})

const addGlobalFilterAllOrders = item => ({
    type: types.ADD_GLOBAL_FILTER_ALL_ORDERS, item
})

const deleteFilterAllOrders = item => ({
    type: types.DELETE_FILTER_ALL_ORDERS, item
})

const resetFiltersAllOrders = () => ({
    type: types.RESET_FILTERS_ALL_ORDERS
})

const addSortedColumnAllOrders = item => ({
    type: types.ADD_SORTED_COLUMN_ALL_ORDERS, item
})

const setPageAllOrders = item => ({
    type: types.SET_PAGE_ALL_ORDERS, item
})

const setOnPageAllOrders = item => ({
    type: types.SET_ON_PAGE_ALL_ORDERS, item
})

//allServicesTable
const addFilterAllServices = item => ({
    type: types.ADD_FILTER_ALL_SERVICES, item
})

const addGlobalFilterAllServices = item => ({
    type: types.ADD_GLOBAL_FILTER_ALL_SERVICES, item
})

const deleteFilterAllServices = item => ({
    type: types.DELETE_FILTER_ALL_SERVICES, item
})

const resetFiltersAllServices = () => ({
    type: types.RESET_FILTERS_ALL_SERVICES
})

const addSortedColumnAllServices = item => ({
    type: types.ADD_SORTED_COLUMN_ALL_SERVICES, item
})

const setPageAllServices = item => ({
    type: types.SET_PAGE_ALL_SERVICES, item
})

const setOnPageAllServices = item => ({
    type: types.SET_ON_PAGE_ALL_SERVICES, item
})

//customers
const addFilterCustomers = item => ({
    type: types.ADD_FILTER_CUSTOMERS, item
})

const addGlobalFilterCustomers = item => ({
    type: types.ADD_GLOBAL_FILTER_CUSTOMERS, item
})

const deleteFilterCustomers = item => ({
    type: types.DELETE_FILTER_CUSTOMERS, item
})

const resetFiltersCustomers = () => ({
    type: types.RESET_FILTERS_CUSTOMERS
})

const addSortedColumnCustomers = item => ({
    type: types.ADD_SORTED_COLUMN_CUSTOMERS, item
})

const setPageCustomers = item => ({
    type: types.SET_PAGE_CUSTOMERS, item
})

const setOnPageCustomers = item => ({
    type: types.SET_ON_PAGE_CUSTOMERS, item
})

//history
const addFilterHistory = item => ({
    type: types.ADD_FILTER_HISTORY, item
})

const addGlobalFilterHistory = item => ({
    type: types.ADD_GLOBAL_FILTER_HISTORY, item
})

const deleteFilterHistory = item => ({
    type: types.DELETE_FILTER_HISTORY, item
})

const resetFiltersHistory = () => ({
    type: types.RESET_FILTERS_HISTORY
})

const addSortedColumnHistory = item => ({
    type: types.ADD_SORTED_COLUMN_HISTORY, item
})

const setPageHistory = item => ({
    type: types.SET_PAGE_HISTORY, item
})

const setOnPageHistory = item => ({
    type: types.SET_ON_PAGE_HISTORY, item
})

//costs
const addFilterCosts = item => ({
    type: types.ADD_FILTER_COSTS, item
})

const addGlobalFilterCosts = item => ({
    type: types.ADD_GLOBAL_FILTER_COSTS, item
})

const deleteFilterCosts = item => ({
    type: types.DELETE_FILTER_COSTS, item
})

const resetFiltersCosts = () => ({
    type: types.RESET_FILTERS_COSTS
})

const addSortedColumnCosts = item => ({
    type: types.ADD_SORTED_COLUMN_COSTS, item
})

const setPageCosts = item => ({
    type: types.SET_PAGE_COSTS, item
})

const setOnPageCosts = item => ({
    type: types.SET_ON_PAGE_COSTS, item
})

//reportsPdf
const setStartDate = item => ({
    type: types.SET_START_DATE, item
})

const setEndDate = item => ({
    type: types.SET_END_DATE, item
})

const addUser = item => ({
    type: types.ADD_USER, item
})

const removeUser = item => ({
    type: types.REMOVE_USER, item
})

const setAllUsers = item => ({
    type: types.SET_ALL_USERS,item
})

const setNoUsers = () => ({
    type: types.SET_NO_USERS
})

const setStart = item => ({
    type: types.SET_START,item
})

const setEnd = item => ({
    type: types.SET_END,item
})

const resetReportsPdf = () => ({
    type: types.RESET_REPORTS_PDF
})

const actions = {
    //sanitary installations
    addFilterSanit,
    deleteFilterSanit,
    resetFiltersSanit,
    addGlobalFilterSanit,
    addSortedColumnSanit,
    setPageSanit,
    setOnPageSanit,

    //typical projects
    addFilterTyp,
    deleteFilterTyp,
    resetFiltersTyp,
    addGlobalFilterTyp,
    addSortedColumnTyp,
    setPageTyp,
    setOnPageTyp,

    //individual projects
    addFilterInd,
    deleteFilterInd,
    resetFiltersInd,
    addGlobalFilterInd,
    addSortedColumnInd,
    setPageInd,
    setOnPageInd,

    //geodesy
    addFilterGeodesy,
    deleteFilterGeodesy,
    resetFiltersGeodesy,
    addGlobalFilterGeodesy,
    addSortedColumnGeodesy,
    setPageGeodesy,
    setOnPageGeodesy,

    //geology
    addFilterGeology,
    deleteFilterGeology,
    resetFiltersGeology,
    addGlobalFilterGeology,
    addSortedColumnGeology,
    setPageGeology,
    setOnPageGeology,

    //conditions
    addFilterCond,
    deleteFilterCond,
    resetFiltersCond,
    addGlobalFilterCond,
    addSortedColumnCond,
    setPageCond,
    setOnPageCond,

    //allOrdersTable
    addFilterAllOrders,
    deleteFilterAllOrders,
    resetFiltersAllOrders,
    addGlobalFilterAllOrders,
    addSortedColumnAllOrders,
    setPageAllOrders,
    setOnPageAllOrders,

    //allServicesTable
    addFilterAllServices,
    deleteFilterAllServices,
    resetFiltersAllServices,
    addGlobalFilterAllServices,
    addSortedColumnAllServices,
    setPageAllServices,
    setOnPageAllServices,

    //history
    addFilterHistory,
    deleteFilterHistory,
    resetFiltersHistory,
    addGlobalFilterHistory,
    addSortedColumnHistory,
    setPageHistory,
    setOnPageHistory,

    //customers
    addFilterCustomers,
    deleteFilterCustomers,
    resetFiltersCustomers,
    addGlobalFilterCustomers,
    addSortedColumnCustomers,
    setPageCustomers,
    setOnPageCustomers,

    //costs
    addFilterCosts,
    deleteFilterCosts,
    resetFiltersCosts,
    addGlobalFilterCosts,
    addSortedColumnCosts,
    setPageCosts,
    setOnPageCosts,

    //reportsPdf
    setStartDate,
    setEndDate,
    addUser,
    removeUser,
    setAllUsers,
    setNoUsers,
    setStart,
    setEnd,
    resetReportsPdf

}

export default actions