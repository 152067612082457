const SET_CUSTOMER_ID="SET_CUSTOMER_ID"
const SET_CUSTOMER_NAME="SET_CUSTOMER_NAME"
const SET_COMMUNITY_ID="SET_COMMUNITY_ID"
const SET_COMMUNITY="SET_COMMUNITY"
const SET_PRECINCT_ID="SET_PRECINCT_ID"
const SET_PRECINCT="SET_PRECINCT"
const SET_PLOT_NUMBER="SET_PLOT_NUMBER"
const SET_PROJECT_NAME="SET_PROJECT_NAME"
const ADD_PHONE_NUMBER="ADD_PHONE_NUMBER"
const ADD_EMAIL="ADD_EMAIL"
const ADD_ADDRESS="ADD_ADDRESS"
const REMOVE_PHONE_NUMBER="REMOVE_PHONE_NUMBER"
const REMOVE_EMAIL="REMOVE_EMAIL"
const REMOVE_ADDRESS="REMOVE_ADDRESS"
const SET_NET="SET_NET"
const SET_DATE="SET_DATE"
const ADD_COMMENT="ADD_COMMENT"
const REMOVE_COMMENT="REMOVE_COMMENT"
const RESET_FORM="RESET_FORM"
const SET_CUSTOM_CUSTOMER="SET_CUSTOM_CUSTOMER"
const SET_CUSTOMER_SET="SET_CUSTOMER_SET"
const CLEAR_PHONE_NUMBERS="CLEAR_PHONE_NUMBERS"
const CLEAR_EMAILS="CLEAR_EMAILS"
const CLEAR_ADDRESSES="CLEAR_ADDRESSES"
const CLEAR_NEW_PHONE_NUMBERS="CLEAR_NEW_PHONE_NUMBERS"
const CLEAR_NEW_EMAILS="CLEAR_NEW_EMAILS"
const CLEAR_NEW_ADDRESSES="CLEAR_NEW_ADDRESSES"
const ADD_NEW_PHONE_NUMBER="ADD_NEW_PHONE_NUMBER"
const ADD_NEW_EMAIL="ADD_NEW_EMAIL"
const ADD_NEW_ADDRESS="ADD_NEW_ADDRESS"
const REMOVE_NEW_PHONE_NUMBER="AREMOVE_NEW_PHONE_NUMBER"
const REMOVE_NEW_EMAIL="REMOVE_NEW_EMAIL"
const REMOVE_NEW_ADDRESS="REMOVE_NEW_ADDRESS"
const ADD_SERVICE_GEOD="ADD_SERVICE_GEOD"
const REMOVE_SERVICE_GEOD="REMOVE_SERVICE_GEOD"
const CHANGE_SERVICE_GEOD="CHANGE_SERVICE_GEOD"
const ADD_SERVICE_INDI="ADD_SERVICE_INDI"
const REMOVE_SERVICE_INDI="REMOVE_SERVICE_INDI"
const CHANGE_SERVICE_INDI="CHANGE_SERVICE_INDI"
const ADD_SERVICE_TYPI="ADD_SERVICE_TYPI"
const REMOVE_SERVICE_TYPI="REMOVE_SERVICE_TYPI"
const CHANGE_SERVICE_TYPI="CHANGE_SERVICE_TYPI"
const ADD_SERVICE_COND="ADD_SERVICE_COND"
const REMOVE_SERVICE_COND="REMOVE_SERVICE_COND"
const CHANGE_SERVICE_COND="CHANGE_SERVICE_COND"
const ADD_SERVICE_SANI="ADD_SERVICE_SANI"
const REMOVE_SERVICE_SANI="REMOVE_SERVICE_SANI"
const CHANGE_SERVICE_SANI="CHANGE_SERVICE_SANI"
const ADD_SERVICE_GEOL="ADD_SERVICE_GEOL"
const REMOVE_SERVICE_GEOL="REMOVE_SERVICE_GEOL"
const CHANGE_SERVICE_GEOL="CHANGE_SERVICE_GEOL"
const SET_STEP="SET_STEP"
const SET_GEOLOGY_OPEN="SET_GEOLOGY_OPEN"
const SET_TYPICAL_PROJECTS_OPEN="SET_TYPICAL_PROJECTS_OPEN"
const SET_INDIVIDUAL_PROJECTS_OPEN="SET_INDIVIDUAL_PROJECTS_OPEN"
const SET_CONDITIONS_OPEN="SET_CONDITIONS_OPEN"
const SET_SANITARY_INSTALLATIONS_OPEN="SET_SANITARY_INSTALLATIONS_OPEN"
const SET_GEODESY_OPEN="SET_GEODESY_OPEN"
const ARRAY_CHANGE="ARRAY_CHANGE"
const SET_SUPERVISOR_GEOD="SET_SUPERVISOR_GEOD"
const SET_SUPERVISOR_GEOL="SET_SUPERVISOR_GEOL"
const SET_SUPERVISOR_TYPI="SET_SUPERVISOR_TYPI"
const SET_SUPERVISOR_INDI="SET_SUPERVISOR_INDI"
const SET_SUPERVISOR_COND="SET_SUPERVISOR_COND"
const SET_SUPERVISOR_SANI="SET_SUPERVISOR_SANI"
const types = {
    SET_CUSTOMER_ID,
    SET_CUSTOMER_NAME,
    SET_COMMUNITY_ID,
    SET_COMMUNITY,
    SET_PRECINCT,
    SET_PRECINCT_ID,
    SET_PLOT_NUMBER,
    SET_PROJECT_NAME,
    ADD_PHONE_NUMBER,
    ADD_EMAIL,
    ADD_ADDRESS,
    REMOVE_EMAIL,
    REMOVE_PHONE_NUMBER,
    REMOVE_ADDRESS,
    REMOVE_COMMENT,
    ADD_COMMENT,
    SET_DATE,
    RESET_FORM,
    SET_NET,

    SET_CUSTOM_CUSTOMER,
    SET_CUSTOMER_SET,
    CLEAR_PHONE_NUMBERS,
    CLEAR_EMAILS,
    CLEAR_ADDRESSES,
    CLEAR_NEW_ADDRESSES,
    CLEAR_NEW_PHONE_NUMBERS,
    CLEAR_NEW_EMAILS,
    ADD_NEW_PHONE_NUMBER,
    ADD_NEW_EMAIL,
    ADD_NEW_ADDRESS,
    REMOVE_NEW_PHONE_NUMBER,
    REMOVE_NEW_EMAIL,
    REMOVE_NEW_ADDRESS,
    ADD_SERVICE_GEOD,
    REMOVE_SERVICE_GEOD,
    CHANGE_SERVICE_GEOD,
    ADD_SERVICE_TYPI,
    REMOVE_SERVICE_TYPI,
    CHANGE_SERVICE_TYPI,
    ADD_SERVICE_INDI,
    REMOVE_SERVICE_INDI,
    CHANGE_SERVICE_INDI,
    ADD_SERVICE_COND,
    REMOVE_SERVICE_COND,
    CHANGE_SERVICE_COND,
    ADD_SERVICE_SANI,
    REMOVE_SERVICE_SANI,
    CHANGE_SERVICE_SANI,
    ADD_SERVICE_GEOL,
    REMOVE_SERVICE_GEOL,
    CHANGE_SERVICE_GEOL,
    SET_STEP,
    SET_TYPICAL_PROJECTS_OPEN,
    SET_INDIVIDUAL_PROJECTS_OPEN,
    SET_GEODESY_OPEN,
    SET_GEOLOGY_OPEN,
    SET_CONDITIONS_OPEN,
    SET_SANITARY_INSTALLATIONS_OPEN,
    ARRAY_CHANGE,
    SET_SUPERVISOR_GEOD,
    SET_SUPERVISOR_GEOL,
    SET_SUPERVISOR_INDI,
    SET_SUPERVISOR_TYPI,
    SET_SUPERVISOR_SANI,
    SET_SUPERVISOR_COND
}
export default types