import { Paper, Typography } from "@material-ui/core"
import { useSelector } from "react-redux"

const LogTimer = () => {
    const timeLeft = useSelector(state => state.log.timeLeft)
    
    return(
        <div style={{flexGrow:1}}>
            <Paper style={{
                border:"solid #8fb5e9 1px",
                float:"left",
                paddingTop:2,
                paddingBottom:2,
                paddingLeft:8,
                paddingRight:8,
                marginLeft:'4%',
                display:"flex"
            }}>
                {
                    timeLeft?
                    <>
                    <Typography style={{
                        fontWeight:500,
                        color:'#0750b5',
                    }}>
                        {timeLeft.hours+"h "+timeLeft.minutes+"m"}
                    </Typography>
                    <Typography style={{
                        color:'#ffab00',
                        marginLeft:4,
                    }}>
                        {timeLeft.seconds+"s"}
                    </Typography>
                    </>
                    :
                    <Typography style={{
                        fontWeight:500,
                        color:'#0750b5',
                    }}>
                        Ładowanie
                    </Typography>
                }
            </Paper>
        </div>
        
    )
}

export default LogTimer