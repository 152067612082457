import React, { useEffect, useState } from "react";
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useGroupBy,
  usePagination,
  useSortBy, 
  useExpanded,
} from "react-table";
// A great library for fuzzy filtering/sorting items
import {matchSorter} from "match-sorter";
import { 
  Button,
  CircularProgress,
  Dialog, 
  FormControl, 
  IconButton, 
  MenuItem, 
  Select, 
  TextField, 
  withStyles,
  DialogContent,
  DialogTitle,
  useTheme,
  DialogActions,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
 } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import "../style.css";
import { connect } from "react-redux";
import actions from "../duck/actions";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { serverUrl } from "../../config/serverConfig";
import GeodesyDialog from "./GeodesyDialog";
import { useHistory, useLocation } from "react-router";
import { Grid } from "@material-ui/core";

const RowRadioGroup = withStyles({
  root: {
    flexDirection:"row"
  },
})(RadioGroup);


const useStyles = makeStyles({
  root: {
    padding: '0px'
  },
});

const mapStateToProps = (state) =>{
 //console.log(state.filters.sanitaryTable.sortedColumn)
  return{
    pageNum: state.filters.geodesyTable.pagination.page,
    onPage: state.filters.geodesyTable.pagination.onPage,
    filters: state.filters.geodesyTable.columnFilters,
    globalFilterVal: state.filters.geodesyTable.globalFilter,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    addFilter : (obj) => dispatch(actions.addFilterGeodesy(obj)),
    addGlobalFilter : (obj) => dispatch(actions.addGlobalFilterGeodesy(obj)),
    addSortedColumn : (obj) => dispatch(actions.addSortedColumnGeodesy(obj)),
    resetAllFilters : () => dispatch(actions.resetFiltersGeodesy()),
    setPage: (obj) => dispatch(actions.setPageGeodesy(obj)),
    setOnPage: (obj) => dispatch(actions.setOnPageGeodesy(obj)),
  }
}

        
const SelectColumnFilter = connect(null,mapDispatchToProps)(({
  column: { filterValue, setFilter, preFilteredRows, id },addFilter
})=>{
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  let selectValue;
  if(filterValue==null){
    selectValue=""
  }else{
    selectValue=filterValue;
  }
  // Render a multi-select box
  return (
    <FormControl style={{width:'100%'}} variant="outlined">
    <Select
      style={{backgroundColor:'white',borderRadius:'4px'}}
      value={selectValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
        addFilter({value:e.target.value,id:id})
      }}
    >
      <MenuItem value="">Wszystkie</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
    </FormControl>
  );
})

const GlobalFilter = connect(mapStateToProps,mapDispatchToProps)(({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  addGlobalFilter,
  globalFilterVal,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(()=>{
    setGlobalFilter(globalFilterVal)
    setValue(globalFilterVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[globalFilter])
  return (
      <TextField
        variant="outlined"
        InputLabelProps={{style:{color:'#0750b5'}}}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          addGlobalFilter({value:e.target.value})
        }}
        label={`Wyszukaj cokolwiek z ${count} prac`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          backgroundColor:'white',
          borderRadius:'4px',
          width:'40%'
        }}
      />
  );
})
// Our table component
const Table = connect(mapStateToProps,mapDispatchToProps)
      (({columns,data,isLoading,onChange,filters,addFilter,globalFilterVal,resetAllFilters,onChangeDataFilter,pageNum,onPage,setPage,setOnPage}) =>{
  //console.log("table-renderd")

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id }
}) {
  const count = preFilteredRows.length;

  return (
    <TextField
      variant="outlined"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        addFilter({value:e.target.value,id:id})
      }}
      label={`${count} prac`}
      style={{backgroundColor:'white',borderRadius:'4px'}}
    />
  );
}

const skipPageResetRef = React.useRef(true)

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;


  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setSortBy,
    setGroupBy,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    toggleAllRowsExpanded,
  } = useTable(
    {
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      disableMultiSort:true,
      initialState: { 
        pageIndex: pageNum,
        pageSize: onPage, 
        hiddenColumns:["serviceId"],
        filters:filters,
        globalFilter:globalFilterVal,
        sortBy:[],
        //groupBy:["orderId"],
     },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useGroupBy,
    useSortBy,
    useExpanded, 
    usePagination,
    
  );

  useEffect(()=>{
   gotoPage(0)
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[globalFilterVal,filters,state.groupBy])
   //console.log(state)
  const StyledButton = withStyles({
    root: {
      background: 'white',
      borderRadius: 3,
      border: 0,
    },
    label: {
      textTransform: 'capitalize',
    },
  })(Button);

  const theme = createMuiTheme({
    overrides: {
      MuiTableCell: {
        body: {
          "&:first-child": {
            fontWeight:"bold"
        }
      }
    },
    MuiTable: {
      root:{
        tableLayout:"fixed",
      },
    }}
  });
  
  const handleRowClick = (object) => {
    
    if(object.original!==undefined){
      //console.log(object.original)
      onChange(object.original)
    }
  }
  
  const [dataFilterVal,setDataFilterVal] = useState("actual")
  
  const resetFilters = () => {
    resetAllFilters()
    setAllFilters([])
    setGlobalFilter("")
    setSortBy([])
    setGroupBy([])
    setDataFilterVal('actual')
    onChangeDataFilter('actual')
    gotoPage(0)

  }

  

  const handleDataFilterChange = (e) => {
    setDataFilterVal(e.target.value)
    onChangeDataFilter(e.target.value)
    gotoPage(0)
  }

  const classes = useStyles() 
  
  const checkSort = (column) =>{
    
   // console.log("odpalenie funkcji",column.id)
    if(column.isSorted){
      if(column.isSortedDesc){
       // console.log(column.id," DESC")
        return ' 🔽'
      }else{
       // console.log(column.id," ASC")
        return ' 🔼'
      }
    }else{
     // console.log(column.id,"none")
      return("")
    }
    
  }

  useEffect(()=>{
    setPage(pageIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageIndex])

  useEffect(()=>{
    setOnPage(pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageSize])
  return (
    
    <div style={{backgroundColor:'rgb(210 210 210)',paddingBottom:'100px',minHeight:'100vh'}}>
      <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={9} style={{marginLeft:'auto',marginRight:'auto',overflowX:"auto"}}>
     <MuiThemeProvider theme={theme}>
      <MaUTable className="mainTable" style={{marginLeft:'auto',marginRight:'auto',width:'95%',minWidth:'1500px'}} {...getTableProps()}>
        <colgroup>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'30px'}}/>
        <col style={{width:'15px'}} />
        </colgroup>
        <TableHead>
          
            <TableRow>
              
              <TableCell
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "center",
                  border:'none',
                }}
              >
                <RowRadioGroup className="filtersPack" style={{width:"60%"}} name="filter" value={dataFilterVal} onChange={(e)=>{handleDataFilterChange(e)}}>
                  <Paper className="filterRadio"><FormControlLabel value="actual" control={<Radio size="small" />} label="Aktualne prace"/></Paper>
                  <Paper className="filterRadio"><FormControlLabel value="ended" control={<Radio size="small"/>} label="Zakończone prace"/></Paper>
                  <Paper className="filterRadio"><FormControlLabel value="suspended" control={<Radio size="small"/>} label="Z zawieszonych zleceń"/></Paper>
                </RowRadioGroup>

                <GlobalFilter 
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <Button style={{height:'56px',marginLeft:'15px'}} variant="contained" color="secondary" onClick={()=>{resetFilters()}}>Wyczyść filtry</Button>
              
                

              </TableCell>
             
            </TableRow> 
          
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <td style={{textAlign:"center",border:'none'}} {...column.getHeaderProps()}>
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <>
                      <StyledButton variant="contained" 
                      size="small" 
                      style={{marginBottom:'5px'}}
                      {...column.getGroupByToggleProps()}
                      >
                        GRUPUJ
                        {column.isGrouped ? '🛑 ' : '👊 '}
                      </StyledButton>
                      {column.isGrouped ? 
                      <IconButton onClick={() => toggleAllRowsExpanded()}>
                        <ArrowDropDownIcon  />
                      </IconButton>
                      :
                      null
                      }</>
                    ) : null}
                  </td>
                ))}
              </TableRow>
          ))}
          {headerGroups.map((headerGroup) => (
            <TableRow style={{backgroundColor: '#0750b5',}} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell className={classes.root}
                  
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span style={{
                        fontSize: '1.12rem',
                        fontFamily: 'Arial',
                        width: '100%',
                        textAlign: 'center',
                        display: 'block',
                        color:'white',
                        paddingBottom:'16px',
                        paddingTop:'16px',
                  }}
                  >{column.render("Header")}
                    {/* Render the columns filter UI */}
                      {checkSort(column)}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
          ))}
          {headerGroups.map((headerGroup) => (
            <TableRow style={{backgroundColor:'#e8e8e8',}} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </TableCell>
                ))}
              </TableRow>
          ))}
        </TableHead>

        <TableBody style={{backgroundColor:'white'}} {...getTableBodyProps()}>
          {!isLoading?page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow className="tabRow" onClick={() => handleRowClick(row)}  disabled={!canPreviousPage} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                    if(cell.column.id==="statusId"){
                      
                      //console.log(cell)
                      //console.log(row)
                      //console.log(state)
                      let color=null
                      if(state.groupBy.length===0){
                        color=cell.row.original.color
                      }else{
                        if(row.canExpand===false){
                          //console.log(cell.row.allCels)
                           color=cell.row.allCells[9].row.original.color
                        }
                       
                      }
                        return (
                          <TableCell style={{
                            //backgroundColor:cell.row.original.color,
                            borderRight:'1px solid rgba(224, 224, 224, 1)',
                            fontSize:'1rem',
                            fontFamily:'Arial',
                            textAlign:'center',
                            backgroundColor:color,
                          }} {...cell.getCellProps()}>
                            {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? '👇' : '👉'}
                              {' '}
                              {cell.render('Cell')} ({row.subRows.length})</span>
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render('Aggregated')
                          ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render('Cell')
                          )}

                          </TableCell>
                        )
                      }else{
                        return (
                          <TableCell style={{
                            //backgroundColor:cell.row.original.color,
                            borderRight:'1px solid rgba(224, 224, 224, 1)',
                            fontSize:'1rem',
                            fontFamily:'Arial',
                            textAlign:'center',
                          }} {...cell.getCellProps()}>
                            {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                              <span className="groupedSpan" {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? <ExpandMoreIcon color="secondary"/> : <SubdirectoryArrowRightIcon color="secondary"/>}
                              {' '}
                              {cell.render('Cell')} ({row.subRows.length})
                              </span>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render('Aggregated')
                          ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render('Cell')
                          )}
    
                          </TableCell>
                        )
                      }
                 
                })}
              </TableRow>
            );
          }):
              <TableRow>
            <TableCell 
              colSpan={visibleColumns.length}
              style={{
                textAlign: "center"
              }}
            >
              <CircularProgress color="secondary" />
            </TableCell>
          </TableRow>
          }
         
        </TableBody>
        
      </MaUTable>
      
      <div className="paginationContainer">
        
        <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <SkipPreviousIcon  />
        </IconButton>
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateNextIcon style={{transform:'rotateY(-180deg)'}}/>
        </IconButton>
        <span>
          Strona {' '}
          <strong>
            {pageIndex + 1} z {pageOptions.length}
          </strong>{' '}
        </span>
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNextIcon  />
        </IconButton>
        <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <SkipNextIcon  />
        </IconButton>
       
        <Select className="pageSize"

          variant="outlined"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 30, 50, 100, 200].map(pageSize => (
            <MenuItem key={pageSize} value={pageSize}>
              Pokaż {pageSize}
            </MenuItem>
          ))}
        </Select>
      </div>
      </MuiThemeProvider>
      </Grid></Grid>
    </div>
    
  );
  
})



const Geodesy = connect(
  state => ({
      jwt: state.log.jwt,
  })
  )(({jwt})=>{

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "serviceId",
        id:"serviceId",
        // Use our custom `fuzzyText` filter on this column
      },
      {
        Header: "Kod zlecenia",
        accessor: "orderCode",
        id:"orderId",
        // Use our custom `fuzzyText` filter on this column
      },
      {
        Header: "KERG",
        accessor: row=>{
          if(row.kergNumber){
            return row.kergNumber
          }else{
            return "--"
          }
        },
        id:"kergNumber",
      },
      {
        Header: "Data przyjęcia",
        accessor: row=>{
          return((row.entryDate).toString().substr(0,16))
        },
        id:"entryDate",
      },
      {
        Header: "PODGiK",
        accessor: row=>{
          if(row.podgikDate){
            return(row.podgikDate).toString().substr(0,10)
          }else{
            return "--"
          }
        },
        id:"podgikDate",
      },
      {
        Header: "Inwestor",
        accessor: "customerName",
        id: "customerName",
      },
      {
        Header: "Gmina",
        accessor: "community",
        Filter: SelectColumnFilter,
        filter: "includes",
        id: "community",
      },
      {
        Header: "Obręb",
        accessor: "precinct",
        Filter: SelectColumnFilter,
        filter: "includes",
        id: "precinct",
      },
      {
        Header: "Nr. działki",
        accessor: "plotNumber",
        id: "plotNumber",
      },
      {
        Header: "Usługa",
        accessor: "serviceName",
        Filter: SelectColumnFilter,
        filter: "includes",
        id: "serviceName",
      },
      {
        Header: "Status",
        accessor: "statusName",
        Filter: SelectColumnFilter,
        filter: "includes",
        id: "statusId",
      },
      {
        Header: "Uwagi",
        accessor: row => {
          if(row.comments.length===0){
            return(<center><Avatar style={{backgroundColor:"rgb(202 202 202)",fontSize:"1.3rem"}}>{row.comments.length}</Avatar></center>)
          }
          if(row.comments.length>0){
            return(<center><Avatar style={{backgroundColor:"rgb(76 175 80)",fontSize:"1.3rem"}}>{row.comments.length}</Avatar></center>)
          }

          
        },
        id:"comments",
        disableFilters:true,
        disableSortBy:true,
        disableGroupBy:true,
      }
    ],
    []
  );
  
  //const [isUpdating] = useState(true);
  const history = useHistory()
  const location = useLocation()
  const [refresh,setRefresh] = useState(0)
  const [data,setServices] = useState([]);
  const [isLoading,setIsLoading] = useState(true);
  const token="Bearer ".concat(jwt)
  const [option,setOption] = useState("actual")

  useEffect(()=>{
    if(location.state===undefined||location.state===null){
    }else{
      handleChange({
        orderId:location.state.orderId,
        serviceId:location.state.serviceId,
        serviceInfoId:location.state.serviceInfoId
      })
    }
    
  },[location])

  useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/services/geodesy/${option}`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        setServices(json);
        setIsLoading(false);
       // setIsLoadingIcon(false);
      })
      .catch(error => {
        console.log(error);
      })
    }
    if (isLoading||refresh>0) {
      // if the result is not ready so you make the axios call
      //console.log("wyłączony stan ",skipPageResetRef)
      getData();
      setRefresh(0);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refresh])

  const [orderId, setOrderId] = useState()
  const [serviceId, setServiceId] = useState()
  const [serviceInfoId, setServiceInfoId] = useState()
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  function handleChange(obj) {
      setOrderId(obj.orderId)
      setServiceId(obj.serviceId)
      setServiceInfoId(obj.serviceInfoId)
      setOpen(true)
  }

  function handleChangeInfos() {
    //setIsLoading(false)
    setRefresh(1+refresh)
  }

  function handleDataFilterChange(filterValue) {
    //setIsLoading(false)
    if(filterValue==="ended"){
      setOption("ended")
    }
    if(filterValue==="suspended"){
      setOption("suspended")
    }
    if(filterValue==="actual"){
      setOption("actual")
    }
    setRefresh(1+refresh)
  }

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false)
    handleChangeInfos()
    if(location.state!==undefined&&location.state!==null){
      history.push("/geodesy")
    }
  };

  return (
    <>
      
      <Table data={data} columns={columns} isLoading={isLoading} onChange={handleChange} onChangeDataFilter={(e) => handleDataFilterChange(e)}/>
      <Dialog 
       maxWidth="lg"
       fullWidth={true}
       fullScreen={fullScreen} 
       open={open}
       onClose={handleClose}
      >
        <DialogTitle>Karta Usługi:</DialogTitle>
        <DialogContent className="dialogMid">
          <GeodesyDialog onChange={handleChangeInfos}
          orderId={orderId}
          serviceId={serviceId}
          serviceInfoId={serviceInfoId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zakończ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
})


 export default (Geodesy)
