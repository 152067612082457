import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog"
import { getUserLeaves } from "../duck/operations"
import NewRequestDialog from "../newRequest/NewRequestDialog"
import LeaveUserPanel from "../LeaveUserPanel"
import LeavesTable2 from "./LeavesTable2"
import moment from "moment"
import StatusBar from "../table/StatusBar"
import Button from "./Button"
import UserYearDialog from "./UserYearDialog"



const MyLeavesTable = () => {

    const dispatch = useDispatch()
    const leaves = useSelector(state => state.leaves.userLeaves)
    const isLoading = useSelector(state => state.leaves.loadingUserLeaves)
    const error = useSelector(state => state.leaves.fetchUserLeavesError)
    

    useEffect(() => {
        dispatch(getUserLeaves())
    },[])

    //console.log('table rerender')

    const colGroup = useMemo(
        ()=>
            <colgroup>
            <col width={'5%'}/>
            <col width={'15%'}/>
            <col width={'12%'}/>
            <col width={'12%'}/>
            <col width={'4%'}/>
            <col width={'26%'}/>
            <col width={'21%'}/>
            <col width={'5%'}/>
            </colgroup>
        ,
    []
    )
    

    const columns = useMemo(
        () => [
                {
                    Header: 'ID',
                    accessor: ((row)=>
                        row.leaveId
                    ),
                    id:1
                },
                {
                    Header: 'Data złożenia',
                    accessor: ((row)=>
                        moment.utc(row.submissionDate).local().format('YYYY-MM-DD HH:mm')
                    ),
                    id:2
                },
                {
                    Header: 'Data od:',
                    accessor: ((row)=>
                        moment.utc(row.startDate).local().format('YYYY-MM-DD')
                    ),
                    id:3
                },
                {
                    Header: 'Data do:',
                    accessor: ((row)=>
                        moment.utc(row.endDate).local().format('YYYY-MM-DD')
                    ),
                    id:4
                },
                {
                    Header:'Dni',
                    accessor: ((row)=>
                        <span style={{color:"green"}}>{moment(row.endDate).diff(row.startDate,'days')+1}</span>
                    ),
                    id:5
                },
                {
                    Header: 'Uwagi',
                    accessor: ((row)=>
                    row.comment
                    ),
                    id:6
                },
                {
                    Header: 'Status',
                    accessor: ((row)=>
                        <StatusBar statusId={row.statusId} statusName={row.statusName}/>
                    ),
                    id:7
                },
                {
                    Header: '',
                    accessor: ((row)=>
                        <Button statusId={row.statusId} leaveId={row.leaveId}/>
                    ),
                    id:8
                }
            ],
        []
      )

     return(
        <LeaveUserPanel>
        <UserYearDialog/>
        <div style={{marginLeft:'auto',marginRight:'auto',overflowX:"auto",marginTop:20}}>
        <LeavesTable2 columns={columns} data={leaves} isLoading={isLoading} error={error} colGroup={colGroup} colQuant={8}/>
        <DeleteConfirmationDialog/>
        <NewRequestDialog/>
        </div>
        </LeaveUserPanel>
     )
}


export default (MyLeavesTable)