import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './style.css'
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button, Grid, IconButton, TextField, useMediaQuery } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { serverUrl } from '../config/serverConfig';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import DialogPay from './DialogPay';
import DialogUnPay from './DialogUnPay';
import DialogDel from './DialogDel';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useHistory } from 'react-router';
import StatusesHistoryTable from './StatusesHistoryTable';
import PaymentHistoryTable from './PaymentHistoryTable';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import AddServices from './AddServices';

const useRowStyles = makeStyles({
  root: {
      
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: "1.1rem",
        backgroundColor:"rgb(7, 80, 181)",
        color:"white",
      },
    body: {
      fontSize: "1.1rem",
    },
    root: {
        fontFamily:"arial",
    },
  }))(TableCell);

  const StyledTableCellSmall = withStyles((theme) => ({
    head: {
        fontSize: "1rem",
        backgroundColor:"rgb(7, 80, 181)",
        color:"white",
        textAlign:"center",
      },
    body: {
      fontSize: "1rem",
      textAlign:"center",
    },
    root: {
        fontFamily:"arial",
    },
  }))(TableCell);

  const GreenButton = withStyles((theme) => ({
    root: {
        backgroundColor:"#43a047",
        color:"white",
        '&:hover': {
            backgroundColor: '#66b66a',
          },
        paddingBottom:"8px",
        paddingTop:"8px",
    },
  }))(Button);

  const OrangeButton = withStyles((theme) => ({
    root: {
        backgroundColor:"#FF9800",
        color:"white",
        '&:hover': {
            backgroundColor: '#ffac31',
          },
        paddingBottom:"8px",
        paddingTop:"8px",
    },
  }))(Button);

  const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }

const Row = connect(mapStateToProps,null)((props) => {
  const { row } = props
  const { orderId } = props
  const { jwt } = props
  const token="Bearer ".concat(jwt)
  const [open, setOpen] = React.useState(false);
  const [openDialogPay, setOpenDialogPay] = React.useState(false);
  const [openDialogUnPay, setOpenDialogUnPay] = React.useState(false);
  const [openDialogDel, setOpenDialogDel] = React.useState(false);
  const [amountBase,setAmountBase] = useState(row.paidAmount)
  const [hoursBase,setHoursBase] = useState("")
  const [amountValue,setAmountValue] = useState(row.paidAmount)
  const [hoursValue,setHoursValue] = useState(row.paidAmount)
  const [priceValue,setPriceValue] = useState(row.price)
  const [priceBase,setPriceBase] = useState(row.price)
  const classes = useRowStyles();
  const color1="#ffe6e6"
  const color3="#f2ffe5"
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory()
  const [refresh,setRefresh] = useState(0)

  useEffect(()=>{
    if(row.customHours===null){
      setHoursBase(row.defaultHours)
      setHoursValue(row.defaultHours)
    }else{
      setHoursBase(row.customHours)
      setHoursValue(row.customHours)
    }
    setAmountValue(row.paidAmount)
    setAmountBase(row.paidAmount)
    setPriceValue(row.price)
    setPriceBase(row.price)
    console.log(row)
  },[row])

  async function payService(value,amount) {
    await fetch(`${serverUrl}/services/${row.serviceId}/paid?paid=${value}&amount=${amount}&price=${amount}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        if(value===true){
          enqueueSnackbar("Pomyślnie rozliczono usługę",{variant: 'success',autoHideDuration: 2000})
        }else{
          enqueueSnackbar("Pomyślnie cofnięto rozliczenie usługi",{variant: 'success',autoHideDuration: 2000})
        }
        setRefresh(refresh+1)
        props.refresh()
        setAmountValue(row.paidAmount)
        setAmountBase(row.paidAmount)
        setPriceValue(row.price)
        setPriceBase(row.price)
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }

  async function changePrice(value,amount) {
    await fetch(`${serverUrl}/services/${row.serviceId}/price?price=${value}&amount=${amount}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie zmieniono wartość usługi",{variant: 'success',autoHideDuration: 2000})
        setRefresh(refresh+1)
        props.refresh()
        setAmountValue(row.paidAmount)
        setAmountBase(row.paidAmount)
        setPriceValue(row.price)
        setPriceBase(row.price)
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }

  async function changeAmount(value,price) {
    await fetch(`${serverUrl}/services/${row.serviceId}/paid-amount?amount=${value}&price=${price}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie zmieniono zapłaconą kwotę",{variant: 'success',autoHideDuration: 2000})
        setRefresh(refresh+1)
        props.refresh()
        setAmountValue(row.paidAmount)
        setAmountBase(row.paidAmount)
        setPriceValue(row.price)
        setPriceBase(row.price)
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }

  async function changeHours(value) {
    await fetch(`${serverUrl}/services/${row.serviceId}/custom-hours?hours=${value}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie zmieniono r-godziny pracy",{variant: 'success',autoHideDuration: 2000})
        props.refresh()
        setAmountValue(row.paidAmount)
        setAmountBase(row.paidAmount)
        setPriceValue(row.price)
        setPriceBase(row.price)
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }
  
  async function deleteService() {
    await fetch(`${serverUrl}/services/${row.serviceId}`,
    {method:'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie usunięto usługę",{variant: 'success',autoHideDuration: 2000})
        props.refresh()
        setAmountValue(row.paidAmount)
        setAmountBase(row.paidAmount)
        setPriceValue(row.price)
        setPriceBase(row.price)
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }

  const paidCheck = (paid,priceDefined,price,paidAmount) => {
    if(priceDefined===true){
        if(paid===true){
            return(<StyledTableCell align="center" className="servicesTableBorderRight" style={{color:"green",fontSize:"1.3rem",fontWeight:"bold"}}>TAK</StyledTableCell>)
        }else{
            if(paidAmount>0){
                return(<StyledTableCell align="center" className="servicesTableBorderRight" style={{fontSize:"1.3rem"}}>{paidAmount}zł</StyledTableCell>)
            }else{
                return(<StyledTableCell align="center" className="servicesTableBorderRight" style={{color:"red",fontSize:"1.3rem",fontWeight:"bold"}}>NIE</StyledTableCell>)
            }
        }
    }else{
        return(<StyledTableCell align="center" className="servicesTableBorderRight" style={{color:"red",fontSize:"1.3rem",fontWeight:"bold"}}>NIE</StyledTableCell>)
    }
    
  }

  const handlePayClick = (serviceId) =>{
    console.log(serviceId)
    setOpenDialogPay(true)
  }

  const handleUnPayClick = (serviceId) =>{
    console.log(serviceId)
    setOpenDialogUnPay(true)
  }

  const handleDelete = (serviceId) =>{
    setOpenDialogDel(true)
  }

  const handlePayAmount = (serviceId) =>{
    if(amountValue>row.price){
      enqueueSnackbar(`Wprowadzona kwota (${amountValue}) nie może być wyższa niż wartość usługi (${row.price}).`,{variant: 'warning',autoHideDuration: 4000})
    }else{
      if(amountValue===row.price.toString()){
        payService(true,row.price)
      }else{
        changeAmount(amountValue,row.price)
      }
    }
  }

  const handleHoursChange = () =>{
    changeHours(hoursValue)
  }

  const handlePriceChange = () =>{
    if(row.paid===true){
      enqueueSnackbar(`Nie można zmieniać wartości rozliczonej usługi (Możesz cofnąć rozliczenie i zmienić kwoty).`,{variant: 'warning',autoHideDuration: 5000})
    }else{
      if(priceValue<row.paidAmount){
        enqueueSnackbar(`Wartość usługi nie może być mniejsza od zapłaconej kwoty! Przed zmianą kwoty zmień zapłaconą kwotę na mniejszą.`,{variant: 'warning',autoHideDuration: 4000})
      }else{
        changePrice(priceValue,row.paidAmount)
      }
    }
  }

  const closeDialogPay = (confirmed) =>{
    setOpenDialogPay(false)
    if(confirmed===true){
      payService(true,row.price)
      props.refresh()
    }
  }

  const closeDialogUnPay = (confirmed) =>{
    setOpenDialogUnPay(false)
    if(confirmed===true){
      payService(false,row.price)
      props.refresh()
    }
  }

  const closeDialogDel = (confirmed) =>{
    setOpenDialogDel(false)
    if(confirmed===true){
      deleteService()
      props.refresh()
    }
  }

  const handleRedirect = (e) =>{
    e.stopPropagation() 
    
    switch(row.departmentId){
      case 1:
        //console.log(history);
        history.push({
          pathname: '/geodesy',
          state: {
            orderId: orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;
        
      case 2:
        history.push({
          pathname: '/typical-projects',
          state: { 
            orderId: orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;

      case 3:
        history.replace({
          pathname: '/individual-projects',
          state: { 
            orderId: orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
            projectId: row.projectId
          }
        })
      break;

      case 4:
        history.push({
          pathname: '/sanitary-installations',
          state: { 
            orderId: orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;

      case 5:
        history.push({
          pathname: '/conditions',
          state: { 
            orderId: orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
            conditionsId: row.conditionsId
          }
        })
      break;

      case 6:
        history.push({
          pathname: '/geology',
          state: { 
            orderId: orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;

      default: return null;
    }
  } 

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} className={classes.root} style={{backgroundColor:row.ended?color3:color1}}>
        <StyledTableCell align="center" className="servicesTableBorderRight" component="th" scope="row" style={{backgroundColor:"white"}}>
          <IconButton>
            <DoubleArrowIcon onClick={(e)=>handleRedirect(e)}/>
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="center" className="servicesTableBorderRight" component="th" scope="row">
          {row.serviceName}
        </StyledTableCell>
            {paidCheck(row.paid,row.priceDefined,row.price,row.paidAmount)}
        <StyledTableCell align="center" className="servicesTableBorderRight">{row.priceDefined?row.price.toString().concat("zł"):"nieznana"}</StyledTableCell>
        <StyledTableCell align="center" className="servicesTableBorderRight">
            {row.payingUser===null?<span style={{color:"red"}}>--</span>:row.payingUser}
        </StyledTableCell>
        {row.ended===true?
        <StyledTableCell align="center" className="servicesTableBorderRight"><CheckCircleOutlineIcon style={{fontSize:"2rem",color:"green"}}/></StyledTableCell>
        :
        <StyledTableCell align="center" className="servicesTableBorderRight"><HighlightOffIcon style={{fontSize:"2rem",color:"red"}}/></StyledTableCell>
        }
        <StyledTableCell align="center" className="servicesTableBorderRight">{row.customHours===null?row.defaultHours:row.customHours}h.</StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid spacing={2} container style={{paddingTop:"10px",paddingBottom:"10px"}}>
              <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
              <TableContainer component={Paper}>
                    <Table size="small" style={{backgroundColor:"#0750b5"}}>
                        <TableBody>
                        <TableRow>
                            <StyledTableCellSmall style={{color:"white"}}>Data dodania:</StyledTableCellSmall>
                            <StyledTableCellSmall style={{color:"white"}}>{(row.entryDate).toString().substr(0,16)}</StyledTableCellSmall>
                        </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
              </Grid>
              <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
              <TableContainer component={Paper}>
                    <Table size="small" style={{backgroundColor:"#0750b5"}}>
                        <TableBody>
                        <TableRow>
                            <StyledTableCellSmall style={{color:"white"}}>Dodano przez:</StyledTableCellSmall>
                            <StyledTableCellSmall style={{color:"white"}}>{row.enteringUser}</StyledTableCellSmall>
                        </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
              </Grid> 
              <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
              <DialogPay open={openDialogPay} closeDialog={closeDialogPay}></DialogPay>
              <DialogUnPay open={openDialogUnPay} closeDialog={closeDialogUnPay}></DialogUnPay>
              <DialogDel open={openDialogDel} closeDialog={closeDialogDel}></DialogDel>
                {!row.paid?
                <GreenButton onClick={()=>handlePayClick(row.serviceId)} fullWidth={true}>Rozlicz</GreenButton>
                :
                <OrangeButton onClick={()=>handleUnPayClick(row.serviceId)} fullWidth={true}>cofnij rozliczenie</OrangeButton>
                }
              </Grid>
              <Grid xs={12} sm={6} md={6} lg={6} xl={6} item>
                <Button onClick={()=>{handleDelete()}} fullWidth={true} color="secondary" variant="contained" style={{backgroundColor:"#db0000",paddingTop:"8px",paddingBottom:"8px"}}>Usuń pracę</Button>
              </Grid>
              <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
                <Paper>
                <TextField 
                 onChange={(e)=>{setAmountValue(e.target.value)}} 
                 variant="outlined" 
                 label="Zapłacona kwota" 
                 size="small" 
                 style={{width:"85%"}}
                 value={amountValue}
                 type="number"
                 disabled={row.paid}/>
                <IconButton
                 disabled={amountValue.toString()===amountBase.toString()?true:false} 
                 onClick={()=>{handlePayAmount(row.serviceId)}} 
                 style={{width:'15%',padding:"0px",height:"40px"}}>
                    <SaveIcon style={{color:amountValue.toString()===amountBase.toString()?"grey":"green"}}/>
                </IconButton>
                </Paper>
              </Grid>
              <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
              <Paper>
                <TextField 
                onChange={(e)=>{setPriceValue(e.target.value)}} 
                variant="outlined" 
                label="Wartość usługi" 
                size="small" 
                style={{width:"85%"}}
                value={priceValue}
                type="number"/>
                <IconButton
                 style={{width:'15%',padding:"0px",height:"40px"}}
                 disabled={priceValue.toString()===priceBase.toString()?true:false} 
                 onClick={()=>{handlePriceChange()}} >
                    <SaveIcon style={{color:priceValue.toString()===priceBase.toString()?"grey":"green"}}/>
                </IconButton>
                </Paper>
              </Grid>
              <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
              <Paper>
                <TextField 
                onChange={(e)=>{setHoursValue(e.target.value)}} 
                variant="outlined" 
                label="Roboczo-godziny" 
                size="small" 
                style={{width:"85%"}}
                value={hoursValue}
                type="number"/>
                <IconButton
                 style={{width:'15%',padding:"0px",height:"40px"}}
                 disabled={hoursValue.toString()===hoursBase.toString()?true:false} 
                 onClick={()=>{handleHoursChange()}} >
                    <SaveIcon style={{color:hoursValue.toString()===hoursBase.toString()?"grey":"green"}}/>
                </IconButton>
                </Paper>
              </Grid>
              <Grid xs={12} sm={5} md={5} lg={5} xl={5} item>
                <StatusesHistoryTable serviceId={row.serviceId}/>
              </Grid>
              <Grid xs={12} sm={7} md={7} lg={7} xl={7} item>
                <PaymentHistoryTable key={refresh} refresh={refresh} serviceId={row.serviceId}/>
              </Grid>
              
          </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
})


function ServicesTable(props) {

  const [isLoading,setIsLoanding] = useState(true);
  const [data,setData] = useState([])
  const token='Bearer '.concat(props.jwt)
  const [refresh,setRefresh] = useState(0)
  const [open,setOpen] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  let paidSum=0
  let sum=0
  let hours=0
  useEffect(() => {
      async function getData() {
        await fetch(`${serverUrl}/orders/${props.orderId}/services`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(json => {
          //console.log(json);
          setIsLoanding(false);
          setData(json)
        })
        .catch(error => {
          
        })
      }
      if (isLoading||refresh>0) {
        getData()
        setRefresh(0)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const handleTableRefresh = () => {
      setRefresh(refresh+1)
    }

    const handleClose = () => {
      setOpen(false)
    }

  return (
    <>
    <TableContainer component={Paper}>
      <Table style={{minWidth:"800px"}} aria-label="collapsible table">
        <colgroup>
            <col style={{width:'14px'}}/>
            <col style={{width:'35px'}}/>
            <col style={{width:'23px'}}/>
            <col style={{width:'20px'}}/>
            <col style={{width:'30px'}}/>
            <col style={{width:'20px'}}/>
            <col style={{width:'20px'}}/>
        </colgroup>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{padding:0}}><IconButton size='medium' onClick={()=>setOpen(true)}><AddBoxIcon style={{color:'white',fontSize:'2.1rem'}}/></IconButton></StyledTableCell>
            <StyledTableCell align="center">Praca</StyledTableCell>
            <StyledTableCell align="center">Rozliczono</StyledTableCell>
            <StyledTableCell align="center">Kwota</StyledTableCell>
            <StyledTableCell align="center">Rozliczający</StyledTableCell>
            <StyledTableCell align="center">Zakończona</StyledTableCell>
            <StyledTableCell align="center">R. godzin</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            sum=sum+row.price
            paidSum=paidSum+row.paidAmount
            row.customHours===null?(hours=hours+row.defaultHours):(hours=hours+row.customHours);
            return <Row refresh={handleTableRefresh} orderId={props.orderId} key={row.serviceId} row={row} />
          })}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="center" style={{fontSize:'1.2rem'}}>{paidSum}zł</TableCell>
            <TableCell align="center" style={{fontSize:'1.2rem'}}>{sum}zł</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="center" style={{fontSize:'1.2rem'}}>{hours}h.</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Dialog 
      maxWidth="lg"
      fullWidth={true}
      fullScreen={fullScreen} 
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Dodaj prace:</DialogTitle>
      <DialogContent className="dialogMid">
        <AddServices tabRefresh={handleTableRefresh} orderId={props.orderId}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Zakończ
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}


export default connect(mapStateToProps,null)(ServicesTable)