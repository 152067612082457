import { Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverUrl } from "../../config/serverConfig";
import actions from "../duck/actions";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import NewEmail from "./NewEmail";

const mapStateToProps = (state) =>{
    return{
       customerId:state.newOrder.customerData.customerId,
       emails:state.newOrder.customerData.emails,
       newEmails:state.newOrder.customerData.newEmails,
       jwt:state.log.jwt
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return{
      addEmail: (obj) => dispatch(actions.addEmail(obj)),
      removeEmail: (obj) => dispatch(actions.removeEmail(obj)),
      removeNewEmail: (obj) => dispatch(actions.removeNewEmail(obj)),
    }
  }

const ListOption = connect(mapStateToProps,mapDispatchToProps)((props) =>{

    const {emailId} = props.item
    const {email} = props.item
    const {emails} = props
    const [checked,setChecked] = useState(false)

    useEffect(()=>{
        emails.forEach(element => {
            if(element.emailId===emailId){
                setChecked(true)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleChange = () => {
        if(checked!==true){
            props.addEmail({emailId:emailId,email:email})
        }else{
            props.removeEmail(emailId)
        }
        setChecked(!checked)
    }

    return(
        <>
        <ListItem onClick={()=>handleChange()} key={emailId} button style={{backgroundColor:"#f5f5f5"}}>
        <ListItemIcon>
            <AlternateEmailIcon style={{color:"#0750b5"}}/>
        </ListItemIcon>
        <ListItemText id={emailId} primary={email}></ListItemText>
        <ListItemSecondaryAction>
            <Checkbox
            edge="end"
            onChange={()=>handleChange()}
            checked={checked}
            
        />
        </ListItemSecondaryAction>
        </ListItem>
        <Divider style={{backgroundColor:"white",height:"5px"}}/>

        </>
    )

})

const NewListOption = connect(mapStateToProps,mapDispatchToProps)((props) =>{

    const {email} = props

    const handleDelete = () => { 
        props.removeNewEmail(email)
        props.refresh()
    }

    return(
        <>
        <ListItem key={email} style={{backgroundColor:"#d9ffda"}}>
        <ListItemIcon>
            <AlternateEmailIcon style={{color:"#43a047"}}/>
        </ListItemIcon>
        <ListItemText id={email} primary={email}></ListItemText>
        <ListItemSecondaryAction style={{right:0}}>
            <Checkbox
            edge="end"
            checked={true}
            disabled={true}
            />
        <IconButton onClick={()=>{handleDelete()}}>
            <DeleteForeverIcon style={{color:"red",fontSize:"1.8rem"}}/>
        </IconButton>
        </ListItemSecondaryAction>
        
        </ListItem>
        <Divider style={{backgroundColor:"white",height:"5px"}}/>

        </>
    )

})


const CustomerEmailsList = (props) => {
    
    const [data,setData] = useState([]);
    const token="Bearer ".concat(props.jwt)
    const [newEmails,setNewEmails] = useState(props.newEmails)

    useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/emails/customer?customerId=${props.customerId}`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        setData(json)
      })
      .catch(error => {
        console.log(error)
      })
    }
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.customerId])

    const refresh = () => {
        console.log(props.newEmails)
        setNewEmails([...props.newEmails])
    }

    return (
        <Grid container justify="center" style={{marginTop:"60px"}}> 
           
            <Grid item xs={12} sm={12} md={11} lg={11} xl={10}>
            <Typography className="infoText" style={{color:"green",textAlign:"left"}}>Adresy email</Typography>
            <List >
            {data.map((value) => {
                return (
                <ListOption key={value.emailId} item={value}/>
                );
            })}
            <Divider style={{backgroundColor:"white",height:"5px"}}/>
            {newEmails.map((value) => {
                return (
                <NewListOption refresh={refresh} key={value} email={value}/>
                );
            })}

            </List>

            <NewEmail refresh={refresh}/>
            </Grid>
        </Grid>

      );

}


  
  export default connect(mapStateToProps,mapDispatchToProps)(CustomerEmailsList)