import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Accordion, AccordionDetails, AccordionSummary, Badge, CircularProgress, Typography} from '@material-ui/core';
import { connect } from 'react-redux';
import { serverUrl } from '../../config/serverConfig';
import { useEffect, useState } from 'react';
import "../style.css"
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import actions from '../duck/actions';
import ServiceRowGeod from './ServiceRowGeod';

const GeodesyTable = (props) => {
    
    const [expanded,setExpanded] = useState(props.geodesy)
    const [services,setServices] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const token="Bearer ".concat(props.jwt)

    useEffect(() => {
        async function getData() {
        await fetch(`${serverUrl}/services-info?departmentId=1`,
        {method:'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(json => {
            setServices(json);
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
        })
        }
        if (isLoading) {
        getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        props.setGeodesyOpen(expanded)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[expanded])

    return(
        <Accordion onChange={()=>setExpanded(!expanded)} expanded={expanded} component={Paper}  >
        <AccordionSummary className="accordHead" expandIcon={<ExpandMoreIcon className="expandArrow"/>}>
            <Badge badgeContent={props.services.length} color="secondary" overlap="circle" anchorOrigin={{vertical: 'bottom',horizontal: 'right',}}>
                <LanguageIcon className="accordIcon"/>
            </Badge>
            <Typography align="center" className="accordTitle">GEODEZJA</Typography>
        </AccordionSummary>
        <AccordionDetails className="servicesAccordion">
        <TableContainer component={Paper}>
        <Table size="small">
            <TableHead className="servicesTableHead">
                <TableRow>
                    <TableCell className="headCell"></TableCell>
                    <TableCell align="center" className="headCell">Nazwa usługi</TableCell>
                    <TableCell align="center" className="headCell">Cena</TableCell>
                    <TableCell align="center" className="headCell">Cena nieznana</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isLoading===true?
                        <TableRow><TableCell colSpan={4}><CircularProgress/></TableCell></TableRow>
                    :
                        services.map(service=>{
                            let init={
                                serviceId:service.serviceInfoId,
                                price:"",
                                undefinedPrice:false,
                                selected:false,
                            };
                            props.services.forEach(element => {
                                
                                if(element.serviceId===service.serviceInfoId){
                                    init={
                                        serviceId:element.serviceId,
                                        price:element.price,
                                        undefinedPrice:element.undefinedPrice,
                                        selected:true,
                                    }
                                    
                                }else{
                                   
                                   
                                }
                            })
                            
                            return(
                                <ServiceRowGeod
                                key={service.serviceInfoId}
                                serviceName={service.serviceName}
                                serviceInfoId={service.serviceInfoId}
                                init={init}/>
                            )
                        })
                }
                
            </TableBody>
        </Table>
        </TableContainer>
        </AccordionDetails>
        </Accordion>
    )
}


const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
        services:state.newOrder.servicesGeod,
        geodesy:state.newOrder.geodesy,
        typicalProjects:state.newOrder.typicalProjects,
        individualProjects:state.newOrder.individualProjects,
        sanitaryInstallations:state.newOrder.sanitaryInstallations,
        conditions:state.newOrder.conditions,
        geology:state.newOrder.geology,
        change:state.newOrder.servicesChange,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setGeodesyOpen: (obj) => dispatch(actions.setGeodesyOpen(obj)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(GeodesyTable)