import { Checkbox, TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../departments/duck/actions';

const UsersTableElement = ({user,usersList,addUser,removeUser}) => {

    const [checked,setChecked] = useState(false)
    
    const handleCheck = (bool) => {
        //console.log(bool)
        if(bool){
            addUser(user.userId)
        }else{
            removeUser(user.userId)
        }
    }

    useEffect(()=>{
        //console.log('refresh',usersList)
        if(usersList.includes(user.userId)){
            setChecked(true)
        }else{
            setChecked(false)
        }
    },[usersList])

    return(
        <TableRow hover={true}>
            <TableCell align='left'>{user.name+" "+user.surname}</TableCell>
            <TableCell align='center'>
            <Checkbox 
                checked={checked}
                style={{padding:0}}
                color="primary"
                onChange={(e)=>handleCheck(e.target.checked)}/>
            </TableCell>
        </TableRow>
    )
}

const mapDispatchToProps = (dispatch) => {
    return{
        addUser: (obj) => dispatch(actions.addUser(obj)),
        removeUser: (obj) => dispatch(actions.removeUser(obj))
    }
}

const mapStateToProps = (state) =>{
    return{
        usersList:state.filters.reportsPdf.usersList,
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(UsersTableElement) 