import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MenuIcon from '@material-ui/icons/Menu';
import SmallUserView from "./SmallUserView";
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Button, ButtonBase } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import { logOut } from "../login/logOut";
import { RoleService } from "../RoleService";

function LeftMenu({logout}) {
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer = (open) => (event) => {
    
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, 'left': open });
  };

  const roleService = new RoleService()

  const menu =
    <div
      className="left"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{zIndex:'2'}}
    >
      
        <SmallUserView>
          
        </SmallUserView>
        <List>
          <Link to='/new-order' style={{ textDecoration: 'none',color:'inherit' }}><ListItem button key='Dodaj zlecenie' style={{paddingTop:'4px',paddingBottom:'4px'}}>
              <ListItemIcon>
                <AddBoxIcon style={{color:'#0c9c00', fontSize:'1.6rem'}}/>
              </ListItemIcon>
              <ListItemText primary='Dodaj zlecenie' />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link to='/reports' style={{ textDecoration: 'none',color:'inherit' }}> 
            <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
              <ListItemIcon>
                <FiberManualRecordIcon style={{color:'#ffab00', fontSize:'0.9rem'}}/>
              </ListItemIcon>
              <ListItemText primary="Raporty" />
            </ListItem>
          </Link>
        </List>
        <Divider />
      <List>
      {roleService.canGeodesy()?<Link to='/geodesy' style={{ textDecoration: 'none',color:'inherit' }}> <ListItem button key="Geodezja" style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#0750b5', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Geodezja" />
          </ListItem>
      </Link>:null}
      {roleService.canTypicalProjects()?<Link to='/typical-projects' style={{ textDecoration: 'none',color:'inherit' }}> <ListItem button key="Projekty typowe" style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#0750b5', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Projekty typowe" />
            </ListItem>
      </Link>:null}
      {roleService.canIndividualProjects()?<Link to='/individual-projects' style={{ textDecoration: 'none',color:'inherit' }}><ListItem button key="Projekty indywidualne" style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#0750b5', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Projekty indywidualne" />
        </ListItem>
      </Link>:null}
      {roleService.canSanitaryInstallations()?<Link to='/sanitary-installations' style={{ textDecoration: 'none',color:'inherit' }}><ListItem button key="Instalacje sanitarne" style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#0750b5', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Instalacje sanitarne" />
          </ListItem>
      </Link>:null}
      {roleService.canConditions()?<Link to='/conditions' style={{ textDecoration: 'none',color:'inherit' }}><ListItem button key="Warunki zabudowy" style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#0750b5', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Warunki zabudowy" />
          </ListItem>
      </Link>:null}
      {roleService.canGeology()?<Link to='/geology' style={{ textDecoration: 'none',color:'inherit' }}><ListItem button key="Geologia" style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#0750b5', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Geologia" />
          </ListItem>
      </Link>:null}
      </List>
      <Divider/>
      <List>
        <Link to='/all-orders' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Wszystkie zlecenia" />
          </ListItem>
        </Link>
        {roleService.hasOneOfRoles(["ADMIN","BOSS","ACCOUNTANT"])?<Link to='/all-services' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Zakończone prace" />
          </ListItem>
        </Link>:null}
        {roleService.hasOneOfRoles(["ADMIN","BOSS","ACCOUNTANT"])?<Link to='/history' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Historia" />
          </ListItem>
        </Link>:null}
        <Link to='/customers' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Klienci" />
          </ListItem>
        </Link>
        <Link to='/' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Kalendarz" />
          </ListItem>
        </Link>
        {roleService.hasOneOfRoles(["ADMIN","BOSS","STATS"])?<Link to='/stats' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Statystyki" />
          </ListItem>
        </Link>:null}
        {roleService.hasOneOfRoles(["ADMIN","BOSS"])?<Link to='/costs' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Koszty" />
          </ListItem>
        </Link>:null}
        <Divider/>
        <List>
        <Link to='/leave-user-panel' style={{ textDecoration: 'none',color:'inherit' }}> 
          <ListItem button style={{paddingTop:'4px',paddingBottom:'4px'}}>
            <ListItemIcon>
              <FiberManualRecordIcon style={{color:'#ab0000', fontSize:'0.9rem'}}/>
            </ListItemIcon>
            <ListItemText primary="Urlopy" />
          </ListItem>
        </Link>
        </List>
      </List>
      <div>

      </div>
    </div>;

  

    return(
        <div>
      
        <React.Fragment key={'left'}>
          <Button onClick={toggleDrawer(true)}><MenuIcon style={{ color: "white" }}/></Button>
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer(false)}
          >
            {menu}
            <div style={{marginTop:'75px',marginBottom:'15px'}} >
            <Button onClick={()=>{logout()}} variant="outlined" color="secondary" 
            style={{color:'#ab0000', borderColor:'color:#ab0000',marginLeft:'50px'}} >
                WYLOGUJ
            </Button>
            <Link to="/settings">
            <ButtonBase onClick={toggleDrawer(false)} style={{marginLeft:'35px'}}>
                <div><SettingsIcon style={{color:'gray'}}/></div>
            </ButtonBase>
            </Link>
            </div>
           
          </Drawer>
        </React.Fragment>
     
    </div>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => { dispatch(logOut()) } 
  }
}



export default connect(null,mapDispatchToProps)(LeftMenu)
