import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import font from "../fonts/arial.ttf"

Font.register({
  family: "Arial",
  format: "truetype",
  src: font 
});
const styles = StyleSheet.create({
    table: { 
        display: "table", 
        width: "80%",
        marginLeft:'5%',
        borderStyle: "solid", 
        marginTop:'10px',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        fontFamily:'Arial',
        textAlign:'left'
    },
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    },
    tableColL: { 
      width: "5%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },
    tableColR: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColM: { 
        width: "50%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColM2: { 
        width: "15%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColLHead: { 
        width: "70%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColRHead: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCell: {
      
      fontSize: 9 ,
      textAlign:'left',
      marginLeft:5
    },
    tableCellBot: {
        
        fontSize: 9 ,
        textAlign:'right',
        marginLeft:5
    },
    tableColNoBorder: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth:0 
    },
    tableCellHead: {
        marginTop: 1,
        marginBottom:1,
        fontSize: 10 ,
        textAlign:'left',
        marginLeft:5,
        fontWeight:'bold'
    },
    tableColLBot: { 
        width: "55%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColRBot: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColMBot: { 
        width: "15%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColRSum: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColLSum: { 
        width: "55%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColMSum: { 
        width: "15%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
});



const PdfServicesTable = ({data}) =>{
    console.log('dataCheck:',data)
    return(
        <>
        {data.geodesyServices.length>0?
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColLHead}>
                <Text style={styles.tableCellHead}>Zakres świadczonych usług(Geodezja):</Text> 
            </View> 
            <View style={styles.tableColRHead}> 
                <Text style={styles.tableCellHead}>Opiekun:</Text> 
            </View>
            </View>
            {data.geodesyServices.map((service,index)=>(
                <View style={styles.tableRow}>
                <View style={styles.tableColL}>
                    <Text style={styles.tableCell}>{index+1}</Text> 
                </View>
                <View style={styles.tableColM}>
                    <Text style={styles.tableCell}>{service.serviceName}</Text> 
                </View>
                <View style={styles.tableColM2}> 
                    <Text style={styles.tableCell}>{service.priceDefined===true?service.price:"cena nieznana"}</Text> 
                </View>
                {index===0?
                <View style={styles.tableColNoBorder}> 
                    <View style={styles.tableCell}>
                        <Text>{data.geodesy.name+" "+data.geodesy.surname}</Text><br/>
                        <Text>{"Tel. "+data.geodesy.phoneNumber}</Text>
                    </View>
                    
                </View>:
                <View style={styles.tableColNoBorder}>
                    <Text style={styles.tableCell}></Text> 
                </View>
                }
                </View>
            ))}
            <View style={styles.tableRow}>
            <View style={styles.tableColLBot}>
                <Text style={styles.tableCellBot}>Geodezja(RAZEM):</Text>
            </View>
            <View style={styles.tableColMBot}>
                    <Text style={styles.tableCell}>{data.geodesy.price}</Text>
            </View>
            <View style={styles.tableColRBot}>
                <Text style={styles.tableCell}></Text>
            </View>
            </View>
        </View>
        :null}
        
        {data.typicalProjectsServices.length>0?
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColLHead}>
                <Text style={styles.tableCellHead}>Zakres świadczonych usług(Projekty Typowe):</Text> 
            </View> 
            <View style={styles.tableColRHead}> 
                <Text style={styles.tableCellHead}>Opiekun:</Text> 
            </View>
            </View>
            {data.typicalProjectsServices.map((service,index)=>(
                <View style={styles.tableRow}>
                <View style={styles.tableColL}>
                    <Text style={styles.tableCell}>{index+1}</Text> 
                </View>
                <View style={styles.tableColM}>
                    <Text style={styles.tableCell}>{service.serviceName}</Text> 
                </View>
                <View style={styles.tableColM2}> 
                    <Text style={styles.tableCell}>{service.priceDefined===true?service.price:"cena nieznana"}</Text> 
                </View>
                {index===0?
                <View style={styles.tableColNoBorder}> 
                    <View style={styles.tableCell}>
                        <Text>{data.typicalProjects.name+" "+data.typicalProjects.surname}</Text><br/>
                        <Text>{"Tel. "+data.typicalProjects.phoneNumber}</Text>
                    </View>
                    
                </View>:
                <View style={styles.tableColNoBorder}>
                    <Text style={styles.tableCell}></Text> 
                </View>
                }
                </View>
            ))}
            <View style={styles.tableRow}>
            <View style={styles.tableColLBot}>
                <Text style={styles.tableCellBot}>Projekty Typowe(RAZEM):</Text>
            </View>
            <View style={styles.tableColMBot}>
                    <Text style={styles.tableCell}>{data.typicalProjects.price}</Text>
            </View>
            <View style={styles.tableColRBot}>
                <Text style={styles.tableCell}></Text>
            </View>
            </View>
        </View>
        :null}

        {data.individualProjectsServices.length>0?
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColLHead}>
                <Text style={styles.tableCellHead}>Zakres świadczonych usług(Projekty Indywidualne):</Text> 
            </View> 
            <View style={styles.tableColRHead}> 
                <Text style={styles.tableCellHead}>Opiekun:</Text> 
            </View>
            </View>
            {data.individualProjectsServices.map((service,index)=>(
                <View style={styles.tableRow}>
                <View style={styles.tableColL}>
                    <Text style={styles.tableCell}>{index+1}</Text> 
                </View>
                <View style={styles.tableColM}>
                    <Text style={styles.tableCell}>{service.serviceName}</Text> 
                </View>
                <View style={styles.tableColM2}> 
                    <Text style={styles.tableCell}>{service.priceDefined===true?service.price:"cena nieznana"}</Text> 
                </View>
                {index===0?
                <View style={styles.tableColNoBorder}> 
                    <View style={styles.tableCell}>
                        <Text>{data.individualProjects.name+" "+data.individualProjects.surname}</Text><br/>
                        <Text>{"Tel. "+data.individualProjects.phoneNumber}</Text>
                    </View>
                    
                </View>:
                <View style={styles.tableColNoBorder}>
                    <Text style={styles.tableCell}></Text> 
                </View>
                }
                </View>
            ))}
            <View style={styles.tableRow}>
            <View style={styles.tableColLBot}>
                <Text style={styles.tableCellBot}>Projekty Indywidualne(RAZEM):</Text>
            </View>
            <View style={styles.tableColMBot}>
                    <Text style={styles.tableCell}>{data.individualProjects.price}</Text>
            </View>
            <View style={styles.tableColRBot}>
                <Text style={styles.tableCell}></Text>
            </View>
            </View>
        </View>
        :null}
        
        {data.sanitaryInstallationsServices.length>0?
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColLHead}>
                <Text style={styles.tableCellHead}>Zakres świadczonych usług(Instalacje Sanitarne):</Text> 
            </View> 
            <View style={styles.tableColRHead}> 
                <Text style={styles.tableCellHead}>Opiekun:</Text> 
            </View>
            </View>
            {data.sanitaryInstallationsServices.map((service,index)=>(
                <View style={styles.tableRow}>
                <View style={styles.tableColL}>
                    <Text style={styles.tableCell}>{index+1}</Text> 
                </View>
                <View style={styles.tableColM}>
                    <Text style={styles.tableCell}>{service.serviceName}</Text> 
                </View>
                <View style={styles.tableColM2}> 
                    <Text style={styles.tableCell}>{service.priceDefined===true?service.price:"cena nieznana"}</Text> 
                </View>
                {index===0?
                <View style={styles.tableColNoBorder}> 
                    <View style={styles.tableCell}>
                        <Text>{data.sanitaryInstallations.name+" "+data.sanitaryInstallations.surname}</Text><br/>
                        <Text>{"Tel. "+data.sanitaryInstallations.phoneNumber}</Text>
                    </View>
                    
                </View>:
                <View style={styles.tableColNoBorder}>
                    <Text style={styles.tableCell}></Text> 
                </View>
                }
                </View>
            ))}
            <View style={styles.tableRow}>
            <View style={styles.tableColLBot}>
                <Text style={styles.tableCellBot}>Instalacje Sanitarne(RAZEM):</Text>
            </View>
            <View style={styles.tableColMBot}>
                    <Text style={styles.tableCell}>{data.sanitaryInstallations.price}</Text>
            </View>
            <View style={styles.tableColRBot}>
                <Text style={styles.tableCell}></Text>
            </View>
            </View>
        </View>
        :null}

        {data.conditionsServices.length>0?
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColLHead}>
                <Text style={styles.tableCellHead}>Zakres świadczonych usług(Warunki):</Text> 
            </View> 
            <View style={styles.tableColRHead}> 
                <Text style={styles.tableCellHead}>Opiekun:</Text> 
            </View>
            </View>
            {data.conditionsServices.map((service,index)=>(
                <View style={styles.tableRow}>
                <View style={styles.tableColL}>
                    <Text style={styles.tableCell}>{index+1}</Text> 
                </View>
                <View style={styles.tableColM}>
                    <Text style={styles.tableCell}>{service.serviceName}</Text> 
                </View>
                <View style={styles.tableColM2}> 
                    <Text style={styles.tableCell}>{service.priceDefined===true?service.price:"cena nieznana"}</Text> 
                </View>
                {index===0?
                <View style={styles.tableColNoBorder}> 
                    <View style={styles.tableCell}>
                        <Text>{data.conditions.name+" "+data.conditions.surname}</Text><br/>
                        <Text>{"Tel. "+data.conditions.phoneNumber}</Text>
                    </View>
                </View>:
                <View style={styles.tableColNoBorder}>
                    <Text style={styles.tableCell}></Text> 
                </View>
                }
                </View>
            ))}
            <View style={styles.tableRow}>
            <View style={styles.tableColLBot}>
                <Text style={styles.tableCellBot}>Warunki Zabudowy(RAZEM):</Text>
            </View>
            <View style={styles.tableColMBot}>
                    <Text style={styles.tableCell}>{data.conditions.price}</Text>
            </View>
            <View style={styles.tableColRBot}>
                <Text style={styles.tableCell}></Text>
            </View>
            </View>
        </View>
        :null}

        {data.geologyServices.length>0?
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColLHead}>
                <Text style={styles.tableCellHead}>Zakres świadczonych usług(Geologia):</Text> 
            </View> 
            <View style={styles.tableColRHead}> 
                <Text style={styles.tableCellHead}>Opiekun:</Text> 
            </View>
            </View>
            {data.geologyServices.map((service,index)=>(
                <View style={styles.tableRow}>
                <View style={styles.tableColL}>
                    <Text style={styles.tableCell}>{index+1}</Text> 
                </View>
                <View style={styles.tableColM}>
                    <Text style={styles.tableCell}>{service.serviceName}</Text> 
                </View>
                <View style={styles.tableColM2}> 
                    <Text style={styles.tableCell}>{service.priceDefined===true?service.price:"cena nieznana"}</Text> 
                </View>
                {index===0?
                <View style={styles.tableColNoBorder}> 
                    <View style={styles.tableCell}>
                        <Text>{data.geology.name+" "+data.geology.surname}</Text><br/>
                        <Text>{"Tel. "+data.geology.phoneNumber}</Text>
                    </View>
                </View>:
                <View style={styles.tableColNoBorder}>
                    <Text style={styles.tableCell}></Text> 
                </View>
                }
                </View>
            ))}
            <View style={styles.tableRow}>
            <View style={styles.tableColLBot}>
                <Text style={styles.tableCellBot}>Geologia(RAZEM):</Text>
            </View>
            <View style={styles.tableColMBot}>
                    <Text style={styles.tableCell}>{data.geology.price}</Text>
            </View>
            <View style={styles.tableColRBot}>
                <Text style={styles.tableCell}></Text>
            </View>
            </View>
        </View>
        :null}

        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColLSum}>
                <Text style={styles.tableCellBot}>RAZEM:</Text> 
            </View> 
            <View style={styles.tableColMSum}> 
                <Text style={styles.tableCell}>{data.sum+" zł."}</Text> 
            </View>
            <View style={styles.tableColRSum}> 
                <Text style={styles.tableCell}>{data.net===true?"NETTO":"BRUTTO"}</Text> 
            </View>
            </View>
        </View>
        </>
    )
}

export default PdfServicesTable