import { CircularProgress, Container, Paper, Typography } from "@material-ui/core"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverUrl } from "../config/serverConfig";

const SimpleInfoVierwer = (props) => {
    const {activity} = props
    const {jwt} = props
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const token="Bearer ".concat(jwt)
    useEffect(() => {
        async function getData() {
            await fetch(`${serverUrl}/history/history-details?activityId=${activity.activityId}`,
            {method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            })
            .then(response => response.json())
            .then(json => {
                setData(json);
                setIsLoading(false);
                console.log(json)
            })
            .catch(error => {
                console.log(error);
            })
        }
        if (isLoading) {
        getData();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    return(
        <Container style={{marginTop:'15px',marginBottom:'15px',width:'100%'}}>
            {isLoading?<CircularProgress/>:
            <>
            <Paper style={{width:'30%',border:'solid 2px #0750b5',float:'left',padding:'10px'}}>
            <Typography align='center'>{data.before===null||data.before===""?"--":data.before}</Typography>
            </Paper>
            <ArrowForwardIcon style={{width:'6%',float:'left',padding:'10px'}}/>
            <Paper style={{width:'30%',border:'solid 2px #0750b5',float:'left',padding:'10px'}}>
            <Typography align='center'>{data.after===null||data.after===""?"--":data.after}</Typography>
            </Paper>
            <div style={{clear:'both'}}></div>
            </>}
        </Container>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(SimpleInfoVierwer)