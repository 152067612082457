import { useDispatch, useSelector } from "react-redux"
import ResponseInfoStatusChange from "../allLeaves/ResponseInfoStatusChange"
import StatusSelect from "../allLeaves/StatusSelect"
import { changeLeaveStatusTimeline } from "../duck/operations"

const StatusChanger = () => {

    const statusId = useSelector(state => state.leaves.leaveDetails.statusId)
    const leaveId = useSelector(state => state.leaves.timelineDialogId)
    const response = useSelector(state => state.leaves.changeStatusTimeline.response)
    const dispatch = useDispatch()
    const handleStatusChange = (newStatusId) => {
        dispatch(changeLeaveStatusTimeline(newStatusId,leaveId))
    }

    return(
        <>
        <StatusSelect status={statusId} handleStatusChange={handleStatusChange}/>
        <ResponseInfoStatusChange response={response}/>
        </>
    )
}
export default StatusChanger