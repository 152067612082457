const LOGIN_USER = 'LOGIN_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const GET_JWT = 'GET_JWT';
const SET_TIME_LEFT = 'SET_TIME_LEFT'

const types = {
    LOGIN_USER,
    LOGOUT_USER,
    GET_JWT,
    SET_TIME_LEFT
}

export default types