import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const MyEvent = (props) => {


    return(
        <Paper style={{padding:'3px 5px 0px 5px',backgroundColor:'#aefa8b',textAlign:'end'}}>
            <Typography style={{float:'left',marginLeft:'4px'}}>Raport</Typography> 
            <CheckCircleIcon style={{color:'#0c9c00'}}/>
        </Paper>
    )
        
}

export default MyEvent