import { Box, Button, Grid, Typography} from "@material-ui/core"
import CustomerInput from "./CustomerInput"
import { connect } from "react-redux";
import CommunityInput from "./CommunityInput";
import PrecinctInput from "./PrecinctInput";
import PlotNumberInput from "./PlotNumberInput";
import CustomerNumbersList from "./CustomerNumbersList";
import CustomerAddressesList from "./CustomerAddressesList";
import CustomerEmailsList from "./CustomerEmailsList";
import CustomerNumbersListNew from "./CustomerNumbersListNew";
import CustomerEmailsListNew from "./CustomerEmailsListNew";
import CustomerAddressesListNew from "./CustomerAddressesListNew";
import ProjectNameInput from "./ProjectNameInput";
import DeleteIcon from '@material-ui/icons/Delete';
import actions from "../duck/actions";
import DialogClear from "./DialogClear";
import { useState } from "react";

const Data = (props) => {
    
    const [openDialog,setOpenDialog] = useState(false)
    
    const handleReset = () => {
       setOpenDialog(true)
    }

    const closeDialog = () =>{
        setOpenDialog(false)
    }

    
    return(
        <Grid container spacing={3} style={{width:"96%",marginLeft:"2%",marginRight:"2%",marginTop:"20px"}}>
            <Grid item xs={12} sm={10} md={6} lg={6} xl={6} >
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    style={{marginLeft:"auto",marginRight:"auto",display:"flex",width:"80%",marginBottom:"30px"}}
                    onClick={()=>handleReset()}
                >
                    wyczyść cały formularz
                </Button>
                <CustomerInput/>
                {
                    props.customerSet===true?
                        props.customCustomer===true?
                        <Typography className="infoText" style={{color:"green",textAlign:"left",marginLeft:"10%"}}>Dodajesz nowego klienta</Typography>
                        :
                        <Typography className="infoText" style={{color:"green",textAlign:"left",marginLeft:"10%",width:"80%"}}>Wybrałeś klienta z listy</Typography>
                    :
                    null
                }
                <CommunityInput/>
                <PrecinctInput/>
                <PlotNumberInput/>
                <ProjectNameInput/>
            </Grid>
            <DialogClear open={openDialog} closeDialog={closeDialog}></DialogClear>
            <Box item component={Grid} display={{ xs: "none", lg: "block" }} lg={1} xl={1}/>
            <Grid item xs={12} sm={10} md={6} lg={5} xl={5} >
                {
                props.customerSet===true?
                    props.customCustomer===true?
                    <>
                    <CustomerNumbersListNew/>
                    <CustomerAddressesListNew/>
                    <CustomerEmailsListNew/>
                    </>
                    :
                    <>
                    <CustomerNumbersList/>
                    <CustomerAddressesList/>
                    <CustomerEmailsList/>
                    </>
                :
                    <Typography className="infoText" color="error">Wybierz lub dodaj klienta</Typography>
                }
            </Grid>
        </Grid>
    )
    
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
       customCustomer:state.newOrder.customerData.customCustomer,
       customerSet:state.newOrder.customerData.customerSet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        resetForm: () => dispatch(actions.resetForm()),
    }
}
  
  export default connect(mapStateToProps,mapDispatchToProps)(Data)