import { Container, Grid } from "@mui/material"
import NavigationBar from "./navigation/NavigationBar"
import LeaveTimeline from "./timeline/Timeline"


const LeaveContainer = () => {


    return(
        <>
        <Container style={{textAlign:'center',marginTop:20}}>
        <NavigationBar add={false}/>
        </Container>
        <LeaveTimeline/>
        </>
    )
}

export default LeaveContainer