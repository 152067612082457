import {Grid, } from "@material-ui/core"
import React from 'react'
import { connect } from "react-redux"
import InfoTable from "../../infoTable/InfoTable"
import CommentsSection from '../CommentsSection'
import ServiceInfo from '../ServiceInfo'
import '../style.css'
import DeAgriStatus from "./DeAgriStatus"
import ReclamationStatus from "./ReclamationStatus"
 function IndividualDialog(props){
    
    const {orderId} = props
    const {serviceId} = props
    const {serviceInfoId} = props

    return(
        <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
                <InfoTable orderId={orderId} linkEnabled={true}/>
            </Grid>
            <Grid item lg={5} xs={12}>
                <ServiceInfo 
                serviceId={serviceId} 
                serviceInfoId={serviceInfoId}
                onChange={props.onChange}>
                </ServiceInfo>
                <DeAgriStatus
                orderId={orderId} 
                serviceId={serviceId}
                >
                </DeAgriStatus>
                <ReclamationStatus
                orderId={orderId} 
                serviceId={serviceId}
                >
                </ReclamationStatus>
            </Grid>
            <Grid item lg={3} xs={12}>
                <CommentsSection 
                style={{width:'30%',float:'left'}}
                orderId={orderId}
                onChange={props.onChange}>
                </CommentsSection>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(IndividualDialog)