import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const persistConfig = {
    key: 'root',
    storage,
    //blacklist: ['router']
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)



const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk)),
    );

export {store}