import { green, grey, red, yellow } from "../helpers/colors"
const colorSwitch = (statusId) => {
    switch (statusId) {
        case 1:
            return yellow
        case 2:
            return green
        case 3:
            return red
        case 4:
            return grey
        default:
            return grey
    }
}

export default colorSwitch