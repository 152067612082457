import { AccordionDetails, Button, TextField, Typography, withStyles } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { connect } from "react-redux"
import EmailIcon from '@material-ui/icons/Email';
import '../style.css'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';
import actions from "../duck/actions";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const NewAddress = (props) => {

    const [expanded,setExpanded] = useState(false)
    const [newAddress,setNewAddress] = useState("")
    const { enqueueSnackbar } = useSnackbar()
    const [short,setShort] = useState(false)


    const handleAddAddress = () => {
      if(newAddress.length>=1&&newAddress.length<255){
        props.addNewAddress(newAddress)
        setExpanded(false)
        setNewAddress("")
        props.refresh()
      }else{
          enqueueSnackbar("Adres musi mieć od 1 do 255 znaków",{variant: 'warning',autoHideDuration: 5000})
      }  
    }

      
    const handleInputChange = (val) => {
      if(val.length<1){
          setShort(true)
      }else{
          setShort(false)
      }
      if(val.length<255){
        setNewAddress(val)
      }
    }

    return(
    <Accordion expanded={expanded} onChange={()=>{setExpanded(!expanded)}}>
    <AccordionSummary  style={{backgroundColor:"#0750b5",color:"white"}}
    expandIcon={<EmailIcon style={{color:"white",fontSize:"1.4rem"}} />}
    >
    <Typography className="infoText">NOWY ADRES KORESPONDENCYJNY</Typography>
    </AccordionSummary>
    <AccordionDetails>
        <div>
        <TextField
            value={newAddress}
            style={{marginTop:"8px",marginBottom:"8px"}}
            label="Nowy adres"
            variant="outlined"
            fullWidth={true}
            inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
            onChange={(e) => handleInputChange(e.target.value)}
        />
        <Button onClick={() => {handleAddAddress()}} style={{backgroundColor:"rgb(67, 160, 71)",color:'white'}} fullWidth={true}>Dodaj</Button>
        </div>
        
        
    </AccordionDetails>
    </Accordion>
    )
}

const mapDispatchToProps = (dispatch) => {
  return{
    addNewAddress: (obj) => dispatch(actions.addNewAddress(obj)),
  }
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(NewAddress)