import { TableBody, TableCell, TableHead, TableRow, Table, Paper, TableContainer, IconButton } from "@material-ui/core"
import { connect } from "react-redux"
import actions from "../duck/actions"
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const DataTable = (props) => {

    const calcRowSpan = (newLen,oldLen) => {
        if(newLen+oldLen===1||newLen+oldLen===0){
            return 1
        }else{
            return(newLen+oldLen)
        }
    }

    const empty = () =>{
        return(<center><span style={{color:"#0750b5",fontWeight:"bold"}}>--</span></center>)
    }

    const handleDataEdit = (number) => {
        props.setStep(number)
    }

    return(
        <TableContainer component={Paper}>
        <Table>
            <TableHead style={{backgroundColor:"#0750b5"}}>
                <TableRow>
                    <TableCell style={{width:"30%"}}></TableCell>
                    <TableCell style={{color:"white",width:"65%",textAlign:"center"}}>Dane</TableCell>
                    <TableCell style={{width:"5%"}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell className="tableFirstChild">Nazwa inwestora</TableCell>
                    <TableCell>{props.customerName}</TableCell>
                    <TableCell className="noPaddingCell"><IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tableFirstChild">Gmina</TableCell>
                    <TableCell>{props.community}</TableCell>
                    <TableCell className="noPaddingCell"><IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tableFirstChild">Obręb</TableCell>
                    <TableCell>{props.precinct}</TableCell>
                    <TableCell className="noPaddingCell"><IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tableFirstChild">Numer działki</TableCell>
                    <TableCell>{props.plotNumber}</TableCell>
                    <TableCell className="noPaddingCell"><IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tableFirstChild">Nazwa projektu</TableCell>
                    <TableCell>{props.projectName}</TableCell>
                    <TableCell className="noPaddingCell"><IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="tableFirstChild" rowSpan={calcRowSpan(props.newPhoneNumbers.length, props.phoneNumbers.length)}>Telefony</TableCell>
                    {
                        (props.newPhoneNumbers.concat(props.phoneNumbers)).length===0? <TableCell>{empty()}</TableCell> : 
                        <TableCell>
                            {
                            typeof((props.newPhoneNumbers.concat(props.phoneNumbers))[0])==="string"?
                            <span style={{color:"green"}} key={0}>{(props.newPhoneNumbers.concat(props.phoneNumbers))[0]}</span>:
                            <span key={0}>{(props.newPhoneNumbers.concat(props.phoneNumbers))[0].phoneNumber}</span>
                            }
                        </TableCell>
                    }
                    <TableCell className="noPaddingCell" rowSpan={calcRowSpan(props.newPhoneNumbers.length, props.phoneNumbers.length)}>
                        <IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton>
                    </TableCell>
                </TableRow>
                {   
                    (props.newPhoneNumbers.concat(props.phoneNumbers)).map((number,index)=>{
                        //console.log(number, typeof(number),index)
                        if(index!==0){
                            if(typeof(number)==="string"){
                                //console.log("zielony")
                                return (
                                <TableRow key={index}>
                                    <TableCell key={index} style={{fontWeight:"normal"}}>
                                        <span style={{color:"green"}} key={index}>{number}</span>
                                    </TableCell>
                                </TableRow>
                                )
                            }else{
                                //console.log("zwykłe")
                                return (
                                <TableRow key={index}>
                                    <TableCell key={index} style={{fontWeight:"normal"}}>
                                        <span key={index}>{number.phoneNumber}</span>
                                    </TableCell>
                                </TableRow>
                                )
                            }
                        }else{
                            return null
                        }
                    })
                }
                <TableRow>
                    <TableCell className="tableFirstChild" rowSpan={calcRowSpan(props.newAddresses.length, props.addresses.length)}>Adresy</TableCell>
                    {
                        (props.newAddresses.concat(props.addresses)).length===0? <TableCell>{empty()}</TableCell> : 
                        <TableCell>
                            {
                            typeof((props.newAddresses.concat(props.addresses))[0])==="string"?
                            <span style={{color:"green"}} key={0}>{(props.newAddresses.concat(props.addresses))[0]}</span>:
                            <span key={0}>{(props.newAddresses.concat(props.addresses))[0].address}</span>
                            }
                        </TableCell>
                    }
                    <TableCell className="noPaddingCell" rowSpan={calcRowSpan(props.newAddresses.length, props.addresses.length)}>
                        <IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton>
                    </TableCell>
                </TableRow>
                {   
                    (props.newAddresses.concat(props.addresses)).map((address,index)=>{
                        //console.log(address, typeof(address),index)
                        if(index!==0){
                            if(typeof(address)==="string"){
                                //console.log("zielony")
                                return (
                                <TableRow key={index}>
                                    <TableCell key={index} style={{fontWeight:"normal"}}>
                                        <span style={{color:"green"}} key={index}>{address}</span>
                                    </TableCell>
                                </TableRow>
                                )
                            }else{
                                //console.log("zwykłe")
                                return (
                                <TableRow key={index}>
                                    <TableCell key={index} style={{fontWeight:"normal"}}>
                                        <span key={index}>{address.address}</span>
                                    </TableCell>
                                </TableRow>
                                )
                            }
                        }else{
                            return null
                        }
                        
                    })
                }
                <TableRow>
                    <TableCell className="tableFirstChild" rowSpan={calcRowSpan(props.newEmails.length, props.emails.length)}>Emaile</TableCell>
                    {
                        (props.newEmails.concat(props.emails)).length===0? <TableCell>{empty()}</TableCell> : 
                        <TableCell>
                            {
                            typeof((props.newEmails.concat(props.emails))[0])==="string"?
                            <span style={{color:"green"}} key={0}>{(props.newEmails.concat(props.emails))[0]}</span>:
                            <span key={0}>{(props.newEmails.concat(props.emails))[0].email}</span>
                            }
                        </TableCell>
                    }
                    <TableCell className="noPaddingCell" rowSpan={calcRowSpan(props.newEmails.length, props.emails.length)}>
                        <IconButton onClick={()=>handleDataEdit(0)}><EditOutlinedIcon className="editIcon"/></IconButton>
                    </TableCell>
                </TableRow>
                {   
                    (props.newEmails.concat(props.emails)).map((email,index)=>{
                        //console.log(email, typeof(email),index)
                        if(index!==0){
                            if(typeof(email)==="string"){
                                //console.log("zielony")
                                return (
                                <TableRow key={index}>
                                    <TableCell key={index} style={{fontWeight:"normal"}}>
                                        <span style={{color:"green"}} key={index}>{email}</span>
                                    </TableCell>
                                </TableRow>
                                )
                            }else{
                                //console.log("zwykłe")
                                return (
                                <TableRow key={index}>
                                    <TableCell key={index} style={{fontWeight:"normal"}}>
                                        <span key={index}>{email.email}</span>
                                    </TableCell>
                                </TableRow>
                                )
                            }
                        }else{
                            return null
                        }
                        
                    })
                }
                <TableRow>
                    <TableCell className="tableFirstChild">Brutto / Netto</TableCell>
                    <TableCell><span style={{fontWeight:"bold",width:"100%",textAlign:"center",display:"block"}}>{props.net===true?"Netto":"Brutto"}</span></TableCell>
                    <TableCell className="noPaddingCell"><IconButton onClick={()=>handleDataEdit(1)}><EditOutlinedIcon className="editIcon"/></IconButton></TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
    )
}

const mapStateToProps = (state) =>{
    return{
        customerName:state.newOrder.customerData.customerName,
        community:state.newOrder.customerData.community,
        precinct:state.newOrder.customerData.precinct,
        projectName:state.newOrder.customerData.projectName,
        plotNumber:state.newOrder.customerData.plotNumber,
        newAddresses:state.newOrder.customerData.newAddresses,
        newEmails:state.newOrder.customerData.newEmails,
        newPhoneNumbers:state.newOrder.customerData.newPhoneNumbers,
        addresses:state.newOrder.customerData.addresses,
        emails:state.newOrder.customerData.emails,
        phoneNumbers:state.newOrder.customerData.phoneNumbers,
        net:state.newOrder.net,
        //tu dalej ściągać dane z reducerów :)
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setNet: (obj) => dispatch(actions.setNet(obj)),
        setStep: (step) => dispatch(actions.setStep(step)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DataTable)