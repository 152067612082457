import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import getNow from '../getNow';
import { Accordion, AccordionDetails, AccordionSummary, Button, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from "@material-ui/core"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React from 'react'
import { serverUrl } from '../config/serverConfig';
function CommentsSection(props){

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { enqueueSnackbar } = useSnackbar();
    const [refresh,setRefresh] = useState(0)
    const [isLoading,setIsLoanding] = useState(true);
    const [comments,setComments] = useState([])
    const token='Bearer '.concat(props.jwt)
    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/comments?orderId=${props.orderId}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            //console.log(json);
            setComments(json);
            setIsLoanding(false);
          })
          .catch(error => {
            
          })
        }
        if (isLoading||refresh>0) {
            // if the result is not ready so you make the axios call
            //console.log("wyłączony stan ",skipPageResetRef)
            getData();
            setRefresh(0);
          }
          
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },[refresh])


    async function addComment(orderId,content,entryDate,enteringUser) {
        await fetch(`${serverUrl}/comments?content=${content}&entryDate=${entryDate}&enteringUser=${enteringUser}&orderId=${orderId}`,
        {method:'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie dodano komentarz",{variant: 'success',autoHideDuration: 2000})
            afterSuccessAdd()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Komentarz nie został dodany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Komentarz nie został dodany",{variant: 'error',autoHideDuration: 2000})
        })
      }

      async function deleteComment(commentId) {
        await fetch(`${serverUrl}/comments?commentId=${commentId}`,
        {method:'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie usunięto komentarz",{variant: 'success',autoHideDuration: 2000})
            afterSuccessDelete()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Komentarz nie został usunięty",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Komentarz nie został usunięty",{variant: 'error',autoHideDuration: 2000})
        })
      }

    async function editComment(commentId,content) {
        await fetch(`${serverUrl}/comments?commentId=${commentId}&content=${content}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie edytowano komentarz",{variant: 'success',autoHideDuration: 2000})
            afterSuccessEdit()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Komentarz nie został edytowany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Komentarz nie został edytowany",{variant: 'error',autoHideDuration: 2000})
        })
      }  
    
        const [expanded,setExpanded] = useState(false)
        const [editContent,setEditContent] = useState("")
        const [editCommentId,setEditCommentId] = useState(null)
        const newComment = useRef(null)
        const editCommentRef = useRef(null)
    
        const afterSuccessAdd = () => {
          newComment.current.value=""
          setExpanded(false)
          setRefresh(refresh+1)
          props.onChange()
        }

        const afterSuccessEdit = () => {
            editCommentRef.current.value=""
            setOpen(false)
            setEditContent("")
            setEditCommentId(null)
            setRefresh(refresh+1)
          }

        const afterSuccessDelete = () => {
            setRefresh(refresh+1)
            props.onChange()
          }
    
        const handleCommentAdd = () => {
          const date = getNow()
          //console.log("data: ",date)
          addComment(props.orderId,encodeURIComponent(newComment.current.value.trim()),date,props.enteringUser)
        }

        const handleDelete = (commentId) => {
            deleteComment(commentId)
        }

        const handleEdit = (commentId,content) => {
            setOpen(true)
            setEditCommentId(commentId)
            setEditContent(content)
        }

        const handleSaveEdited = (commentId,content) => {
            editComment(commentId,encodeURIComponent(content.trim()))
        }

    comments.sort(function(a, b) {
        return b.commentId - a.commentId;
    });

    //console.log(props.comments)
    return(
        <React.Fragment>
        <table className="commentsTable">
            <tbody>
                <tr>
                    <td>
                    <Accordion style={{marginBottom:"15px"}} expanded={expanded} onChange={()=>{setExpanded(!expanded)}}>
          
                    <AccordionSummary 
                    expandIcon={<AddCircleOutlineIcon style={{color:"white",fontSize:"2rem"}} />}
                    style={{backgroundColor:'#43a047',color:'white',fontSize:'1.2rem'}}>
                        Dodaj komentarz
                    </AccordionSummary>

                    <AccordionDetails>
                        <div>
                        <TextField
                            style={{marginTop:"15px",marginBottom:"15px"}}
                            label="Twój komentarz"
                            multiline
                            rows={6}
                            variant="outlined"
                            fullWidth={true}
                            inputRef={newComment}
                        />
                        
                        <Button onClick={() => {handleCommentAdd()}} style={{backgroundColor:"rgb(67, 160, 71)",color:'white'}} fullWidth={true}>Dodaj komentarz!</Button>
                        </div>
                        
                        
                    </AccordionDetails>

                    </Accordion>    
                    </td>
                </tr>
                    {comments.map( comment=>{
                      
                        return(<tr key={comment.commentId}><td>
                            <Card style={{marginBottom:'15px'}}>
                                <CardHeader
                                    avatar={
                                    <Avatar style={{backgroundColor:'white',color:"#0750b5"}}>
                                        {comment.userName.substr(0,1)}
                                    </Avatar>
                                    }
                                    title={<span style={{color:'white',fontSize:'1.3rem',fontFamily:'arial'}}>{comment.userName}</span>}
                                    subheader={<span style={{color:'white',fontSize:'0.95rem',fontFamily:'arial'}}>{comment.entryDate}</span>}
                                    style={{backgroundColor:'#0750b5',paddingTop:'6px',paddingBottom:'6px'}}
                                />
                                <CardContent style={{minHeight:"70px"}}>
                                    <Typography variant="body1" color="textPrimary" component="p" style={{fontSize:"1.1rem"}}>
                                    - {comment.content}
                                    </Typography>
                                </CardContent>
                                {comment.userId===props.userId?
                                <CardActions>
                                    <Button onClick={()=>{handleEdit(comment.commentId,comment.content)}} fullWidth={true} size="small" color="primary">
                                    EDYTUJ
                                    </Button>
                                    <Button onClick={()=>{handleDelete(comment.commentId)}} fullWidth={true} size="small" color="primary">
                                    USUŃ
                                    </Button>
                                </CardActions>
                                :null}
                            </Card>
                        </td></tr>
            )})}
                    
                
            </tbody>
        </table>
        <Dialog 
        maxWidth="sm"
        fullWidth={true}
        fullScreen={fullScreen} 
        open={open}
        className="editComDialog"
        onClose={()=>{
            setEditCommentId(null)
            setEditContent("")
        }}
       >
        <DialogTitle>Edytuj uwagę</DialogTitle>
        <DialogContent className="dialogMid">
        <div>
        <TextField
            style={{marginTop:"15px",marginBottom:"15px"}}
            label="Twój komentarz"
            multiline
            rows={6}
            variant="outlined"
            fullWidth={true}
            inputRef={editCommentRef}
            defaultValue={editContent}
        />
        <Button onClick={()=>handleSaveEdited(editCommentId,editCommentRef.current.value)} style={{backgroundColor:"rgb(67, 160, 71)",color:'white'}} fullWidth={true}>Zapisz zmiany!</Button>
        </div>   
        </DialogContent>
        <DialogActions>
            <Button fullWidth={true} onClick={()=> {setOpen(false)}} color="primary">
                Opuść
            </Button>
        </DialogActions>
        </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
     return{
        jwt:state.log.jwt,
        enteringUser:state.log.user.userId,
        userId:state.log.user.userId
     }
   }

export default connect(mapStateToProps,null)(CommentsSection)