import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
const NewPhoneNumber = (props) => {

    const [expandedNumber,setExpandedNumber] = useState(false)
    const [newNumber,setNewNumber] = useState("")
    const { enqueueSnackbar } = useSnackbar()
    const token='Bearer '.concat(props.jwt)
    const [short,setShort] = useState(false)
    const regex=/(^(\+?|))\d{0,14}$/

    async function addNumber(phoneNumber,orderId) {
        await fetch(`${serverUrl}/phone-numbers?phoneNumber=${phoneNumber}&orderId=${orderId}`,
        {method:'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie dodano numer telefonu",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            setNewNumber("")
            setExpandedNumber(false)
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Numer telefonu nie został dodany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Numer telefonu nie został dodany",{variant: 'error',autoHideDuration: 2000})
        })
      }


    const handleAddNumber = () => {
        if(regex.test(newNumber)&&newNumber.length>=6){
            addNumber(encodeURIComponent(newNumber.trim()),props.orderId)
        }else{
            enqueueSnackbar("Numer musi mieć od 6 do 14 znaków, może zaczynać się od plusa i zawierać tylko cyfry",{variant: 'warning',autoHideDuration: 5000})
        }  
      }

      
      const handleInputChange = (val) => {
        if(regex.test(val)){
            setNewNumber(val)
        }
        if(val.length<6){
            setShort(true)
        }else{
            setShort(false)
        }
      }

    return(
    <Accordion style={{marginTop:"10px",width:"60%",marginLeft:"10%"}} expanded={expandedNumber} onChange={()=>{setExpandedNumber(!expandedNumber)}}>
    <AccordionSummary 
    expandIcon={<AddIcCallIcon style={{color:"white",fontSize:"1.4rem"}} />}
    style={{backgroundColor:'#43a047',
    color:'white',
    fontSize:'0.875rem',
    fontWeight:"bold",
    fontFamily: "Roboto",
    lineHeight: "1.75",
    letterSpacing: "0.02857em",
    }}>
        DODAJ NUMER TELEFONU
    </AccordionSummary>
    <AccordionDetails>
        <div>
        <TextField
            value={newNumber}
            style={{marginTop:"8px",marginBottom:"8px"}}
            label="Nowy numer telefonu"
            variant="outlined"
            fullWidth={true}
            inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
            onChange={(e) => handleInputChange(e.target.value)}
        />
        
        <Button onClick={() => {handleAddNumber()}} style={{backgroundColor:"rgb(67, 160, 71)",color:'white'}} fullWidth={true}>Dodaj</Button>
        </div>
        
        
    </AccordionDetails>
    </Accordion>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,null)(NewPhoneNumber)