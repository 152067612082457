import { Button, withStyles } from "@material-ui/core"
import { CircularProgress, Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useEffect,useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import ReplayIcon from '@material-ui/icons/Replay';
import { Box } from "@material-ui/core"

const SuspendButton = withStyles({
    root:{
        backgroundColor:'#ff9800',
        color:'white',
        '&:hover':{
            backgroundColor:'#ff9800cc'
        }
    }
})(Button)

const EndButton = withStyles({
    root:{
        backgroundColor:'#43a047',
        color:'white',
        '&:hover':{
            backgroundColor:'#43a047cc'
        }
    }
})(Button)

const FunctionalButtons = ({jwt,orderId}) => {
    
    const [isSuspended,setIsSuspended] = useState()
    const [isEnded,setIsEnded] = useState()
    const [isLoading,setIsLoanding] = useState(true)
    const token='Bearer '.concat(jwt)
    const [refresh,setRefresh] = useState(0)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        async function getData() {
            await Promise.all([ 
                fetch(`${serverUrl}/orders/is-suspended?orderId=${orderId}`,
                {method:'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    },
                }).then(response => response.json()),
                fetch(`${serverUrl}/orders/is-ended?orderId=${orderId}`,
                {method:'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    },
                }).then(response => response.json()),
            ])
              .then(json => {
                //console.log("guziki:",json)
                setIsSuspended(json[0])
                setIsEnded(json[1])
                setIsLoanding(false)
              })
              .catch(error => {
                console.log(error)
              })
        }
        if (isLoading||refresh>0) {getData()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const suspend = async(bool) =>{
        let param
        if(bool===true){param="suspend"}else{param="resume"}
        await fetch(`${serverUrl}/orders/${orderId}/${param}`,
        {method:'PATCH',
            headers: {
                'Content-Type': "application/octet-stream",
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(json => {
            console.log(json);
            setRefresh(refresh+1)

        })
        .catch(error => {
            console.log(error);
        })
    }

    const end = async(bool) =>{
        let param
        if(bool===true){param="end"}else{param="restore"}
        await fetch(`${serverUrl}/orders/${orderId}/${param}`,
        {method:'PATCH',
            headers: {
                'Content-Type': "application/octet-stream",
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(json => {
            console.log(json);
            setRefresh(refresh+1)

        })
        .catch(error => {
            console.log(error);
        })
    }
    
    const handleSuspend = (bool) =>{
        suspend(bool)
    }

    const handleEnd = (bool) => {
        end(bool)
    }



    return(
        <>
        <Grid container alignItems="center" component={Paper} style={{marginBottom:'24px'}}>
            <Grid alignItems='center' item xs={12} sm={6} md={6} lg={6} xl={6} style={{padding:'10px'}}>
                {isLoading===true?<CircularProgress style={{marginLeft:'auto',marginRight:'auto',display:'block',color:'#ff9800'}}/>
                :
                    isSuspended===false?
                    <SuspendButton onClick={()=>handleSuspend(true)} fullWidth={true} variant="contained">ZAWIEŚ</SuspendButton>
                    :
                    <Box alignItems="center">
                    <Typography align='center' style={{color:'#ab0000',fontSize:'1.2rem',fontWeight:'500'}}>ZAWIESZONE</Typography> 
                    <Button onClick={()=>handleSuspend(false)} fullWidth={true} variant="contained">
                        {"ODWIEŚ  "}<ReplayIcon/>
                    </Button>
                    </Box>
                }
            </Grid>
            <Grid alignItems='center' item xs={12} sm={6} md={6} lg={6} xl={6} style={{padding:'10px'}}>
                {isLoading===true?<CircularProgress style={{marginLeft:'auto',marginRight:'auto',display:'block',color:'#43a047'}}/>
                :
                    isEnded===false?<EndButton onClick={()=>handleEnd(true)} fullWidth={true} variant="contained">ZAKOŃCZ</EndButton>
                    :
                    <Box alignItems="center">
                    <Typography align='center' style={{color:'#ab0000',fontSize:'1.2rem',fontWeight:'500'}}>ZAKOŃCZONE</Typography> 
                    <Button  onClick={()=>handleEnd(false)} fullWidth={true} variant="contained">
                        {"PRZYWRÓĆ  "}<ReplayIcon/>
                    </Button>
                    </Box>
                }
            </Grid>
        </Grid>
        </>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(FunctionalButtons)