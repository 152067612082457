import { Dialog,DialogTitle,DialogActions,DialogContent, Button, Paper } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import actions from "../duck/actions"
import NewReqestForm from "./NewRequestForm"
import InfoIcon from '@material-ui/icons/Info'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from "@material-ui/core"

const NewRequestDialog = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const open = useSelector(state=>state.leaves.openNewRequestDialog)
    const dispatch = useDispatch() 

    const handleClose = () => {
        dispatch(actions.setOpenNewRequestDialog(false))
        dispatch(actions.setLoadingForm(false))
        dispatch(actions.clearForm())
    }

    return(
    <Dialog
    fullWidth={true}
    maxWidth='md'
    open={open}
    fullScreen={fullScreen}
    >
    <DialogTitle>Nowy urlop:</DialogTitle>
    <DialogContent>
        <Paper style={{padding:'8px',paddingRight:'100px',border:'2px solid #1976d2',marginBottom:15}}>
            <InfoIcon style={{float: 'left',marginRight: '10px',color:'#1976d2',marginLeft: '10px'}}/>
            Wybierz date rozpoczęcia i zakończenia urlopu. Możesz zostawić opcjonalny komentarz.
        </Paper>
        <NewReqestForm/>
    </DialogContent>
    <DialogActions>
        <Button onClick={()=>handleClose()}>Zamknij</Button>
    </DialogActions>
    </Dialog>
    )
}
export default NewRequestDialog