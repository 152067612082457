import { store } from "../../store"


const adminValidCheck = () => {
    let valid = true
    let info = ''
    const calendarValue = store.getState().leaves.form.rangePickerValue
    const commentValue = store.getState().leaves.form.commentValue
    const userId = store.getState().leaves.form.userId
    const statusId = store.getState().leaves.form.statusId

    if(Array.isArray(calendarValue)){
        if(calendarValue.length===2){
            if(calendarValue[0]===null||calendarValue[1]===null){
                valid=false
                //console.log('picker value is null')
                info='Nieprawidłowa data'
            }
        }else{
            valid=false
            //console.log('picker len is not 2')
            info='Błąd kalendarza'
        }
    }else{
        valid=false
        //console.log('picker value is not an array')
        info='Błąd kalendarza'
    }

    if(typeof(commentValue).toString()!=='string'){
        valid=false
        //console.log('comment value is not a string')
    }

    if(userId===null||typeof(userId)!=='number'){
        valid=false
        //console.log('user must be selected:',userId)
        info='Wybierz użytkownika'
    }

    if(statusId===null||typeof(statusId)!=='number'){
        valid=false
        //console.log('status must be selected:',typeof(statusId))
        info='Wybierz status'
    }

    return {
        valid:valid,
        info:info
    }
}

export default adminValidCheck