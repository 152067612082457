import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import actions from "../duck/actions";
import {green,yellow} from '../helpers/colors'
import { CircularProgress } from "@material-ui/core";

const YearDialog = () => {

    const open = useSelector(state => state.leaves.userYears.openDialog)
    const isLoading = useSelector(state => state.leaves.userYears.isLoading)
    const years = useSelector(state => state.leaves.userYears.years)
    const fetchError = useSelector(state => state.leaves.userYears.fetchError)
    const dispatch = useDispatch()

    const renderEmptyCell = () => (
        <TableRow>
            <TableCell colSpan={2} style={{
                textAlign:"center",
                color:'grey'
            }}>
                Brak urlopów.
            </TableCell>
        </TableRow>
    )

    const handleOpen = (open) => {
        dispatch(actions.setUserYearsOpenDialog(open))
    }

    return(
        <Dialog
        fullWidth={false}
        maxWidth='md'
        open={open}
        >
        <DialogTitle>Twoje urlopy w latach:</DialogTitle>
        <DialogContent>
            {
                isLoading?
                <CircularProgress/>
                :
                    fetchError?
                    <Typography style={{
                        color:"grey",
                        width:'100%'
                    }}>
                        Wystąpił błąd :(
                    </Typography>
                    :
                    <Table style={{
                        minWidth:200
                    }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Rok</TableCell>
                            <TableCell align="center">Dni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            years?
                            years.length<1?
                                renderEmptyCell()
                            :
                            years.map(year=>
                                <TableRow>
                                    <TableCell style={{
                                        color:yellow,
                                        fontWeight:'bold',
                                        textAlign:'center'
                                    }}>{year.year}</TableCell>
                                    <TableCell style={{
                                        color:green,
                                        fontWeight:'bold',
                                        textAlign:'center'
                                    }}>{year.days}</TableCell>
                                </TableRow>
                            )
                            :
                            renderEmptyCell()
                        }
                    </TableBody>
                </Table>
            }
            
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>handleOpen(false)}>ZAMKNIJ</Button>
        </DialogActions>
        </Dialog>
    )
}

export default YearDialog