import { MenuItem, Select } from '@material-ui/core';



const UserSelect = ({selectedUser,handleUserChange,loadingUsers,users,error}) => {

    return(
        <Select
            displayEmpty
            onChange={(e)=>handleUserChange(e.target.value)}
            value={selectedUser}
            style={{width:'100%'}}
        >
            <MenuItem value={null} disabled={loadingUsers}>
                {loadingUsers?'Ładowanie...':
                    error?
                    'Błąd ładowania':
                    'Wszyscy pracownicy'}
            </MenuItem>
            {
                loadingUsers?
                    null
                :
                    users?
                        users.map(user=>(
                            <MenuItem value={user.id} key={user.id}>
                                {user.title}
                            </MenuItem>
                        ))
                    :
                    null
            }
        </Select>
    )
}


export default UserSelect