

const SET_OPEN_CONFIRMATION_DIALOG = 'SET_OPEN_CONFIRMATION_DIALOG'
const SET_LEAVE_ID_TO_DELETE ='SET_LEAVE_ID_TO_DELETE'
const SET_OPEN_NEW_REQUEST_DIALOG = 'SET_OPEN_NEW_REQUEST_DIALOG'
const SET_USERS = 'SET_USERS'
const SET_LOADING_USERS = 'SET_LOADING_USERS'
const SET_CALENDAR_LEAVES = 'SET_CALENDAR_LEAVES'
const SET_LOADING_CALENDAR_LEAVES = 'SET_LOADING_CALENDAR_LEAVES'
const SET_USER_LEAVES = 'SET_USER_LEAVES'
const SET_LEAVE_DETAILS = 'SET_LEAVE_DETAILS'
const SET_LOADING_USER_LEAVES = 'SET_LOADING_USER_LEAVES'
const CLEAR_USER_LEAVES = 'CLEAR_USER_LEAVES'
const SET_ALL_LEAVES = 'SET_ALL_LEAVES'
const SET_LOADING_ALL_LEAVES = 'SET_LOADING_ALL_LEAVES'
const SET_LOADING_CANCEL_LEAVE = 'SET_LOADING_CANCEL_LEAVE'
const SET_LOADING_TIMELINE_INFO = 'SET_LOADING_TIMELINE_INFO'
const SET_TIMELINE_DIALOG_ID = 'SET_TIMELINE_DIALOG_ID'
const SET_OPEN_TIMELINE_INFO_DIALOG = 'SET_OPEN_TIMELINE_INFO_DIALOG'
const SET_LOADING_CHANGE_STATUS = 'SET_LOADING_CHANGE_STATUS'
const SET_ALL_TABLE_DIALOG_ID = 'SET_ALL_TABLE_DIALOG_ID'
const SET_ALL_TABLE_DIALOG_STATUS_ID = 'SET_ALL_TABLE_DIALOG_STATUS_ID'
const SET_OPEN_ALL_TABLE_DIALOG = 'SET_OPEN_ALL_TABLE_DIALOG'

const SET_LOADING_CHANGE_STATUS_ALL_LEAVES = 'SET_LOADING_CHANGE_STATUS_ALL_LEAVES'
const SET_LOADING_CHANGE_STATUS_TIMELINE = 'SET_LOADING_CHANGE_STATUS_TIMELINE'
const SET_RESPONSE_CHANGE_STATUS_ALL_LEAVES = 'SET_RESPONSE_CHANGE_STATUS_ALL_LEAVES'
const SET_RESPONSE_CHANGE_STATUS_TIMELINE = 'SET_RESPONSE_CHANGE_STATUS_TIMELINE'
const RESET_CHANGE_STATUS_ALL_LEAVES = 'RESET_CHANGE_STATUS_ALL_LEAVES'
const RESET_CHANGE_STATUS_TIMELINE = 'RESET_CHANGE_STATUS_TIMELINE'
//form
const SET_RANGE_PICKER_VALUE = 'SET_RANGE_PICKER_VALUE'
const SET_COMMENT_VALUE = 'SET_COMMENT_VALUE'
const CLEAR_FORM = 'CLEAR_FORM'
const SET_LOADING_FORM = 'SET_LOADING_FORM'
const SET_FORM_USER_ID = 'SET_FORM_USER_ID'
const SET_FORM_STATUS_ID = 'SET_FORM_STATUS_ID'
const SET_FORM_RESPONSE = 'SET_FORM_RESPONSE'
//filters
const SET_FILTER_PAGE = 'SET_FILTER_PAGE'
const SET_FILTER_STATUS = 'SET_FILTER_STATUS'
const SET_FILTER_USER = 'SET_FILTER_USER'
const SET_FILTER_PAGE_COUNT = 'SET_ALL_LEAVES_PAGE_COUNT'

const SET_FETCH_USERS_ERROR = 'SET_FETCH_USERS_ERROR'
const SET_FETCH_CALENDAR_LEAVES_ERROR = 'SET_FETCH_CALENDAR_LEAVES_ERROR'
const SET_FETCH_ALL_LEAVES_ERROR = 'SET_FETCH_ALL_LEAVES_ERROR'
const SET_FETCH_USER_LEAVES_ERROR = 'SET_FETCH_USER_LEAVES_ERROR'

const SET_USER_YEARS = 'SET_USER_YEARS'
const SET_USER_YEARS_FETCH_ERROR = 'SET_USER_YEARS_FETCH_ERROR'
const SET_USER_YEARS_LOADING = 'SET_USER_YEARS_LOADING'
const SET_USER_YEARS_OPEN_DIALOG = 'SET_USER_YEARS_OPEN_DIALOG'
const RESET_LEAVES = 'RESET_LEAVES'

const SET_FETCH_CANCEL_LEAVE_ERROR = 'SET_FETCH_CANCEL_LEAVE_ERROR'
const SET_FETCH_LEAVE_INFO_ERROR = 'SET_FETCH_LEAVE_INFO_ERROR'
const SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_TIMELINE = 'SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_TIMELINE'
const SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_ALL_LEAVES = 'SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_ALL_LEAVES'
export default {
    SET_OPEN_CONFIRMATION_DIALOG,
    SET_LEAVE_ID_TO_DELETE,
    SET_OPEN_NEW_REQUEST_DIALOG,
    SET_USERS,
    SET_CALENDAR_LEAVES,
    SET_USER_LEAVES,
    SET_LEAVE_DETAILS,
    SET_RANGE_PICKER_VALUE,
    SET_COMMENT_VALUE,
    CLEAR_USER_LEAVES,
    CLEAR_FORM,
    SET_LOADING_FORM,
    SET_LOADING_USER_LEAVES,
    SET_ALL_LEAVES,
    SET_LOADING_ALL_LEAVES,
    SET_FILTER_PAGE,
    SET_FILTER_STATUS,
    SET_FILTER_USER,
    SET_FILTER_PAGE_COUNT,
    SET_LOADING_USERS,SET_LOADING_CALENDAR_LEAVES,
    SET_FORM_STATUS_ID,
    SET_FORM_USER_ID,
    SET_FORM_RESPONSE,
    SET_LOADING_CANCEL_LEAVE,
    SET_OPEN_TIMELINE_INFO_DIALOG,
    SET_TIMELINE_DIALOG_ID,
    SET_LOADING_TIMELINE_INFO,
    SET_LOADING_CHANGE_STATUS,
    SET_ALL_TABLE_DIALOG_ID,
    SET_OPEN_ALL_TABLE_DIALOG,
    SET_ALL_TABLE_DIALOG_STATUS_ID,
    SET_RESPONSE_CHANGE_STATUS_TIMELINE,
    SET_RESPONSE_CHANGE_STATUS_ALL_LEAVES,
    SET_LOADING_CHANGE_STATUS_TIMELINE,
    SET_LOADING_CHANGE_STATUS_ALL_LEAVES,
    RESET_CHANGE_STATUS_TIMELINE,
    RESET_CHANGE_STATUS_ALL_LEAVES,
    SET_FETCH_USERS_ERROR,
    SET_FETCH_CALENDAR_LEAVES_ERROR,
    SET_FETCH_ALL_LEAVES_ERROR,
    SET_FETCH_USER_LEAVES_ERROR,
    SET_USER_YEARS,
    SET_USER_YEARS_FETCH_ERROR,
    SET_USER_YEARS_LOADING,
    SET_USER_YEARS_OPEN_DIALOG,
    RESET_LEAVES,
    SET_FETCH_CANCEL_LEAVE_ERROR,
    SET_FETCH_LEAVE_INFO_ERROR,
    SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_TIMELINE,
    SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_ALL_LEAVES
}
