import { useSelector,useDispatch } from "react-redux"
import actions from "../duck/actions"
import { getAllLeaves } from "../duck/operations"
import StatusSelect from "./StatusSelect"

const TableStatusSelect = () => {

    const dispatch = useDispatch()
    const status = useSelector(state=>state.leaves.tableFilters.statusId)

    const handleStatusChange = (status) => {
        //console.log("status change")
        dispatch(actions.setFilterStatus(status))
        dispatch(getAllLeaves())
    }
    
    return (
        <div style={{marginLeft:50,marginRight:50,width:200,display:'inline-flex'}}>
        <StatusSelect
            status={status}
            handleStatusChange={handleStatusChange}
        />
        </div>
    )
}

export default TableStatusSelect