import { AccordionDetails, Button, TextField, Typography, withStyles } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { connect } from "react-redux"
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import '../style.css'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';
import actions from "../duck/actions";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const NewPhoneNumber = (props) => {

    const [expandedNumber,setExpandedNumber] = useState(false)
    const [newNumber,setNewNumber] = useState("")
    const { enqueueSnackbar } = useSnackbar()
    const [short,setShort] = useState(false)
    const regex=/(^(\+?|))\d{0,14}$/


    const handleAddNumber = () => {
        if(regex.test(newNumber)&&newNumber.length>=6){
            props.addNewPhoneNumber(newNumber)
            setExpandedNumber(false)
            setNewNumber("")
            props.refresh()
        }else{
            enqueueSnackbar("Numer musi mieć od 6 do 14 znaków, może zaczynać się od plusa i zawierać tylko cyfry",{variant: 'warning',autoHideDuration: 5000})
        }  
      }

      
      const handleInputChange = (val) => {
        if(regex.test(val)){
            setNewNumber(val)
        }
        if(val.length<6){
            setShort(true)
        }else{
            setShort(false)
        }
      }

    return(
    <Accordion expanded={expandedNumber} onChange={()=>{setExpandedNumber(!expandedNumber)}}>
    <AccordionSummary  style={{backgroundColor:"#0750b5",color:"white"}}
    expandIcon={<AddIcCallIcon style={{color:"white",fontSize:"1.4rem"}} />}
    >
      <Typography className="infoText">DODAJ NOWY NUMER</Typography>
    </AccordionSummary>
    <AccordionDetails>
        <div>
        <TextField
            value={newNumber}
            style={{marginTop:"8px",marginBottom:"8px"}}
            label="Nowy numer telefonu"
            variant="outlined"
            fullWidth={true}
            inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
            onChange={(e) => handleInputChange(e.target.value)}
        />
        
        <Button onClick={() => {handleAddNumber()}} style={{backgroundColor:"rgb(67, 160, 71)",color:'white'}} fullWidth={true}>Dodaj</Button>
        </div>
        
        
    </AccordionDetails>
    </Accordion>
    )
}

const mapDispatchToProps = (dispatch) => {
  return{
    addNewPhoneNumber: (obj) => dispatch(actions.addNewPhoneNumber(obj)),
  }
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(NewPhoneNumber)