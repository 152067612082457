import { Grid } from "@mui/material"
import NavigationBar from "./navigation/NavigationBar"

const LeaveUserPanel = ({children}) => {

    return(
            <Grid container justifyContent='center' style={{textAlign:'center',minHeight:'90vh'}}>
                <Grid style={{
                    paddingTop:40,
                    paddingBottom:50,
                    paddingLeft:'30px',
                    paddingRight:'30px',
                    backgroundColor:'white'
                }} item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <NavigationBar add={true}/>
                    {children}
                </Grid>
            </Grid>
    )
}

export default LeaveUserPanel