import React from 'react';
import { Paper } from '@mui/material';

const colorSwitch = (statusId) => {
    switch(statusId){
        case 1:
            return "#ffab00"
        case 2:
            return "#0c9c00"
        case 3:
            return "#ec0000"
        case 4:
            return "#c8c8c8"
        default:
            return "black"
    }
}
const StatusBar = ({statusId,statusName}) => {

    const color = colorSwitch(statusId)

  return (
    <Paper
    style={{
        paddingTop:4,
        paddingBottom:4,
        fontWeight:'bold',
        color:color,
        border:`solid ${color} 2px`,
        boxShadow:'none',
        textAlign:'center'
    }}
    >
        {statusName}
    </Paper>
  );
}

export default StatusBar