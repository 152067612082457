
import { IconButton, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import "./style.css"
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from "notistack";
import { serverUrl } from "../config/serverConfig";
import { connect } from "react-redux";
import EmailIcon from '@material-ui/icons/Email';

const AddressInput = (props) => {
    const {address} = props
    const {addressId} = props
    const {counter} = props
    const {quantity} = props
    const {orderId} = props
    const [addressVal,setAddressVal] = useState(address)
    const [baseVal,setBaseVal] = useState(address)
    const { enqueueSnackbar } = useSnackbar()
    const token='Bearer '.concat(props.jwt)
    const [short,setShort] = useState(false)
    const [quantityVal,setQuantityVal] = useState(quantity)

    useEffect(() =>{
        setAddressVal(address)
        setBaseVal(address)
        setQuantityVal(quantity)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props])

    async function deleteAddress(addressId) {
        await fetch(`${serverUrl}/addresses?addressId=${addressId}&orderId=${orderId}`,
        {method:'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie usunięto adres",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Adres nie został usunięty",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Adres nie został usunięty",{variant: 'error',autoHideDuration: 2000})
        })
      }

      async function changeAddress(addressId,address) {
        await fetch(`${serverUrl}/addresses?addressId=${addressId}&address=${address}&orderId=${orderId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie edytowano adres",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Adres nie został edytowany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Adres nie został edytowany",{variant: 'error',autoHideDuration: 2000})
        })
      }

      const handleDeleteAddress = () => {
        if(1<quantityVal){
          deleteAddress(addressId)
        }
      }

      const handleChangeAddress = () => {
        if(addressVal.length>=6){
           changeAddress(addressId,encodeURIComponent(addressVal.trim()))
        }else{
            enqueueSnackbar("Adres musi mieć od 1 do 255 znaków",{variant: 'warning',autoHideDuration: 5000})
        }  
      }

      
      const changeAddressVal = (val) => {
        if(val.length<1){
            setShort(true)
        }else{
            setShort(false)
        }
        setAddressVal(val)
      }

    return(
        <div style={{marginTop:"30px"}}>
         <div className="iconContainer">
         <EmailIcon className="icon"/>{counter}
        </div>   
        <TextField
        value={addressVal}
        label="Adres korespondencyjny"
        variant="outlined"
        type="text"
        className="addressInput"
        inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
        onChange={(e)=>changeAddressVal(e.target.value)}
        />
        <IconButton disabled={addressVal===baseVal} className="saveButton" onClick={()=>{handleChangeAddress()}}>
            <SaveIcon className="saveIcon" style={{color:addressVal===baseVal?"grey":"green"}}/>
        </IconButton>
        <IconButton disabled={1>=quantityVal?true:false} className="saveButton" onClick={()=>{handleDeleteAddress()}}>
            <DeleteForeverIcon className="saveIcon" style={{color:1>=quantityVal?"grey":"red"}}/>
        </IconButton>
        </div>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,null)(AddressInput)