import types from './types';

const initial = {
    customerData:{
        customCustomer:false,
        customerId:null,
        customerSet:false,
        customerName:"",
        communityId:null,
        community:"",
        precinctId:null,
        precinct:"",
        plotNumber:"",
        projectName:"",
        addresses:[],
        newAddresses:[],
        emails:[],
        newEmails:[],
        phoneNumbers:[],
        newPhoneNumbers:[],
        arrayChange:true,
    },
    date:null,
    servicesGeod:[],
    servicesTypi:[],
    servicesIndi:[],
    servicesCond:[],
    servicesSani:[],
    servicesGeol:[],
    servicesChange:true,
    net:true,
    comment:null,
    geodesy:false,
    typicalProjects:false,
    individualProjects:false,
    sanitaryInstallations:false,
    conditions:false,
    geology:false,
    stepNumber:0,
    supervisorGeod:"",
    supervisorTypi:"",
    supervisorIndi:"",
    supervisorCond:"",
    supervisorSani:"",
    supervisorGeol:"",
}



const removePhone = (array,id) => {
    let newArray=[]
    array.forEach(element => {
        if(element.phoneNumberId!==id){
            newArray.push(element)
        }
    });
    return newArray
}

const removeNewPhone = (array,phoneNumber) => {
    const index = array.indexOf(phoneNumber);
    if (index > -1) {
      array.splice(index, 1);
    }
    //console.log(array)
    return array
}

const pushNumber = (array,obj) => {
    array.push(obj)
    console.log(array)
    return array
}

const addNewPhoneNumber = (array,phoneNumber) =>{
    array.push(phoneNumber)
    return array
}

const removeAddress = (array,id) => {
    let newArray=[]
    array.forEach(element => {
        if(element.addressId!==id){
            newArray.push(element)
        }
    });
    return newArray
}

const removeNewAddress = (array,address) => {
    const index = array.indexOf(address);
    if (index > -1) {
      array.splice(index, 1);
    }
    console.log(array)
    return array
}

const pushAddress = (array,obj) => {
    array.push(obj)
    //console.log(array)
    return array
}

const addNewAddress = (array,address) =>{
    array.push(address)
    return array
}

const removeEmails = (array,id) => {
    let newArray=[]
    array.forEach(element => {
        if(element.emailId!==id){
            newArray.push(element)
        }
    });
    return newArray
}

const removeNewEmail = (array,email) => {
    const index = array.indexOf(email);
    if (index > -1) {
      array.splice(index, 1);
    }
    console.log(array)
    return array
}

const pushEmail = (array,obj) => {
    array.push(obj)
    console.log(array)
    return array
}

const addNewEmail = (array,email) =>{
    array.push(email)
    return array
}

const addService = (array,object) => {
    array.push({
        serviceId:object.serviceId,
        price:"",
        undefinedPrice:false,
        serviceName:object.serviceName,
    })
    return array
}

const removeService = (array,serviceId) => {
    let newArray=[]
    array.forEach(element => {
        if(element.serviceId!==serviceId){
            newArray.push(element)
        }
    });
    return newArray
}

const changeService = (array,object) => {
    let newArray=[]
    array.forEach(element => {
        if(element.serviceId===object.serviceId){
            if(object.price===""){
                newArray.push({
                    serviceId:object.serviceId,
                    price:"",
                    undefinedPrice:object.undefinedPrice,
                    serviceName:object.serviceName,
                })
            }else{
                newArray.push({
                    serviceId:object.serviceId,
                    price:object.price,
                    undefinedPrice:object.undefinedPrice,
                    serviceName:object.serviceName,
                })
            }
            
        }else{
            newArray.push(element)
        }
    });
    return newArray
}


function servicesReducer(state = initial, action)
{
    switch(action.type){

        case types.RESET_FORM:
        return initial

        case types.SET_CUSTOMER_ID:
        return {
            ...state,customerData:{...state.customerData, customerId:action.item}
        }

        case types.SET_CUSTOMER_NAME:
        return {
            ...state,customerData:{...state.customerData, customerName:action.item}
        }

        case types.SET_COMMUNITY_ID:
        return {
            ...state,customerData:{...state.customerData, communityId:action.item}
        }

        case types.SET_COMMUNITY:
        return {
            ...state,customerData:{...state.customerData, community:action.item}
        }

        case types.SET_PRECINCT_ID:
        return {
            ...state,customerData:{...state.customerData, precinctId:action.item}
        }

        case types.SET_PRECINCT:
        return{
            ...state,customerData:{...state.customerData, precinct:action.item}
        }

        case types.SET_PLOT_NUMBER:
        return{
            ...state,customerData:{...state.customerData, plotNumber:action.item}
        }

        case types.SET_CUSTOM_CUSTOMER:
        return{
            ...state,customerData:{...state.customerData, customCustomer:action.item}
        }

        case types.SET_PROJECT_NAME:
        return{
            ...state,customerData:{...state.customerData, projectName:action.item}
        }

        case types.SET_CUSTOMER_SET:
        return{
            ...state,customerData:{...state.customerData, customerSet:action.item}
        }




        case types.ADD_NEW_PHONE_NUMBER:
        return{
            ...state,customerData:{...state.customerData, 
                newPhoneNumbers:addNewPhoneNumber(state.customerData.newPhoneNumbers,action.item),arrayChange:!state.customerData.arrayChange}
                
        }

        case types.ADD_PHONE_NUMBER:
        return{
            ...state,customerData:{...state.customerData, 
                phoneNumbers:pushNumber(state.customerData.phoneNumbers,action.item),arrayChange:!state.customerData.arrayChange}
        }

        case types.REMOVE_PHONE_NUMBER:
            console.log(removePhone(state.customerData.phoneNumbers,action.item))
        return{
            ...state,customerData:{...state.customerData, arrayChange:!state.customerData.arrayChange,
             phoneNumbers:removePhone(state.customerData.phoneNumbers,action.item)}
        }
        
        case types.CLEAR_PHONE_NUMBERS:
        return{
            ...state,customerData:{...state.customerData, phoneNumbers:[]}
        }

        case types.REMOVE_NEW_PHONE_NUMBER:
        return{
            ...state,customerData:{...state.customerData, newPhoneNumbers:removeNewPhone(state.customerData.newPhoneNumbers,action.item),arrayChange:!state.customerData.arrayChange}
        }
        
        case types.CLEAR_NEW_PHONE_NUMBERS:
        return{
            ...state,customerData:{...state.customerData, newPhoneNumbers:[]}
        }



        case types.ADD_NEW_ADDRESS:
        return{
            ...state,customerData:{...state.customerData, 
                newAddresses:addNewAddress(state.customerData.newAddresses,action.item),arrayChange:!state.customerData.arrayChange}
        }

        case types.ADD_ADDRESS:
        return{
            ...state,customerData:{...state.customerData, 
                addresses:pushAddress(state.customerData.addresses,action.item),arrayChange:!state.customerData.arrayChange}
        }

        case types.REMOVE_ADDRESS:
        return{
            ...state,customerData:{...state.customerData, arrayChange:!state.customerData.arrayChange,
                addresses:removeAddress(state.customerData.addresses,action.item)}
        }
        
        case types.CLEAR_ADDRESSES:
        return{
            ...state,customerData:{...state.customerData, addresses:[]}
        }

        case types.REMOVE_NEW_ADDRESS:
        return{
            ...state,customerData:{...state.customerData, newAddresses:removeNewAddress(state.customerData.newAddresses,action.item),arrayChange:!state.customerData.arrayChange}
        }

        case types.CLEAR_NEW_ADDRESSES:
        return{
            ...state,customerData:{...state.customerData, newAddresses:[]}
        }



        case types.ADD_NEW_EMAIL:
        return{
            ...state,customerData:{...state.customerData, 
                newEmails:addNewEmail(state.customerData.newEmails,action.item),arrayChange:!state.customerData.arrayChange}
        }

        case types.ADD_EMAIL:
        return{
            ...state,customerData:{...state.customerData, 
                emails:pushEmail(state.customerData.emails,action.item),arrayChange:!state.customerData.arrayChange}
        }

        case types.REMOVE_EMAIL:
        return{
            ...state,customerData:{...state.customerData, arrayChange:!state.customerData.arrayChange,
                emails:removeEmails(state.customerData.emails,action.item)}
        }
        
        case types.CLEAR_EMAILS:
        return{
            ...state,customerData:{...state.customerData, emails:[]}
        }

        case types.REMOVE_NEW_EMAIL:
        return{
            ...state,customerData:{...state.customerData, newEmails:removeNewEmail(state.customerData.newEmails,action.item)}
        }

        case types.CLEAR_NEW_EMAILS:
        return{
            ...state,customerData:{...state.customerData, newEmails:[]}
        }

        case types.SET_STEP:
        return{
            ...state,stepNumber:action.item
        }

        case types.SET_GEODESY_OPEN:
        return{
            ...state,geodesy:action.item
        }

        case types.SET_TYPICAL_PROJECTS_OPEN:
        return{
            ...state,typicalProjects:action.item
        }

        case types.SET_INDIVIDUAL_PROJECTS_OPEN:
        return{
            ...state,individualProjects:action.item
        }

        case types.SET_CONDITIONS_OPEN:
        return{
            ...state,conditions:action.item
        }

        case types.SET_GEOLOGY_OPEN:
        return{
            ...state,geology:action.item
        }

        case types.SET_SANITARY_INSTALLATIONS_OPEN:
        return{
            ...state,sanitaryInstallations:action.item
        }


        case types.SET_NET:
        return{
            ...state,net:action.item
        }

        case types.ADD_SERVICE_GEOD:
        return{
            ...state,servicesGeod:addService(state.servicesGeod,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.REMOVE_SERVICE_GEOD:
        return{
            ...state,servicesGeod:removeService(state.servicesGeod,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.CHANGE_SERVICE_GEOD:
        return{
            ...state,servicesGeod:changeService(state.servicesGeod,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.ADD_SERVICE_TYPI:
        return{
            ...state,servicesTypi:addService(state.servicesTypi,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.REMOVE_SERVICE_TYPI:
        return{
            ...state,servicesTypi:removeService(state.servicesTypi,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.CHANGE_SERVICE_TYPI:
        return{
            ...state,servicesTypi:changeService(state.servicesTypi,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.ADD_SERVICE_INDI:
        return{
            ...state,servicesIndi:addService(state.servicesIndi,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.REMOVE_SERVICE_INDI:
        return{
            ...state,servicesIndi:removeService(state.servicesIndi,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.CHANGE_SERVICE_INDI:
        return{
            ...state,servicesIndi:changeService(state.servicesIndi,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.ADD_SERVICE_COND:
        return{
            ...state,servicesCond:addService(state.servicesCond,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.REMOVE_SERVICE_COND:
        return{
            ...state,servicesCond:removeService(state.servicesCond,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.CHANGE_SERVICE_COND:
        return{
            ...state,servicesCond:changeService(state.servicesCond,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.ADD_SERVICE_SANI:
        return{
            ...state,servicesSani:addService(state.servicesSani,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.REMOVE_SERVICE_SANI:
        return{
            ...state,servicesSani:removeService(state.servicesSani,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.CHANGE_SERVICE_SANI:
        return{
            ...state,servicesSani:changeService(state.servicesSani,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.ADD_SERVICE_GEOL:
        return{
            ...state,servicesGeol:addService(state.servicesGeol,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.REMOVE_SERVICE_GEOL:
        return{
            ...state,servicesGeol:removeService(state.servicesGeol,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.CHANGE_SERVICE_GEOL:
        return{
            ...state,servicesGeol:changeService(state.servicesGeol,action.item), 
            servicesChange:!state.servicesChange
        }

        case types.SET_DATE:
        return{
            ...state,date:action.item
        }

        case types.ADD_COMMENT:
        return{
            ...state,comment:action.item
        }



        case types.SET_SUPERVISOR_GEOD:
        return{
            ...state,supervisorGeod:action.item
        }

        case types.SET_SUPERVISOR_GEOL:
        return{
            ...state,supervisorGeol:action.item
        }

        case types.SET_SUPERVISOR_TYPI:
        return{
            ...state,supervisorTypi:action.item
        }

        case types.SET_SUPERVISOR_INDI:
        return{
            ...state,supervisorIndi:action.item
        }

        case types.SET_SUPERVISOR_COND:
        return{
            ...state,supervisorCond:action.item
        }

        case types.SET_SUPERVISOR_SANI:
        return{
            ...state,supervisorSani:action.item
        }

        default:return state;
    }
    
}

export default servicesReducer;