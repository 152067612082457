import { Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverUrl } from "../config/serverConfig";
import DOMPurify from 'dompurify';


const ReportView = ({reportId,jwt}) => {

    const [report,setReport] = useState()
    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    
    useEffect(() => {
        async function getData() {

          await fetch(`${serverUrl}/reports/report?reportId=${reportId}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            console.log(stateToHTML(convertFromRaw(JSON.parse(json.content))));
            setReport(json)
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
          })
        }
        if (isLoading) {
          getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    return(
        <>
        {isLoading===true?<CircularProgress/>:
        <Typography
        style={{
            width:'95%',
            marginLeft:'auto',
            marginRight:'auto',
            padding:'15px',
            backgroundColor:'white',
            border:'solid #0750b582 1px',
            borderRadius:'5px'
        }} onClick={(e)=>e.stopPropagation()} dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(stateToHTML(convertFromRaw(JSON.parse(report.content))))}}/> 
        }
        
        </>
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(ReportView)