import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import moment from 'moment';
import convertToLocal from '../convertToLocal';
import font from "../fonts/roboto-medium-webfont.ttf";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Html from 'react-pdf-html';

Font.register({
  family: "Roboto",
  src: font
});
const styles = StyleSheet.create({
    table: { 
        display: "table", 
        width: "80%",
        marginLeft:'5%',
        borderStyle: "solid", 
        marginTop:'20px',
        borderWidth: 0,
        fontFamily:'Roboto',
        textAlign:'left'
    },
    tableRow: { 
      flexDirection: "row" 
    },
    tableColL: { 
      width: "5%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },
    tableColR: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColM: { 
        width: "50%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColM2: { 
        width: "15%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColLHead: { 
        width: "20%",
        borderStyle: "solid", 
        borderWidth: 1,
        borderTopWidth: 0 
    },
    tableColFullWidth: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1,
        borderTopWidth: 0 
    },
    tableColFullWidthBold: { 
        width: "100%", 
        borderStyle: "solid",
        fontWeight:'bold',
        borderWidth: 1,
    },
    tableColRHead: { 
        width: "80%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCell: {
      
      fontSize: 9 ,
      textAlign:'left',
      marginLeft:5
    },
    tableCellBot: {
        
        fontSize: 9 ,
        textAlign:'right',
        marginLeft:5
    },
    tableColNoBorder: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth:0 
    },
    tableCellHead: {
        marginTop: 1,
        marginBottom:1,
        fontSize: 10 ,
        textAlign:'left',
        marginLeft:5,
        fontWeight:'bold'
    },
    tableColLBot: { 
        width: "55%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColRBot: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColMBot: { 
        width: "15%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColRSum: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColLSum: { 
        width: "55%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColMSum: { 
        width: "15%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
});



const PdfReportDay = ({data}) =>{
    //console.log("pdf:",data)
    let x=0
    return(
        <>
        <View style={styles.table}> 
            {
            data.reports.length===0?
            <View wrap={false}>
            <View style={styles.tableColFullWidthBold} wrap={false}>
                <Text style={styles.tableCellHead}>{moment.utc(data.date,"YYYY-MM-DD HH:mm:ss").local().format('YYYY-MM-DD').concat("  -  ").concat(moment.utc(data.date,"YYYY-MM-DD HH:mm:ss").local().format('dddd'))}</Text> 
            </View>
            <View style={styles.tableColFullWidth} wrap={false}>
                <Text style={styles.tableCellHead}>Brak raportów w tym dniu.</Text>
            </View>
            </View>
            :
            <View wrap={false}>
            <View style={styles.tableColFullWidthBold} wrap={false}>
                <Text style={styles.tableCellHead}>{moment.utc(data.date,"YYYY-MM-DD HH:mm:ss").local().format('YYYY-MM-DD').concat("  -  ").concat(moment.utc(data.date,"YYYY-MM-DD HH:mm:ss").local().format('dddd')).concat(" ("+data.reports.length+")")}</Text> 
            </View>
            <View style={styles.tableRow} wrap={false}>
            <View style={styles.tableColLHead}>
                <Text style={styles.tableCellHead}>{data.reports[0].userName+' '}{convertToLocal(data.reports[0].entryDate)}</Text>
            </View> 
            <View style={styles.tableColRHead}> 
                <View style={styles.tableCellHead}><Html style={{ fontSize: 9 }}>{"<p style='font-size:10px !importnat'>".concat(stateToHTML(convertFromRaw(JSON.parse(data.reports[0].content)))).concat("</p>")}</Html></View> 
            </View>
            </View>
            </View>
            }{
                data.reports.length===0?null:
                data.reports.map((report)=>{
                    x++
                    //console.log(stateToHTML(convertFromRaw(JSON.parse(report.content))))
                    return(
                    x<=1?null:
                    <View style={styles.tableRow} wrap={false}>
                    <View style={styles.tableColLHead}>
                        <Text style={styles.tableCellHead}>{report.userName+' '}{convertToLocal(report.entryDate)}</Text> 
                    </View> 
                    <View style={styles.tableColRHead}> 
                        <View style={styles.tableCellHead}><Html style={{ fontSize: 9 }}>{"<p style='font-size:10px !importnat'>".concat(stateToHTML(convertFromRaw(JSON.parse(report.content)))).concat("</p>")}</Html></View> 
                    </View>
                    </View>
                    )
                })
            }
            </View>
        </>
    )
}

export default PdfReportDay