import { CircularProgress } from "@material-ui/core"
import { useState,useEffect } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IconButton } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from "notistack";
import DeleteDialog from "./DeleteDialog";
import convertToLocal from "../convertToLocal";

const FilesList = ({orderId,categoryId,jwt,refreshTabel,refreshIcons}) => {

    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    const [files,setFiles] = useState() 
    const [refresh,setRefresh] = useState(0)
    const {enqueueSnackbar} = useSnackbar()
    const [deleteDialogOpen,setDeleteDialogOpen] = useState(false)
    const [deleteId,setDeleteId] = useState()
    const [deleteName,setDeleteName] = useState()
    useEffect(() => {
        console.log("jest odświerzana tabela")
        setRefresh(refresh+1)
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTabel])

    useEffect(() => {
        async function getData() {
            await fetch(`${serverUrl}/file-system/files-list?orderId=${orderId}&categoryId=${categoryId}`,
            {method:'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
            })
            .then(response => response.json())
            .then(json => {
              console.log(json);
              setFiles(json);
              setIsLoading(false);
            })
            .catch(error => {
              console.log(error);
            })
        }
        if (isLoading||refresh>0) {getData()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const downloadFile = async(fileId,name) =>{
        await fetch(`${serverUrl}/file-system/file/${fileId}`,
        {method:'GET',
            headers: {
                'Content-Type': "application/octet-stream",
                'Authorization': token,
            },
        })
        .then(response => response.blob())
        .then(file => {
            console.log(file);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = name
            link.click();
        // setIsLoadingIcon(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const deleteFile = async(fileId,name) =>{
        await fetch(`${serverUrl}/file-system/file/${fileId}`,
        {method:'DELETE',
            headers: {
                'Content-Type': "application/octet-stream",
                'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(fileId => {
            console.log(fileId);
            setRefresh(refresh+1)
            refreshIcons()
            enqueueSnackbar("Pomyslnie usunięto plik - ".concat(name),{variant: 'success',autoHideDuration: 2000})
        // setIsLoadingIcon(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDownloadFile = (fileId,name) => {
        downloadFile(fileId,name)
    }


    const openDeleteDialog = (costId,name) => {
        setDeleteDialogOpen(true)
        setDeleteId(costId)
        setDeleteName(name)
    }

    const closeDialogDelete = (confirmed) => {
        console.log(confirmed)
        if(confirmed===true){
            deleteFile(deleteId,deleteName)
            setDeleteDialogOpen(false)
        }else{
            setDeleteDialogOpen(false)
        }
    }

    return(
        <>
        {isLoading===true?<CircularProgress/>:
        <TableContainer component={Paper} style={{marginBottom:'24px'}}>
        <Table size="small">
            <colgroup>
                <col style={{width:1}}/>
                <col style={{width:10}}/>
                <col style={{width:4}}/>
                <col style={{width:6}}/>
                <col style={{width:6}}/>
            </colgroup>
            <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">Nazwa</TableCell>
                <TableCell align="left">Rozmiar</TableCell>
                <TableCell align="left">Data</TableCell>
                <TableCell align="center">Funkcje</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {files.length===0?
            <TableRow>
                <TableCell style={{textAlign:'center',paddingTop:'10px',paddingBottom:'10px'}} colSpan={5}>Brak plików do wyświetlenia.</TableCell>
            </TableRow>
            :
            files.map((file,index) => (
                <TableRow key={file.fileId}>
                <TableCell component="th" scope="row">{index+1}</TableCell>
                <TableCell align="left">{file.name}</TableCell>
                <TableCell align="left">{parseFloat(file.size/1024/1024).toFixed(2)+"MB"}</TableCell>
                <TableCell align="left">{convertToLocal(file.entryDate)}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={()=>handleDownloadFile(file.fileId,file.name)}>
                        <GetAppIcon style={{color:'#0750b5'}}/>
                    </IconButton>
                    <IconButton onClick={()=>openDeleteDialog(file.fileId,file.name)}>
                        <DeleteForeverIcon style={{color:'red'}}/>
                    </IconButton>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        
        }
        <DeleteDialog open={deleteDialogOpen} fileName={deleteName} fileId={deleteId} closeDialog={closeDialogDelete}/>
        </>
    )
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(FilesList)