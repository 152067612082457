import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Table,TableContainer,TableBody,TableHead,TableRow, withStyles, CircularProgress,TableCell } from "@material-ui/core"
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverUrl } from "../../config/serverConfig";


const StyledTableCellSmall = withStyles((theme) => ({
    head: {
        fontSize: "1rem",
        backgroundColor:"rgb(7, 80, 181)",
        color:"white",
        textAlign:"center",
      },
    body: {
      fontSize: "1rem",
      textAlign:"center",
    },
    root: {
        fontFamily:"arial",
    },
  }))(TableCell);

const KergHistoryTable = ({jwt,orderId}) =>{

    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const [refresh,setRefresh] = useState(0)
    const token="Bearer ".concat(jwt)

    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/history/kerg?orderId=${orderId}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
              console.log(json)
            setData(json);
            setIsLoading(false);
          })
          .catch(error => {console.log(error);})
        }
        if (isLoading||refresh>0) {getData();}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    return(
        <TableContainer component={Paper}>
            <Table size="small" >
                <TableHead>
                <TableRow>
                    <StyledTableCellSmall >Data</StyledTableCellSmall>
                    <StyledTableCellSmall>Kerg</StyledTableCellSmall>
                </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading===true?<CircularProgress/>:
                    data.length===0?
                    <TableRow>
                        <TableCell style={{textAlign:'center'}} colSpan={2}><Typography>Nie dokonano jeszcze zmian.</Typography></TableCell>
                    </TableRow>
                    :
                    data.map((item) => (
                        <TableRow key={item.activityId}>
                            <StyledTableCellSmall>{item.date.substr(0,16)}</StyledTableCellSmall>
                            <StyledTableCellSmall>{item.afterChange}</StyledTableCellSmall>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(KergHistoryTable)