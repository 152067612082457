import TableUserSelect from "./TableUserSelect"
import TableStatusSelect from "./TableStatusSelect"


const FilterBar = () => {

    return(
        <div style={{marginTop:20,width:'100%'}}>
            <TableStatusSelect/>
            <TableUserSelect/>
        </div>
    )
}

export default FilterBar