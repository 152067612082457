import { Accordion, AccordionDetails, AccordionSummary, IconButton, TextField } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import SaveIcon from '@material-ui/icons/Save';
import { serverUrl } from "../../config/serverConfig";
import PickUpHistoryTable from "./PickUpHistoryTable";

function GeologyPickUp(props) {
  
  const [refresh,setRefresh] = useState(0)
  const { enqueueSnackbar } = useSnackbar();
  const [value,setValue] = useState("");
  const [expanded,setExpanded] = useState(false)
  const token='Bearer '.concat(props.jwt)
  const [isLoading,setIsLoading] = useState(true)
  const [baseValue,setBaseValue] = useState()
  const [canSave,setCanSave] = useState(false)
  const color2="green"

  useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/orders/${props.orderId}/geology-others-props`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        //console.log(json);
        setIsLoading(false)
        setValue(json.geologyPickUp)
        setBaseValue(json.geologyPickUp)
      })
      .catch(error => {
        
      })
    }
    if (isLoading||refresh>0) {
      getData()
      setRefresh(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  async function changeDate(value) {
    await fetch(`${serverUrl}/conditions/${props.orderId}/geology-pick-up?content=${value}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie zmieniono datę odbioru - Geologia",{variant: 'success',autoHideDuration: 2000})
        setBaseValue(value)
        setCanSave(false)
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }


  function handleDateChange(e){
      setValue(e.target.value)
      if(e.target.value!==baseValue){
        setCanSave(true)
      }else{
        setCanSave(false)
      }
      //
      //console.log("date: ",date.format('YYYY-MM-DD [00:00:00]').toString())
      //
  }

  const handleSave = () => {
    changeDate(value)
    setRefresh(refresh+1)
    props.onChange()
    
  }

    return(
        <Accordion expanded={expanded} style={{marginBottom:"15px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={()=>{setExpanded(!expanded)}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span style={{fontSize:'1.3rem',fontFamily:'arial',width:'60%',textAlign:'center',display:'flex',alignItems:'center'}}>
              Data odbioru
          </span>
          
          <TextField
            value={value}
            style={{width:'48%'}}
            label="Geologia odbiór"
            variant="outlined"
            size="small"
            inputProps={{style:{textAlign:"center",fontSize:'1.2rem',fontFamily:'arial',color:color2}}}
            onChange={(e)=>handleDateChange(e)}
          />
          <IconButton disabled={!canSave} style={{width:'10%'}} onClick={()=>{handleSave()}}>
            <SaveIcon style={{color:canSave?"green":"grey"}}/>
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
            <PickUpHistoryTable orderId={props.orderId} key={refresh}/>
        </AccordionDetails>
        </Accordion>
    )
}

export default connect((state)=>({jwt:state.log.jwt}))(GeologyPickUp)