import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { connect } from 'react-redux';
import actions from '../duck/actions';

function DialogClear(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (decision) => {
    if(decision===true){
      props.resetForm()
    }
    props.closeDialog()
  };

  useEffect(() => {
      setOpen(props.open)  
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <div>
      <Dialog
        open={open}
      >
        <DialogTitle>
            <ReportProblemOutlinedIcon style={{color:"#ff9800",fontSize:"2rem",display:"block",float:"left"}}/>
            {"Czy na pewno chcesz wyczyścić cały formularz?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zostaną usunięte WSZYSTKIE dane znajdujące się w formularzu!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={()=>handleClose(false)} >
            ANULUJ
          </Button>
          <Button color="secondary" variant="contained" onClick={()=>handleClose(true)}  autoFocus>
            POTWIERDŹ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => {
  return{
    resetForm: () => dispatch(actions.resetForm()),
  }
}

export default connect(null,mapDispatchToProps)(DialogClear)