import { Button, Grid, Paper, Step, StepConnector, StepLabel, Stepper, withStyles } from "@material-ui/core"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useState, useEffect } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import "./style.css"
import Data from "./data/Data";
import { connect } from "react-redux";
import Services from "./services/Services";
import actions from "./duck/actions";
import Summary from "./summary/Summary";
import SaveNewOrder from "./SaveNewOrder";

const CustomConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    active: {
      '& $line': {
        borderColor: '#ff9800',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#43a047',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,

    },
  })(StepConnector);
  
  function CustomStepIcon(props) {
    const { active, completed } = props;
  
    if(active===true){
        return <ExpandMoreOutlinedIcon style={{color:"#ff9800"}}/>
        
    }
    if(completed===true){
        return <CheckCircleIcon style={{color:"#43a047"}} />
    }else{
        return <HelpOutlineIcon style={{color:"grey"}}/>
    }

    
  }
  
  

function getSteps() {
  return ['Dane','Usługi','Podsumowanie','Zakończ']
}



function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <Data/>;
    case 1:
      return <Services/>;
    case 2:
      return <Summary/>;
    case 3:
      return <SaveNewOrder/>;
    case 4:
      return 'Zakończ';
    default:
      return 'To nie powinno się pojawić';
  }
}

const NewOrder = (props) => {

  const checkValid = (step) => {
    if(step===0){
      //console.log("leci valid")
      if(props.customerSet!==true){
        //console.log(props.customerSet)
        return false
      }
      if(props.precinct.length<1){
        //console.log(props.precinct)
        return false
      }
      if(props.plotNumber.length<1){
        //console.log(props.precinct)
        return false
      }
      if(props.precinct.length<1){
        //console.log(props.precinct)
        return false
      }
      if(props.addresses.length<1&&props.newAddresses.length<1){
        //console.log(props.addresses,props.newAddresses)
        return false
      }
      if(props.phoneNumbers.length<1&&props.newPhoneNumbers.length<1){
        //console.log(props.phoneNumbers,props.newPhoneNumbers)
        return false
      }
      return true
    }
    if(step===1){
      let errors=0
      const allServices = props.servicesGeod.concat(props.servicesGeol,props.servicesIndi,props.servicesSani,props.servicesTypi,props.servicesCond)
      if(allServices.length>=1){
        allServices.forEach(service => {
          if(service.price===""&&service.undefinedPrice===false){
            errors++
            
          }
        })
        //console.log("errors: ",errors)
        if(errors===0){
          //console.log("nie ma błędów")
          return true
        }else{
          //console.log("są błędy")
          return false
        }
      }
      //console.log("tablica jest krótsza niż 1")
      return false
    }
    if(step===2){
      if(props.servicesGeod.length>0&&props.supervisorGeod===""){
        return false
      }
      if(props.servicesGeol.length>0&&props.supervisorGeol===""){
        return false
      }
      if(props.servicesTypi.length>0&&props.supervisorTypi===""){
        return false
      }
      if(props.servicesIndi.length>0&&props.supervisorIndi===""){
        return false
      }
      if(props.servicesCond.length>0&&props.supervisorCond===""){
        return false
      }
      if(props.servicesSani.length>0&&props.supervisorSani===""){
        return false
      }
      return true // TUTAJ JESZCZE JUTRO POPRAWIC BO WIXA Z TYMI JAK SIE ODHACZA I WYCHODZI I WRACA
    }
  }
    
    const [activeStep, setActiveStep] = useState(props.stepNumber);
    const steps = getSteps();
    const [disabledNext,setDisabledNext]=useState(!checkValid(activeStep))

    useEffect(()=>{
        setDisabledNext(!checkValid(activeStep))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
      props.arrayChange,
      props.servicesChange,
      props.supervisorGeod,
      props.supervisorGeol,
      props.supervisorIndi,
      props.supervisorTypi,
      props.supervisorSani,
      props.supervisorCond,
      props.customCustomer,
      props.customerId,
      props.customerSet,
      props.customerName,
      props.precinct,
      props.plotNumber,
      
    ])

    useEffect(()=>{
      if(activeStep===3){}
      props.setStep(activeStep)
      setDisabledNext(!checkValid(activeStep))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeStep])

    useEffect(()=>{
      setActiveStep(props.stepNumber)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.stepNumber])

    const handleGoNext = () => {
      setActiveStep(activeStep+1)
    }

    
      
    return(
        
        <Grid container className="mainContainer" justify="center">
            <Grid item xs={12} sm={11} md={10} lg={8} xl={7} style={{marginBottom:"10px"}}>
                <Paper className="mainPaper">
                    <Stepper activeStep={activeStep} alternativeLabel className="stepper" connector={<CustomConnector />}>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                    {getStepContent(activeStep)}
                    <div className="footer">
                      <Button 
                      style={{float:"left"}}
                      variant="contained" 
                      className="actionButton" 
                      disabled={activeStep===0?true:false} 
                      onClick={()=>{setActiveStep(activeStep-1)}}>
                        wstecz
                      </Button> 
                      <Button 
                      variant="contained" 
                      className="actionButton" 
                      disabled={disabledNext}  
                      onClick={()=>handleGoNext()}
                      style={{backgroundColor:disabledNext===false?"#43a047":null,color:disabledNext===false?"white":null,float:"right"}}>
                        dalej
                      </Button>
                    </div>
                   
                    <div style={{clear:"both"}}/>
                </Paper>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) =>{
  return{
    customerId:state.newOrder.customerData.customerId,
    customCustomer:state.newOrder.customerData.customCustomer,
    customerSet:state.newOrder.customerData.customerSet,
    customerName:state.newOrder.customerData.customerName,
    community:state.newOrder.customerData.community,
    precinct:state.newOrder.customerData.precinct,
    plotNumber:state.newOrder.customerData.plotNumber,
    addresses:state.newOrder.customerData.addresses,
    emails:state.newOrder.customerData.emails,
    phoneNumbers:state.newOrder.customerData.phoneNumbers,
    newAddresses:state.newOrder.customerData.newAddresses,
    newEmails:state.newOrder.customerData.newEmails,
    newPhoneNumbers:state.newOrder.customerData.newPhoneNumbers,
    arrayChange:state.newOrder.customerData.arrayChange,
    stepNumber:state.newOrder.stepNumber,
    servicesGeod:state.newOrder.servicesGeod,
    servicesGeol:state.newOrder.servicesGeol,
    servicesSani:state.newOrder.servicesSani,
    servicesIndi:state.newOrder.servicesIndi,
    servicesTypi:state.newOrder.servicesTypi,
    servicesCond:state.newOrder.servicesCond,
    servicesChange:state.newOrder.servicesChange,
    supervisorGeod:state.newOrder.supervisorGeod,
    supervisorGeol:state.newOrder.supervisorGeol,
    supervisorIndi:state.newOrder.supervisorIndi,
    supervisorTypi:state.newOrder.supervisorTypi,
    supervisorCond:state.newOrder.supervisorCond,
    supervisorSani:state.newOrder.supervisorSani,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      setStep: (obj) => dispatch(actions.setStep(obj)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewOrder)