

import {Grid} from "@material-ui/core"
import ConditionsTable from "./ConditionsTable"
import GeodesyTable from "./GeodesyTable"
import GeologyTable from "./GeologyTable"
import IndividualProjectsTable from "./IndividualProjectsTable"
import NetSwitch from "./NetSwitch"
import SanitaryInstallationsTable from "./SanitaryInstallationsTable"
import TypicalProjectsTable from "./TypicalProjectsTable"


const Services = (props) => {

    
    return(
        <Grid container justify="center" style={{paddingLeft:"2%",paddingRight:"2%"}}>
           
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="accordionGrid">
                <NetSwitch/>
            </Grid>
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className="accordionGrid">
                <GeodesyTable/>
            </Grid>
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className="accordionGrid">
                <TypicalProjectsTable/>
            </Grid>
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className="accordionGrid">
                <IndividualProjectsTable/>
            </Grid>
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className="accordionGrid">
                <SanitaryInstallationsTable/>
            </Grid>
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className="accordionGrid">
                <ConditionsTable/>
            </Grid>
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} className="accordionGrid">
                <GeologyTable/>
            </Grid>
        </Grid>
    )
    
}

  
  export default (Services)