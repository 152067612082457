import { DialogContent, useMediaQuery, useTheme } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { useEffect, useState } from "react";
import NameInput from "./NameInput";
import PriceInput from "./PriceInput";


const EditDialog = ({open,refresh,cost,close,orderId}) => {

    const [openDialog, setOpenDialog] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    
    const handleClose = () => {
        close()
    }

    useEffect(() => {
        setOpenDialog(open)  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
          <Dialog
            open={openDialog}
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
          >
            <DialogTitle>{"Edycja kosztu:"}</DialogTitle>
            <DialogContent>
              <NameInput orderId={orderId} cost={cost} refresh={refresh}/>
              <PriceInput orderId={orderId} cost={cost} refresh={refresh}/>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={()=>handleClose()}>
                ZAKOŃCZ
              </Button>
            </DialogActions>
          </Dialog>
      );
}

export default EditDialog