import { useEffect, useState } from "react";
import { TextField } from '@material-ui/core';
import "../style.css"
import { connect } from 'react-redux';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import actions from '../duck/actions';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

const ProjectNameInput = (props) => {
  const [val,setVal] = useState(props.projectName)
  const [valid,setValid] = useState(false)

  useEffect(()=>{
    if(props.projectName===""){
      setValid(false)
    }else{
      setValid(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleInputChange = (value) => {
    if(val.length<255){
        setValid(true)
        setVal(value)
        props.setProjectName(value)
    }
  }

  useEffect(()=>{
    setVal(props.projectName)
  },[props.projectName])

  return(
      <div className="inputContainer">
      <div className="iconContainerNew">
        <HomeRoundedIcon className="icon"/>
      </div>
      <TextField
      value={val}
      label="Nazwa projektu"
      variant="outlined"
      type="text"
      className="bigInput"
      inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:"#0750b5"}}}
      onChange={(e)=>handleInputChange(e.target.value)}
      
      />
      <div className="iconContainer">
      {val===""?<HelpOutlineIcon className="icon" style={{color:"grey"}}/>:valid===true?<CheckCircleIcon className="icon" style={{color:"#43a047"}}/>:<CancelOutlinedIcon className="icon" style={{color:"red"}}/>}
      </div>
      </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return{
    setProjectName : (obj) => dispatch(actions.setProjectName(obj)),
  }
}

const mapStateToProps = (state) =>{
  //console.log(state.filters.sanitaryTable.sortedColumn)
  return{
      projectName: state.newOrder.customerData.projectName
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(ProjectNameInput)