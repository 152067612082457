import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LeftMenu from './LeftMenu';
import HomeIcon from '@material-ui/icons/Home';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LogTimer from '../login/LogTimer';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      justifyContent:'space-between',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      
    },
  }));
export default function LeftBar() {
    const classes = useStyles();
    let history = useHistory();
  return (
  <AppBar position="sticky" style={{backgroundColor:'#0750b5'}}>
    <Toolbar >
      <LeftMenu ></LeftMenu>
      <Typography variant="h6" className={classes.title}>
        Menu
      </Typography>
      <LogTimer/>
      <IconButton onClick={()=>{history.push("/")}} style={{color:'white',padding:'12px'}}>
        <HomeIcon style={{width:"40px",height:"40px"}}/>
      </IconButton>
    </Toolbar>
  </AppBar>
  );
}
