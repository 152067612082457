import actions from './actions';
import types from './types';

const sanitaryInit = {
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
}


const geodesyInit={
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
            {
                id:"geologySubmission",
                value:"",
            },
            {
                id:"geologyPickUp",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
}

const typicalInit={
    columnFilters:
    [
        {
            id:"serviceId",
            value:"",
        },
        {
            id:"orderId",
            value:"",
        },
        {
            id:"entryDate",
            value:"",
        },
        {
            id:"projectName",
            value:"",
        },
        {
            id:"customerName",
            value:"",
        },
        {
            id:"community",
            value:"",
        },
        {
            id:"precinct",
            value:"",
        },
        {
            id:"plotNumber",
            value:"",
        },
        {
            id:"serviceName",
            value:"",
        },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
}

const individualInit={
    columnFilters:
    [
        {
            id:"serviceId",
            value:"",
        },
        {
            id:"orderId",
            value:"",
        },
        {
            id:"entryDate",
            value:"",
        },
        {
            id:"projectName",
            value:"",
        },
        {
            id:"customerName",
            value:"",
        },
        {
            id:"community",
            value:"",
        },
        {
            id:"precinct",
            value:"",
        },
        {
            id:"plotNumber",
            value:"",
        },
        {
            id:"serviceName",
            value:"",
        },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
}

const conditionsInit={
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
            {
                id:"conditionsDate",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
}

const geologyInit={
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
            {
                id:"geologySubmission",
                value:"",
            },
            {
                id:"geologyPickUp",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
}

const historyInit={
    columnFilters:
        [
            {
                id:"orderId",
                value:"",
            },
            {
                id:"date",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"userId",
                value:"",
            },
            {
                id:"historyInfoId",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
}

const allOrdersInit={
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
}

const customersInit={
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
}

const allServicesInit={
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"serviceInfoId",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"price",
                value:"",
            },
            {
                id:"payingUserId",
                value:"",
            },
            {
                id:"paidAmount",
                value:"",
            },
            {
                id:"paidDate",
                value:"",
            },
            {
                id:"endingUserId",
                value:"",
            },
            {
                id:"endDate",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
}

const costsInit={
    columnFilters:
        [
            {
                id:"userId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"costName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"price",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
}

const reportsPdf = {
    usersList:[],
    startDate:null,
    endDate:null
}

const init = {

sanitaryTable:{
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
},

typicalTable:{
    columnFilters:
    [
        {
            id:"serviceId",
            value:"",
        },
        {
            id:"orderId",
            value:"",
        },
        {
            id:"entryDate",
            value:"",
        },
        {
            id:"projectName",
            value:"",
        },
        {
            id:"customerName",
            value:"",
        },
        {
            id:"community",
            value:"",
        },
        {
            id:"precinct",
            value:"",
        },
        {
            id:"plotNumber",
            value:"",
        },
        {
            id:"serviceName",
            value:"",
        },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
},

individualTable:{
    columnFilters:
    [
        {
            id:"serviceId",
            value:"",
        },
        {
            id:"orderId",
            value:"",
        },
        {
            id:"entryDate",
            value:"",
        },
        {
            id:"projectName",
            value:"",
        },
        {
            id:"customerName",
            value:"",
        },
        {
            id:"community",
            value:"",
        },
        {
            id:"precinct",
            value:"",
        },
        {
            id:"plotNumber",
            value:"",
        },
        {
            id:"serviceName",
            value:"",
        },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
},

geodesyTable:{
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
            {
                id:"podgikDate",
                value:"",
            },
            {
                id:"kergNumber",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
},

geologyTable:{
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
            {
                id:"geologySubmission",
                value:"",
            },
            {
                id:"geologyPickUp",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
},

conditionsTable:{
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"community",
                value:"",
            },
            {
                id:"precinct",
                value:"",
            },
            {
                id:"plotNumber",
                value:"",
            },
            {
                id:"serviceName",
                value:"",
            },
            {
                id:"conditionsDate",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
},

allOrdersTable:{
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"projectName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"addresses",
                value:"",
            },
            {
                id:"paidAmount",
                value:"",
            },
            {
                id:"toPay",
                value:"",
            },
            {
                id:"orderPrice",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
},

allServicesTable:{
    columnFilters:
        [
            {
                id:"serviceId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"serviceInfoId",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"price",
                value:"",
            },
            {
                id:"payingUserId",
                value:"",
            },
            {
                id:"paidAmount",
                value:"",
            },
            {
                id:"paidDate",
                value:"",
            },
            {
                id:"endingUserId",
                value:"",
            },
            {
                id:"endDate",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
},

historyTable:{
    columnFilters:
    [
        {
            id:"orderId",
            value:"",
        },
        {
            id:"date",
            value:"",
        },
        {
            id:"customerName",
            value:"",
        },
        {
            id:"userId",
            value:"",
        },
        {
            id:"historyInfoId",
            value:"",
        },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
},

customersTable:{
    columnFilters:
        [
            {
                id:"customerId",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"orders",
                value:"",
            },
            {
                id:"services",
                value:"",
            },
            {
                id:"concatedAddresses",
                value:"",
            },
            {
                id:"concatedPhoneNumbers",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:50,
    }
},

costsTable:{
    columnFilters:
        [
            {
                id:"userId",
                value:"",
            },
            {
                id:"orderId",
                value:"",
            },
            {
                id:"entryDate",
                value:"",
            },
            {
                id:"costName",
                value:"",
            },
            {
                id:"customerName",
                value:"",
            },
            {
                id:"price",
                value:"",
            },
    ],
    globalFilter:"",
    sortedColumn:[],
    groupedColumns:[
    ],
    pagination:{
        page:0,
        onPage:30,
    }
},
reportsPdf:reportsPdf,

}

function setFilter(array,value,id){
    console.log(array,value,id)
    let newArray=[];
    array.forEach(element => {
        if(element.id===id){
            newArray.push({id:id,value:value})
        }else{
            newArray.push(element)
        }
    });
    return newArray
}

const addUser = (oldList,id) =>{
    //console.log(oldList,id)
    let newList=[]
    newList.push(...oldList)
    newList.push(id)
    return newList
}

const setNoUsers = () => {
    const newArr=[]
    return(newArr)
}

const setAllUsers = (users) => {
    let newArr=[]
    users.map((user)=>{
        newArr.push(user.userId)
    })
    return(newArr)
}

const removeUser = (oldList,id) => {
    console.log(oldList,id)
    let newList=[]
    oldList.forEach(userId => {
        if(userId!==id){
            newList.push(userId)
        }
    });
    return newList
}

function filtersReducer(state = init, action)
{
    switch(action.type){

        //sanitary installations
        case types.ADD_FILTER_SANIT:
            return {...state,sanitaryTable:{...state.sanitaryTable,columnFilters:setFilter(state.sanitaryTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_SANIT:
            return {...state,sanitaryTable:sanitaryInit}
                
        case types.ADD_GLOBAL_FILTER_SANIT:
            return {...state,sanitaryTable:{...state.sanitaryTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_SANIT:
            return {...state,sanitaryTable:{...state.sanitaryTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_SANIT:
            return{...state,sanitaryTable:{...state.sanitaryTable,pagination:{...state.sanitaryTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_SANIT:
            return{...state,sanitaryTable:{...state.sanitaryTable,pagination:{...state.sanitaryTable.pagination,onPage:action.item}}}
        
        //typical projects
        case types.ADD_FILTER_TYP:
            return {...state,typicalTable:{...state.typicalTable,columnFilters:setFilter(state.typicalTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_TYP:
            return {...state,typicalTable:typicalInit}
                
        case types.ADD_GLOBAL_FILTER_TYP:
            return {...state,typicalTable:{...state.typicalTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_TYP:
            return {...state,typicalTable:{...state.typicalTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_TYP:
            return{...state,typicalTable:{...state.typicalTable,pagination:{...state.typicalTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_TYP:
            return{...state,typicalTable:{...state.typicalTable,pagination:{...state.typicalTable.pagination,onPage:action.item}}}

        //individual projects
         case types.ADD_FILTER_IND:
            return {...state,individualTable:{...state.individualTable,columnFilters:setFilter(state.individualTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_IND:
            return {...state,individualTable:individualInit}
                
        case types.ADD_GLOBAL_FILTER_IND:
            return {...state,individualTable:{...state.individualTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_IND:
            return {...state,individualTable:{...state.individualTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_IND:
            return{...state,individualTable:{...state.individualTable,pagination:{...state.individualTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_IND:
            return{...state,individualTable:{...state.individualTable,pagination:{...state.individualTable.pagination,onPage:action.item}}}


        //geodesy
        case types.ADD_FILTER_GEODESY:
            return {...state,geodesyTable:{...state.geodesyTable,columnFilters:setFilter(state.geodesyTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_GEODESY:
            return {...state,geodesyTable:geodesyInit}
                
        case types.ADD_GLOBAL_FILTER_GEODESY:
            return {...state,geodesyTable:{...state.geodesyTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_GEODESY:
            return {...state,geodesyTable:{...state.geodesyTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_GEODESY:
            return{...state,geodesyTable:{...state.geodesyTable,pagination:{...state.geodesyTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_GEODESY:
            return{...state,geodesyTable:{...state.geodesyTable,pagination:{...state.geodesyTable.pagination,onPage:action.item}}}


        //conditions
        case types.ADD_FILTER_COND:
            return {...state,conditionsTable:{...state.conditionsTable,columnFilters:setFilter(state.conditionsTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_COND:
            return {...state,conditionsTable:conditionsInit}
                
        case types.ADD_GLOBAL_FILTER_COND:
            return {...state,conditionsTable:{...state.conditionsTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_COND:
            return {...state,conditionsTable:{...state.conditionsTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_COND:
            return{...state,conditionsTable:{...state.conditionsTable,pagination:{...state.conditionsTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_COND:
            return{...state,conditionsTable:{...state.conditionsTable,pagination:{...state.conditionsTable.pagination,onPage:action.item}}}


        //allOrdersTAble
        case types.ADD_FILTER_ALL_ORDERS:
            return {...state,allOrdersTable:{...state.allOrdersTable,columnFilters:setFilter(state.allOrdersTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_ALL_ORDERS:
            return {...state,allOrdersTable:allOrdersInit}
                
        case types.ADD_GLOBAL_FILTER_ALL_ORDERS:
            return {...state,allOrdersTable:{...state.allOrdersTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_ALL_ORDERS:
            return {...state,allOrdersTable:{...state.allOrdersTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_ALL_ORDERS:
            return{...state,allOrdersTable:{...state.allOrdersTable,pagination:{...state.allOrdersTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_ALL_ORDERS:
            return{...state,allOrdersTable:{...state.allOrdersTable,pagination:{...state.allOrdersTable.pagination,onPage:action.item}}}

        //allServicesTAble
        case types.ADD_FILTER_ALL_SERVICES:
            return {...state,allServicesTable:{...state.allServicesTable,columnFilters:setFilter(state.allServicesTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_ALL_SERVICES:
            return {...state,allServicesTable:allServicesInit}
                
        case types.ADD_GLOBAL_FILTER_ALL_SERVICES:
            return {...state,allServicesTable:{...state.allServicesTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_ALL_SERVICES:
            return {...state,allServicesTable:{...state.allServicesTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_ALL_SERVICES:
            return{...state,allServicesTable:{...state.allServicesTable,pagination:{...state.allServicesTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_ALL_SERVICES:
            return{...state,allServicesTable:{...state.allServicesTable,pagination:{...state.allServicesTable.pagination,onPage:action.item}}}


        //customers
        case types.ADD_FILTER_CUSTOMERS:
            return {...state,customersTable:{...state.customersTable,columnFilters:setFilter(state.customersTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_CUSTOMERS:
            return {...state,customersTable:customersInit}
                
        case types.ADD_GLOBAL_FILTER_CUSTOMERS:
            return {...state,customersTable:{...state.customersTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_CUSTOMERS:
            return {...state,customersTable:{...state.customersTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_CUSTOMERS:
            return{...state,customersTable:{...state.customersTable,pagination:{...state.customersTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_CUSTOMERS:
            return{...state,customersTable:{...state.customersTable,pagination:{...state.customersTable.pagination,onPage:action.item}}}


        //history
        case types.ADD_FILTER_HISTORY:
            return {...state,historyTable:{...state.historyTable,columnFilters:setFilter(state.historyTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_HISTORY:
            return {...state,historyTable:historyInit}
                
        case types.ADD_GLOBAL_FILTER_HISTORY:
            return {...state,historyTable:{...state.historyTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_HISTORY:
            return {...state,historyTable:{...state.historyTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_HISTORY:
            return{...state,historyTable:{...state.historyTable,pagination:{...state.historyTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_HISTORY:
            return{...state,historyTable:{...state.historyTable,pagination:{...state.historyTable.pagination,onPage:action.item}}}


        //geology
        case types.ADD_FILTER_GEOLOGY:
            return {...state,geologyTable:{...state.geologyTable,columnFilters:setFilter(state.geologyTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_GEOLOGY:
            return {...state,geologyTable:geologyInit}
                
        case types.ADD_GLOBAL_FILTER_GEOLOGY:
            return {...state,geologyTable:{...state.geologyTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_GEOLOGY:
            return {...state,geologyTable:{...state.geologyTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_GEOLOGY:
            return{...state,geologyTable:{...state.geologyTable,pagination:{...state.geologyTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_GEOLOGY:
            return{...state,geologyTable:{...state.geologyTable,pagination:{...state.geologyTable.pagination,onPage:action.item}}}


        //costs
        case types.ADD_FILTER_COSTS:
            return {...state,costsTable:{...state.costsTable,columnFilters:setFilter(state.costsTable.columnFilters,action.item.value,action.item.id)}}

        case types.RESET_FILTERS_COSTS:
            return {...state,costsTable:costsInit}
                
        case types.ADD_GLOBAL_FILTER_COSTS:
            return {...state,costsTable:{...state.costsTable,globalFilter:action.item.value}}
        
        case types.ADD_SORTED_COLUMN_COSTS:
            return {...state,costsTable:{...state.costsTable,sortedColumn:action.item}}
        
        case types.SET_PAGE_COSTS:
            return{...state,costsTable:{...state.costsTable,pagination:{...state.costsTable.pagination,page:action.item}}}
        
        case types.SET_ON_PAGE_COSTS:
            return{...state,costsTable:{...state.costsTable,pagination:{...state.costsTable.pagination,onPage:action.item}}}

        //reportsPdf
        case types.SET_START_DATE:
            return{...state,reportsPdf:{...state.reportsPdf,startDate:action.item}}

        case types.SET_END_DATE:
            return{...state,reportsPdf:{...state.reportsPdf,endDate:action.item}}

        case types.ADD_USER:
            return{...state,reportsPdf:{...state.reportsPdf,usersList:addUser(state.reportsPdf.usersList,action.item)}}

        case types.REMOVE_USER:
            return{...state,reportsPdf:{...state.reportsPdf,usersList:removeUser(state.reportsPdf.usersList,action.item)}}
        
        case types.SET_ALL_USERS:
            return{...state,reportsPdf:{...state.reportsPdf,usersList:setAllUsers(action.item)}}
        
        case types.SET_NO_USERS:
            return{...state,reportsPdf:{...state.reportsPdf,usersList:setNoUsers()}}
        
        case types.SET_START:
            return{...state,reportsPdf:{...state.reportsPdf,startDate:action.item}}

        case types.SET_END:
            return{...state,reportsPdf:{...state.reportsPdf,endDate:action.item}}

        case types.RESET_REPORTS_PDF:
            return{...state,reportsPdf:reportsPdf}

        default:return state
    }
}

export default filtersReducer;