import { Paper } from "@material-ui/core"
import { Typography } from "@material-ui/core";
import { Container } from "@material-ui/core"
import InfoIcon from '@material-ui/icons/Info';


const Information = () => {

    return(
        <Container style={{marginTop:'50px'}}>
            <Paper style={{padding:'10px',border:'2px solid #1976d2'}}>
                <InfoIcon style={{float:'left',marginRight:'10px',color:'#1976d2',fontSize:'2rem'}}/><Typography style={{fontSize:'1.3rem'}}>Nie posiadasz uprawnień do wprowadzania raportów.</Typography>
            </Paper>
        </Container>
        )
}

export default(Information)