import React, { useEffect } from 'react'
import { useTable, usePagination } from 'react-table'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import FirstPageRoundedIcon from '@material-ui/icons/FirstPageRounded';
import LastPageRoundedIcon from '@material-ui/icons/LastPageRounded';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLeaves } from '../duck/operations';
import actions from '../duck/actions';
import { renderLoadingCell, renderNoLeavesCell, renderErrorCell } from '../table/helperRows';
import { useIsMount } from '../../departments/mountHook';

function LeavesTable({ columns, data, isLoading, colGroup, colQuant, error }) {
  const pageIndexState = useSelector(state => state.leaves.tableFilters.pageIndex)
  const pageCountState = useSelector(state => state.leaves.tableFilters.pageCount)
  const isMount = useIsMount()
  const dispatch = useDispatch()
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageIndexState,
        pageSize: 20,
      },
      manualPagination: true,
      pageCount: pageCountState
    },
    usePagination
  )

  useEffect(()=>{
    if(!isMount){
    //console.log("getAllLeaves from leavesTable")
    dispatch(actions.setFilterPage(pageIndex))
    dispatch(getAllLeaves())
    }
  },[pageIndex])

    const firstId=columns[0].id
    const lastId=columns[columns.length-1].id

    const classNamePicker = (columnId) => {
      if(columnId===firstId){
        return({
          borderTopLeftRadius:10,
          color:'white',
          textAlign:'center'
      })
      }else if(columnId===lastId){
        return({
          borderTopRightRadius:10,
          color:'white',
          textAlign:'center'
      })
      }else{
        return({
          color:'white',
          textAlign:'center'
      })
      }
    }
    

  return (
    <>
      <Table size='small' {...getTableProps()}>
        {colGroup}
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow style={{
                backgroundColor:'#0750b5',
                fontWeight:'bold'
            }} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell style={classNamePicker(column.id)} {...column.getHeaderProps()}><span style={{color:'white',width:'100%',display:'block',textAlign:'center'}}>{column.render('Header')}</span></TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody style={{
            backgroundColor:'#f3f3f3'
        }} {...getTableBodyProps()}>
        {isLoading?
          renderLoadingCell(colQuant)
          :   
          error===true?
          renderErrorCell(colQuant)
          :
          data?
             data.length>0?
          page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <TableCell align='center' style={{
                      textAlign:'center'
                  }} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                })}
              </TableRow>
            )
          })
          :renderNoLeavesCell(colQuant)
          :renderNoLeavesCell(colQuant)}
          <TableRow>
            <TableCell align='center' style={{
                backgroundColor:'#0750b5',
                color:'white',
                borderBottomRightRadius:10,
                borderBottomLeftRadius:10,
                borderBottom:'none',
                paddingTop:'0px',
                paddingBottom:'0px'
            }} colSpan={colQuant}>
                <div>
                  <IconButton disabled={!canPreviousPage} onClick={()=>gotoPage(0)}>
                      <FirstPageRoundedIcon style={{color:'white'}}/>
                  </IconButton>
                  <IconButton disabled={!canPreviousPage} onClick={()=>previousPage()}>
                      <ArrowBackIosRoundedIcon style={{color:'white'}}/>
                  </IconButton>
                  <span style={{
                    color:'white',
                    fontWeight:'bold',
                    marginTop:'6px'
                  }}>{pageIndex+1}z{pageCount}</span>
                  <IconButton disabled={!canNextPage} onClick={()=>nextPage()}>
                      <ArrowForwardIosRoundedIcon style={{color:'white'}}/>
                  </IconButton>
                  <IconButton disabled={!canNextPage} onClick={()=>gotoPage(pageCount - 1)}>
                    <LastPageRoundedIcon style={{color:'white'}}/>
                  </IconButton>
                </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default LeavesTable