import { Divider, Grid } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import PdfViewer from "../raports/PdfViewer"
import { RoleService } from "../RoleService"
import Informator from "./Informator"
import MenuOption from "./MenuOption"

const Home = () => {
    let history = useHistory()

    const roleService = new RoleService()
    return(
        <Grid container={true} >
            <Grid  item  xl={3} lg={4} md={4} sm={12} xs={12}>
            <MenuOption label="dodaj zlecenie" variant="green" onClick={()=>{history.push("/new-order")}}/>
            <Divider variant="middle" style={{marginLeft:"5%",marginRight:"5%",marginTop:"25px"}}/>
            <MenuOption label="raporty" variant="yellow" onClick={()=>{history.push("/reports")}}/>
            <Divider variant="middle" style={{marginLeft:"5%",marginRight:"5%",marginTop:"25px"}}/>
            <MenuOption label="geodezja" variant="blue" onClick={()=>{history.push("/geodesy")}}/>
            <MenuOption label="projekty typowe" variant="blue" onClick={()=>{history.push("/typical-projects")}} />
            <MenuOption label="projekty indywidualne" variant="blue"  onClick={()=>{history.push("/individual-projects")}}/>
            <MenuOption label="instalacje sanitarne" variant="blue" onClick={()=>{history.push("/sanitary-installations")}}/>
            <MenuOption label="warunki zabudowy" variant="blue"  onClick={()=>{history.push("/conditions")}}/>
            <MenuOption label="geologia" variant="blue"  onClick={()=>{history.push("/geology")}}/>
            <Divider variant="middle" style={{marginLeft:"5%",marginRight:"5%",marginTop:"25px"}}/>
            <MenuOption label="wszystkie zlecenia" variant="red" onClick={()=>{history.push("/all-orders")}}/>
            {roleService.hasOneOfRoles(["ADMIN","BOSS","ACCOUNTANT"])?<MenuOption label="zakończone prace" variant="red" onClick={()=>{history.push("/all-services")}}/>:null}
            {roleService.hasOneOfRoles(["ADMIN","BOSS"])?<MenuOption label="historia" variant="red" onClick={()=>{history.push("/history")}}/>:null}
            <MenuOption label="klienci" variant="red" onClick={()=>{history.push("/customers")}}/>
            <MenuOption label="kalendarz" variant="red" onClick={()=>{history.push("/")}}/>
            {roleService.hasOneOfRoles(["ADMIN","BOSS"])?<MenuOption label="statystyki" variant="red" onClick={()=>{history.push("/stats")}}/>:null}
            {roleService.hasOneOfRoles(["ADMIN","BOSS"])?<MenuOption label="koszty" variant="red" onClick={()=>{history.push("/costs")}}/>:null}
            <MenuOption label="urlopy" variant="red" onClick={()=>{history.push("/leave-user-panel")}}/>
            </Grid>
            <Grid item xl={9} lg={8} md={8} sm={12} xs={12}>
            <Informator/>
            </Grid>
        </Grid>

    )
}

export default(Home)