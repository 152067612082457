import { IconButton } from "@mui/material"
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from "react-redux";
import actions from "../duck/actions";
import { green } from "../helpers/colors";

const Button = ({leaveId,statusId}) => {
    
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(actions.setAllTableDialogId(leaveId))
        dispatch(actions.setAllTableDialogStatusId(statusId))
        dispatch(actions.setOpenAllTableDialog(true))
    }
    return(
        <IconButton onClick={handleClick}>
            <EditIcon style={{color:green}}/>
        </IconButton>
    )
}

export default Button