
import { IconButton, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import "./style.css"
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhoneIcon from '@material-ui/icons/Phone';
import { useSnackbar } from "notistack";
import { serverUrl } from "../config/serverConfig";
import { connect } from "react-redux";

const PhoneNumberInput = (props) => {
    const {phoneNumber} = props
    const {phoneNumberId} = props
    const {counter} = props
    const {quantity} = props
    const {orderId} = props
    const [numVal,setNumVal] = useState(phoneNumber)
    const [baseVal,setBaseVal] = useState(phoneNumber)
    const { enqueueSnackbar } = useSnackbar()
    const token='Bearer '.concat(props.jwt)
    const [short,setShort] = useState(false)
    const regex=/(^(\+?|))\d{0,14}$/
    const [quantityVal,setQuantityVal] = useState(quantity)

    useEffect(() =>{
        setNumVal(phoneNumber)
        setBaseVal(phoneNumber)
        setQuantityVal(quantity)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props])

    async function deleteNumber(phoneNumberId) {
        await fetch(`${serverUrl}/phone-numbers?phoneNumberId=${phoneNumberId}&orderId=${orderId}`,
        {method:'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie usunięto numer telefonu",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Numer telefonu nie został usunięty",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Numer telefonu nie został usunięty",{variant: 'error',autoHideDuration: 2000})
        })
      }

      async function changeNumber(phoneNumberId,phoneNumber) {
        await fetch(`${serverUrl}/phone-numbers?phoneNumberId=${phoneNumberId}&phoneNumber=${phoneNumber}&orderId=${orderId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie edytowano numer telefonu",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Numer telefonu nie został edytowany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Numer telefonu nie został edytowany",{variant: 'error',autoHideDuration: 2000})
        })
      }

      const handleDeleteNumber = () => {
          if(1<quantityVal){
             deleteNumber(phoneNumberId) 
          }
      }

      const handleChangeNumber = () => {
        if(regex.test(numVal)&&numVal.length>=6){
           changeNumber(phoneNumberId,encodeURIComponent(numVal.trim()))
        }else{
            enqueueSnackbar("Numer musi mieć od 6 do 14 znaków, może zaczynać się od plusa i zawierać tylko cyfry",{variant: 'warning',autoHideDuration: 5000})
        }  
      }

      
      const changeNumVal = (val) => {
        if(regex.test(val)){
            setNumVal(val)
        }
        if(val.length<6){
            setShort(true)
        }else{
            setShort(false)
        }
      }

    return(
        <div style={{marginTop:"30px"}}>
         <div className="iconContainer">
        <PhoneIcon className="icon"/>{counter}
        </div>   
        <TextField
        value={numVal}
        label="Numer telefonu"
        variant="outlined"
        type="text"
        className="midInput"
        inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
        onChange={(e)=>changeNumVal(e.target.value)}
        />
        <IconButton disabled={numVal===baseVal} className="saveButton" onClick={()=>{handleChangeNumber()}}>
            <SaveIcon className="saveIcon" style={{color:numVal===baseVal?"grey":"green"}}/>
        </IconButton>
        <IconButton disabled={1>=quantityVal?true:false} className="saveButton" onClick={()=>{handleDeleteNumber()}}>
            <DeleteForeverIcon className="saveIcon" style={{color:1>=quantityVal?"grey":"red"}}/>
        </IconButton>
        </div>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,null)(PhoneNumberInput)