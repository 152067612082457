import { Paper,Button, makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import {  Grid,Box } from "@material-ui/core";
import { pl } from "date-fns/locale";
import moment from "moment";

const useStyles = makeStyles({
    button: {
        backgroundColor:'#0750b5',
        color:'white',
        margin:'2px 5px 2px 5px',
        "&:hover": {
            backgroundColor:'#0c9c00',
        }
    },
})

const RangeCalendar = ({changeDate}) => {

    const classes = useStyles()

    const [value,setValue] = useState([moment().startOf('day').toDate(),moment().endOf('day').toDate()])

    useEffect(()=>{
        changeDate(value)
    },[])

    const addDay = (val) => {
        let startDate=value[0]
        let endDate=value[1]
        startDate=moment(startDate).add(val,'day').toDate()
        endDate=moment(endDate).add(val,'day').toDate()
        setValue([startDate,endDate])
        changeDate([startDate,endDate])
    }

    const addWeek = (val) => {
        let startDate=value[0]
        let endDate=value[1]
        startDate=moment(startDate).add(val,'week').toDate()
        endDate=moment(endDate).add(val,'week').toDate()
        setValue([startDate,endDate])
        changeDate([startDate,endDate])
    }

    const addMonth = (val) => {
        let startDate=value[0]
        let endDate=value[1]
        startDate=moment(startDate).add(val,'month').toDate()
        endDate=moment(endDate).add(val,'month').toDate()
        setValue([startDate,endDate])
        changeDate([startDate,endDate])
    }

    const addYear = (val) => {
        let startDate=value[0]
        let endDate=value[1]
        startDate=moment(startDate).add(val,'year').toDate()
        endDate=moment(endDate).add(val,'year').toDate()
        setValue([startDate,endDate])
        changeDate([startDate,endDate])
    }

    return(
        <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns} >
            <MobileDateRangePicker
            components={Paper}
            startText="Data"
            endText="Data"
            cancelText="Anuluj"
            clearText="Wyczyść"
            todayText="Dzisiaj"
            toolbarTitle="Wybierz przedział czasu"
            showDaysOutsideCurrentMonth={false}
            showTodayButton={false}
            clearable={false}
            inputFormat='dd/MM/yyyy'
            value={value}
            onChange={() =>{}}
            onAccept={(newValue) => {
                setValue(newValue)
                changeDate(newValue)
            }}
            renderInput={(startProps, endProps) => (
                <Grid container justify='center' spacing={2} alignItems='center'>
                    <Grid item>
                        <Button className={classes.button} onClick={()=>addYear(-1)}>
                            -rok
                        </Button>
                        <Button className={classes.button} onClick={()=>addMonth(-1)}>
                            -miesiąc
                        </Button>
                        <Button className={classes.button} onClick={()=>addWeek(-1)}>
                            -tydzień
                        </Button>
                        <Button className={classes.button} onClick={()=>addDay(-1)}>
                            -dzień
                        </Button>
                    </Grid>
                    <Grid item>
                        <TextField size='small' style={{backgroundColor:'white'}} {...startProps} />
                    </Grid>
                    <Grid item>
                        <Box> do </Box>
                    </Grid>
                    <Grid item>
                        <TextField size='small' style={{backgroundColor:'white'}} {...endProps} />
                    </Grid>
                    <Grid item>
                        <Button className={classes.button} onClick={()=>addDay(1)}>
                            +dzień
                        </Button>
                        <Button className={classes.button} onClick={()=>addWeek(1)}>
                            +tydzień
                        </Button>
                        <Button className={classes.button} onClick={()=>addMonth(1)}>
                            +miesiąc
                        </Button>
                        <Button className={classes.button} onClick={()=>addYear(1)}>
                            +rok
                        </Button>
                    </Grid>
                </Grid>
            )}
            />
        </LocalizationProvider>
    )

}

export default RangeCalendar