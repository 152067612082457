import actions from "./actions"
import {serverUrl} from '../../config/serverConfig'
import {store} from '../../store'
import snackbar from './snackbar'
import {
    convertItemsToLeaves,
    convertUsersToGroups
}from "../helpers/objectConverters"
import moment from "moment"
import errorMessageSwitch from "./errorMessageSwitch"

const requestLeave = async (isAdmin,allTable) =>  {
    store.dispatch(actions.setLoadingForm(true))
    const comment = store.getState().leaves.form.commentValue
    const startDate = moment(store.getState().leaves.form.rangePickerValue[0]).format('YYYY-MM-DD HH:mm:ss')
    const endDate = moment(store.getState().leaves.form.rangePickerValue[1]).add(23,'hours').add(59,'minutes').add(59,'seconds').format('YYYY-MM-DD HH:mm:ss')
    const token = "Bearer ".concat(store.getState().log.jwt)
    let userId = undefined
    let statusId = undefined
    if(isAdmin){
        userId = store.getState().leaves.form.userId
        statusId = store.getState().leaves.form.statusId
    }else{
        userId = store.getState().log.user.userId
        statusId = 1
    }
    await fetch(`${serverUrl}/leaves?comment=${comment}&startDate=${startDate}&endDate=${endDate}&userId=${userId}&statusId=${statusId}`,
        {method:'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res=> res.json())
        .then(json => {
            store.dispatch(actions.setLoadingForm(false))
            store.dispatch(actions.setFormResponse(json))
            //console.log(json)
            if(json.canGetLeave){
                if(isAdmin){
                    store.dispatch(getAllLeaves())
                    if(userId===store.getState().log.user.userId){
                        store.dispatch(getUserLeaves())
                    }
                }else{
                    store.dispatch(getUserLeaves())
                }
                store.dispatch(actions.setOpenNewRequestDialog(false))
                store.dispatch(actions.clearForm())
            }
        })
}

//
const cancelLeave = (leaveId) => {
    return (dispatch) => {
        dispatch(actions.setLoadingCancelLeave(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        fetch(`${serverUrl}/leaves/change-status?statusId=${4}&leaveId=${leaveId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/change-status"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            dispatch(actions.setLoadingCancelLeave(false))
            dispatch(getUserLeaves())
            dispatch(actions.setOpenConfirmationDialog(false))
            dispatch(actions.setFetchCancelLeaveError(false))//
            snackbar.success("Wniosek został anulowany.")
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchCancelLeaveError(true))
            dispatch(actions.setLoadingCancelLeave(false))
        })
    }
}

const getAllUsers = () => {
    return (dispatch) => {
        dispatch(actions.setLoadingUsers(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        fetch(`${serverUrl}/users/simple-users-list-leaves`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/users/simple-users-list"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            dispatch(actions.setUsers(convertUsersToGroups(json)))
            dispatch(actions.setLoadingUsers(false))
            dispatch(actions.setFetchUsersError(false))
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchUsersError(true))
            dispatch(actions.setLoadingUsers(false))
        })
    }
}

const getCalendarLeaves = () => {
    return (dispatch) => {
        dispatch(actions.setLoadingCalendarLeaves(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        fetch(`${serverUrl}/leaves/all-leaves`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/all-leaves"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            //dispatch(actions.clearUserLeaves())
            dispatch(actions.setCalendarLeaves(convertItemsToLeaves(json)))
            dispatch(actions.setLoadingCalendarLeaves(false))
            dispatch(actions.setFetchCalendarLeavesError(false))
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchCalendarLeavesError(true))
            dispatch(actions.setLoadingCalendarLeaves(false))
        })
    }
}  

const getUserLeaves = () => {
    return (dispatch) => {
        dispatch(actions.setLoadingUserLeaves(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        const userId = store.getState().log.user.userId
        fetch(`${serverUrl}/leaves/user?userId=${userId}`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/user"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            //dispatch(actions.clearUserLeaves())
            dispatch(actions.setUserLeaves(json))
            dispatch(actions.setLoadingUserLeaves(false))
            dispatch(actions.setFetchUserLeavesError(false))
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchUserLeavesError(true))
            dispatch(actions.setLoadingUserLeaves(false))
        })
    }
}

const getAllLeaves = () => {
    return (dispatch) => {
        //console.log("getAllLeaves")
        dispatch(actions.setLoadingAllLeaves(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        const {userId,statusId,pageIndex,onSite} = store.getState().leaves.tableFilters
        //console.log(`${serverUrl}/leaves/all-table?userId=${userId}&statusId=${statusId}&pageIndex=${pageIndex}&onSite=${onSite}`)
        const makeUrl=()=>{
            const url= new URL(serverUrl+'/leaves/all-table')
            if(userId!==null){
                url.searchParams.append('userId',userId)
            }
            if(statusId!==null){
                url.searchParams.append('statusId',statusId)
            }
            url.searchParams.append('onPage',onSite)
            url.searchParams.append('pageIndex',pageIndex)
            //console.log(url.toString())
            return(url.toString())
        }
        fetch(makeUrl(),
        {method:'GET',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/all-table"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            //dispatch(actions.clearUserLeaves())
            dispatch(actions.setAllLeaves(json.content))
            dispatch(actions.setFilterPageCount(json.totalPages))
            dispatch(actions.setLoadingAllLeaves(false))
            dispatch(actions.setFetchAllLeavesError(false))
            //console.log(json)
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchAllLeavesError(true))
            dispatch(actions.setLoadingAllLeaves(false))
        })
    }
}
//
const getLeaveInfo = () => {
    return (dispatch) => {
        dispatch(actions.setLoadingTimelineInfo(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        const leaveId = store.getState().leaves.timelineDialogId
        fetch(`${serverUrl}/leaves/details?leaveId=${leaveId}`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/details"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            dispatch(actions.setLeaveDetails(json))
            dispatch(actions.setLoadingTimelineInfo(false))
            dispatch(actions.setFetchLeaveInfoError(false))
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchLeaveInfoError(true))
            dispatch(actions.setLoadingTimelineInfo(false))
        })
    }
}
//
const changeLeaveStatusAllLeaves = (statusId,leaveId) => {
    return (dispatch) => {
        dispatch(actions.setLoadingChangeStatusAllLeaves(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        fetch(`${serverUrl}/leaves/change-status?statusId=${statusId}&leaveId=${leaveId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/change-status"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            //console.log(json)
            dispatch(actions.setLoadingChangeStatusAllLeaves(false))
            dispatch(actions.setResponseChangeStatusAllLeaves(json))
            if(json.canChangeStatus===true){
                dispatch(getAllLeaves())
                dispatch(actions.setOpenAllTableDialog(false))
                dispatch(actions.resetChangeStatusAllLeaves())
            }
            dispatch(actions.setFetchChangeLeaveStatusErrorAllLeaves(false))
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchChangeLeaveStatusErrorAllLeaves(true))
            dispatch(actions.setLoadingChangeStatusAllLeaves(false))
        })
    }
}
//
const changeLeaveStatusTimeline = (statusId,leaveId) => {
    return (dispatch) => {
        dispatch(actions.setLoadingChangeStatusTimeline(true))
        const token = "Bearer ".concat(store.getState().log.jwt)
        fetch(`${serverUrl}/leaves/change-status?statusId=${statusId}&leaveId=${leaveId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/change-status"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            dispatch(actions.setLoadingChangeStatusTimeline(false))
            dispatch(actions.setResponseChangeStatusTimeline(json))
            //console.log(json)
            if(json.canChangeStatus===true){
                dispatch(getLeaveInfo())
                dispatch(getCalendarLeaves())
                dispatch(actions.setOpenTimelineInfoDialog(false))
                dispatch(actions.resetChangeStatusTimeline())
            }
            dispatch(actions.setFetchChangeLeaveStatusErrorTimeline(false))
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setFetchChangeLeaveStatusErrorTimeline(true))
            dispatch(actions.setLoadingChangeStatusTimeline(false))
        })
    }
}

const getYearLeaves = () => {
    return (dispatch) => {
        dispatch(actions.setUserYearsLoading(true))
        const userId = store.getState().log.user.userId
        const token = "Bearer ".concat(store.getState().log.jwt)
        fetch(`${serverUrl}/leaves/user-days?userId=${userId}`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
        })
        .then(res => {
            if(!res.ok){
                snackbar.warning(errorMessageSwitch(res.status,"/leaves/user-days"))
                throw new Error('handled')
            }else{
                return res.json()
            }
        })
        .then(json => {
            dispatch(actions.setUserYears(json))
            dispatch(actions.setUserYearsLoading(false))
            dispatch(actions.setUserYearsFetchError(false))
        })
        .catch(err=>{
            if(err.message==='handled'){
                //console.log(err)
            }else{
                snackbar.warning(err.toString()+" (Sprawdź połączenie z internetem.)")
            }
            dispatch(actions.setUserYearsFetchError(true))
            dispatch(actions.setUserYearsLoading(false))
        })
    }
}

export {
    getAllUsers,
    getCalendarLeaves,
    getUserLeaves,
    requestLeave,
    cancelLeave,
    getAllLeaves,
    getLeaveInfo,
    changeLeaveStatusTimeline,
    changeLeaveStatusAllLeaves,
    getYearLeaves,

}