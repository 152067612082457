import { TextField, withStyles } from "@material-ui/core"
import moment from "moment"
import { useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Button } from "@material-ui/core"
import { useSnackbar } from "notistack"

const SaveButton = withStyles({
    root:{
        backgroundColor:'#43a047',
        color:'white',
        '&:hover':{
            backgroundColor:'#43a047b0'
        }
    },
    disabled:{
        backgroundColor:'lightgrey',
        color:'grey'
    }
})(Button)

const NewCost = ({orderId,jwt,close}) =>{

    const token="Bearer ".concat(jwt)
    const [value,setValue] = useState()
    const [price,setPrice] = useState()
    const [validValue,setValidValue] = useState(true)
    const [validPrice,setValidPrice] = useState(true)
    const { enqueueSnackbar } = useSnackbar()
    
    const changePrice = (price) => {
        console.log(validPrice)
        if(price<0||price===""){
            setPrice(price)
            setValidPrice(false)
        }else{
            setPrice(price)
            setValidPrice(true)
        }
    }

    const changeName = (name) => {
        console.log(validValue)
        if(name===null||name===undefined){
            setValidValue(false)
        }else{
            if(name.length>=255){
                setValidValue(false)
            }else{
                if(name.length<3){
                    setValue(name)
                    setValidValue(false) 
                }else{
                    setValue(name)
                    setValidValue(true) 
                }
                
            }
        }
    }

    const  handleSave = async() => {
            await fetch(`${serverUrl}/costs?description=${value}&entryDate=${moment().format("YYYY-MM-DD HH:mm:ss")}&orderId=${orderId}&price=${price}`,
            {method:'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
            })
            .then(response => response)
            .then(response => {
                console.log(response);
                if(response.status===200){
                    enqueueSnackbar("Pomyslnie dodano koszt",{variant: 'success',autoHideDuration: 2000})
                    close()
                }else{
                    enqueueSnackbar("UPS! Koszt nie został dodany, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
                }
            })
            .catch(error => {
                enqueueSnackbar("UPS! Koszt nie został dodany, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
                console.log(error);
            })
    }

    return(
        <>  
        <div style={{width:'100%'}}>
            {
            (value===""||value===undefined)?
                <HelpOutlineIcon style={{fontSize:'2.2rem',color:'grey',marginTop:'25px',marginRight:'10px'}}/>
            :
                validValue===false?
                    <CancelOutlinedIcon style={{fontSize:'2.2rem',color:'red',marginTop:'25px',marginRight:'10px'}}/>
                :
                    <CheckCircleIcon style={{fontSize:'2.2rem',color:'green',marginTop:'25px',marginRight:'10px'}}/>
            }
            <TextField 
                label="Nazwa kosztu" 
                variant="outlined"
                value={value}
                fullWidth={true}
                type='text'
                style={{borderRadius:'3px',backgroundColor:'white',marginTop:'15px',maxWidth:'800px'}}
                onChange={(e)=>changeName(e.target.value)}
                multiline={true}
            />
        </div>
        <div style={{width:'100%'}}>
            {
            (price===""||price===undefined)?
                <HelpOutlineIcon style={{fontSize:'2.2rem',color:'grey',marginTop:'25px',marginRight:'10px'}}/>
            :
                validPrice===false?
                    <CancelOutlinedIcon style={{fontSize:'2.2rem',color:'red',marginTop:'25px',marginRight:'10px'}}/>
                :
                    <CheckCircleIcon style={{fontSize:'2.2rem',color:'green',marginTop:'25px',marginRight:'10px'}}/>
            }
            <TextField 
                label="Kwota"
                variant="outlined"
                value={price}
                fullWidth={false}
                type='number'
                style={{borderRadius:'3px',backgroundColor:'white',marginTop:'15px',maxWidth:'200px'}}
                onChange={(e)=>changePrice(e.target.value)}
            />
            
        </div>
        <SaveButton 
            disabled={!(validValue&&validPrice&&value!==undefined&&value!==""&&price!==undefined&&price!=="")}
            size='large' 
            style={{marginLeft:'45px',marginTop:'15px',marginBottom:'50px'}}
            onClick={()=>handleSave()}>
            Dodaj koszt
        </SaveButton>
        </>
    )
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(NewCost)