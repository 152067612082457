

const errorMessageSwitch = (errCode,url) => {
    //console.log("switch:",errCode,url)
    if(errCode>=300&&errCode<400){
        return errCode+" - Błąd przekierowań."+" ("+url+")"
    }else if(errCode===400){
        return errCode+" - Nieprawidłowe zapytanie."+" ("+url+")"
    }else if(errCode===401){
        return errCode+" - Nieautoryzowany dostęp."+" ("+url+")"
    }else if(errCode===403){
        return errCode+" - Dostęp do serwera zabroniony."+" ("+url+")"
    }else if(errCode===404){
        return errCode+" - Nie znaleziono zasobu."+" ("+url+")"
    }else if(errCode===405){
        return errCode+" - Niedozwolona metoda."+" ("+url+")"
    }else if(errCode===408){
        return errCode+" - Koniec czasu oczekiwania na żądanie."+" ("+url+")"
    }else if([402,406,407].includes(errCode)||(errCode>=409&&errCode<500)){
        return errCode+" - Błąd żądania."+" ("+url+")"
    }else if(errCode===500){
        return errCode+" - Błąd wewnętrzny serwera. Zgłoś adminowi jego wystąpienie :)"+" ("+url+")"
    }else if(errCode>500&&errCode<600){
        return errCode+" - Błąd serwera."+" ("+url+")"
    }else{
        return errCode+"Wystąpił błąd podczas pobierania danych."+" ("+url+")"
    }
}

export default errorMessageSwitch