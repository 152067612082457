import { Button } from "@material-ui/core"
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { useState } from "react";
import { connect } from "react-redux";
import { serverUrl } from "../config/serverConfig";
import OrderCardPdf from "../pdf/OrderCardPdf";

const GeneratePdfButton = ({orderId,orderCode,jwt}) => {

    const [isLoading,setIsLoading] = useState(false)
    const token='Bearer '.concat(jwt)

    async function getData() {
    setIsLoading(true)
      await fetch(`${serverUrl}/pdf-departments/order-card?orderId=${orderId}`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        generatePdf(json)
      })
      .catch(error => {console.log(error)})
    }

    const generatePdf = async (data) => {
        const blob = await pdf((
            <OrderCardPdf
                data={data}
            />
        )).toBlob();
        setIsLoading(false)
        saveAs(blob, "KZ".concat(orderCode,".pdf"));
    }

    return(
        <>
        {isLoading===true?<CircularProgress/>:<Button size="small" onClick={()=>getData()} style={{
            display: "block",
            cssFloat: "left",
            marginTop: "7px",
            backgroundColor: "lightgray",
            marginLeft: "5px"
        }}>PDF</Button>}
        </>
    )
}
const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(GeneratePdfButton)