import { TextField } from "@mui/material"
import { useSelector,useDispatch } from "react-redux"
import actions from "../duck/actions"


const CommentInput = () => {

    const value = useSelector(state=>state.leaves.form.commentValue)

    const dispatch = useDispatch()
    const setValue = (val) => {
        //console.log(val)
        dispatch(actions.setCommentValue(val))
    }
    return (
        <TextField
            minRows={2}
            maxRows={5}
            variant='outlined'
            value={value}
            onChange={(e)=>{setValue(e.target.value)}}
            label="Komentarz"
            size='small'
            fullWidth
        />
    )
}

export default CommentInput