import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import moment from 'moment';
import { pl } from "date-fns/locale";
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Grid } from '@material-ui/core';

const createFormat=(date)=>{
  return("'".concat(moment(date).format("YYYY"),"'"))
}

const createDateRange = (date) => {
  return([moment(date).startOf('year').toDate(),moment(date).endOf('year').toDate()])
}

export default function YearPicker({changeDate}) {
  
    const [value, setValue] = React.useState(new Date())
    const [format,setFormat] = React.useState(createFormat(new Date()))

    React.useEffect(()=>{
        changeDate(createDateRange(value))
    },[])

    const handleChange = (newValue) => {
        setValue(newValue);
        setFormat(createFormat(newValue))
        changeDate(createDateRange(newValue))
        console.log("change")
    }

    const addYear = (val) => {
        const newValue = moment(value).add(val,'year').toDate()
        setValue(newValue)
        setFormat(createFormat(newValue))
        changeDate(createDateRange(newValue))
        console.log("add")
    }


  return (
    <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        views={['year']}
        value={value}
        onChange={()=>{}}
        renderInput={(params) =>
            <Grid container justify='center' spacing={2} alignItems='center'>
                <Grid item>
                    <IconButton onClick={()=>addYear(-1)}>
                        <NavigateBeforeIcon/>
                    </IconButton>
                </Grid>
                <Grid item>
                    <TextField 
                    inputProps={{style: { textAlign: 'center' }}}
                    size='small' {...params} helperText={null}/>
                </Grid>
                <Grid item>
                    <IconButton onClick={()=>addYear(1)}>
                        <NavigateNextIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        }
        onAccept={(newValue) => handleChange(newValue)}
        inputFormat={format}
      />
    </LocalizationProvider>
  );
}
