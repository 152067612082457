import { AccordionDetails, Button, TextField, Typography, withStyles } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { connect } from "react-redux"
import '../style.css'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';
import actions from "../duck/actions";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const NewEmail = (props) => {

    const [expanded,setExpanded] = useState(false)
    const [newEmail,setNewEmail] = useState("")
    const { enqueueSnackbar } = useSnackbar()
    const [short,setShort] = useState(false)

    const emailValid = (email) =>{

      if(email.length===0){
        enqueueSnackbar("Email musi mieć długość od 4 do 255 znaków",{variant: 'warning',autoHideDuration: 4000})
      }else{
        let ats=0
        for (let x = 0; x < email.length; x++) {
          if(email.charAt(x)==="@"){ats++}
        }
        if(ats===1&&email.charAt(0)!=="@"){
          let afterAt = email.split('@')[1]
          let dots=0
          for (let x = 0; x < afterAt.length; x++) {
            if(afterAt.charAt(x)==="."){dots++}
            //console.log(afterAt.charAt(x))
          }
          //console.log(dots,afterAt.charAt(0),afterAt.charAt(afterAt.length-1))
          if(dots===1&&afterAt.charAt(0)!=="."&&afterAt.charAt(afterAt.length-1)!=="."){
            return true
          }else{
            enqueueSnackbar("Email musi zawierać jedną kropkę NIEbezpośrednio po małpie @",{variant: 'warning',autoHideDuration: 4000})
            return false
          }
        }else{
          enqueueSnackbar("Email musi zawierać jedną małpę @ i nie może ona znajdować się na początku",{variant: 'warning',autoHideDuration: 4000})
          return false
        }
      }
      
    }


    const handleAddEmail = () => {
      if(emailValid(newEmail)===true&&newEmail.length>=4&&newEmail.length<255){
        props.addNewEmail(newEmail)
        setExpanded(false)
        setNewEmail("")
        props.refresh()
      }
    }

      
    const handleInputChange = (val) => {
      if(val.length<4){
          setShort(true)
      }else{
          setShort(false)
      }if(val.length<255){
        setNewEmail(val)
      }
    }

    return(
    <Accordion expanded={expanded} onChange={()=>{setExpanded(!expanded)}}>
    <AccordionSummary  style={{backgroundColor:"#0750b5",color:"white"}}
    expandIcon={<AlternateEmailIcon style={{color:"white",fontSize:"1.4rem"}} />}
    >
      <Typography className="infoText">NOWY EMAIL</Typography>
    </AccordionSummary>
    <AccordionDetails>
        <div>
        <TextField
            value={newEmail}
            style={{marginTop:"8px",marginBottom:"8px"}}
            label="Nowy email"
            variant="outlined"
            fullWidth={true}
            inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
            onChange={(e) => handleInputChange(e.target.value)}
        />
        
        <Button onClick={() => {handleAddEmail()}} style={{backgroundColor:"rgb(67, 160, 71)",color:'white'}} fullWidth={true}>Dodaj</Button>
        </div>
        
        
    </AccordionDetails>
    </Accordion>
    )
}

const mapDispatchToProps = (dispatch) => {
  return{
    addNewEmail: (obj) => dispatch(actions.addNewEmail(obj)),
  }
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(NewEmail)