import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import StatusesHistoryTable from "../allOrders/StatusesHistoryTable";
import { serverUrl } from "../config/serverConfig";

function ServiceInfo(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [value,setValue] = useState("");
  const [serviceInfo,setServiceInfo] = useState();
  const [color,setColor] = useState();
  const [data,setServices] = useState([]);
  const [isLoading,setIsLoanding] = useState(true);
  const [expanded,setExpanded] = useState(false)
  const token='Bearer '.concat(props.jwt)
  const [refresh,setRefresh] = useState(0)
  

  useEffect(() => {
    async function getData() {
      await Promise.all([ 

        fetch(`${serverUrl}/statuses?serviceInfoId=${props.serviceInfoId}`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        }).then(response => response.json()),

        fetch(`${serverUrl}/services/${props.serviceId}`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        }).then(response => response.json())

      ]).then(json => {
        console.log(json)
        setServices(json[0])
        setServiceInfo(json[1])
        setValue(json[1].statusId)
        setIsLoanding(false)
      })
      .catch(error => {
        
      })
      
    }
    if (isLoading) {
      // if the result is not ready so you make the axios call
      getData();
    }else{
      changeStatus(value)
      props.onChange()
    }
    //console.log("wykonanie w serviceinfo")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])

  async function changeStatus() {
    await fetch(`${serverUrl}/services/${props.serviceId}/status?statusId=${value}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie zmieniono status",{variant: 'success',autoHideDuration: 2000})
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
      setRefresh(refresh+1)
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }

    function handleSelect(event){
    setValue(event.target.value)
    data.forEach(element => {
      if(event.target.value===element.statusId){
        setColor(element.color)
      }
    });
    
    }
  

    return(
        <Accordion expanded={expanded} style={{marginBottom:"15px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={()=>{setExpanded(!expanded)}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            
            <span style={{color:'black',fontSize:'1.3rem',fontFamily:'arial',width:'60%',textAlign:'center',display:'flex',alignItems:'center'}}>
               {isLoading===true?null:serviceInfo.serviceName}
            </span>
            <Select 
            value={value} 
            className="selectStatus" 
            style={{width:'60%',backgroundColor:color,color:'black',fontSize:'1.2rem',fontFamily:'arial',textAlign:'center',padding:'3px'}} 
            onChange={e=> handleSelect(e)}
            >
               {data.map( (status,index) => (
                   
                        <MenuItem
                            value={status.statusId} 
                            style={{backgroundColor:status.color,padding:'15px'}} 
                            key={index}
                            id={index}
                            >
                            {status.statusName.concat(status.ending===true?" (zak.)":"")}
                        </MenuItem>
                    
               ))}
            </Select>
        </AccordionSummary>
        <AccordionDetails>
            <StatusesHistoryTable key={refresh} serviceId={props.serviceId}/>
        </AccordionDetails>
        </Accordion>
    )
}

export default connect((state)=>({jwt:state.log.jwt}))(ServiceInfo)