import {Grid } from "@material-ui/core"
import React from 'react'
import OrdersCosts from "../costs/OrdersCosts"
import CommentsSection from '../departments/CommentsSection'
import '../departments/style.css'
import CategoriesTable from "../files/CategoriesTable"
import InfoTable from "../infoTable/InfoTable"
import FunctionalButtons from "./FunctionalButtons"
import ServicesTable from "./ServicesTable"

 function AllOrdersDialog(props){

    
   
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                <Grid item>
                    <CategoriesTable orderId={props.orderId}/>
                </Grid>
                <Grid>
                    <FunctionalButtons orderId={props.orderId}/>
                </Grid>
                <Grid item >
                    <InfoTable orderId={props.orderId} linkEnabled={false}/>
                </Grid>
                <Grid item style={{marginTop:'24px'}}>
                    <CommentsSection
                    style={{width:'30%',float:'left'}}
                    orderId={props.orderId}
                    onChange={props.onChange}>
                    </CommentsSection>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={9} >
                <Grid item>
                    <ServicesTable orderId={props.orderId}/>
                </Grid>
                <Grid item style={{marginTop:'24px'}}>
                    <OrdersCosts orderId={props.orderId}/>
                </Grid>
            </Grid>
            
        </Grid>
    )
}

export default (AllOrdersDialog)