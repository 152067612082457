import { ListItem } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { List } from "@material-ui/core"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { serverUrl } from "../config/serverConfig"



const OrdersList = ({jwt,customerId}) => {
    
    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    const [services,setServices] = useState([])
    const history = useHistory()
    

    const redirectService = (row) =>{
    
        switch(row.departmentId){
          case 1:
            //console.log(history);
            history.push({
              pathname: '/geodesy',
              state: {
                orderId: row.orderId,
                serviceId: row.serviceId,
                serviceInfoId: row.serviceInfoId,
              }
            })
          break;
            
          case 2:
            history.push({
              pathname: '/typical-projects',
              state: { 
                orderId: row.orderId,
                serviceId: row.serviceId,
                serviceInfoId: row.serviceInfoId,
              }
            })
          break;
    
          case 3:
            history.replace({
              pathname: '/individual-projects',
              state: { 
                orderId: row.orderId,
                serviceId: row.serviceId,
                serviceInfoId: row.serviceInfoId,
                projectId: row.projectId
              }
            })
          break;
    
          case 4:
            history.push({
              pathname: '/sanitary-installations',
              state: { 
                orderId: row.orderId,
                serviceId: row.serviceId,
                serviceInfoId: row.serviceInfoId,
              }
            })
          break;
    
          case 5:
            history.push({
              pathname: '/conditions',
              state: { 
                orderId: row.orderId,
                serviceId: row.serviceId,
                serviceInfoId: row.serviceInfoId,
                conditionsId: row.conditionsId
              }
            })
          break;
    
          case 6:
            history.push({
              pathname: '/geology',
              state: { 
                orderId: row.orderId,
                serviceId: row.serviceId,
                serviceInfoId: row.serviceInfoId,
              }
            })
          break;

          default:return null;
        }
      } 
    

    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/customers/services?customerId=${customerId}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            console.log(json);
            setServices(json);
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
          })
        }
        if (isLoading) {
          getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    return(
        <List component={Paper}>
            {isLoading?
            <ListItem>
                <CircularProgress></CircularProgress>
            </ListItem>
            :
            services.map((service,index) =>(
                <ListItem key={index}>
                    <div style={{width:'100%'}}>
                    <span>Dodano: </span>
                    <span>{service.entryDate.substr(0,10)}</span>
                    <span style={{float:'right'}}>{service.statusName}</span>
                    <Button 
                    variant="outlined"
                    style={{display:'inline-flex',justifyContent:'flex-start',marginBottom:'6px'}} 
                    size="large"
                    color="secondary"
                    key={index}
                    onClick={()=>redirectService({
                        departmentId:service.departmentId,
                        orderId: service.orderId,
                        serviceId: service.serviceId,
                        serviceInfoId: service.serviceInfoId,
                    })}
                    fullWidth={true}>
                        <span>{service.serviceId}  -  {service.serviceName}</span>
                    </Button>
                    </div>
                </ListItem>
            ))
            }
        </List>
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(OrdersList)