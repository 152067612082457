import types from './types';
const init = {
    loggedIn: false,
    user:{
        name: null,
        surname: null,
        roles:[],
        image:null,
        phone:null,
        userId:null,
        userName:null,
    },
    jwt:null,
    exp:null,
    iat:null,
    error:0,
    timeLeft:null,
}

function logReducer(state = init, action)
{

    switch(action.type){
        case types.LOGIN_USER:
            
            let roles=[]
            action.item.loginObject.userRoles.forEach(object => {
                roles.push(object.authority.substr(5))
            });
            console.log(action.item.loginObject.userRoles,roles)
            return {
                ...state,
                loggedIn:true,
                user:{
                    name:action.item.loginObject.userData.name,
                    surname:action.item.loginObject.userData.surname,
                    roles:roles,
                    image:null,
                    phone:action.item.loginObject.userData.phoneNumber,
                    userId:action.item.loginObject.userData.userId,
                    userName:action.item.loginObject.sub
                },
                jwt:action.item.jwt,
                exp:action.item.loginObject.exp,
                iat:action.item.loginObject.iat,
                error:0
            }
        case types.LOGOUT_USER:
            return {
                ...state,
                loggedIn: false,
                user:{
                    name: null,
                    surname: null,
                    roles:[],
                    image:null,
                    phone:null,
                    userId:null,
                    userName:null,
                },
                jwt:null,
                exp:null,
                iat:null,
                error:0,
                timeLeft:null
            }
        case types.GET_JWT:
            return {
                ...state.jwt
            }
        case types.SET_TIME_LEFT:
            return {...state,timeLeft:action.item}
        
        default:return state;
    }
    
}

export default logReducer;