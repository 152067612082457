import { CircularProgress, TableCell, TableRow, Typography } from "@mui/material"

const renderNoLeavesCell = (colQuant) => (
    <TableRow>
        <TableCell colSpan={colQuant} style={{textAlign:'center',color:'grey'}}>Brak urlopów.</TableCell>    
    </TableRow>
)

const renderLoadingCell = (colQuant) => (
    <TableRow>
        <TableCell colSpan={colQuant} align="center">
            <CircularProgress style={{marginLeft:'auto',marginRight:'auto',display:'block'}}/>
        </TableCell>    
    </TableRow>
)

const renderErrorCell = (colQuant) => (
    <TableRow>
        <TableCell colSpan={colQuant} align="center">
        <div style={{
            width:'100%',
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
            flexDirection:'column'
        }}>
            <>
            <Typography style={{
                color:'grey',
                fontSize:25,
            }}>
                Wystąpił błąd ładowania danych... :(
            </Typography>
            <Typography style={{
                color:'grey',
                fontSize:15,
            }}>
                Spróbuj odświeżyć stronę...
            </Typography>
            </>
        </div>
        </TableCell>    
    </TableRow>
)

export {
    renderLoadingCell,
    renderNoLeavesCell,
    renderErrorCell
}