import { withStyles } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import CreateIcon from '@material-ui/icons/Create';
import MapIcon from '@material-ui/icons/Map';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GavelIcon from '@material-ui/icons/Gavel';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { Badge } from "@material-ui/core"
import { TableRow } from "@material-ui/core"
import { TableCell } from "@material-ui/core"
import { Table } from "@material-ui/core"
import { TableContainer } from "@material-ui/core"
import { TableBody } from "@material-ui/core"
import FileDialog from "./FileDialog"

const ButtonTableCell = withStyles({
    root:{
        backgroundColor:'white',
        color:'#0750b5',
        padding:'12px 4px 8px', 
        borderBottom:'solid 6px white',
        '&:hover':{
            backgroundColor:'#f6f6f6',
            paddingTop:'4px',
            borderBottom:'solid 6px',
            cursor:'pointer'
        }
    }
})(TableCell)

const CategoriesTable = ({jwt,orderId}) => {


    
    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    const [categories,setCategories] = useState([])
    const [open,setOpen] = useState(false)
    const [categoryId,setCategoryId] = useState()
    const [refresh,setRefresh] = useState(0)
    const [name,setName] = useState()

    const assignation = (id) => {

        const style={
            backgroundColor:'#f6f6f6',
            color:'#0750b5',
            padding:'6px',
            fontSize:'2rem',
            borderRadius:'30px',
            margin:'5px'
            }

        switch(id){
            case 1: return<CreateIcon style={style}/> 
            case 2: return<MapIcon style={style}/>
            case 3: return<AssignmentIcon style={style}/>
            case 4: return<GavelIcon style={style}/>
            case 5: return<FolderOpenIcon style={style}/>
            default: return "icon"
        }

    }

    useEffect(() => {
        async function getData() {
            await fetch(`${serverUrl}/file-system/order-files?orderId=${orderId}`,
            {method:'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
            })
            .then(response => response.json())
            .then(json => {
              console.log(json);
              setCategories(json);
              setIsLoading(false);
            })
            .catch(error => {
              console.log(error);
            })
        }
        if (isLoading||refresh>0) {getData()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = (categoryId,name) => {
        setOpen(true)
        setCategoryId(categoryId)
        setName(name)
    }

    return(
        <>
        <TableContainer component={Paper} style={{marginBottom:'24px'}}>
            <Table>
                <colgroup>
                    <col style={{width:'2px'}}/>
                    <col style={{width:'2px'}}/>
                    <col style={{width:'2px'}}/>
                    <col style={{width:'2px'}}/>
                    <col style={{width:'2px'}}/>
                </colgroup>
                <TableBody>
                    <TableRow style={{borderBottom:'solid white'}}>
                    {categories.map((category,index)=>(
                        <ButtonTableCell 
                        onClick={() => handleOpen(category.categoryId,category.name)}
                        key={category.categoryId}
                        style={{textAlign:'center'}}
                        >  
                            <Badge
                            color='secondary'
                            overlap='circle'
                            badgeContent={category.files}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            >
                                {assignation(category.categoryId)}
                            </Badge>
                            <Typography>
                                {category.name.length>7?category.name.substr(0,7).concat('.'):category.name}
                            </Typography>
                        </ButtonTableCell>
                    ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <FileDialog 
        open={open} 
        close={handleClose} 
        orderId={orderId}
        categoryId={categoryId}
        refresh={()=>{setRefresh(refresh+1)}}
        name={name}/>
        </>
    )
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(CategoriesTable)