import { TableBody, TableCell, TableHead, TableRow, Table, Paper, TableContainer, Select, InputAdornment, MenuItem } from "@material-ui/core"
import { connect } from "react-redux"
import actions from "../duck/actions"
import PersonIcon from '@material-ui/icons/Person';
import { serverUrl } from "../../config/serverConfig";
import { useEffect, useState } from "react";
const DepartmentsSummary = (props) => {

    const {servicesGeod} = props
    const {servicesGeol} = props
    const {servicesIndi} = props
    const {servicesTypi} = props
    const {servicesCond} = props
    const {servicesSani} = props

    const [isLoading,setIsLoanding] = useState(true);
    const [options,setOptions] = useState([])
    const token='Bearer '.concat(props.jwt)
    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/users/simple-users-list`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            //console.log(json)
            setOptions(json);
            setIsLoanding(false);
          })
          .catch(error => {
            console.log(error)
          })
        }
        if (isLoading) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    

    const handleGeod = (id) =>{
        props.setSupervisorGeod(id)
        return null
    }

    const handleTypi = (id) =>{
        props.setSupervisorTypi(id)
        return null
    }

    const handleSani = (id) =>{
        props.setSupervisorSani(id)
        return null
    }

    const handleCond = (id) =>{
        props.setSupervisorCond(id)
        return null
    }

    const handleGeol = (id) =>{
        props.setSupervisorGeol(id)
        return null
    }

    const handleIndi = (id) =>{
        props.setSupervisorIndi(id)
        return null
    }

    return(
        <TableContainer component={Paper}>
        <Table>
            <TableHead style={{backgroundColor:"#0750b5"}}>
                <TableRow>
                    <TableCell style={{width:"20%",color:"white",textAlign:"center"}}>Dział</TableCell>
                    <TableCell style={{color:"white",width:"40%",textAlign:"center"}}>Usługi</TableCell>
                    <TableCell style={{width:"40%",color:"white",textAlign:"center"}}>Osoba odpowiedzialna *</TableCell>
                </TableRow>
            </TableHead>
            
            <TableBody>
                {
                    servicesGeod.length>0?
                    <>
                    <TableRow key={1}>
                        <TableCell className="tableFirstChild" rowSpan={servicesGeod.length}><span className="depsSpan">GEODEZJA</span></TableCell>
                        <TableCell>{servicesGeod[0].serviceName}</TableCell>
                        <TableCell className="tableLastChild" rowSpan={servicesGeod.length}>
                            <Select 
                            className="depSelect" 
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                            onChange={(e)=>handleGeod(e.target.value)}
                            value={isLoading===false?props.supervisorGeod:''}>
                            {
                                options.map((user,index)=>{
                                    return(
                                        <MenuItem key={user.userId} value={user.userId}>{user.name.concat(" ",user.surname)}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </TableCell>
                    </TableRow>
                    {
                        servicesGeod.map((service,index)=>{
                            //console.log(index+1,service)
                            if(index>0){
                                return(
                                    <TableRow key={service.serviceId}>
                                        <TableCell key={service.serviceId}>{service.serviceName}</TableCell>
                                    </TableRow>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                    </>:handleGeod("")
                }
                {
                    servicesTypi.length>0?
                    <>
                    <TableRow key={2}>
                        <TableCell className="tableFirstChild" rowSpan={servicesTypi.length}><span className="depsSpan">PROJEKTY TYPOWE</span></TableCell>
                        <TableCell>{servicesTypi[0].serviceName}</TableCell>
                        <TableCell className="tableLastChild" rowSpan={servicesTypi.length}>
                            <Select 
                            className="depSelect" 
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                            onChange={(e)=>{handleTypi(e.target.value)}}
                            value={isLoading===false?props.supervisorTypi:''}>
                            {
                                options.map((user,index)=>{
                                    return(
                                        <MenuItem key={user.userId} value={user.userId}>{user.name.concat(" ",user.surname)}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </TableCell>
                    </TableRow>
                    {
                        servicesTypi.map((service,index)=>{
                            //console.log(index+1,service)
                            if(index>0){
                                return(
                                    <TableRow key={service.serviceId}>
                                        <TableCell key={service.serviceId}>{service.serviceName}</TableCell>
                                    </TableRow>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                    </>:handleTypi("")
                }
                {
                    servicesIndi.length>0?
                    <>
                    <TableRow key={3}>
                        <TableCell className="tableFirstChild" rowSpan={servicesIndi.length}><span className="depsSpan">PROJEKTY INDYWIDUALNE</span></TableCell>
                        <TableCell>{servicesIndi[0].serviceName}</TableCell>
                        <TableCell className="tableLastChild" rowSpan={servicesIndi.length}>
                            <Select 
                            className="depSelect" 
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                            onChange={(e)=>{handleIndi(e.target.value)}}
                            value={isLoading===false?props.supervisorIndi:''}>
                            {
                                options.map((user,index)=>{
                                    return(
                                        <MenuItem key={user.userId} value={user.userId}>{user.name.concat(" ",user.surname)}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </TableCell>
                    </TableRow>
                    {
                        servicesIndi.map((service,index)=>{
                            //console.log(index+1,service)
                            if(index>0){
                                return(
                                    <TableRow key={service.serviceId}>
                                        <TableCell key={service.serviceId}>{service.serviceName}</TableCell>
                                    </TableRow>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                    </>:handleIndi("")
                }
                {
                    servicesSani.length>0?
                    <>
                    <TableRow  key={4}>
                        <TableCell className="tableFirstChild" rowSpan={servicesSani.length}><span className="depsSpan">INSTALACJE SANITARNE</span></TableCell>
                        <TableCell>{servicesSani[0].serviceName}</TableCell>
                        <TableCell className="tableLastChild" rowSpan={servicesSani.length}>
                            <Select 
                            className="depSelect" 
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                            onChange={(e)=>{handleSani(e.target.value)}}
                            value={isLoading===false?props.supervisorSani:''}>
                            {
                                options.map((user,index)=>{
                                    return(
                                        <MenuItem key={user.userId} value={user.userId}>{user.name.concat(" ",user.surname)}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </TableCell>
                    </TableRow>
                    {
                        servicesSani.map((service,index)=>{
                            //console.log(index+1,service)
                            if(index>0){
                                return(
                                    <TableRow key={service.serviceId}>
                                        <TableCell key={service.serviceId}>{service.serviceName}</TableCell>
                                    </TableRow>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                    </>:handleSani("")
                }
                {
                    servicesCond.length>0?
                    <>
                    <TableRow  key={5}>
                        <TableCell className="tableFirstChild" rowSpan={servicesCond.length}><span className="depsSpan">WARUNKI ZABUDOWY</span></TableCell>
                        <TableCell>{servicesCond[0].serviceName}</TableCell>
                        <TableCell className="tableLastChild" rowSpan={servicesCond.length}>
                            <Select 
                            className="depSelect" 
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                            onChange={(e)=>{handleCond(e.target.value)}}
                            value={isLoading===false?props.supervisorCond:''}>
                            {
                                options.map((user,index)=>{
                                    return(
                                        <MenuItem key={user.userId} value={user.userId}>{user.name.concat(" ",user.surname)}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </TableCell>
                    </TableRow>
                    {
                        servicesCond.map((service,index)=>{
                            //console.log(index+1,service)
                            if(index>0){
                                return(
                                    <TableRow key={service.serviceId}>
                                        <TableCell key={service.serviceId}>{service.serviceName}</TableCell>
                                    </TableRow>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                    </>:handleCond("")
                }
                {
                    servicesGeol.length>0?
                    <>
                    <TableRow  key={6}>
                        <TableCell className="tableFirstChild" rowSpan={servicesGeol.length}><span className="depsSpan">GEOLOGIA</span></TableCell>
                        <TableCell>{servicesGeol[0].serviceName}</TableCell>
                        <TableCell className="tableLastChild" rowSpan={servicesGeol.length}>
                            <Select 
                            className="depSelect" 
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                            onChange={(e)=>{handleGeol(e.target.value)}}
                            value={isLoading===false?props.supervisorGeol:''}>
                            {
                                options.map((user,index)=>{
                                    return(
                                        <MenuItem key={user.userId} value={user.userId}>{user.name.concat(" ",user.surname)}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </TableCell>
                    </TableRow>
                    {
                        servicesGeol.map((service,index)=>{
                            //console.log(index+1,service)
                            if(index>0){
                                return(
                                    <TableRow key={service.serviceId}>
                                        <TableCell key={service.serviceId}>{service.serviceName}</TableCell>
                                    </TableRow>
                                )
                            }else{
                                return null
                            }
                        })
                    }
                    </>:handleGeol("")
                }
                
            </TableBody>
        </Table>
        </TableContainer>
    )
}

const mapStateToProps = (state) =>{
    return{
        servicesGeod:state.newOrder.servicesGeod,
        servicesGeol:state.newOrder.servicesGeol,
        servicesIndi:state.newOrder.servicesIndi,
        servicesTypi:state.newOrder.servicesTypi,
        servicesCond:state.newOrder.servicesCond,
        servicesSani:state.newOrder.servicesSani,
        //tu dalej ściągać dane z reducerów :)
        supervisorGeod:state.newOrder.supervisorGeod,
        supervisorGeol:state.newOrder.supervisorGeol,
        supervisorIndi:state.newOrder.supervisorIndi,
        supervisorTypi:state.newOrder.supervisorTypi,
        supervisorCond:state.newOrder.supervisorCond,
        supervisorSani:state.newOrder.supervisorSani,
        jwt: state.log.jwt
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setSupervisorGeod: (id) => dispatch(actions.setSupervisorGeod(id)),
        setSupervisorGeol: (id) => dispatch(actions.setSupervisorGeol(id)),
        setSupervisorTypi: (id) => dispatch(actions.setSupervisorTypi(id)),
        setSupervisorIndi: (id) => dispatch(actions.setSupervisorIndi(id)),
        setSupervisorCond: (id) => dispatch(actions.setSupervisorCond(id)),
        setSupervisorSani: (id) => dispatch(actions.setSupervisorSani(id)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DepartmentsSummary)