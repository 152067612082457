import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig";

const CustomerDataTable = (props) => {
    const {customerId} = props
    const [emails,setEmails] = useState([])
    const [phoneNumbers,setPhoneNumbers] = useState([])
    const [addresses,setAddresses] = useState([])
    const [customerProps,setCustomerProps]=useState({})
    const [isLoading,setIsLoanding] = useState(true)
    const token='Bearer '.concat(props.jwt)
    const [refreshVar,setRefreshVar] = useState(0)
    
    useEffect(() => {
        async function getData() {
        await Promise.all([ 

            fetch(`${serverUrl}/customers/customer-info?customerId=${customerId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json()),

            fetch(`${serverUrl}/phone-numbers/customer?customerId=${customerId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json()),

            fetch(`${serverUrl}/emails/customer?customerId=${customerId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json()),

            fetch(`${serverUrl}/addresses/customer?customerId=${customerId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json())
        ])
          .then(json => {
            console.log("to jest json: ",json)
            setIsLoanding(false)
           
            setAddresses(json[3])
            setEmails(json[2])
            setCustomerProps(json[0])
            setPhoneNumbers(json[1])
            
            
          })
          .catch(error => {
            
          })
        }
        if (isLoading||refreshVar>0) {
          // if the result is not ready so you make the axios call
          getData();
          setRefreshVar(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[refreshVar])


    const empty = () =>{
        return(<center><span style={{color:"red",fontSize:"1.2rem"}}>--</span></center>)
    }

    

    return(
        <>
        <table className="infoTable">
                    
                <tbody>

                        
                        <tr >
                        <td>Nazwa</td>
                        <td>{customerProps.customerName}</td>
                        </tr>
                        <tr >
                        <td>Zlecenia</td>
                        <td>
                            <div style={{    
                              width: '36px',
                              height: '36px',
                              borderRadius: '50%',
                              backgroundColor: '#3f51b5',
                              textAlign: 'center',
                              color:'white'
                            }}
                            >
                              <span style={{display:'block',fontSize:'1.3rem',paddingTop:'6px'}}>
                                {customerProps.orders}
                              </span>
                            </div>
                        </td>
                        </tr>
                        <tr >
                        <td>Prace</td>
                        <td>
                            <div style={{    
                              width: '36px',
                              height: '36px',
                              borderRadius: '50%',
                              backgroundColor: '#f50057',
                              color:'white',
                              textAlign: 'center'
                            }}
                            >
                              <span style={{display:'block',fontSize:'1.3rem',paddingTop:'6px'}}>
                                {customerProps.services}
                              </span>
                            </div></td>
                        </tr>
                        <tr >
                        <td>Usługi na kwotę</td>
                        <td>{customerProps.amount}</td>
                        </tr>
                        <tr>
                        <td rowSpan={phoneNumbers.length===0?1:phoneNumbers.length}>Telefon</td>
                        <td >{phoneNumbers.length===0?empty():phoneNumbers[0].phoneNumber}</td>
                        </tr>
                            {
                                phoneNumbers.map((item,index)=>{
                                    if(index!==0){
                                        return (<tr key={item.phoneNumberId}><td key={item.phoneNumberId} style={{fontWeight:"normal"}}><span key={item.phoneNumberId}>{item.phoneNumber}</span></td></tr>)
                                    }else{
                                        return null
                                    }
                                })
                            }
                        <tr>
                        <td rowSpan={addresses.length===0?1:addresses.length}>Adres koresp.</td>
                        <td >{addresses.length===0?empty():addresses[0].address}</td>
                        </tr>
                            {   
                                addresses.map((item,index)=>{
                                    if(index!==0){
                                        return (<tr key={item.addressId}><td key={item.addressId} style={{fontWeight:"normal"}}><span key={item.addressId}>{item.address}</span></td></tr>)
                                    }else{
                                        return null
                                    }
                                })
                            }
                        <tr>
                        <td rowSpan={emails.length===0?1:emails.length}>Email</td>
                        <td >{emails.length===0?empty():emails[0].email}</td>
                        </tr>
                            {
                                emails.map((item,index)=>{
                                    if(index!==0){
                                        return (<tr key={item.emailId}><td key={item.emailId} style={{fontWeight:"normal"}}><span key={item.emailId}>{item.email}</span></td></tr>)
                                    }else{
                                        return null
                                    }
                                })
                            }
                </tbody>
            
               </table> 
              </>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(CustomerDataTable)