import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import { CircularProgress} from "@material-ui/core"
import ReportListElement from "./ReportListElement"
import { Grid } from "@material-ui/core"
import UsersList from "./UsersList"

const DayReportsList = (props) =>{

    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(props.jwt)
    const [reports,setReports] = useState([])

    useEffect(() => {
        async function getData() {
            await fetch(`${serverUrl}/reports/all?startDate=${props.date}&endDate=${props.date}`,
            {method:'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
            })
            .then(response => response.json())
            .then(json => {
              console.log(json);
              setReports(json);
              setIsLoading(false);
            })
            .catch(error => {
              console.log(error);
            })
        }
        if (isLoading) {getData()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Grid container spacing={2}>
            <Grid item lg={3} xs={12}>
                <UsersList date={props.date}/>
            </Grid>
            <Grid item lg={9} xs={12}>{
                isLoading===true?<CircularProgress/>:
                reports.map((report)=>(
                    <ReportListElement report={report}/>
                ))
            }</Grid>
        </Grid>
        
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(DayReportsList)