import React, { useState,useEffect } from 'react'
import { useTable, useSortBy } from 'react-table'
import { Grid, makeStyles,Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Modal, CircularProgress, Typography, Popover } from '@material-ui/core';
import RangeCalendar from './RangeCalendar';
import WeekPicker from './WeekPicker';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';
import { connect } from 'react-redux';
import { serverUrl } from '../config/serverConfig';
import moment from 'moment'
import { Box } from '@mui/system';

/*const data = [
    {
        'age':'Wytyczenie',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Mapa do celów projektowych',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Podział działki',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Wznowenie znaków granicznych',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Inwentaryzacja obiektów budowlanych',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Aktualizacja ewidencji',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Połączenie działek',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Sieć wodociągowa',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Sieć gazowa',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Zbiornik na nieczystości ciekłe',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Zakup projektu typowego',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Adaptacja projektu',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    {
        'age':'Warunki zabudowy',
        'visits':51,
        'status':'gitoowa',
        'progress':120,
        'progress1':1231,
        'progress2':131,
        'progress3':1331,
        'progress4':143,
        'progress5':131,
        'progress6':13,
        'progress7':1531,
        'progress8':163
    },
    
]
*/
const useStyles = makeStyles({
    cellGreyFirst: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 180,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        backgroundColor:'#cacaca',
        position: 'sticky',
        left: 0,
        zIndex: 1,
    },
    cellGreyFirstCorner: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 180,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        backgroundColor:'#cacaca',
        position: 'sticky',
        left: 0,
        top:0,
        zIndex: 1,
    },
    cellRealGreyFirst: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 180,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        backgroundColor:'#cacaca',
        position:'sticky',
        left:0
    },
    cellGrey: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        minWidth:50,
        backgroundColor:'#5b9ad5'
    },
    cellRed: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        minWidth:50,
        backgroundColor:'#ed7c31'
    },
    cellYellow: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        minWidth:50,
        backgroundColor:'#ffbf00'
    },
    cellGreen: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        minWidth:50,
        backgroundColor:'#70ad15'
    },
    cellBlue: {
        borderRight:'1px solid #dadada',
        paddingLeft: 5,
        paddingRight: 5,
        textAlign:'center',
        "&:last-child": {
            borderRight:0,
        },
        minWidth:50,
        backgroundColor:'#4472c6'
    },
    button: {
        backgroundColor:'white',
        margin:'2px 5px 2px 5px',
        "&:hover": {
            backgroundColor:'#ffff00',
        }
    },
    buttonSelected: {
        margin:'2px 5px 2px 5px',
        backgroundColor:'#0c9c00',
        color:'white',
        "&:hover": {
            backgroundColor:'#0c9c00',
        }
    },
    stickyLeft:{
        "&:nth-child(1)": {
            backgroundColor: "#e9e9e9",
            fontWeight:'bold',
            position: 'sticky',
            left: 0,
            zIndex: 1,
        },
    },
    stickyHeader1:{
        position: 'sticky',
        top: 0,
        zIndex:2
    },
    stickyHeader2:{
        position: 'sticky',
        top: 36,
        zIndex:2
    },
    summary:{
        backgroundColor:'#0750b5',
        color:'white',
        //position:'sticky',
        //bottom:'0px',
        zIndex:2,
        //borderRight:'solid 1px #053d8a',
        "&:nth-child(1)": {
            backgroundColor: "#8b8b8b",
            fontWeight:'bold',
            position: 'sticky',
            left: 0,
            zIndex: 3,
        },
    },
    summaryRow:{
        position: 'sticky',
        bottom: '0px',
        zIndex: 3,
    },
    modalLoading:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }
    
    
})

const summaryInit={
    defaultHours:0,
    hoursSum:0,
    addedServices:0,
    addedServicesWithNoPriceDefined:0,
    priceSum:0,
    endedServices:0,
    endedServicesWithNoPriceDefined:0,
    endedPriceSum:0,
    endedFullyPaidServices:0,
    endedPaidSum:0,
    moneyToGetFromEnded:0,
    endedServicesHours:0,
    suspendedServices:0,
    suspendedServicesWithNoPriceDefined:0,
    suspendedPriceSum:0,
    suspendedFullyPaidServices:0,
    suspendedPaidSum:0,
    moneyToGetFromSuspended:0,
    suspendedServicesHours:0,
    activeServices:0,
    activeServicesWithNoPriceDefined:0,
    activePriceSum:0,
    activeFullyPaidServices:0,
    activePaidSum:0,
    moneyToGetFromActive:0,
    activeServicesHours:0,
    avgServicePrice:0,
    avgHourPrice:0,
    undefinedPriceHoursSum:0
}

const calculateSummary = (data) => {

    let defaultHours=0
    let hoursSum=0
    let addedServices=0
    let addedServicesWithNoPriceDefined=0
    let priceSum=0
    let endedServices=0
    let endedServicesWithNoPriceDefined=0
    let endedPriceSum=0
    let endedFullyPaidServices=0
    let endedPaidSum=0
    let moneyToGetFromEnded=0
    let endedServicesHours=0
    let suspendedServices=0
    let suspendedServicesWithNoPriceDefined=0
    let suspendedPriceSum=0
    let suspendedFullyPaidServices=0
    let suspendedPaidSum=0
    let moneyToGetFromSuspended=0
    let suspendedServicesHours=0
    let activeServices=0
    let activeServicesWithNoPriceDefined=0
    let activePriceSum=0
    let activeFullyPaidServices=0
    let activePaidSum=0
    let moneyToGetFromActive=0
    let activeServicesHours=0
    let avgServicePrice=0
    let avgHourPrice=0
    let undefinedPriceHoursSum=0

    data.forEach(service => {
    defaultHours+=service.defaultHours
    hoursSum+=service.hoursSum
    addedServices+=service.addedServices
    addedServicesWithNoPriceDefined+=service.addedServicesWithNoPriceDefined
    priceSum+=service.priceSum
    endedServices+=service.endedServices
    endedServicesWithNoPriceDefined+=service.endedServicesWithNoPriceDefined
    endedPriceSum+=service.endedPriceSum
    endedFullyPaidServices+=service.endedFullyPaidServices
    endedPaidSum+=service.endedPaidSum
    moneyToGetFromEnded+=service.moneyToGetFromEnded
    endedServicesHours+=service.endedServicesHours
    suspendedServices+=service.suspendedServices
    suspendedServicesWithNoPriceDefined+=service.suspendedServicesWithNoPriceDefined
    suspendedPriceSum+=service.suspendedPriceSum
    suspendedFullyPaidServices+=service.suspendedFullyPaidServices
    suspendedPaidSum+=service.suspendedPaidSum
    moneyToGetFromSuspended+=service.moneyToGetFromSuspended
    suspendedServicesHours+=service.suspendedServicesHours
    activeServices+=service.activeServices
    activeServicesWithNoPriceDefined+=service.activeServicesWithNoPriceDefined
    activePriceSum+=service.activePriceSum
    activeFullyPaidServices+=service.activeFullyPaidServices
    activePaidSum+=service.activePaidSum
    moneyToGetFromActive+=service.moneyToGetFromActive
    activeServicesHours+=service.activeServicesHours
    avgServicePrice+=service.avgServicePrice
    avgHourPrice+=service.avgHourPrice
    undefinedPriceHoursSum+=service.undefinedPriceHoursSum
    });

    return {
        defaultHours:defaultHours,
        hoursSum:hoursSum,
        addedServices:addedServices,
        addedServicesWithNoPriceDefined:addedServicesWithNoPriceDefined,
        priceSum:priceSum,
        endedServices:endedServices,
        endedServicesWithNoPriceDefined:endedServicesWithNoPriceDefined,
        endedPriceSum:endedPriceSum,
        endedFullyPaidServices:endedFullyPaidServices,
        endedPaidSum:endedPaidSum,
        moneyToGetFromEnded:moneyToGetFromEnded,
        endedServicesHours:endedServicesHours,
        suspendedServices:suspendedServices,
        suspendedServicesWithNoPriceDefined:suspendedServicesWithNoPriceDefined,
        suspendedPriceSum:suspendedPriceSum,
        suspendedFullyPaidServices:suspendedFullyPaidServices,
        suspendedPaidSum:suspendedPaidSum,
        moneyToGetFromSuspended:moneyToGetFromSuspended,
        suspendedServicesHours:suspendedServicesHours,
        activeServices:activeServices,
        activeServicesWithNoPriceDefined:activeServicesWithNoPriceDefined,
        activePriceSum:activePriceSum,
        activeFullyPaidServices:activeFullyPaidServices,
        activePaidSum:activePaidSum,
        moneyToGetFromActive:moneyToGetFromActive,
        activeServicesHours:activeServicesHours,
        avgServicePrice:avgServicePrice,
        avgHourPrice:avgHourPrice,
        undefinedPriceHoursSum:undefinedPriceHoursSum
    }
}

function Tab({tableName,columns,data,dateChangeFunction}) {

    const [selected,setSelected] = useState('all')
    const [summary,setSummary]=useState(summaryInit)
    const {getTableProps,getTableBodyProps,headerGroups,rows,prepareRow} = useTable({columns,data},useSortBy)
    const classes = useStyles();

    useEffect(()=>{
        if(selected==='all'){
            dateChangeFunction(null,selected)
        }
    },[selected])

    useEffect(()=>{
        setSummary(calculateSummary(data))
        //console.log("recalculate")
    },[data])

    const changeTimeRange = (val) => {
        setSelected(val)
    }

    const changeDate = (dateRange) =>{
        dateChangeFunction(dateRange,selected)
    }

    const colorSwitch = (color) =>{
        //console.log(color)
        switch(color){
            case 'grey':
                return classes.cellGrey
            case 'red':
                return classes.cellRed
            case 'yellow':
                return classes.cellYellow
            case 'green':
                return classes.cellGreen
            case 'blue':
                return classes.cellBlue
            case 'realGrey':
                return classes.cellRealGreyFirst
            default:
                return classes.cellGrey
        }
    }

    return (
        <div>
        <Grid container justify='center' style={{backgroundColor:'white',padding:2}}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginLeft:'auto',marginRight:'auto',overflowX:"auto",maxHeight:'80vh'}}>
        <Box alignItems='center' style={{color:'white',backgroundColor:'#0750b5',padding:2,position:'sticky',left:0}}>
            <Button onClick={()=>changeTimeRange("all")} className={selected==='all'?classes.buttonSelected:classes.button}>OD POCZĄTKU</Button>
            <Button onClick={()=>changeTimeRange("week")} className={selected==='week'?classes.buttonSelected:classes.button}>TYDZIEŃ</Button>
            <Button onClick={()=>changeTimeRange("month")} className={selected==='month'?classes.buttonSelected:classes.button}>MIESIĄC</Button>
            <Button onClick={()=>changeTimeRange("year")} className={selected==='year'?classes.buttonSelected:classes.button}>ROK</Button>
            <Button onClick={()=>changeTimeRange("custom")} className={selected==='custom'?classes.buttonSelected:classes.button}>WŁASNY</Button>
        </Box>
        {selected!='all'?
        <Box style={{color:'#0750b5',backgroundColor:'white',padding:2,position:'sticky',left:0}}>
            {selected==='custom'?<RangeCalendar changeDate={changeDate}/>:null}
            {selected==='week'?<WeekPicker changeDate={changeDate}/>:null}
            {selected==='month'?<MonthPicker changeDate={changeDate}/>:null}
            {selected==='year'?<YearPicker changeDate={changeDate}/>:null}
        </Box>:null
        }
        <Table size="small" component={Paper} {...getTableProps()}>
            <TableHead >
                {/*<TableRow>
                    <TableCell style={{color:'white',backgroundColor:'#0750b5',borderTopLeftRadius:5,borderTopRightRadius:5,padding:10,borderRight:'1px solid rgba(224, 224, 224, 1)'}} colSpan={28} align="center">{tableName.toUpperCase()}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{color:'white',backgroundColor:'#0750b5',padding:2,borderRight:'1px solid rgba(224, 224, 224, 1)'}} colSpan={28} align="center">
                        
                    </TableCell>
                </TableRow>
                <TableRow style={{display:selected==='all'?'none':'contents'}}>
                    <TableCell colSpan={28}>
                        {selected==='custom'?<RangeCalendar changeDate={changeDate}/>:null}
                        {selected==='week'?<WeekPicker changeDate={changeDate}/>:null}
                        {selected==='month'?<MonthPicker changeDate={changeDate}/>:null}
                        {selected==='year'?<YearPicker changeDate={changeDate}/>:null}
                    </TableCell> 
                </TableRow>*/}
                {
                headerGroups.map((headerGroup,x) => {
                    
                    return(
                    <TableRow {...headerGroup.getHeaderGroupProps()} className={x===0?classes.stickyHeader1:classes.stickyHeader2}>
                        {headerGroup.headers.map(column => {
                            let className=null
                            if(column.id==='topLeft_0'){
                                className=classes.cellGreyFirstCorner
                            }else{
                                className=colorSwitch(column.color);
                            }
                                
                            return(
                            <TableCell className={className} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                    : ''}
                                </span>
                            </TableCell>
                        )})}
                    </TableRow>
                )})}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map(
                    (row, i) => {
                    prepareRow(row);
                    return (
                        <TableRow hover {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                            <TableCell className={classes.stickyLeft} style={{
                                backgroundColor:cell.column.id==='age'?'#eeeeee':null,
                                fontWeight:cell.column.id==='age'?'bold':'normal',
                                borderRight:'1px solid rgba(224, 224, 224, 1)'
                            }}
                            {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                            )
                        })}
                        </TableRow>
                        
                    )}
                )}
                <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.summary}>PODSUMOWANIE</TableCell>
                    <TableCell className={classes.summary}>--</TableCell>
                    <TableCell className={classes.summary}>{summary.hoursSum}</TableCell>
                    <TableCell className={classes.summary}><span style={{fontWeight:'bold'}}>{summary.addedServices}</span>{"("+summary.addedServicesWithNoPriceDefined+")"}</TableCell>
                    <TableCell className={classes.summary}>{summary.priceSum}</TableCell>
                    <TableCell className={classes.summary}><span style={{fontWeight:'bold'}}>{summary.endedServices}</span>{"("+summary.endedServicesWithNoPriceDefined+")"}</TableCell>
                    <TableCell className={classes.summary}>{summary.endedPriceSum}</TableCell>
                    <TableCell className={classes.summary}>{summary.endedFullyPaidServices}</TableCell>
                    <TableCell className={classes.summary}>{summary.endedPaidSum}</TableCell>
                    <TableCell className={classes.summary}>{summary.moneyToGetFromEnded}</TableCell>
                    <TableCell className={classes.summary}>{summary.endedServicesHours}</TableCell>
                    <TableCell className={classes.summary}><span style={{fontWeight:'bold'}}>{summary.suspendedServices}</span>{"("+summary.suspendedServicesWithNoPriceDefined+")"}</TableCell>
                    <TableCell className={classes.summary}>{summary.suspendedPriceSum}</TableCell>
                    <TableCell className={classes.summary}>{summary.suspendedFullyPaidServices}</TableCell>
                    <TableCell className={classes.summary}>{summary.suspendedPaidSum}</TableCell>
                    <TableCell className={classes.summary}>{summary.moneyToGetFromSuspended}</TableCell>
                    <TableCell className={classes.summary}>{summary.suspendedServicesHours}</TableCell>
                    <TableCell className={classes.summary}><span style={{fontWeight:'bold'}}>{summary.activeServices}</span>{"("+summary.activeServicesWithNoPriceDefined+")"}</TableCell>
                    <TableCell className={classes.summary}>{summary.activePriceSum}</TableCell>
                    <TableCell className={classes.summary}>{summary.activeFullyPaidServices}</TableCell>
                    <TableCell className={classes.summary}>{summary.activePaidSum}</TableCell>
                    <TableCell className={classes.summary}>{summary.moneyToGetFromActive}</TableCell>
                    <TableCell className={classes.summary}>{summary.activeServicesHours}</TableCell>
                    <TableCell className={classes.summary}>{
                        summary.priceSum===null?0:parseFloat(summary.priceSum/(summary.addedServices-summary.addedServicesWithNoPriceDefined)).toFixed(2)
                    }</TableCell>
                    <TableCell className={classes.summary}>{
                        summary.priceSum===null?0:parseFloat(summary.priceSum/(summary.hoursSum-summary.undefinedPriceHoursSum)).toFixed(2)
                    }</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </Grid>
        </Grid>
        </div>
    )
}

const StatTable = ({departmentId,tableName,jwt}) =>{

    const classes = useStyles()
    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    const [data,setData] = useState([])
    
    const columns = React.useMemo(()=>[
                {
                    Header:' ',
                    color:'realGrey',
                    id:'topLeft',
                    columns:[
                        {
                            Header: 'Usługa',
                            accessor: 'serviceName',
                            color:'realGrey',
                        }
                    ]
                },
                {
                    Header:'Ogólne',
                    color:'grey',
                    columns:[
                        {
                            Header: 'Domyślne r-g.',
                            accessor: (row)=>(row.defaultHours===null?0:row.defaultHours),
                            color:'grey',
                            id:'defaultHours'
                        },
                        {
                            Header: 'Suma r-g.',
                            accessor: (row)=>(row.hoursSum===null?0:row.hoursSum),
                            color:'grey',
                            id:'hoursSum'
                        },
                        {
                            Header: 'Dodano(CN)',
                            accessor: (row)=>{
                                //console.log(row)
                                return(<><span style={{fontWeight:'bold'}}>{row.addedServices}</span>{" ("+row.addedServicesWithNoPriceDefined+")"}</>)
                            },
                            color:'grey',
                            id:'addedServices'

                        },
                        {
                            Header: 'Dodano na kw.',
                            accessor: (row)=>(row.priceSum===null?0:row.priceSum),
                            color:'grey',
                            id:'priceSum'
                        },
                    ]
                },
                {
                    Header:'Zakończone',
                    color:'red',
                    columns:[
                        {
                            Header: 'Ilość (CN)',
                            accessor: (row)=>{
                                //console.log(row)
                                return(<><span style={{fontWeight:'bold'}}>{row.endedServices}</span>{" ("+row.endedServicesWithNoPriceDefined+")"}</>)
                            },
                            color:'red',
                            id:'endedServices'
                        },
                        {
                            Header: 'Kwota',
                            accessor:(row)=>(row.endedPriceSum===null?0:row.endedPriceSum),
                            color:'red',
                            id:'endedPriceSum'
                        },
                        {
                            Header: 'Zapłacono',
                            accessor: (row)=>(row.endedFullyPaidServices===null?0:row.endedFullyPaidServices),
                            color:'red',
                            id:'endedFullyPaidServices'
                        },
                        {
                            Header: 'Zapł. na kw.',
                            accessor: (row)=>(row.endedPaidSum===null?0:row.endedPaidSum),
                            color:'red',
                            id:'endedPaidSum'
                        },
                        {
                            Header: 'Do odebrania',
                            accessor: (row)=>(row.moneyToGetFromEnded===null?0:row.moneyToGetFromEnded),
                            color:'red',
                            id:'moneyToGetFromEnded'
                        },
                        {
                            Header: 'R-g. zrobione',
                            accessor: (row)=>(row.endedServicesHours===null?0:row.endedServicesHours),
                            color:'red',
                            id:'endedServicesHours'
                        },
                        /*{
                            Header: 'R-godz. do zrobienia',
                            accessor: 'c7',
                            color:'red',
                        },*/
                    ]
                },
                {
                    Header:'Zawieszone',
                    color:'yellow',
                    columns:[
                        {
                            Header: 'Ilość (CN)',
                            accessor: (row)=>{
                                //console.log(row)
                                return(<><span style={{fontWeight:'bold'}}>{row.suspendedServices}</span>{" ("+row.suspendedServicesWithNoPriceDefined+")"}</>)
                            },
                            color:'yellow',
                            id:'suspendedServices'
                        },
                        {
                            Header: 'Kwota',
                            accessor: (row)=>(row.suspendedPriceSum===null?0:row.suspendedPriceSum),
                            color:'yellow',
                            id:'suspendedPriceSum'
                        },
                        {
                            Header: 'Zapłacono',
                            accessor:(row)=>(row.suspendedFullyPaidServices===null?0:row.suspendedFullyPaidServices),
                            color:'yellow',
                            id:'suspendedFullyPaidServices'
                        },
                        {
                            Header: 'Zapł. na kw.',
                            accessor: (row)=>(row.suspendedPaidSum===null?0:row.suspendedPaidSum),
                            color:'yellow',
                            id:'suspendedPaidSum'
                        },
                        {
                            Header: 'Do odebrania',
                            accessor: (row)=>(row.moneyToGetFromSuspended===null?0:row.moneyToGetFromSuspended),
                            color:'yellow',
                            id:'moneyToGetFromSuspended'
                        },
                        {
                            Header: 'R-g. do zrob.',
                            accessor: (row)=>(row.suspendedServicesHours===null?0:row.suspendedServicesHours),
                            color:'yellow',
                            id:'suspendedServicesHours'
                        },
                    ]
                },
                {
                    Header:'Aktualne',
                    color:'green',
                    columns:[
                        {
                            Header: 'Ilość (CN)',
                            accessor: (row)=>{
                                //console.log(row)
                                return(<><span style={{fontWeight:'bold'}}>{row.activeServices}</span>{" ("+row.activeServicesWithNoPriceDefined+")"}</>)
                            },
                            color:'green',
                            id:'activeServices'
                        },
                        {
                            Header: 'Kwota',
                            accessor:(row)=>(row.activePriceSum===null?0:row.activePriceSum),
                            color:'green',
                            id:'activePriceSum'
                        },
                        {
                            Header: 'Zapłacono',
                            accessor: (row)=>(row.activeFullyPaidServices===null?0:row.activeFullyPaidServices),
                            color:'green',
                            id:'activeFullyPaidServices'
                        },
                        {
                            Header: 'Zapł. na kw.',
                            accessor: (row)=>(row.activePaidSum===null?0:row.activePaidSum),
                            color:'green',
                            id:'activePaidSum'
                        },
                        {
                            Header: 'Do odebrania',
                            accessor: (row)=>(row.moneyToGetFromActive===null?0:row.moneyToGetFromActive),
                            color:'green',
                            id:'moneyToGetFromActive'
                        },
                        /*{
                            Header: 'R-godz. zrobione',
                            accessor: 'c20',
                            color:'green',
                        },*/
                        {
                            Header: 'R-g. do zrob.',
                            accessor:(row)=>(row.activeServicesHours===null?0:row.activeServicesHours),
                            color:'green',
                            id:'activeServicesHours'
                        },
                    ]
                },
                {
                    Header:'Średnia',
                    color:'blue',
                    columns:[
                        {
                            Header: 'Śr. Cena',
                            accessor: (row)=>{
                                return(row.priceSum===null?0:parseFloat(row.priceSum/(row.addedServices-row.addedServicesWithNoPriceDefined)).toFixed(2))
                            },
                            color:'blue',
                            id:'avgServicePrice'
                        },
                        {
                            Header: 'Śr. PLN/h',
                            accessor: (row)=>{
                                //ilość roboczogodzin w pracach z niezdefiniowanymi cenami
                                //console.log(parseFloat(row.priceSum/(row.hoursSum-row.undefinedPriceHoursSum)).toFixed(2))
                                return(row.priceSum===null?0:parseFloat(row.priceSum/(row.hoursSum-row.undefinedPriceHoursSum)).toFixed(2))
                            },
                            color:'blue',
                            id:'avgHourPrice'
                        },
                    ]
                }
            ],
        []
    )

    const getRangeData = async(dateRange) => {
        const startDate=moment(dateRange[0]).startOf('day').format("YYYY-MM-DD HH:mm:ss")
        const endDate=moment(dateRange[1]).endOf('day').format("YYYY-MM-DD HH:mm:ss")
        await fetch(`${serverUrl}/statistics/all-services-time?startDate=${startDate}&endDate=${endDate}`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(json => {
          //console.log(json)
          //console.log(startDate,endDate)
          setData(json)
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const getAllData = async() => {
        await fetch(`${serverUrl}/statistics/all-services-time?startDate=2000-01-01 00:00:00&endDate=2150-01-01 00:00:00`,
        {method:'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response.json())
        .then(json => {
         // console.log(json);
          setData(json);
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
        })
    }

    const dateChange = (dateRange,type) => {
        setIsLoading(true)
        if(type==='all'){
            getAllData()
        }else{
            getRangeData(dateRange)
        }
    }
    
    return(
        <>
        <Tab departmentId={departmentId} tableName={tableName} columns={columns} data={data} dateChangeFunction={dateChange}/>
        <Modal  open={isLoading}>
            <Box style={{height:'100vh'}}>
                <CircularProgress size={50} style={{position:'absolute',top:'50%',left:'50%',color:'white'}}/>
            </Box>
        </Modal>
        </>
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(StatTable)