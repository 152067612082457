import types from "./types"

const init = {
    openConfirmationDialog:false,
    leaveIdToDelete:null,
    openNewRequestDialog:false,

    users:[],
    loadingUsers:false,
    fetchUsersError:false,

    userLeaves:[],
    loadingUserLeaves:false,
    fetchUserLeavesError:false,

    allLeaves:[],
    loadingAllLeaves:false,
    fetchAllLeavesError:false,

    calendarLeaves:[],
    loadingCalendarLeaves:false,
    fetchCalendarLeavesError:false,

    leaveDetails:{
        "leaveId": null,
        "startDate": null,
        "endDate": null,
        "submissionDate": null,
        "userId": null,
        "userName": null,
        "userSurname": null,
        "statusId": null,
        "statusName": null,
        "comment": null
    },

    loadingCancelLeave:false,
    fetchCancelLeaveError:false,//
    

    openTimelineInfoDialog:false,
    timelineDialogId:null,
    loadingTimelineInfo:false,
    fetchLeaveInfoError:false,//

    openAllTableDialog:false,
    allTableDialogId:null,
    allTableDialogStatusId:null,

    userYears:{
        isLoading:false,
        fetchError:false,
        years:[],
        openDialog:false
    },

    changeStatusAllLeaves:{
        isLoading:false,
        response:null,
        fetchError:false//
    },

    changeStatusTimeline:{
        isLoading:false,
        response:null,
        fetchError:false//
    },

    form:{
        rangePickerValue:[null,null],
        commentValue:'',
        isLoading:false,
        userId:null,
        statusId:null,
        response:null
    },
    tableFilters:{
        userId:null,
        statusId:1,
        onSite:20,
        pageIndex:0,
        pageCount:0,
    }
}

const leavesReducer = (state=init,action) => {
    switch(action.type){
        case types.SET_OPEN_CONFIRMATION_DIALOG:
            return {...state,openConfirmationDialog:action.item}
        case types.SET_LEAVE_ID_TO_DELETE:
            return {...state,leaveIdToDelete:action.item}
        case types.SET_OPEN_NEW_REQUEST_DIALOG:
            return {...state,openNewRequestDialog:action.item}
        case types.SET_USERS:
            return {...state,users:action.item}
        case types.SET_LOADING_USERS:
            return {...state,loadingUsers:action.item}
        case types.SET_USER_LEAVES:
            return {...state,userLeaves:action.item}
        case types.SET_CALENDAR_LEAVES:
            return {...state,calendarLeaves:action.item}
        case types.SET_LOADING_CALENDAR_LEAVES:
            return {...state,loadingCalendarLeaves:action.item}
        case types.SET_LEAVE_DETAILS:
            return {...state,leaveDetails:action.item}
        case types.SET_RANGE_PICKER_VALUE:
            return {...state,form:{...state.form,rangePickerValue:action.item}}
        case types.SET_COMMENT_VALUE:
            return {...state,form:{...state.form,commentValue:action.item}}
        case types.CLEAR_USER_LEAVES:
            return {...state,userLeaves:[]}
        case types.CLEAR_FORM:
            return {...state,form:init.form}
        case types.SET_FORM_RESPONSE:
            return {...state,form:{...state.form,response:action.item}}
        case types.SET_FORM_USER_ID:
            return {...state,form:{...state.form,userId:action.item}}
        case types.SET_FORM_STATUS_ID:
            return {...state,form:{...state.form,statusId:action.item}}
        case types.SET_LOADING_FORM:
            return {...state,form:{...state.form,isLoading:action.item}}
        case types.SET_LOADING_USER_LEAVES:
            return {...state,loadingUserLeaves:action.item}
        case types.SET_LOADING_ALL_LEAVES:
            return {...state,loadingAllLeaves:action.item}
        case types.SET_ALL_LEAVES:
            return {...state,allLeaves:action.item}
        case types.SET_FILTER_USER:
            return {...state,tableFilters:{...state.tableFilters,userId:action.item}}
        case types.SET_FILTER_STATUS:
            return {...state,tableFilters:{...state.tableFilters,statusId:action.item}}
        case types.SET_FILTER_PAGE:
            return {...state,tableFilters:{...state.tableFilters,pageIndex:action.item}}
        case types.SET_FILTER_PAGE_COUNT:
            return {...state,tableFilters:{...state.tableFilters,pageCount:action.item}}
        case types.SET_LOADING_CANCEL_LEAVE:
            return {...state,loadingCancelLeave:action.item}
        case types.SET_OPEN_TIMELINE_INFO_DIALOG:
            return {...state,openTimelineInfoDialog:action.item}
        case types.SET_TIMELINE_DIALOG_ID:
            return {...state,timelineDialogId:action.item}
        case types.SET_LOADING_TIMELINE_INFO:
            return {...state,loadingTimelineInfo:action.item}
        case types.SET_LOADING_CHANGE_STATUS:
            return {...state,loadingChangeStatus:action.item}
        case types.SET_ALL_TABLE_DIALOG_ID:
            return {...state,allTableDialogId:action.item}
        case types.SET_ALL_TABLE_DIALOG_STATUS_ID:
            return {...state,allTableDialogStatusId:action.item}
        case types.SET_OPEN_ALL_TABLE_DIALOG:
            return {...state,openAllTableDialog:action.item}
        case types.SET_LOADING_CHANGE_STATUS_ALL_LEAVES:
            return {...state,changeStatusAllLeaves:{...state.changeStatusAllLeaves,isLoading:action.item}}
        case types.SET_LOADING_CHANGE_STATUS_TIMELINE:
            return {...state,changeStatusTimeline:{...state.changeStatusTimeline,isLoading:action.item}}
        case types.SET_RESPONSE_CHANGE_STATUS_ALL_LEAVES:
            return {...state,changeStatusAllLeaves:{...state.changeStatusAllLeaves,response:action.item}}
        case types.SET_RESPONSE_CHANGE_STATUS_TIMELINE:
            return {...state,changeStatusTimeline:{...state.changeStatusTimeline,response:action.item}}
        case types.RESET_CHANGE_STATUS_TIMELINE:
            return {...state,changeStatusTimeline:init.changeStatusTimeline}
        case types.RESET_CHANGE_STATUS_ALL_LEAVES:
            return {...state,changeStatusAllLeaves:init.changeStatusAllLeaves}
        case types.SET_FETCH_USERS_ERROR:
            return {...state,fetchUsersError:action.item}
        case types.SET_FETCH_CALENDAR_LEAVES_ERROR:
            return {...state,fetchCalendarLeavesError:action.item}
        case types.SET_FETCH_ALL_LEAVES_ERROR:
            return {...state,fetchAllLeavesError:action.item}
        case types.SET_FETCH_USER_LEAVES_ERROR:
            return {...state,fetchUserLeavesError:action.item}
        case types.SET_USER_YEARS_LOADING:
            return {...state,userYears:{...state.userYears,isLoading:action.item}}
        case types.SET_USER_YEARS_FETCH_ERROR:
            return {...state,userYears:{...state.userYears,fetchError:action.item}}
        case types.SET_USER_YEARS:
            return {...state,userYears:{...state.userYears,years:action.item}}
        case types.SET_USER_YEARS_OPEN_DIALOG:
            return {...state,userYears:{...state.userYears,openDialog:action.item}}
        case types.RESET_LEAVES:
            return init
        case types.SET_FETCH_CANCEL_LEAVE_ERROR:
            return {...state,fetchCancelLeaveError:action.item}
        case types.SET_FETCH_LEAVE_INFO_ERROR:
            return {...state,fetchLeaveInfoError:action.item}
        case types.SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_TIMELINE:
            return {...state,changeStatusTimeline:{...state.changeStatusTimeline,fetchError:action.item}}
        case types.SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_ALL_LEAVES:
            return {...state,changeStatusAllLeaves:{...state.changeStatusAllLeaves,fetchError:action.item}}
        default:
        return state
    }
}

export default leavesReducer