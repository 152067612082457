import moment from 'moment';

const getNow = () => {

    const date = moment().format('YYYY-MM-DD HH:mm:ss')

    return date
    
}

export default getNow