

import {Grid} from "@material-ui/core"
import DataTable from "./DataTable"
import DepartmentsSummary from "./DepartmentsSummary"
import NewComment from "./NewComment"
import ServicesSummary from "./ServicesSummary"


const Summary = (props) => {

    
    return(
        <Grid container justify="center" style={{paddingLeft:"2%",paddingRight:"2%"}}>
           
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} >
                <DataTable/>
            </Grid>
            <Grid item xs={12} sm={10} md={9} lg={8} xl={7} style={{marginTop:"40px"}}>
                <ServicesSummary/>
            </Grid>

            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} style={{marginTop:"40px"}}>
               <DepartmentsSummary/>
            </Grid>

            <Grid item xs={12} sm={11} md={10} lg={9} xl={8} style={{marginTop:"40px"}}>
               <NewComment/>
            </Grid>

        </Grid>
    )
    
}

  
  export default (Summary)