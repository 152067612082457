import { store } from "./store"



export class RoleService {
   
    constructor(){

    }

    _isUserAdmin = () =>{
        let bool = false
        store.getState().log.user.roles.forEach(role => {
            if(role==="ADMIN"){
                bool=true
            }
        });
        return bool
    }
    
    _findRole = (roleName) => {
        if(this._isUserAdmin===true){
            return true
        }else{
            let hasRole = false
            store.getState().log.user.roles.forEach(role => {
                if(role===roleName){
                    hasRole=true
                }
            });
            return hasRole
        }
        
    }
   
    getState(){
        console.log(store.getState()) 
    }

    isAdmin(){
        return this._findRole("ADMIN")
    }

    isBoss(){
        return this._findRole("BOSS")
    }

    isUser(){
        return this._findRole("USER")
    }

    isAccountant(){
        return this._findRole("ACCOUNTANT")
    }

    canGeodesy(){
        return this._findRole("GEODESY")
    }

    canTypicalProjects(){
        return this._findRole("TYP_PROJECTS")
    }

    canIndividualProjects(){
        return this._findRole("IND_PROJECTS")
    }

    canConditions(){
        return this._findRole("CONDITIONS")
    }

    canSanitaryInstallations(){
        return this._findRole("SANIT_INSTALL")
    }

    canAppOrders(){
        return this._findRole("APP_ORDERS")
    }

    canHistory(){
        return this._findRole("HISTORY")
    }

    canStats(){
        return this._findRole("STATS")
    }

    canGeology(){
        return this._findRole("GEOLOGY")
    }

    canReports(){
        return this._findRole("REPORTS")
    }

    hasOneOfRoles(roles){
        console.log(roles)
        let bool = false
        store.getState().log.user.roles.forEach(userRole => {
            roles.forEach(role => {
                if(role===userRole){
                    bool=true
                }
            });
            
        });
        return bool
    }

}
