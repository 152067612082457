import { Table, TableCell, TableHead, TableBody,TableRow } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { CardContent,Card } from "@material-ui/core"
import { TableContainer } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { Container } from "@material-ui/core"
import { RoleService } from "../RoleService"
import { store } from "../store"
import Avatar from '@material-ui/core/Avatar';


const UserPanel = () =>{
    const user = store.getState().log.user
    const getBool = (bool) =>{
        if(bool===true){
            return(<Typography style={{color:'green',fontWeight:'bold'}}>TAK</Typography>)
        }else{
            return(<Typography style={{color:'red',fontWeight:'bold'}}>NIE</Typography>)
        }
    }

    const roleService = new RoleService()
    return(
        <Container style={{maxWidth:'500px',marginTop:'25px',paddingBottom:'24px',paddingTop:'24px'}} component={Paper}>
            <Card style={{marginBottom:'24px'}}>
                <CardContent>
                <Avatar src={user.image} 
                style={{height:'60px',width:'60px',margin:'11px', marginLeft:'auto', marginRight:'auto',fontFamily:'calibri'}}>
                </Avatar>
                    <Typography align='center' style={{fontWeight:'bold',color:'darkorange'}}>{user.name+' '+user.surname}</Typography>
                    <Typography align='center' style={{fontWeight:'bold'}}>{"Tel. "+user.phone}</Typography>
                    <Typography align='center' style={{fontWeight:'bold'}}>{"Twoje ID:  "+user.userId}</Typography>
                    <Typography align='center' style={{fontWeight:'bold'}}>{"Twój login:  "+user.userName}</Typography>
                </CardContent>
            </Card>
            <TableContainer>
                <Table size='small' component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rola</TableCell>
                            <TableCell>Dostęp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>ADMIN</TableCell>
                            <TableCell>{getBool(roleService.isAdmin())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>SZEF</TableCell>
                            <TableCell>{getBool(roleService.isBoss())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>UŻYTKOWNIK</TableCell>
                            <TableCell>{getBool(roleService.isUser())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>KSIĘGOWY</TableCell>
                            <TableCell>{getBool(roleService.isAccountant())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>GEODEZJA</TableCell>
                            <TableCell>{getBool(roleService.canGeodesy())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>PROJEKTY TYPOWE</TableCell>
                            <TableCell>{getBool(roleService.canTypicalProjects())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>PROJEKTY INDYWIDUALNE</TableCell>
                            <TableCell>{getBool(roleService.canIndividualProjects())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>WARUNKI</TableCell>
                            <TableCell>{getBool(roleService.canConditions())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>INSTALACJE SANITARNE</TableCell>
                            <TableCell>{getBool(roleService.canSanitaryInstallations())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>HISTORIA</TableCell>
                            <TableCell>{getBool(roleService.canHistory())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>STATYSTYKA</TableCell>
                            <TableCell>{getBool(roleService.canStats())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>GEOLOGIA</TableCell>
                            <TableCell>{getBool(roleService.canGeology())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>RAPORTY</TableCell>
                            <TableCell>{getBool(roleService.canReports())}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default UserPanel