import { Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import actions from "../duck/actions";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NewEmail from "./NewEmail";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const mapStateToProps = (state) =>{
    return{
       customerId:state.newOrder.customerData.customerId,
       emails:state.newOrder.customerData.emails,
       newEmails:state.newOrder.customerData.newEmails,
       jwt:state.log.jwt
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return{
      addEmail: (obj) => dispatch(actions.addEmail(obj)),
      removeEmail: (obj) => dispatch(actions.removeEmail(obj)),
      removeNewEmail: (obj) => dispatch(actions.removeNewEmail(obj)),
    }
  }

const NewListOption = connect(mapStateToProps,mapDispatchToProps)((props) =>{

    const {email} = props

    const handleDelete = () => { 
        props.removeNewEmail(email)
        props.refresh()
    }

    return(
        <>
        <ListItem key={email} style={{backgroundColor:"#d9ffda"}}>
        <ListItemIcon>
            <AlternateEmailIcon style={{color:"#43a047"}}/>
        </ListItemIcon>
        <ListItemText id={email} primary={email}></ListItemText>
        <ListItemSecondaryAction style={{right:0}}>
            <Checkbox
            edge="end"
            checked={true}
            disabled={true}
            />
        <IconButton onClick={()=>{handleDelete()}}>
            <DeleteForeverIcon style={{color:"red",fontSize:"1.8rem"}}/>
        </IconButton>
        </ListItemSecondaryAction>
        
        </ListItem>
        <Divider style={{backgroundColor:"white",height:"5px"}}/>

        </>
    )

})


const CustomerEmailsListNew = (props) => {
    
    const [newEmails,setNewEmails] = useState(props.newEmails)

    const refresh = () => {
        console.log(props.newEmails)
        setNewEmails([...props.newEmails])
    }

    return (
        <Grid container justify="center" style={{marginTop:"60px"}}> 
           
            <Grid item xs={12} sm={12} md={11} lg={11} xl={10}>
            <Typography className="infoText" style={{color:"green",textAlign:"left"}}>Adresy email</Typography>
            <List>
                {newEmails.map((value) => {
                    return (
                    <NewListOption refresh={refresh} key={value} email={value}/>
                    );
                })}
            </List>
            <NewEmail refresh={refresh}/>
            </Grid>
        </Grid>

      );

}


  
  export default connect(mapStateToProps,mapDispatchToProps)(CustomerEmailsListNew)