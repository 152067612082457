
import Home from '../homePage/Home';
import {BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router'
import Geodesy from '../departments/geodesy/Geodesy';
import TypicalProjects from '../departments/typicalProjects/TypicalProjects';
import IndividualProjects from '../departments/individualProjects/IndividualProjects';
import SanitaryInstallations from '../departments/sanitaryInstallations/SanitaryInstallations';
import Conditions from '../departments/conditions/Conditions';
import NewOrder from '../newOrder/NewOrder';
import LoginPage from '../login/LoginPage';
import { connect } from 'react-redux';
import LeftBar from '../navi/LeftBar';
import moment from 'moment'
import pl from 'moment/locale/pl'
import PrivateRoute from './PrivateRoute';
import AllOrders from '../allOrders/AllOrders';
import securityCheck from './securityCheck';
import HistoryTable from '../history/HistoryTable';
import AllServices from '../allServices/AllServices';
import CustomersTable from '../customers/CustomersTable';
import ReportsHome from '../raports/ReportsHome';
import UserPanel from '../userPanel/UserPanel';
import StatContainer from '../statistics/StatContainer';
import Geology from '../departments/geology/Geology';
import { RoleService } from '../RoleService';
import Costs from '../departments/costs/Costs';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import LeaveContainer from '../leave/LeaveContainer';
import LeaveUserPanel from '../leave/LeaveUserPanel';
import AllLeavesTable from '../leave/allLeaves/AllLeavesTable';
import MyLeavesTable from '../leave/myLeaves/MyLeavesTable';
import timer from '../login/timer';
import { useEffect } from 'react';

moment.updateLocale('pl', pl) 
const roleService = new RoleService()
const theme = createMuiTheme();

function App({loggedIn,exp}) {
  //chyba lepiej zrobić to z eseefect na loggedinie bo tu trza odświerzać 
  useEffect(()=>{
    if(loggedIn===true){
      timer()
    }
  },[loggedIn])

  useEffect(()=>{
    console.log("załadowano strone")
  },[])

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
    
    <BrowserRouter>
      {securityCheck(loggedIn,exp)===true?<LeftBar/>:null}
            <Switch>
              <Route path="/login" exact component={LoginPage} canAccess={true}/>
              <PrivateRoute path="/" exact component={Home} canAccess={true}/>
              <PrivateRoute path="/geology" exact component={Geology} canAccess={true}/>
              <PrivateRoute path="/geodesy" exact component={Geodesy} canAccess={true}/>
              <PrivateRoute path="/typical-projects" exact component={TypicalProjects} canAccess={true}/>
              <PrivateRoute path="/individual-projects" exact component={IndividualProjects} canAccess={true}/>
              <PrivateRoute path="/sanitary-installations" exact component={SanitaryInstallations} canAccess={true}/>
              <PrivateRoute path="/conditions" exact component={Conditions} canAccess={true}/>
              <PrivateRoute path="/new-order" exact component={NewOrder} canAccess={true}/>
              <PrivateRoute path="/all-orders" exact component={AllOrders} canAccess={true}/>
              <PrivateRoute path="/history" exact component={HistoryTable} canAccess={roleService.hasOneOfRoles(["ADMIN","BOSS"])}/>
              <PrivateRoute path="/all-services" exact component={AllServices} canAccess={roleService.hasOneOfRoles(["ADMIN","BOSS","ACCOUNTANT"])}/>
              <PrivateRoute path="/customers" exact component={CustomersTable} canAccess={true}/>
              <PrivateRoute path="/reports" exact component={ReportsHome} canAccess={true}/>
              <PrivateRoute path="/user-panel" exact component={UserPanel} canAccess={true}/>
              <PrivateRoute path="/stats" exact component={StatContainer} canAccess={roleService.hasOneOfRoles(["ADMIN","BOSS"])}/>
              <PrivateRoute path="/costs" exact component={Costs} canAccess={roleService.hasOneOfRoles(["ADMIN","BOSS"])}/>
              <PrivateRoute path="/leave-timeline" exact component={LeaveContainer} canAccess={roleService.hasOneOfRoles(["ADMIN","BOSS"])}/>
              <PrivateRoute path="/all-leaves" exact component={AllLeavesTable} canAccess={roleService.hasOneOfRoles(["ADMIN","BOSS"])}/>
              <PrivateRoute path="/leave-user-panel" exact component={MyLeavesTable} canAccess={true}/>
            </Switch>
    </BrowserRouter>
  </div>
  </ThemeProvider>
  );
}

function mapStateToProps(state) {
  return{
  loggedIn: state.log.loggedIn,
  exp: state.log.exp
  }
}

export default connect(mapStateToProps,null)(App);
