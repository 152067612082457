import { MenuItem, Select } from '@material-ui/core'
import { green, grey, red, yellow } from "../helpers/colors"

const statuses = [
    {
        statusId:1,
        statusName:'Oczekuje na rozpatrzenie',
        color:yellow
    },
    {
        statusId:2,
        statusName:'Przyznano urlop',
        color:green
    },
    {
        statusId:3,
        statusName:'Odmowa',
        color:red
    },
    {
        statusId:4,
        statusName:'Anulowano',
        color:grey
    },
]

const StatusSelect = ({status,handleStatusChange}) => {

    return(
        <Select
            size="small"
            displayEmpty
            onChange={(e)=>handleStatusChange(e.target.value)}
            value={status}
            style={{width:'100%'}}
        >
            <MenuItem value={null}>
                Wszystkie statusy
            </MenuItem>
            {
                statuses.map(status=>(
                    <MenuItem value={status.statusId} key={status.id}>
                        <span style={{color:status.color}}>{status.statusName}</span>
                    </MenuItem>
                ))
            }
        </Select>
    )
}


export default StatusSelect