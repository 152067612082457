import types from "./types"

const setOpenConfirmationDialog = item =>({
    type: types.SET_OPEN_CONFIRMATION_DIALOG, item
}) 

const setLeaveIdToDelete = item =>({
    type: types.SET_LEAVE_ID_TO_DELETE, item
})

const setOpenNewRequestDialog = item =>({
    type: types.SET_OPEN_NEW_REQUEST_DIALOG, item
})

const setCalendarLeaves = item => ({
    type: types.SET_CALENDAR_LEAVES, item
})

const setLoadingCalendarLeaves = item => ({
    type: types.SET_LOADING_CALENDAR_LEAVES, item
})

const setUserLeaves = item => ({
    type: types.SET_USER_LEAVES,item
})

const setUsers = item => ({
    type: types.SET_USERS, item
})

const setLoadingUsers = item => ({
    type: types.SET_LOADING_USERS, item
})

const setLeaveDetails = item => ({
    type: types.SET_LEAVE_DETAILS, item
})

const setRangePickerValue = item => ({
    type: types.SET_RANGE_PICKER_VALUE, item
})

const setCommentValue = item => ({
    type: types.SET_COMMENT_VALUE, item
})

const clearUserLeaves = () => ({
    type: types.CLEAR_USER_LEAVES
})

const setLoadingForm = item => ({
    type: types.SET_LOADING_FORM, item
})

const setLoadingUserLeaves = item => ({
    type: types.SET_LOADING_USER_LEAVES, item
})

const setLoadingAllLeaves = item => ({
    type: types.SET_LOADING_ALL_LEAVES, item
})

const setAllLeaves = item => ({
    type: types.SET_ALL_LEAVES,item
})

const setFilterPageCount = item => ({
    type: types.SET_FILTER_PAGE_COUNT,item
})

const setFilterPage = item => ({
    type: types.SET_FILTER_PAGE,item
})

const setFilterStatus = item => ({
    type: types.SET_FILTER_STATUS,item
})

const setFilterUser = item => ({
    type: types.SET_FILTER_USER,item
})

const clearForm = () => ({
    type: types.CLEAR_FORM
})

const setFormStatusId = item => ({
    type: types.SET_FORM_STATUS_ID,item
})

const setFormUserId = item => ({
    type: types.SET_FORM_USER_ID,item
})

const setFormResponse = item => ({
    type: types.SET_FORM_RESPONSE,item
})

const setLoadingCancelLeave = item => ({
    type: types.SET_LOADING_CANCEL_LEAVE,item
})

const setFetchCancelLeaveError = item => ({
    type: types.SET_FETCH_CANCEL_LEAVE_ERROR,item
})

const setOpenTimelineInfoDialog = item => ({
    type: types.SET_OPEN_TIMELINE_INFO_DIALOG,item
})

const setTimelineDialogId = item => ({
    type: types.SET_TIMELINE_DIALOG_ID,item
})

const setLoadingTimelineInfo = item => ({
    type: types.SET_LOADING_TIMELINE_INFO,item
})

const setFetchLeaveInfoError = item => ({
    type: types.SET_FETCH_LEAVE_INFO_ERROR,item
})

const setAllTableDialogId = item => ({
    type: types.SET_ALL_TABLE_DIALOG_ID,item
})

const setAllTableDialogStatusId = item => ({
    type: types.SET_ALL_TABLE_DIALOG_STATUS_ID,item
})

const setOpenAllTableDialog = item => ({
    type: types.SET_OPEN_ALL_TABLE_DIALOG,item
})

const setLoadingChangeStatusTimeline = item => ({
    type: types.SET_LOADING_CHANGE_STATUS_TIMELINE,item
})

const setLoadingChangeStatusAllLeaves = item => ({
    type: types.SET_LOADING_CHANGE_STATUS_ALL_LEAVES,item
})

const setResponseChangeStatusTimeline = item => ({
    type: types.SET_RESPONSE_CHANGE_STATUS_TIMELINE,item
})

const setResponseChangeStatusAllLeaves = item => ({
    type: types.SET_RESPONSE_CHANGE_STATUS_ALL_LEAVES,item
})

const resetChangeStatusTimeline = () => ({
    type: types.RESET_CHANGE_STATUS_TIMELINE
})

const resetChangeStatusAllLeaves = () => ({
    type: types.RESET_CHANGE_STATUS_ALL_LEAVES
})

const setFetchChangeLeaveStatusErrorAllLeaves = (item) => ({
    type: types.SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_ALL_LEAVES,item
})

const setFetchChangeLeaveStatusErrorTimeline = (item) => ({
    type: types.SET_FETCH_CHANGE_LEAVE_STATUS_ERROR_TIMELINE,item
})

const setFetchUsersError = item => ({
    type: types.SET_FETCH_USERS_ERROR,item
})

const setFetchCalendarLeavesError = item => ({
    type: types.SET_FETCH_CALENDAR_LEAVES_ERROR,item
})

const setFetchAllLeavesError = item => ({
    type: types.SET_FETCH_ALL_LEAVES_ERROR,item
})

const setFetchUserLeavesError = item => ({
    type: types.SET_FETCH_USER_LEAVES_ERROR,item
})

const setUserYears = item => ({
    type: types.SET_USER_YEARS, item
})

const setUserYearsLoading = item => ({
    type: types.SET_USER_YEARS_LOADING, item
})

const setUserYearsFetchError = item => ({
    type: types.SET_USER_YEARS_FETCH_ERROR, item
})

const setUserYearsOpenDialog = item => ({
    type: types.SET_USER_YEARS_OPEN_DIALOG, item
})

const resetLeaves = () => ({
    type: types.RESET_LEAVES
})

const actions = {
    setOpenConfirmationDialog,
    setLeaveIdToDelete,
    setOpenNewRequestDialog,
    setCalendarLeaves,
    setUserLeaves,
    setUsers,
    setLeaveDetails,
    setRangePickerValue,
    setCommentValue,
    clearUserLeaves,
    setLoadingForm,
    setLoadingUserLeaves,
    clearForm,
    setLoadingAllLeaves,
    setAllLeaves,
    setFilterPage,
    setFilterStatus,
    setFilterUser,
    setFilterPageCount,
    setLoadingUsers,
    setLoadingCalendarLeaves,
    setFormUserId,
    setFormStatusId,
    setFormResponse,
    setLoadingCancelLeave,
    setOpenTimelineInfoDialog,
    setTimelineDialogId,
    setLoadingTimelineInfo,
    setOpenAllTableDialog,
    setAllTableDialogId,
    setAllTableDialogStatusId,
    setLoadingChangeStatusAllLeaves,
    setLoadingChangeStatusTimeline,
    setResponseChangeStatusAllLeaves,
    setResponseChangeStatusTimeline,
    resetChangeStatusAllLeaves,
    resetChangeStatusTimeline,
    setFetchUsersError,
    setFetchCalendarLeavesError,
    setFetchUserLeavesError,
    setFetchAllLeavesError,
    setUserYears,
    setUserYearsFetchError,
    setUserYearsLoading,
    setUserYearsOpenDialog,
    resetLeaves,
    setFetchCancelLeaveError,
    setFetchLeaveInfoError,
    setFetchChangeLeaveStatusErrorAllLeaves,
    setFetchChangeLeaveStatusErrorTimeline
}

export default actions