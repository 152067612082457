import { useEffect, useState } from "react";
import { TextField } from '@material-ui/core';
import "../style.css"
import { connect } from 'react-redux';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import actions from '../duck/actions';
import NotListedLocationRoundedIcon from '@material-ui/icons/NotListedLocationRounded';

const PrecinctInput = (props) => {
    const [val,setVal] = useState(props.precinct)
    const [valid,setValid] = useState(false)

    useEffect(()=>{
      if(props.precinct===""){
        setValid(false)
      }else{
        setValid(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleInputChange = (value) => {
      if(val.length<255){
          setValid(true)
          setVal(value)
          props.setPrecinct(value)
      }
    }

    useEffect(()=>{
      setVal(props.precinct)
    },[props.precinct])

    return(
      <div className="inputContainer">
        <div className="iconContainerNew">
          <NotListedLocationRoundedIcon className="icon"/>
        </div>
        <TextField
        value={val}
        label="Obręb *"
        variant="outlined"
        type="text"
        className="bigInput"
        inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:"#0750b5"}}}
        onChange={(e)=>handleInputChange(e.target.value)}
        />
        <div className="iconContainer">
        {val===""?<HelpOutlineIcon className="icon" style={{color:"grey"}}/>:valid===true?<CheckCircleIcon className="icon" style={{color:"#43a047"}}/>:<CancelOutlinedIcon className="icon" style={{color:"red"}}/>}
        </div>
      </div>
    )
}

const mapDispatchToProps = (dispatch) => {
  return{
    setPrecinctId : (obj) => dispatch(actions.setPrecinctId(obj)),
    setPrecinct : (obj) => dispatch(actions.setPrecinct(obj)),
  }
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        precinct: state.newOrder.customerData.precinct
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(PrecinctInput)