import moment from "moment"
import colorSwitch from "./colorSwitch"

const convertUsersToGroups = (users) => {
    const groups = []
    users.map(user=>{
        groups.push({
            id:user.userId,
            title:user.name+" "+user.surname,
            stackItems: true,
            height: 30
        })
    })
    return groups
}

const convertItemsToLeaves = (items) => {
    //console.log(items)
    const leaves = []
    items.map(item=>{
        leaves.push({
            id: item.leaveId,
            group: item.userId,
            title: moment(item.endDate).diff(item.startDate,'days')+1,
            start_time: moment.utc(item.startDate).local().valueOf(),
            end_time: moment.utc(item.endDate).local().valueOf(),
            color:colorSwitch(item.statusId)
          })
    })
    return leaves
}

const convertItemsToUserLeaves = (items) => {
    const leaves = []
    items.map(item=>{
        leaves.push({
            leaveId:item.leaveId,
            startDate:moment.utc(item.startDate).local().valueOf(),//.local().format("YYYY-MM-DD"),
            endDate:moment(item.endDate),//.local().format("YYYY-MM-DD"),
            submissionDate:moment(item.submissionDate),//.local().format("YYYY-MM-DD"),
            statusId:item.statusId,
            statusName:item.statusName,
            comment:item.comment
        })
    })
    return leaves
}

export{
    convertItemsToLeaves,
    convertUsersToGroups,
    convertItemsToUserLeaves
}