import { IconButton } from "@mui/material"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch } from "react-redux";
import actions from "../duck/actions";

const isDisabled = (statusId) => {
    switch(statusId){
        case 1:
            return false
        case 2:
            return true
        case 3:
            return true
        case 4:
            return true
        default:
            return true
    }
}

const Button = ({leaveId,statusId}) => {
    
    const disabled = isDisabled(statusId)
    const dispatch = useDispatch()
    const handleDelete = () => {
        dispatch(actions.setLeaveIdToDelete(leaveId))
        dispatch(actions.setOpenConfirmationDialog(true))
    }
    return(
        <IconButton onClick={handleDelete} disabled={disabled}>
            <DeleteForeverIcon style={{color:disabled?'white':'#ec0021'}}/>
        </IconButton>
    )
}

export default Button