import { Switch, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import actions from "../duck/actions";
import "../style.css"

const NewSwitch = withStyles({
    root: {
        width: "120px",
        height: "50px",
        float:"left",
        display:"flex"
      },
      switchBase: {
        color: "limeGreen",
        "&$checked": {
          color: "limeGreen",
          transform: "translateX(80px)",
          "& + $track": {
            opacity: 1,
            backgroundColor: "#e7e7e7",
            borderColor: "lightGrey"
          }
        }
      },
      checked: {},
      track: {
        backgroundColor: "#e7e7e7",
        opacity:1,
        borderRadius:"15px",
      },
      thumb: {
        width: 25,
        height: 25,
        marginTop:3
      },
      input:{
          width:"160px",
      }
    })(Switch);

const NetSwitch = (props) => {

    const handleChange = (param) =>{
        if(param===true){
            props.setNet(true)
        }
        if(param===false){
            props.setNet(false)
        }
        if(param===null){
            props.setNet(!props.net)
        }
    }

    return(
        <div className="netContainer">
        <Typography 
        onClick={()=>handleChange(true)} 
        className="netTypo"
        style={{color:props.net===true?"#43a047":"lightgray"}}>
            NETTO
        </Typography>
        <NewSwitch onClick={()=>handleChange(null)} checked={!props.net}/>
        <Typography 
        onClick={()=>handleChange(false)} 
        className="netTypo"
        style={{color:props.net===false?"#43a047":"lightgray"}}>
            BRUTTO
        </Typography>
        </div>
    )
}


const mapStateToProps = (state) =>{
    return{
        net:state.newOrder.net,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setNet: (obj) => dispatch(actions.setNet(obj)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NetSwitch)