import { Typography } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { List } from "@material-ui/core"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"


const UsersList = ({date,jwt}) => {

    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    const [users,setUsers] = useState([])

    useEffect(() => {
        async function getData() {
            await fetch(`${serverUrl}/reports/day-check?startDate=${date}&endDate=${date}`,
            {method:'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
            })
            .then(response => response.json())
            .then(json => {
              console.log(json);
              setUsers(json);
              setIsLoading(false);
              console.log(date)
            })
            .catch(error => {
              console.log(error);
            })
        }
        if (isLoading) {getData()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
        {isLoading===true?<CircularProgress/>:
        <List component={Paper}> 
            {users.passed.map((user)=>(
                <ListItem style={{backgroundColor:'#00800017',color:'darkgreen'}} key={user.userId}><Typography>{user.name+" "+user.surname}</Typography></ListItem>
            ))}
            <Divider style={{margin:'8px'}}/>
            {users.failed.map((user)=>(
                <ListItem style={{backgroundColor:'#ff000014',color:'darkred'}} key={user.userId}><Typography>{user.name+" "+user.surname}</Typography></ListItem>
            ))}
        </List>
        }
        </>
    )
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(UsersList)