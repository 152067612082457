import { MuiThemeProvider } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { createMuiTheme, Paper } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import { convertFromRaw, convertToRaw } from 'draft-js'
import MUIRichTextEditor from 'mui-rte'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { serverUrl } from '../config/serverConfig'


const TextEditor = (props) => {
    
    const [reportId,setReportId] = useState(props.reportId)
    const [newReport,setNewReport] = useState(props.newRep)
    const [base,setBase]=useState(false)
    const [isEdited,setIsEdited] = useState(false)
    const [report,setReport] = useState()
    const [isLoading,setIsLoading] = useState(true)
    const [refresh,setRefresh] = useState(0)
    const token="Bearer ".concat(props.jwt)
    const { enqueueSnackbar } = useSnackbar()
    const [clicked,setClicked]=useState(false)
    
    useEffect(() => {
        async function getData() {

          await fetch(`${serverUrl}/reports/report?reportId=${reportId}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            console.log(json);
            setReport(json)
            setBase(json.content)
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
          })
        }
        if (isLoading||Refresh>0) {
            if(newReport===false){
                getData();
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[refresh])

      const handleSave = (data) =>{
        if(convertFromRaw(JSON.parse(data)).getPlainText().length<5){
            enqueueSnackbar("Raport powinien mieć więcej niż 5 liter",{variant:'warning',autoHideDuration: 3000})
        }else{
            if(isEdited===true){
                saveCall(data)
            }else{
                enqueueSnackbar("Aktualny stan raportu jest już zapisany",{variant:'warning',autoHideDuration: 3000})
            }
        }
      }
      
    async function saveCall(data) {
        //console.log(typeof(data),date)
        setClicked(true);
        await fetch(`${serverUrl}/reports?date=${props.date.concat(" 00:00:00")}`,
        {method:'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            },
            body:JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
        //console.log(json)
        setReportId(json)
        setClicked(false);
            enqueueSnackbar("Pomyślnie zapisano raport",{variant:'success',autoHideDuration: 3000})
        })
        .catch(error => {
        console.log(error);
        setClicked(false);
        })
        setNewReport(false)
        setRefresh(refresh+1)
    }

    async function editCall(data) {
        setClicked(true);
        await fetch(`${serverUrl}/reports?reportId=${reportId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            body:JSON.stringify(data)
        })
        .then(response => response.json())
        .then((json) => {
          console.log("json:",json)
          setClicked(false);
          enqueueSnackbar("Pomyślnie edytowano raport",{variant:'success',autoHideDuration: 3000})
        })
        .catch(error => {
          console.log(error);
          setClicked(false);
        })
        setBase(data)
        setIsEdited(false)
    }

    const handleEdit = (data) =>{
        if(convertFromRaw(JSON.parse(data)).getPlainText().length<5){
            enqueueSnackbar("Raport powinien mieć więcej niż 5 liter",{variant:'warning',autoHideDuration: 3000})
        }else{
            if(isEdited===true){
                editCall(data)
            }else{
                enqueueSnackbar("Aktualny stan raportu jest już zapisany",{variant:'warning',autoHideDuration: 3000})
            }
        }
        
    }

    const handleReportChange = (event) =>{
        console.log(JSON.stringify(base))
        console.log(JSON.stringify(convertToRaw(event.getCurrentContent())))
        if(base===JSON.stringify(convertToRaw(event.getCurrentContent()))){
            //console.log("równa się")
            setIsEdited(false)
            props.isSaved(true)
        }else{
            setIsEdited(true)
            props.isSaved(false)
        }
    }

    const defaultTheme = createMuiTheme()

    Object.assign(defaultTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    width: "100%",
                    backgroundColor:'lightgrey',
                    border:'3px solid lightgrey',
                    borderRadius:'5px',
                },
                editor: {
                    minHeight:'420px',
                    borderRadius:'5px',
                    padding:'7px',
                    backgroundColor:'white',
                },
                editorContainer: {
                    backgroundColor:'white',
                    minHeight:'400px',
                    borderRadius:'5px',
                }
            }
        }
    })
    const normalOptions=["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "numberList", "bulletList", "quote", "code", "clear", "save"];
    const afterSaveClickOptions=["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "numberList", "bulletList", "quote", "code", "clear"];
    return(
    <MuiThemeProvider theme={defaultTheme}>
        
        {isEdited===true?<span style={{color:'red',fontWeight:'bold'}}>NIEZAPISANE!</span>
        :
        <span style={{color:'green',fontWeight:'bold'}}>ZAPISANE</span>}
        {clicked===true?<CircularProgress size={30} thickness={3}/>:null}
        {isLoading===false||(newReport===true&&isLoading===true)?
        <MUIRichTextEditor
        defaultValue={newReport===true?null:report.content}
        label="Napisz raport..." 
        inlineToolbar={true}
        onSave={newReport===true?handleSave:handleEdit}
        maxLength={5000}
        theme={defaultTheme}
        component={Paper}
        onChange={handleReportChange}
        controls={clicked===true?afterSaveClickOptions:normalOptions}
        />
        :
        <CircularProgress/>}
    </MuiThemeProvider>
    )  
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(TextEditor)