import { Box, Button, Grid, LinearProgress, Typography, withStyles } from "@material-ui/core"
import { useEffect, useState } from "react";
import { useIsMount } from "../departments/mountHook"
import addOrderCall from "./addOrderCall"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useSnackbar } from "notistack";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useHistory } from "react-router";
import { connect } from "react-redux";
import actions from "./duck/actions";

const StyledProgress = withStyles({
    root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: "#e2e2e2",
      },
      bar: {
        borderRadius: 5,
        backgroundColor: '#0750b5',
      },
  })(LinearProgress);

const StyledButton = withStyles({
  root: {
    backgroundColor:"#43a047",
    '&:hover': {
      backgroundColor: '#43a047',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#43a047',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const SaveNewOrder = (props) => {

    const { enqueueSnackbar } = useSnackbar()
    const [progress, setProgress] = useState(0)
    const isMount = useIsMount()
    const [response,setResponse] = useState({})
    const [error,setError] = useState(false)
    const [hidden,setHidden] = useState(true)
    const history = useHistory()

    useEffect(() => {
        if(!isMount){
            if(response.status===200){
                if(progress===100){
                    enqueueSnackbar("Dodano nowe zlecenie!",{variant: 'success',autoHideDuration: 2000})
                }
                const timer = setInterval(() => {
                    if(progress<100){
                    setProgress(progress+4);
                    }
                }, 10);
                return () => {
                    clearInterval(timer);
                };
                
            }else{
                setError(true)
                enqueueSnackbar("UPS! Nie udało się dodać zlecenia spróbuj ponownie {".concat(response.message).concat("}"),{variant: 'error',autoHideDuration: 3000})
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress,response]);

    const handleSave = async () =>{
        setResponse(await addOrderCall())
        setHidden(false)
    }

    useEffect(()=>{
        if(progress===100){
            setTimeout(()=>{
                props.resetForm()
                history.push("/all-orders")
            },1500)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[progress])

    return(

        <Grid container justify="center">
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
                <StyledButton style={{marginTop:"60px"}} size="large" disabled={!hidden} variant="contained" color="primary" fullWidth={true} onClick={()=>handleSave()}>
                    Zapisz zlecenie w bazie
                </StyledButton>
                <Box style={{marginTop:"60px"}} hidden={hidden}>
                    <Typography style={{textAlign:"center",fontSize:"1.8rem"}} color="textSecondary">
                        {`${Math.round(progress)}%`}
                    </Typography>
                    <StyledProgress variant="determinate" value={progress}/>
                    
                    {error===true?<ErrorOutlineIcon style={{color:"red",fontSize:"6rem",width:"100%",textAlign:"center",marginTop:"20px"}}/>:null}
                    {progress===100&&error===false?
                    <>
                    <CheckCircleOutlineIcon style={{color:"#43a047",fontSize:"6rem",width:"100%",textAlign:"center",marginTop:"20px"}}/>
                    <Typography style={{fontSize:"1.4rem", color:"green",textAlign:"center"}}>Nastąpi automatyczne przekierowanie . . .</Typography>
                    </>
                    :null}
                </Box>
                
            </Grid>
        </Grid>
    )
}

function mapDispatchToProps(dispatch) {
    return {
     resetForm : () => dispatch(actions.resetForm())
    }
}

export default connect(null,mapDispatchToProps)(SaveNewOrder)