import { Dialog,DialogTitle,DialogActions,DialogContent, DialogContentText, Button, CircularProgress } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import actions from "../duck/actions"
import { cancelLeave } from "../duck/operations"

const DeleteConfirmationDialog = () => {
    
    const open = useSelector(state=>state.leaves.openConfirmationDialog)    
    const leaveId = useSelector(state=>state.leaves.leaveIdToDelete)
    const isLoading = useSelector(state=>state.leaves.loadingCancelLeave)
    const dispatch = useDispatch() 

    const handleDelete = () => {
        dispatch(cancelLeave(leaveId))
        //console.log("delete functionality for id:",leaveId)
    }

    const handleCancel = () => {
        dispatch(actions.setOpenConfirmationDialog(false))
    }

    return(
    <Dialog
    fullWidth={false}
    maxWidth='md'
    open={open}
    >
    <DialogTitle>Uwaga!</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Czy na pewno chcesz anulować swój urlop ? Spowoduje to jego wymazanie z kalendarza urlopów a termin zostanie zwolniony.
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        {
            isLoading?
            <CircularProgress/>
            :
            <>
            <Button onClick={handleCancel}>NIE</Button>
            <Button onClick={handleDelete}>TAK</Button>
            </>
        }
        
    </DialogActions>
    </Dialog>
    )
}
export default DeleteConfirmationDialog