import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeleteDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (confirmed) => {
    props.closeDialog(confirmed)
  };

  useEffect(() => {
      console.log(props)
      setOpen(props.open)  
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <div>
      <Dialog
        open={open}
      >
        <DialogTitle>{"Uwaga!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Czy na pewno chcesz usunąć ten plik?
          <br/>
          {props.fileName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={()=>handleClose(false)} >
            ANULUJ
          </Button>
          <Button variant="outlined" onClick={()=>handleClose(true)} color="primary" autoFocus>
            USUŃ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}