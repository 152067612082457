import { TableBody, TableCell, TableHead, TableRow, Table, Paper, TableContainer, IconButton } from "@material-ui/core"
import { connect } from "react-redux"
import actions from "../duck/actions"
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const ServicesSummary = (props) => {

    const handleDataEdit = (number) => {
        props.setStep(number)
    }

    const services = props.servicesGeod.concat(props.servicesTypi,props.servicesIndi,props.servicesSani,props.servicesCond,props.servicesGeol)
    let sum = 0
    return(
        <TableContainer component={Paper}>
        <Table size="small">
            <TableHead style={{backgroundColor:"#0750b5"}}>
                <TableRow>
                    <TableCell style={{width:"20%",color:"white"}}>Lp</TableCell>
                    <TableCell style={{color:"white",width:"60%",textAlign:"center"}}>Usługa</TableCell>
                    <TableCell style={{width:"20%",color:"white"}}>Kwota</TableCell>
                </TableRow>
            </TableHead>
            
            <TableBody>
                {
                services.map((service,index)=>{
                    //console.log(index+1,service)
                    if(service.undefinedPrice===false){
                        sum=sum+parseFloat(service.price)
                    }
                    return(
                        <TableRow key={index}>
                            <TableCell><span style={{fontWeight:"bold"}}>{index+1}</span></TableCell>
                            <TableCell>{service.serviceName}</TableCell>
                            <TableCell style={{textAlign:"center"}}>{service.undefinedPrice===true?<span style={{color:"red"}}>Nieokreślona</span>:<span style={{color:"green"}}>{service.price}</span>}</TableCell>
                        </TableRow>
                    )
                })
                }
                <TableRow>
                    <TableCell className="noPaddingCell"><IconButton onClick={()=>handleDataEdit(1)}><EditOutlinedIcon className="editIcon"/></IconButton></TableCell>
                    <TableCell style={{textAlign:"right",fontWeight:"bold"}}>Razem:</TableCell>
                    <TableCell style={{textAlign:"center"}}><span style={{color:"green",fontWeight:"bold"}}>{sum}</span></TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
    )
}

const mapStateToProps = (state) =>{
    return{
        servicesGeod:state.newOrder.servicesGeod,
        servicesGeol:state.newOrder.servicesGeol,
        servicesIndi:state.newOrder.servicesIndi,
        servicesTypi:state.newOrder.servicesTypi,
        servicesCond:state.newOrder.servicesCond,
        servicesSani:state.newOrder.servicesSani,
        //tu dalej ściągać dane z reducerów :)
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setStep: (step) => dispatch(actions.setStep(step)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ServicesSummary)