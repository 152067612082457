import { Container, Paper, Typography } from "@material-ui/core"
import InfoIcon from '@material-ui/icons/Info';
import { store } from "../store";


const Informator = () => {
    const name=store.getState().log.user.name
    return(
        <Container style={{marginTop:'50px'}}>
            <Paper style={{padding:'10px',paddingRight:'100px',border:'2px solid #1976d2'}}>
                <InfoIcon style={{float: 'left',marginRight: '30px',color:'#014fb6',fontSize:'3rem',height: '84px',marginLeft: '21px'}}/>
                <Typography style={{fontSize:'1.3rem'}}><span style={{color:'#43a047'}}>{name+"!  "}</span>Witaj w nowej wersji aplikacji.</Typography>
                <Typography style={{fontSize:'1.2rem',marginTop:'5px'}}>Aby wszystkie funkcje aplikacji działały prawidłowo, przed pierwszym użyciem aplikacji należy wyczyścić pamięć podręczną przeglądarki!</Typography>
                <Typography style={{fontSize:'1.2rem'}}>W przypadku wystąpienia błędu aplikacji możesz:</Typography>
                <Typography style={{fontSize:'1.2rem'}}>1. Odświeżyć stronę.</Typography>
                <Typography style={{fontSize:'1.2rem'}}>2. Wylogować i zalogować się ponownie.</Typography>
                <Typography style={{fontSize:'1.2rem'}}>3. Usunąć pamięć podręczną przeglądarki.</Typography>
                <Typography style={{fontSize:'1.2rem'}}>4. Zgłosić błąd w celu poprawienia.</Typography>
                <Typography style={{fontSize:'1.2rem',marginTop:'15px'}}>Miłej pracy<span style={{color:'#43a047'}}>{" "+name}</span>{" :) !"}</Typography>
            </Paper>
        </Container>
    )
}
export default (Informator)