import { Document, Page, StyleSheet, Font, View,Text } from '@react-pdf/renderer';
import moment from 'moment';
import font from "../fonts/roboto-medium-webfont.ttf";
import PdfReportDay from './PdfReportDay';

Font.register({
  family: "Roboto",
  src: font 
});

const styles = StyleSheet.create({
    page: {
        display: "flex",
        width:'100%',
        padding:15,
        fontFamily:'Roboto',
    },
    section: {
        width:'100%',
        display:'flex',
        fontSize:'12px',
        fontFamily:'Roboto',
    },
    stamp:{
        width:'33%',
        marginTop:'10px',
        marginBottom:'10px',
        display:'flex',
        alignSelf: 'center'
        
    },
    mainContainer:{
        width:'90%',
        height:'auto',
        padding:'10px',
        border:'solid 1px black'
    },
    textBox:{
        marginTop:'20px',
        width:"90%",
        marginLeft:'auto',
        marginRight:'auto',
        borderColor:'black',
        borderStyle:'solid',
        borderWidth:1,
        padding:10,
        fontFamily:'Roboto',
    },
    text:{
        fontFamily:'Roboto',
        fontSize:9
    }
});

const arr = [1,2,3,4,5,6,7,8,9]

const ReportsPdf = ({data,start,end}) => {

    return(
    
        <Document title={"Raporty"}>
            {(data===null||data===undefined)?null:
            <>
            <Page size="A4" style={styles.page}>
                <div style={styles.section}>
                    <Text>{"Data od: "+moment(start).format("YYYY-MM-DD").toString()+" ("+moment(start).format("dddd")+") "+" do:  "+moment(end).format("YYYY-MM-DD").toString()+" ("+moment(start).format("dddd")+")"}</Text>
                    <Text>{"Wygenerowano: "+moment().format("YYYY-MM-DD HH:mm")+" ("+moment().format("dddd")+")"}</Text>
                    <View>
                    {
                        data.map((day) => (<PdfReportDay data={day}/>))
                    }
                    </View>
                </div>
            </Page>
            </>}
        </Document>
    )
}
 export default ReportsPdf