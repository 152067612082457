import { useEffect } from "react"
import { useSelector,useDispatch } from "react-redux"
import UserSelect from "../allLeaves/UserSelect"
import actions from "../duck/actions"
import { getAllUsers } from "../duck/operations"

const AdminUserSelect = () => {

    const dispatch = useDispatch()
    const selectedUser = useSelector(state=>state.leaves.form.userId)
    const users = useSelector(state=>state.leaves.users)
    const loadingUsers = useSelector(state=>state.leaves.loadingUsers)

    const handleUserChange = (user) => {
        dispatch(actions.setFormUserId(user))
    }

    useEffect(()=>{
        dispatch(getAllUsers())
    },[])

    return (
        <UserSelect 
            selectedUser={selectedUser} 
            handleUserChange={handleUserChange}
            users={users}
            loadingUsers={loadingUsers}
        />
    )
}

export default AdminUserSelect