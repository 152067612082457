import { useEffect } from "react"
import { useSelector,useDispatch } from "react-redux"
import UserSelect from "../allLeaves/UserSelect"
import actions from "../duck/actions"
import { getAllLeaves, getAllUsers } from "../duck/operations"

const TableUserSelect = () => {
    const dispatch = useDispatch()
    const selectedUser = useSelector(state=>state.leaves.tableFilters.userId)
    const users = useSelector(state=>state.leaves.users)
    const loadingUsers = useSelector(state=>state.leaves.loadingUsers)
    const error = useSelector(state => state.leaves.fetchUsersError)

    const handleUserChange = (user) => {
        //console.log("status change")
        dispatch(actions.setFilterUser(user))
        dispatch(getAllLeaves())
    }

    useEffect(()=>{
        //console.log("status change")
        //dispatch(getAllLeaves())
        dispatch(getAllUsers())
    },[])

    return (
        <div style={{marginLeft:50,marginRight:50,width:200,display:'inline-flex'}}>
        <UserSelect 
            selectedUser={selectedUser} 
            handleUserChange={handleUserChange}
            users={users}
            loadingUsers={loadingUsers}
            error={error}
        />
        </div>
    )
}

export default TableUserSelect