import { Grid, Paper, Tabs } from "@material-ui/core"
import { AppBar } from "@material-ui/core"
import React from "react"
import TabPanelStat from "./TabPanelStat"
import Tab from '@material-ui/core/Tab';

const StatContainer = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
            <Grid justify="center" container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <AppBar component={Paper} style={{backgroundColor:'#0750b5',marginTop:'15px'}} position="static">
                    <Tabs value={value} onChange={handleChange} variant="scrollable">
                        <Tab label="Wszystko" />
                        <Tab label="Geodezja" />
                        <Tab label="Projekty Typowe" />
                        <Tab label="Projekty Indywidualne" />
                        <Tab label="Instalacje Sanitarne" />
                        <Tab label="Warunki Zabudowy" />
                        <Tab label="Geologia" />
                    </Tabs>
                    </AppBar>
                    <TabPanelStat value={value} departmentId={null} tableName="Wszystkie działy" index={0}>
                    Wszystko
                    </TabPanelStat>
                    <TabPanelStat value={value} departmentId={1} tableName="Geodezja" index={1}>
                    Geodezja
                    </TabPanelStat>
                    <TabPanelStat value={value} departmentId={2} tableName="Projekty Typowe" index={2}>
                    Typowe
                    </TabPanelStat>
                    <TabPanelStat value={value} departmentId={3} tableName="Projekty Indywidualne" index={3}>
                    Indywidualne
                    </TabPanelStat>
                    <TabPanelStat value={value} departmentId={4} tableName="Instalacje sanitarne" index={4}>
                    Sanitarne
                    </TabPanelStat>
                    <TabPanelStat value={value} departmentId={5} tableName="Warunki zabudowy" index={5}>
                    Warunki
                    </TabPanelStat>
                    <TabPanelStat value={value} departmentId={6} tableName="Geologia" index={6}>
                    Geologia
                    </TabPanelStat>
                </Grid>
            </Grid>
    )
}

export default StatContainer