import { IconButton } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import SaveIcon from '@material-ui/icons/Save';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const PriceInput = ({jwt,cost,refresh,orderId}) => {

    const [baseValue,setBaseValue] = useState(cost.price)
    const [value,setValue] = useState(cost.price)
    const token="Bearer ".concat(jwt)
    const { enqueueSnackbar } = useSnackbar()

    const handleValueChange = (value) => {
        setValue(value)
    }

    const saveChanges = async() => {
        await fetch(`${serverUrl}/costs/${cost.costId}/price?price=${value}&orderId=${orderId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
            console.log(response);
            if(response.status===200){
                enqueueSnackbar("Pomyslnie edytowano kwotę kosztu",{variant: 'success',autoHideDuration: 2000})
                setBaseValue(value)
            }else{
                enqueueSnackbar("UPS! Kwota kosztu nie została edytowana, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
            }
        })
        .catch(error => {
            enqueueSnackbar("UPS! Kwota kosztu nie została edytowana, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
            console.log(error);
        })
        refresh()
    }

    const validCheck = () => {
        if(value===undefined||value<0||value===""){return false}else{return true}
    }

    return(
        <>
        {value===baseValue?
            <HelpOutlineIcon style={{fontSize:'2.2rem',color:'grey',marginTop:'25px',marginRight:'10px'}}/>
            :
                validCheck()===false?
                    <CancelOutlinedIcon style={{fontSize:'2.2rem',color:'red',marginTop:'25px',marginRight:'10px'}}/>
                :
                    <CheckCircleIcon style={{fontSize:'2.2rem',color:'green',marginTop:'25px',marginRight:'10px'}}/>
        }
        <TextField 
            value={value}
            onChange={(e) => handleValueChange(e.target.value)}
            label="Nowa Nazwa Kosztu"
            type='number'
            variant='outlined'
            style={{borderRadius:'3px',backgroundColor:'white',marginTop:'15px',maxWidth:'200px'}}
        />
        <IconButton 
            disabled={!validCheck()||baseValue===value} 
            onClick={()=> saveChanges()} 
            style={{marginTop:'-25px'}}
        >
            <SaveIcon style={{fontSize:'2.2rem',color:(validCheck()===true&&baseValue!==value)?'green':'grey'}}/>
        </IconButton>
        </>
    )

}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(PriceInput)