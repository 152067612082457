import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import './calendar.css';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { serverUrl } from '../config/serverConfig';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Button } from '@material-ui/core';
import DialogSave from './DialogSave';
import CounterEvent from './CounterEvent';
import DayReportsList from './DayReportsList';
import colorFinder from './colorFinder';
import ReportsPdfGenerator from './ReportsPdfGenerator';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import actions from '../departments/duck/actions';
const localizer = momentLocalizer(moment)

const AllReportsCalendar = props => {
  
    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(props.jwt)
    const [reports,setReports] = useState([])
    const [startDate,setStartDate] = useState(moment([moment().year(),moment().month()]).subtract(7, 'days').format("YYYY-MM-DD HH:mm:ss"))
    const [endDate,setEndDate] = useState(moment().endOf('month').add(7, 'days').format("YYYY-MM-DD HH:mm:ss"))
    const [refresh,setRefresh] = useState(0)
    const [open, setOpen] = useState(false)
    const [openPdf, setOpenPdf] = useState(false)
    const [date,setDate] = useState()
    const [saveDialogOpen,setSaveDialogOpen] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const convertEvents = (object) =>{

        let convertedEvents = []

        object.reports.forEach((event,index) => {
          //console.log(event.reports)
            let timestamp = moment.utc(event.date,"YYYY-MM-DD HH:mm:ss",true).local()
            let date = new Date(
                timestamp.year(),
                timestamp.month(),
                timestamp.date())
            let obj={
                id:index+1,
                title:"",
                allDay:true,
                start:date,
                end:date,
                color:colorFinder(object.users,event.reports),
                reports:event.reports,
                users:object.users,
            }
            convertedEvents.push(obj)
        });
        //console.log(convertedEvents)
        return convertedEvents
    }

    useEffect(() => {
        async function getData() {

          await fetch(`${serverUrl}/reports/all-per-day?startDate=${startDate}&endDate=${endDate}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            //console.log(json);
            //console.log(startDate,endDate)
            setReports(convertEvents(json));
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
          })
        }
        if (isLoading||refresh>0) {
          getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[refresh])

    let components = {
        event: CounterEvent,
    }


    const handleEventClick = (event) => {
        setOpen(true)
        setDate(moment(event.start).format("YYYY-MM-DD HH:mm:ss"))
    }

    const handleRangeChange = (range) =>{
        setStartDate(moment(range.start).format("YYYY-MM-DD HH:mm:ss"))
        setEndDate(moment(range.end).add(1,'day').format("YYYY-MM-DD HH:mm:ss"))
        setRefresh(refresh+1)
    }

    const handleClose = () =>{
          setRefresh(refresh+1)
          setOpen(false)
    }


    const closeDialogSave = (confirmed) =>{
      setSaveDialogOpen(false)
      if(confirmed===true){
        setOpen(false)
      }
    }

    return(
        <>
        
        <div>
            <Button 
            style={{backgroundColor:'#43a047',color:'white',maxWidth: 900, bottomMargin:20}} 
            fullWidth={true}
            onClick={()=>setOpenPdf(true)}>
              RAPORTY DO PDF
            </Button>
            <Calendar
            components={components}
            localizer={localizer}
            events={reports}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600, maxWidth: 900 }}
            views={['month']}
            onSelectEvent={handleEventClick}
            selectable={false}
            onRangeChange={handleRangeChange}
            />
            
        </div>
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          fullScreen={fullScreen}
          open={openPdf}
        >
          <DialogTitle>Generuj PDF
          <Button onClick={()=>{props.reset()}} style={{lineHeight:1.5,color:'white',backgroundColor:'crimson',float:'right'}}>Wyczyść wszystko</Button>
          </DialogTitle>
          
          <DialogContent style={{minHeight:'500px',paddingTop:'20px'}} className="dialogMid">
            <ReportsPdfGenerator/>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>setOpenPdf(false)} color="primary">
              Zakończ
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          maxWidth="lg"
          fullWidth={true}
          fullScreen={fullScreen} 
          open={open}
        >
          <DialogTitle>Raporty na dzień : 
            <span style={{color:"#0750b5",marginLeft:"15px"}}>{moment(date).format("YYYY-MM-DD")}</span>
            <span style={{color:"#0750b5",marginLeft:"15px"}}>({moment(date).format("dddd")})</span>
          </DialogTitle>
          <DialogContent style={{minHeight:'500px'}} className="dialogMid">
            <DayReportsList date={date}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Zakończ
            </Button>
          </DialogActions>
        </Dialog>

        <DialogSave open={saveDialogOpen} closeDialog={closeDialogSave}/>
      </>
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

const mapDispatchToProps = (dispatch) => {
  return{
    reset: () => dispatch(actions.resetReportsPdf())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(AllReportsCalendar)