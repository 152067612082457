import StatTable from "./StatTable"
import StatTableDepartments from "./StatTableDepartments"


const TabPanelStat = (props) => {
    
    const { value,departmentId,tableName, index } = props

    return(
        <div
        role="tabpanel"
        hidden={value !== index}
        style={{backgroundColor:'white'}}
        >
        {value === index && (
            
            departmentId===null?<StatTable departmentId={departmentId} tableName={tableName}/>:
            <StatTableDepartments departmentId={departmentId} tableName={tableName}/>
            
        )}
        </div>
    )
}

export default TabPanelStat