import React, { useEffect, useState, useRef } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { connect } from 'react-redux';
import { serverUrl } from '../config/serverConfig';
import { CircularProgress, withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const SaveButton = withStyles({
    root:{
        backgroundColor:'#43a047',
        color:'white',
        '&:hover':{
            backgroundColor:'#43a047b0'
        }
    },
    disabled:{
        backgroundColor:'lightgrey',
        color:'grey'
    }
})(Button)

const AddServices = ({jwt,orderId,tabRefresh}) => {
    
    const [value, setValue] = React.useState(0);
    const [price,setPrice] = useState(0);
    const [priceDefined,setPriceDefined] = useState(true)
    const [isLoading,setIsLoanding] = useState(true)
    const [isLoadingSave,setIsLoandingSave] = useState(false)
    const [data,setData] = useState([])
    const token='Bearer '.concat(jwt)
    const [refresh,setRefresh] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
  
    useEffect(() => {
        async function getData() {
            await fetch(`${serverUrl}/services-info/available?orderId=${orderId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            })
            .then(response => response.json())
            .then(json => {
            //console.log(json);
            setIsLoanding(false);
            setData(json)
            })
            .catch(error => {
            
            })
        }
        if (isLoading||refresh>0) {
            getData()
            setRefresh(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const handleChange = (event) => {
      setValue(parseInt(event.target.value));
    };

    const handleChangeDefined = (event) => {
      setPriceDefined(event.target.value==="true"?true:false);
    };

    const saveChanges = async() => {
        setIsLoandingSave(true)
        let pr
        let prDefined
        if(priceDefined===true){
            pr=price
            prDefined=true  
        }else{
            pr=0
            prDefined=false
        }
        await fetch(`${serverUrl}/services/new?price=${pr}&orderId=${orderId}&serviceInfo=${value}&priceDefined=${prDefined}`,
        {method:'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
            console.log(response);
            if(response.status===200){
                enqueueSnackbar("Pomyslnie dodano pracę",{variant: 'success',autoHideDuration: 2000})
                //setBaseValue(value)
            }else{
                enqueueSnackbar("UPS! Praca nie została dodana, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
            }
            setIsLoandingSave(false)
        })
        .catch(error => {
            enqueueSnackbar("UPS! Praca nie została dodana, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
            console.log(error);
            setIsLoandingSave(false)
        })
        setRefresh(refresh+1)
        tabRefresh()
        setValue(0)
        setPriceDefined(true)
        setPrice(0)
    }

    return(
    <Grid container>
        <Grid item  xs={12} sm={12} md={8} lg={7} xl={6}>
            <FormControl component="fieldset">
            <FormLabel component="legend">Usługi:</FormLabel>
            <RadioGroup name="serviceId" value={value} onChange={handleChange}>
                {isLoading===true?<CircularProgress/>:
                    data.map(service=>{
                        let id=service.serviceInfoId
                        return(
                        <><Divider key={id+'div'}/>
                        <FormControlLabel key={id} checked={parseInt(value)===id?true:false} value={id} control={<Radio />} label={service.serviceName} />
                        </>)
                    }   
                    )
                }
            </RadioGroup>
            </FormControl>
        </Grid>
        <Grid  item xs={12} sm={12} md={4} lg={5} xl={6}>
            <Container style={{backgroundColor:'white',padding:'10px',borderRadius:'5px',marginTop:'15px',border:'1px solid #0750b5'}}>
                {value===0?
                    <Typography align="center" style={{fontWeight:'bold',color:'red'}}>Wybierz usługę z listy!</Typography>
                    :
                    <>
                    <FormControl component="fieldset">
                    <RadioGroup name="serviceId" value={priceDefined} onChange={handleChangeDefined}>
                    <FormControlLabel value={true} control={<Radio />} label="Znana cena" />
                    <FormControlLabel value={false} control={<Radio />} label="Nieznana cena" />
                    </RadioGroup>
                    </FormControl>
                    {
                    priceDefined===false?null:
                    <>
                    <Typography align="center" style={{fontWeight:'bold',marginTop:'5px',color:'#0750b5'}}>Wprowadź cenę:</Typography>
                    <TextField 
                    fullWidth={true} 
                    variant="outlined" 
                    type='number' 
                    size='medium' 
                    value={price} 
                    onChange={(e)=>setPrice(e.target.value)}
                    inputProps={{
                        style:{color:'#0750b5',fontWeight:'bold'}
                    }}
                    />
                    </>
                    }
                    {
                    isLoadingSave===true?<CircularProgress></CircularProgress>:
                    <SaveButton
                        disabled={value===0||price===null||price===undefined||price===""}
                        fullWidth={true}
                        size='large' 
                        style={{marginTop:'15px',marginBottom:'5px'}}
                        onClick={()=>saveChanges()}>
                        Dodaj prace
                    </SaveButton>
                    }
                    </>
                }
            </Container>
            
        </Grid>
    </Grid>
    )

}
const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(AddServices)