import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import font from "../fonts/arial.ttf"

Font.register({
  family: "Arial",
  format: "truetype",
  src: font 
});
const styles = StyleSheet.create({
    table: { 
        display: "table", 
        width: "90%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        marginRight:'auto',
        marginLeft:'auto',
        fontFamily:'Arial',
        textAlign:'left'
    },
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColL: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColR: { 
        width: "70%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCell: {
        fontSize: 9 ,
        textAlign:'left',
        marginLeft:5
    }
  });



const PdfInfoTable = ({data}) =>{
    return(
        <>
        <View style={styles.table}> 
            <View style={styles.tableRow}> 
            <View style={styles.tableColL}> 
                <Text style={styles.tableCell}>Data przyjęcia zlecenia:</Text> 
            </View> 
            <View style={styles.tableColR}> 
                <Text style={styles.tableCell}>{data.date}</Text> 
            </View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableColL}>
                <Text style={styles.tableCell}>Inwestor:</Text> 
            </View>
            <View style={styles.tableColR}> 
                <Text style={styles.tableCell}>{data.customerName}</Text> 
            </View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableColL}>
                <Text style={styles.tableCell}>Adresy korespondencyjne:</Text>
            </View>
            <View style={styles.tableColR}>
                {data.addresses.map((address)=>(
                    <Text style={styles.tableCell}>-{address.address}<br/></Text>
                ))}
            </View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableColL}>
                <Text style={styles.tableCell}>Numery Tel.:</Text>
            </View>
            <View style={styles.tableColR}>
                {data.phoneNumbers.map((number)=>(
                    <Text style={styles.tableCell}>-{number.phoneNumber}<br/></Text>
                ))}
            </View>
            </View>
            <View style={styles.tableRow}>
            <View style={styles.tableColL}>
                <Text style={styles.tableCell}>Adresy E-mail:</Text>
            </View>
            <View style={styles.tableColR}>
                {data.emails.map((email)=>(
                    <Text style={styles.tableCell}>-{email.email}<br/></Text>
                ))}
            </View>
            </View>
            <View style={styles.tableRow}>
            <View style={{width: "100%",borderStyle: "solid",borderWidth: 1,borderLeftWidth: 0,borderTopWidth: 0,paddingLeft:'40px'}}>
                <Text style={styles.tableCell}>Adres inwestycji:</Text>
            </View>
            </View>
            <View style={styles.tableRow}> 
            <View style={styles.tableColL}> 
                <Text style={styles.tableCell}>Gmina:</Text> 
            </View> 
            <View style={styles.tableColR}> 
                <Text style={styles.tableCell}>{data.community}</Text> 
            </View>
            </View>
            <View style={styles.tableRow}> 
            <View style={styles.tableColL}> 
                <Text style={styles.tableCell}>Obręb:</Text> 
            </View> 
            <View style={styles.tableColR}> 
                <Text style={styles.tableCell}>{data.precinct}</Text> 
            </View>
            </View>
            <View style={styles.tableRow}> 
            <View style={styles.tableColL}> 
                <Text style={styles.tableCell}>Numer działki:</Text> 
            </View> 
            <View style={styles.tableColR}> 
                <Text style={styles.tableCell}>{data.plotNumber}</Text> 
            </View>
            </View>
        </View>
        </>
    )
}

export default PdfInfoTable
 