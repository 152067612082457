import moment from "moment";
import { useEffect } from "react";
import Timeline, {
TimelineHeaders,
SidebarHeader,
DateHeader,
TodayMarker
} from "react-calendar-timeline/lib";
import "./Timeline.css";
import {CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers,getCalendarLeaves } from "../duck/operations";
import PreviewDialog from "./PreviewDialog";
import actions from "../duck/actions";
import { Typography } from "@material-ui/core";



const LeaveTimeline = () => {
    const groups = useSelector(state => state.leaves.users)
    const leaves = useSelector(state=>state.leaves.calendarLeaves)
    const loadingUsers = useSelector(state=>state.leaves.loadingUsers)
    const loadingCalendarLeaves = useSelector(state=>state.leaves.loadingCalendarLeaves)
    const fetchUsersError = useSelector(state=>state.leaves.fetchUsersError)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllUsers())
        dispatch(getCalendarLeaves())
    }, [])

    const handleItemSelect = (itemId) =>{
        dispatch(actions.setTimelineDialogId(itemId))
        dispatch(actions.setOpenTimelineInfoDialog(true))
    }


    const itemRenderer = ({ item, itemContext, getItemProps }) =>{
        return(
            <div {...getItemProps({
                style: {
                  backgroundColor:item.color,
                  color: 'white',
                  fontSize:18,
                  borderRadius: 4,
                  textAlign:'center',
                  borderColor:item.color,
                  borderWidth:1,
                  borderStyle:'solid'
                }
              })}
            >
            {itemContext.title}
            </div>
        )
    }

    return(
    <>

    {loadingUsers||loadingCalendarLeaves||fetchUsersError?
    <div style={{
        marginTop:40,
        width:'100%',
        height:'70vh',
        backgroundColor:'#ebebeb',
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        flexDirection:'column'

    }}>
        {
          fetchUsersError?
          <>
          <Typography style={{
            color:'grey',
            fontSize:40,
          }}>
            Wystąpił błąd ładowania danych... :(
          </Typography>
          <br/>
          <Typography style={{
            color:'grey',
            fontSize:25,
          }}>
            Spróbuj odświeżyć stronę...
          </Typography>
          </>
          :
          <CircularProgress/>
        }
        
    </div>
    :
    <Timeline
    style={{marginTop:40}}
    groups={groups}
    items={leaves}
    sidebarContent={<div>Above The Left</div>}
    itemTouchSendsClick={false}
    stackItems={false}
    itemHeightRatio={0.75}
    showCursorLine
    canMove={false}
    canResize={false}
    defaultTimeStart={moment().add(-0.5,'year')}
    defaultTimeEnd={moment().add(0.5,'year')}
    traditionalZoom={true}
    minZoom={60*60*24*31*1000}
    maxZoom={60*60*24*365*1000}
    onItemSelect={(itemId)=>{handleItemSelect(itemId)}}
    selected={[]}
    itemRenderer={itemRenderer}
    >
        <TodayMarker />
        <TimelineHeaders className="sticky">
          <SidebarHeader>
            {({ getRootProps }) => {
              return <div {...getRootProps()} ></div>;
            }}
          </SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader />
        </TimelineHeaders>
    </Timeline>
    }
    <PreviewDialog/>
    </>
    )
}

export default LeaveTimeline