import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import {  Grid,Box } from "@material-ui/core";
import { Paper } from "@mui/material";
import { pl } from "date-fns/locale";
import actions from '../duck/actions';
import { useSelector,useDispatch } from 'react-redux';

const RangePicker = () => {

    const value = useSelector(state=>state.leaves.form.rangePickerValue)
    
    const dispatch = useDispatch()

    const setValue = (val) => {
        dispatch(actions.setRangePickerValue(val))
    } 

    return(
        <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns} >
        <MobileDateRangePicker
        components={Paper}
        startText="Data"
        endText="Data"
        cancelText="Anuluj"
        clearText="Wyczyść"
        todayText="Dzisiaj"
        toolbarTitle="Wybierz przedział czasu"
        showDaysOutsideCurrentMonth={false}
        showTodayButton={false}
        clearable={true}
        inputFormat='dd/MM/yyyy'
        value={value}
        onChange={(newValue) => {
            setValue(newValue)
        }}
        renderInput={(startProps, endProps) => (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <TextField  size='small' style={{backgroundColor:'white'}} {...startProps} />
                </Grid>
                <Grid item>
                    <Box> do </Box>
                </Grid>
                <Grid item>
                    <TextField size='small' style={{backgroundColor:'white'}} {...endProps} />
                </Grid>
            </Grid>
        )}
        />
        </LocalizationProvider>
    )

}

export default RangePicker