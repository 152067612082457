
import { serverUrl } from "../config/serverConfig"
import { store } from "../store"

const addOrderCall = async () => {
    
    const state = store.getState().newOrder
    const jwt = store.getState().log.jwt
    const userId = store.getState().log.user.userId
    const token = 'Bearer '.concat(jwt)
    const newOrderObject={
        customCustomer:state.customerData.customCustomer,
        customerId:state.customerData.customerId,
        customerName:state.customerData.customerName,
        community:state.customerData.community,
        precinct:state.customerData.precinct,
        projectName:state.customerData.projectName,
        plotNumber:state.customerData.plotNumber,
        newAddresses:state.customerData.newAddresses,
        addresses:state.customerData.addresses,
        newPhoneNumbers:state.customerData.newPhoneNumbers,
        phoneNumbers:state.customerData.phoneNumbers,
        newEmails:state.customerData.newEmails,
        emails:state.customerData.emails,
        servicesGeodesy:state.servicesGeod,
        servicesGeology:state.servicesGeol,
        servicesTypical:state.servicesTypi,
        servicesIndividual:state.servicesIndi,
        servicesConditions:state.servicesCond,
        servicesSanitary:state.servicesSani,
        net:state.net,
        comment:state.comment,
        supervisorGeodesy:state.supervisorGeod,
        supervisorGeology:state.supervisorGeol,
        supervisorTypical:state.supervisorTypi,
        supervisorIndividual:state.supervisorIndi,
        supervisorConditions:state.supervisorCond,
        supervisorSanitary:state.supervisorSani,
        userId:userId,
    }
    console.log(JSON.stringify(newOrderObject))

    console.log(newOrderObject)

        let resp
        let status
        let mess
        await fetch(`${serverUrl}/orders/add-order`,
        {method:'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
            body:JSON.stringify(newOrderObject)
        })
        .then(response =>{
          status=response.status
          mess=response.statusText
          return response.json()
        })
        .then(json => {
          resp = json
        })
        .catch(error => {
          resp=error
        })

        return {response:resp,status:status,message:mess}
}

export default addOrderCall