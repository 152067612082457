import React, { useEffect, useState } from "react";
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useGroupBy,
  usePagination,
  useSortBy, 
  useExpanded,
} from "react-table";
// A great library for fuzzy filtering/sorting items
import {matchSorter} from "match-sorter";
import { 
  Button,
  CircularProgress,
  Dialog,
  IconButton, 
  MenuItem, 
  Select, 
  TextField,
  DialogContent,
  DialogTitle,
  useTheme,
  DialogActions,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider
 } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import "../departments/style.css";
import ConditionsDialog from "../departments/conditions/ConditionsDialog";
import { connect } from "react-redux";
import actions from "../departments/duck/actions";
import { serverUrl } from "../config/serverConfig";
import { useHistory, useLocation } from "react-router";
import { Grid } from "@material-ui/core";
import { FormControl } from "@material-ui/core";




const useStyles = makeStyles({
  root: {
    padding: '0px'
  },
});

const mapStateToProps = (state) =>{
 //console.log(state.filters.sanitaryTable.sortedColumn)
  return{
    pageNum: state.filters.allServicesTable.pagination.page,
    onPage: state.filters.allServicesTable.pagination.onPage,
    filters: state.filters.allServicesTable.columnFilters,
    globalFilterVal: state.filters.allServicesTable.globalFilter,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    addFilter : (obj) => dispatch(actions.addFilterAllServices(obj)),
    addGlobalFilter : (obj) => dispatch(actions.addGlobalFilterAllServices(obj)),
    addSortedColumn : (obj) => dispatch(actions.addSortedColumnAllServices(obj)),
    resetAllFilters : () => dispatch(actions.resetFiltersAllServices()),
    setPage: (obj) => dispatch(actions.setPageAllServices(obj)),
    setOnPage: (obj) => dispatch(actions.setOnPageAllServices(obj)),
  }
}

const SelectColumnFilter = connect(null,mapDispatchToProps)(({
  column: { filterValue, setFilter, preFilteredRows, id },addFilter
})=>{
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  let selectValue;
  if(filterValue==null){
    selectValue=""
  }else{
    selectValue=filterValue;
  }
  // Render a multi-select box
  return (
    <FormControl style={{width:'100%'}} variant="outlined">
    <Select
      style={{backgroundColor:'white',borderRadius:'4px'}}
      value={selectValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
        addFilter({value:e.target.value,id:id})
      }}
    >
      <MenuItem value="">Wszystkie</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
    </FormControl>
  );
})

const GlobalFilter = connect(mapStateToProps,mapDispatchToProps)(({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  addGlobalFilter,
  globalFilterVal,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(()=>{
    setGlobalFilter(globalFilterVal)
    setValue(globalFilterVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[globalFilter])
  return (
      <TextField
        variant="outlined"
        InputLabelProps={{style:{color:'#0750b5'}}}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          addGlobalFilter({value:e.target.value})
        }}
        label={`Wyszukaj cokolwiek z ${count} prac`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          backgroundColor:'white',
          borderRadius:'4px',
          width:'40%'
        }}
      />
  );
})
// Our table component
const Table = connect(mapStateToProps,mapDispatchToProps)
      (({columns,data,isLoading,onChange,filters,addFilter,globalFilterVal,resetAllFilters,onChangeDataFilter,pageNum,onPage,setPage,setOnPage}) =>{
  
    function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, id }
    }) {
  const count = preFilteredRows.length;

  return (
    <TextField
      variant="outlined"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        addFilter({value:e.target.value,id:id})
      }}
      label={`${count} `}
      style={{backgroundColor:'white',borderRadius:'4px'}}
    />
  );
}

const skipPageResetRef = React.useRef(true)

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;


  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setSortBy,
    setGroupBy,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      disableMultiSort:true,
      initialState: { 
        pageIndex: pageNum,
        pageSize: onPage,
        filters:filters,
        globalFilter:globalFilterVal,
        sortBy:[],
        //groupBy:["orderId"],
     },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useGroupBy,
    useSortBy,
    useExpanded, 
    usePagination,
    
  );

  useEffect(()=>{
   gotoPage(0)
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[globalFilterVal,filters,state.groupBy])
   //console.log(state)

  const theme = createMuiTheme({
    overrides: {
      MuiTableCell: {
        body: {
          "&:first-child": {
            fontWeight:"bold"
        }
      }
    },
    MuiTable: {
      root:{
        tableLayout:"fixed",
      },
    }}
  });
  
  
  const resetFilters = () => {
    resetAllFilters()
    setAllFilters([])
    setGlobalFilter("")
    setSortBy([])
    setGroupBy([])
    gotoPage(0)

  }

  

  

  const classes = useStyles() 
  
  const checkSort = (column) =>{
    
   // console.log("odpalenie funkcji",column.id)
    if(column.isSorted){
      if(column.isSortedDesc){
       // console.log(column.id," DESC")
        return ' 🔽'
      }else{
       // console.log(column.id," ASC")
        return ' 🔼'
      }
    }else{
     // console.log(column.id,"none")
      return("")
    }
    
  }

  useEffect(()=>{
    setPage(pageIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageIndex])

  useEffect(()=>{
    setOnPage(pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageSize])
  const history = useHistory()
  const redirectOrder = (orderId) =>{
    history.push({
        pathname: '/all-orders',
        state: { orderId: orderId }
    })
  }
  

  const redirectService = (row) =>{
    
    switch(row.departmentId){
      case 1:
        //console.log(history);
        history.push({
          pathname: '/geodesy',
          state: {
            orderId: row.orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;
        
      case 2:
        history.push({
          pathname: '/typical-projects',
          state: { 
            orderId: row.orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;

      case 3:
        history.replace({
          pathname: '/individual-projects',
          state: { 
            orderId: row.orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
            projectId: row.projectId
          }
        })
      break;

      case 4:
        history.push({
          pathname: '/sanitary-installations',
          state: { 
            orderId: row.orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;

      case 5:
        history.push({
          pathname: '/conditions',
          state: { 
            orderId: row.orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
            conditionsId: row.conditionsId
          }
        })
      break;

      case 6:
        history.push({
          pathname: '/geology',
          state: { 
            orderId: row.orderId,
            serviceId: row.serviceId,
            serviceInfoId: row.serviceInfoId,
          }
        })
      break;

      default:return null;
    }
  } 

 
  return (
    
    <div style={{backgroundColor:'rgb(210 210 210)',paddingBottom:'100px',minHeight:'100vh'}}>
      <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={9} style={{marginLeft:'auto',marginRight:'auto',overflowX:"auto"}}>
     <MuiThemeProvider theme={theme}>
      <MaUTable className="mainTable" style={{marginLeft:'auto',marginRight:'auto',width:'95%',minWidth:'1500px'}} {...getTableProps()}>
        <colgroup>
        <col style={{width:"7px"}}/>
        <col style={{width:"9px"}}/>
        <col style={{width:"12px"}}/>
        <col style={{width:"12px"}}/>
        <col style={{width:"6px"}}/>
        <col style={{width:"6px"}}/>
        <col style={{width:"10px"}}/>
        <col style={{width:"10px"}}/>
        <col style={{width:"10px"}}/>
        <col style={{width:"10px"}}/>
        </colgroup>
        <TableHead>
          
            <TableRow>
              
              <TableCell
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "center",
                  border:'none',
                }}
              >

                <GlobalFilter 
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <Button style={{height:'56px',marginLeft:'15px'}} variant="contained" color="secondary" onClick={()=>{resetFilters()}}>Wyczyść filtry</Button>

              </TableCell>
             
            </TableRow> 
          
          {headerGroups.map((headerGroup) => (
            <TableRow style={{backgroundColor: '#0750b5'}} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell className={classes.root}
                  
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span style={{
                        fontSize: '1.12rem',
                        fontFamily: 'Arial',
                        width: '100%',
                        textAlign: 'center',
                        display: 'block',
                        color:'white',
                        paddingBottom:'16px',
                        paddingTop:'16px',
                  }}
                  >{column.render("Header")}
                    {/* Render the columns filter UI */}
                      {checkSort(column)}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
          ))}
          {headerGroups.map((headerGroup) => (
            <TableRow style={{backgroundColor:'#e8e8e8',}} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </TableCell>
                ))}
              </TableRow>
          ))}
        </TableHead>

        <TableBody style={{backgroundColor:'white'}} {...getTableBodyProps()}>
          {!isLoading?page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow className="tabRow" style={{cursor:"auto"}}  disabled={!canPreviousPage} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                    if(cell.column.id==="statusId"){

                        let color=null
                        color=cell.row.original.color
                     
                        return (
                          <TableCell style={{
                            borderRight:'1px solid rgba(224, 224, 224, 1)',
                            fontSize:'1.1rem',
                            fontFamily:'Arial',
                            textAlign:'center',
                            backgroundColor:color,
                          }} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        )
                    }else if(cell.column.id==="serviceId"){
                        return (
                          <TableCell style={{
                            borderRight:'1px solid rgba(224, 224, 224, 1)',
                            fontSize:'1.1rem',
                            fontFamily:'Arial',
                            textAlign:'center',
                          }} {...cell.getCellProps()}>
                           
                            <Button 
                            variant="outlined" 
                            onClick={()=>{redirectService(cell.row.original)}} 
                            style={{fontSize:"1.2rem",color:"#3f51b5"}}>
                              {cell.render('Cell')}
                            </Button>
                          
    
                          </TableCell>
                        )
                      }else if(cell.column.id==="orderId"){
                        return (
                          <TableCell style={{
                            borderRight:'1px solid rgba(224, 224, 224, 1)',
                            fontSize:'1.1rem',
                            fontFamily:'Arial',
                            textAlign:'center',
                          }} {...cell.getCellProps()}>
                           
                            <Button 
                            onClick={()=>{redirectOrder(cell.row.original.orderId)}} 
                            variant="outlined" 
                            style={{fontSize:"1.2rem",color:"#3f51b5"}}>
                              {cell.render('Cell')}
                            </Button>

                          </TableCell>
                        )
                      }else if(cell.column.id==="serviceInfoId"){
                        return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              backgroundColor:'#eaf3ff',
                              fontWeight:'bold'
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                      }else if(cell.column.id==="price"){
                        return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              fontWeight:'bold'
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                      }else if(cell.column.id==="paidAmount"){
                        return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              color:"green",
                              fontWeight:'bold'
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                      }else if(cell.column.id==="payingUserId"){
                        console.log(cell)
                        if(cell.row.original.payingUserId===null){
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              color:'red',
                              backgroundColor:'#ffdbdb',
                              fontWeight:'bold'
                            }} {...cell.getCellProps()}>
                              ---
                            </TableCell>
                          )
                        }else{
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              backgroundColor:"#d2ffc4",
                              fontWeight:'bold',
                              color:'#0c540c'
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                        }
                        
                      }else if(cell.column.id==="paidDate"){
                        console.log(cell)
                        if(cell.row.original.paidDate===null){
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              color:'red',
                              backgroundColor:'#ffdbdb',
                              fontWeight:'bold'
                            }} {...cell.getCellProps()}>
                              ---
                            </TableCell>
                          )
                        }else{
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              backgroundColor:"#d2ffc4",
                              fontWeight:'bold',
                              color:'#0c540c',
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                        }
                        
                      }else if(cell.column.id==="endingUserId"){
                        console.log(cell)
                        if(cell.row.original.endingUserId===null){
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              color:'red',
                              backgroundColor:'#ffdbdb',
                              fontWeight:'bold'
                            }} {...cell.getCellProps()}>
                              ---
                            </TableCell>
                          )
                        }else{
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              backgroundColor:"#d2ffc4",
                              fontWeight:'bold',
                              color:'#0c540c'
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                        }
                        
                      }else if(cell.column.id==="endDate"){
                        console.log(cell)
                        if(cell.row.original.endDate===null){
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              color:'red',
                              backgroundColor:'#ffdbdb',
                              fontWeight:'bold'
                            }} {...cell.getCellProps()}>
                              ---
                            </TableCell>
                          )
                        }else{
                          return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                              backgroundColor:"#d2ffc4",
                              fontWeight:'bold',
                              color:'#0c540c',
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                        }
                        
                      }else{
                        return (
                            <TableCell style={{
                              borderRight:'1px solid rgba(224, 224, 224, 1)',
                              fontSize:'1.1rem',
                              fontFamily:'Arial',
                              textAlign:'center',
                            }} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          )
                      }
                 
                })}
              </TableRow>
            );
          }):
              <TableRow>
            <TableCell 
              colSpan={visibleColumns.length}
              style={{
                textAlign: "center"
              }}
            >
              <CircularProgress color="secondary" />
            </TableCell>
          </TableRow>
          }
         
        </TableBody>
        
      </MaUTable>
      
      <div className="paginationContainer">
        
        <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <SkipPreviousIcon  />
        </IconButton>
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateNextIcon style={{transform:'rotateY(-180deg)'}}/>
        </IconButton>
        <span>
          Strona {' '}
          <strong>
            {pageIndex + 1} z {pageOptions.length}
          </strong>{' '}
        </span>
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNextIcon  />
        </IconButton>
        <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <SkipNextIcon  />
        </IconButton>
       
        <Select className="pageSize"

          variant="outlined"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 30, 50, 100, 200].map(pageSize => (
            <MenuItem key={pageSize} value={pageSize}>
              Pokaż {pageSize}
            </MenuItem>
          ))}
        </Select>
      </div>
      </MuiThemeProvider>
      </Grid>
    </Grid>
    </div>
    
  );
  
})



const AllServices = connect(
  state => ({
      jwt: state.log.jwt,
  })
  )(({jwt})=>{

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "serviceId",
        id:"serviceId",
      },
      {
        Header: "Kod zlecenia",
        accessor: "orderCode",
        id:"orderId",
      },
      {
        Header: "Usługa",
        accessor: "serviceName",
        id: "serviceInfoId",
        //Filter: SelectColumnFilter,
      },
      {
        Header: "Inwestor",
        accessor: "customerName",
        id: "customerName",
      },
      {
        Header: "Cena",
        accessor: "price",
        id: "price"
      },
      {
        Header: "Zapłacono",
        accessor: "paidAmount",
        id: "paidAmount"
      },
      {
        Header: "Płacący",
        accessor: "payingUserName",
        id: "payingUserId",
        //Filter: SelectColumnFilter,
      },
      {
        Header: "Zapłacono",
        accessor: "paidDate",
        id:"paidDate",
      },
      {
        Header: "Kończący",
        accessor: "endingUserName",
        id: "endingUserId",
        //Filter: SelectColumnFilter,
      },
      {
        Header: "Zakończono",
        accessor: "endDate",
        id:"endDate",
      },
      
    ],
    []
  );
  
  //const [isUpdating] = useState(true);
  const history = useHistory()
  const location = useLocation()
  const [refresh,setRefresh] = useState(0)
  const [data,setServices] = useState([]);
  const [isLoading,setIsLoading] = useState(true);
  const token="Bearer ".concat(jwt)

  useEffect(()=>{
    if(location.state===undefined||location.state===null){
    }else{
      handleChange({
        orderId:location.state.orderId,
        serviceId:location.state.serviceId,
        serviceInfoId:location.state.serviceInfoId
      })
    }
    
  },[location])

  useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/services/settlement`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        setServices(json);
        setIsLoading(false);
       // setIsLoadingIcon(false);
      })
      .catch(error => {
        console.log(error);
      })
    }
    if (isLoading||refresh>0) {
      // if the result is not ready so you make the axios call
      //console.log("wyłączony stan ",skipPageResetRef)
      getData();
      setRefresh(0);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refresh])

  const [orderId, setOrderId] = useState()
  const [serviceId, setServiceId] = useState()
  const [serviceInfoId, setServiceInfoId] = useState()
  const [conditionsId,setConditionsId] = useState()
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  function handleChange(obj) {
    setOrderId(obj.orderId)
    setServiceId(obj.serviceId)
    setServiceInfoId(obj.serviceInfoId)
    setConditionsId(obj.conditionsId)
    setOpen(true)
  }

  function handleChangeInfos() {
    //setIsLoading(false)
    setRefresh(1+refresh)
  }

  const handleClose = () => {
    setOpen(false)
    setIsLoading(false)
    handleChangeInfos()
    if(location.state!==undefined&&location.state!==null){
      history.push("/conditions")
    }
  };

  return (
    <>
      
      <Table data={data} columns={columns} isLoading={isLoading} onChange={handleChange} />
      <Dialog 
       maxWidth="lg"
       fullWidth={true}
       fullScreen={fullScreen} 
       open={open}
       onClose={handleClose}
      >
        <DialogTitle>Karta Usługi: </DialogTitle>
        <DialogContent className="dialogMid">
          <ConditionsDialog 
          onChange={handleChangeInfos} 
          orderId={orderId}
          serviceId={serviceId}
          serviceInfoId={serviceInfoId}
          conditionsId={conditionsId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zakończ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
})


 export default (AllServices)