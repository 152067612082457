
import { IconButton, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import "./style.css"
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from "notistack";
import { serverUrl } from "../config/serverConfig";
import { connect } from "react-redux";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const EmailInput = (props) => {
    const {email} = props
    const {emailId} = props
    const {counter} = props
    const {orderId} = props
    const [emailVal,setEmailVal] = useState(email)
    const [baseVal,setBaseVal] = useState(email)
    const { enqueueSnackbar } = useSnackbar()
    const token='Bearer '.concat(props.jwt)
    const [short,setShort] = useState(false)
    const [quantityVal,setQuantityVal] = useState(100)

    useEffect(() =>{
        setEmailVal(email)
        setBaseVal(email)
        setQuantityVal(100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props])

    async function deleteEmail(emailId) {
        await fetch(`${serverUrl}/emails?emailId=${emailId}&orderId=${orderId}`,
        {method:'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie usunięto email",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Email nie został usunięty",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Email nie został usunięty",{variant: 'error',autoHideDuration: 2000})
        })
      }

    async function changeEmail(emailId,email) {
        await fetch(`${serverUrl}/emails?emailId=${emailId}&email=${email}&orderId=${orderId}`,
        {method:'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie edytowano email",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Email nie został edytowany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Email nie został edytowany",{variant: 'error',autoHideDuration: 2000})
        })
      }

      const emailValid = (email) =>{
        let ats=0
        for (let x = 0; x < email.length; x++) {
          if(email.charAt(x)==="@"){ats++}
        }
        if(ats===1&&email.charAt(0)!=="@"){
          let afterAt = email.split('@')[1]
          let dots=0
          for (let x = 0; x < afterAt.length; x++) {
            if(afterAt.charAt(x)==="."){dots++}
            //console.log(afterAt.charAt(x))
          }
          //console.log(dots,afterAt.charAt(0),afterAt.charAt(afterAt.length-1))
          if(dots===1&&afterAt.charAt(0)!=="."&&afterAt.charAt(afterAt.length-1)!=="."){
            return true
          }else{
            enqueueSnackbar("Email musi zawierać jedną kropkę NIEbezpośrednio po małpie @",{variant: 'warning',autoHideDuration: 4000})
            return false
          }
        }else{
          enqueueSnackbar("Email musi zawierać jedną małpę @ i nie może ona znajdować się na początku",{variant: 'warning',autoHideDuration: 4000})
          return false
        }
      }

      const handleDeleteEmail = () => {
        if(1<quantityVal){
          deleteEmail(emailId)
        }
      }

      const handleChangeEmail = () => {
        if(emailValid(emailVal)===true&&emailVal.length>=4&&emailVal.length<255){
           changeEmail(emailId,encodeURIComponent(emailVal.trim()))
        }
      }

      
      const changeEmailVal = (val) => {
        if(val.length<255){
            setEmailVal(val)
        }
        if(val.length<4){
            setShort(true)
        }else{
            setShort(false)
        }
      }

    return(
        <div style={{marginTop:"30px"}}>
         <div className="iconContainer">
        <AlternateEmailIcon className="icon"/>{counter}
        </div>   
        <TextField
        value={emailVal}
        label="Email"
        variant="outlined"
        type="text"
        className="addressInput"
        inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
        onChange={(e)=>changeEmailVal(e.target.value)}
        />
        <IconButton disabled={emailVal===baseVal} className="saveButton" onClick={()=>{handleChangeEmail()}}>
            <SaveIcon className="saveIcon" style={{color:emailVal===baseVal?"grey":"green"}}/>
        </IconButton>
        <IconButton disabled={1>=quantityVal?true:false} className="saveButton" onClick={()=>{handleDeleteEmail()}}>
            <DeleteForeverIcon className="saveIcon" style={{color:1>=quantityVal?"grey":"red"}}/>
        </IconButton>
        </div>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,null)(EmailInput)