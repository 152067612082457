import { DialogTitle, useMediaQuery, useTheme } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { Dialog } from "@material-ui/core"
import { useEffect, useState } from "react"
import DropZone from "./DropZone"
import FilesList from "./FilesList"


const FileDialog = ({open,refresh,orderId,name,categoryId,close}) => {

    const [openDialog, setOpenDialog] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [refreshTabel,setRefreshTable] = useState(0)

    const handleClose = () => {
        close()
    }

    useEffect(() => {
        setOpenDialog(open)  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const refreshList = () => {
        setRefreshTable(refreshTabel+1)
    }
    

    return (
        <Dialog
            open={openDialog}
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{"Pliki - ".concat(name)}</DialogTitle>
            <DialogContent>
                <FilesList orderId={orderId} categoryId={categoryId} refreshTabel={refreshTabel} refreshIcons={refresh}/>
                <DropZone orderId={orderId} categoryId={categoryId} refresh={refresh} refreshList={refreshList}/>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={()=>handleClose()}>
                    ZAKOŃCZ
                </Button>
            </DialogActions>
        </Dialog>
      );
}

export default FileDialog