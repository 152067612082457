import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverUrl } from "../../config/serverConfig";
import ReclamationHistoryTable from "./ReclamationHistoryTable";

function ReclamationStatus(props) {
  
  const [refresh,setRefresh] = useState(0)
  const { enqueueSnackbar } = useSnackbar();
  const [value,setValue] = useState("");
  const [projectId,setProjectId] = useState()
  const [color,setColor] = useState(props.color);
  const token='Bearer '.concat(props.jwt)
  const [isLoading,setIsLoading] = useState(true)
  const [expanded,setExpanded] = useState(false)

  const color1="red"
  const color2="green"

  useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/orders/${props.orderId}/individual-projects-others-props`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        //console.log(json);
        setIsLoading(false)
        setValue(json.reclamation)
        setProjectId(json.projectId)
        changeColor(json.reclamation)
      })
      .catch(error => {
        
      })
    }
    if (isLoading||refresh>0) {
      getData()
      setRefresh(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  async function changeStatus(value) {
    await fetch(`${serverUrl}/projects/${projectId}/reclamation?reclamation=${value}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie zmieniono status melioracji",{variant: 'success',autoHideDuration: 2000})
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }

  const changeColor = (val) => {
    //console.log(val)
    if(val===true||val==="true"){
      setColor(color2)
    }else{
      setColor(color1)
    }
  }

    function handleSelect(event){
      setValue(event.target.value)
      changeStatus(event.target.value)
      setRefresh(refresh+1)
      changeColor(event.target.value)
      console.log("refresh")
    }

    return(
        <Accordion expanded={expanded} style={{marginBottom:"15px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={()=>{setExpanded(!expanded)}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            
            <span style={{fontSize:'1.3rem',fontFamily:'arial',width:'60%',textAlign:'center',display:'flex',alignItems:'center'}}>
              Melioracja
            </span>
            <Select
            value={isLoading?true:value} 
            className="selectStatus" 
            style={{padding:'5px',backgroundColor:color,fontSize:'1.3rem',fontFamily:'arial',width:'60%',textAlign:'center',color:'black'}} 
            onChange={e=> handleSelect(e)}
            onClick={e=> e.stopPropagation()}
            >
               
              <MenuItem
              value={true}
              style={{backgroundColor:color2,padding:'15px'}} 
              key="true"
              id={1}
              >
                TAK
              </MenuItem>

              <MenuItem
              value={false}
              style={{backgroundColor:color1,padding:'15px'}} 
              key="false"
              id={2}
              >
                NIE
              </MenuItem>
                    
            </Select>
        </AccordionSummary>
        <AccordionDetails>
            <ReclamationHistoryTable orderId={props.orderId} key={refresh}/>
        </AccordionDetails>
        </Accordion>
    )
}

export default connect((state)=>({jwt:state.log.jwt}))(ReclamationStatus)