import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MyEvent from './MyEvent';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { serverUrl } from '../config/serverConfig';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import TextEditor from './TextEditor';
import { useSnackbar } from 'notistack';
import DialogSave from './DialogSave';
import ReportView from './ReportView';
const localizer = momentLocalizer(moment)

const MyReportsCalendar = props => {

    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(props.jwt)
    const [reports,setReports] = useState([])
    const [startDate,setStartDate] = useState(moment([moment().year(),moment().month()]).subtract(7, 'days').format("YYYY-MM-DD HH:mm:ss"))
    const [endDate,setEndDate] = useState(moment().endOf('month').add(7, 'days').format("YYYY-MM-DD HH:mm:ss"))
    const [refresh,setRefresh] = useState(0)
    const [open, setOpen] = useState(false)
    const [openNew, setOpenNew] = useState(false)
    const [openView, setOpenView] = useState(false)
    const [date,setDate] = useState()
    const [isSaved,setIsSaved] = useState(true)
    const [saveDialogOpen,setSaveDialogOpen] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [reportId,setReportId] = useState()
    const { enqueueSnackbar } = useSnackbar()

    const canEditCheck = (date) => {
      const today = moment()
      if(today.isSame(date,'day')){
        console.log('ten sam dzień')
        return true
      }else{
        console.log('inny dzień')
        return false
      }

    }

    const dayReportCheck = (reports,date) =>{
      let isCreated = false

      reports.forEach(report => {
        if(moment(report.start).isSame(moment(date),'day')===true){
          isCreated=true
        }
      })

      if(isCreated===true){
        enqueueSnackbar("Raport na ten dzień już istnieje.",{variant:'warning',autoHideDuration: 3000})
        return false
      }else{
        return true
      }

    }

    const convertEvents = (events) =>{

        let convertedEvents = []

        events.forEach(event => {
            let timestamp = moment.utc(event.date,"YYYY-MM-DD HH:mm:ss",true).local()
            let date = new Date(
                timestamp.year(),
                timestamp.month(),
                timestamp.date())
            let object={
                id:event.reportId,
                title:'Raport',
                allDay:true,
                start:date,
                end:date
            }
            convertedEvents.push(object)
        });
        console.log(convertedEvents)
        return convertedEvents
    }

    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/reports/user-simple?startDate=${startDate}&endDate=${endDate}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            console.log(json);
            console.log(startDate,endDate)
            setReports(convertEvents(json));
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
          })
        }
        if (isLoading||refresh>0) {
          getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[refresh])

    let components = {
        event: MyEvent,
    }

    const handleDayClick = (event) => {
      if(dayReportCheck(reports,event.start)===true){
        if(canEditCheck(event.start)){
          setOpenNew(true)
          setDate(moment(event.start).format("YYYY-MM-DD"))
          console.log(event)
        }else{
          enqueueSnackbar("Możesz dodać raport tylko na dzisiaj.",{variant:'warning',autoHideDuration: 3000})
        }
      }
    }

    const handleEventClick = (event) => {
      if(canEditCheck(event.start)===true){
        setOpen(true)
        setReportId(event.id)
        setDate(moment(event.start).format("YYYY-MM-DD"))
      }else{
        setOpenView(true)
        setReportId(event.id)
        setDate(moment(event.start).format("YYYY-MM-DD"))
      }
    }

    const handleRangeChange = (range) =>{
        setStartDate(moment(range.start).format("YYYY-MM-DD HH:mm:ss"))
        setEndDate(moment(range.end).format("YYYY-MM-DD HH:mm:ss"))
        setRefresh(refresh+1)
    }

    const handleClose = () =>{
        if(isSaved){
          setRefresh(refresh+1)
          setOpen(false)
        }else{
          setSaveDialogOpen(true)
        }
    }

    const handleCloseNew = () =>{
      if(isSaved){
        setRefresh(refresh+1)
        setOpenNew(false)
      }else{
        setSaveDialogOpen(true)
      }
    }

    const handleCloseView = () =>{
      setOpenView(false)
    }

    const isSavedCheck = (isSaved) =>{
      setIsSaved(isSaved)
    }

    const closeDialogSave = (confirmed) =>{
      setSaveDialogOpen(false)
      if(confirmed===true){
        setOpen(false)
        setOpenNew(false)
      }
    }


    return(
        <>
        <div>
            <Calendar
            components={components}
            localizer={localizer}
            events={reports}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 600, maxWidth: 900 }}
            views={['month']}
            onSelectEvent={handleEventClick}
            onSelectSlot={(e)=>handleDayClick(e)}
            selectable={true}
            onRangeChange={handleRangeChange}
            />
        </div>
        <Dialog 
          maxWidth="lg"
          fullWidth={true}
          fullScreen={fullScreen} 
          open={open}
        >
          <DialogTitle>Raport na dzień : 
            <span style={{color:"#0750b5",marginLeft:"15px"}}>{date}</span>
            <span style={{color:"#0750b5",marginLeft:"15px"}}>({moment(date).format("dddd")})</span>
          </DialogTitle>
          <DialogContent className="dialogMid">
            <TextEditor reportId={reportId} newRep={false} date={date} isSaved={isSavedCheck}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Zakończ
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog 
          maxWidth="lg"
          fullWidth={true}
          fullScreen={fullScreen} 
          open={openNew}
        >
          <DialogTitle>Raport na dzień : 
            <span style={{color:"#0750b5",marginLeft:"15px"}}>{date}</span>
            <span style={{color:"#0750b5",marginLeft:"15px"}}>({moment(date).format("dddd")})</span>
          </DialogTitle>
          <DialogContent className="dialogMid">
              <TextEditor reportId={reportId} newRep={true} date={date} isSaved={isSavedCheck}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNew} color="primary">
              Zakończ
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          maxWidth="lg"
          fullWidth={true}
          fullScreen={fullScreen} 
          open={openView}
        >
          <DialogTitle>Raport na dzień : 
            <span style={{color:"#0750b5",marginLeft:"15px"}}>{date}</span>
            <span style={{color:"#0750b5",marginLeft:"15px"}}>({moment(date).format("dddd")})</span>
          </DialogTitle>
          <DialogContent className="dialogMid">
          <ReportView reportId={reportId}></ReportView>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseView} color="primary">
              Zakończ
            </Button>
          </DialogActions>
        </Dialog>

        <DialogSave open={saveDialogOpen} closeDialog={closeDialogSave}/>
      </>
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(MyReportsCalendar)