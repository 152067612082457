import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { serverUrl } from '../config/serverConfig';
import { useEffect, useState } from 'react';
import actions from '../departments/duck/actions';
import UsersTableElement from './UsersTableElement';

const UsersTable = (props) => {
    
    const [users,setUsers] = useState([]);
    const [checked,setChecked] = useState(false)
    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(props.jwt)

    useEffect(()=>{
        //console.log('usery',props.usersList)
        if(props.usersList.length===users.length){
            setChecked(true)
        }else{
            setChecked(false)
        }
    },[props.usersList])

    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/users/simple-users-list-reports`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            setUsers(json)
            //console.log("spr,",props.usersList.length,json.length,props.usersList.length===json.length?true:false,)
            setChecked(props.usersList.length===json.length?true:false)
            //console.log(json)
            setIsLoading(false)
          })
          .catch(error => {
            console.log(error);
          })
        }
          getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleCheckAll = () => {
            if(checked===true){
                props.setNoUsers()
            }else{
                props.setAllUsers(users)
            }
            setChecked(!checked)
    }

    return(
        <TableContainer component={Paper} style={{marginBottom:20}}>
        <Table size="small">
            <TableHead className="servicesTableHead">
                <TableRow>
                    <TableCell align="center" className="headCell">Pracownik</TableCell>
                    <TableCell align="center" className="headCell">
                        <Checkbox 
                        checked={checked}
                        style={{padding:0,color:'white'}}
                        color="primary"
                        onClick={()=>handleCheckAll()}/>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    isLoading===true?<TableRow><TableCell colSpan={4}><CircularProgress/></TableCell></TableRow>
                    :
                    users.map(user=>{
                    /*props.services.forEach(element => {
                        if(element.serviceId===service.serviceInfoId){
                            init={
                                serviceId:element.serviceId,
                                price:element.price,
                                undefinedPrice:element.undefinedPrice,
                                selected:true,
                            }

                        }else{

                        }
                    })*/
                    return(
                        <UsersTableElement
                            user={user} key={user.userId}
                        />
                    )
                    })
                }
                
            </TableBody>
        </Table>
        </TableContainer>
    )
}


const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
        usersList:state.filters.reportsPdf.usersList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setGeodesyOpen: (obj) => dispatch(actions.setGeodesyOpen(obj)),
        setAllUsers: (obj) => dispatch(actions.setAllUsers(obj)),
        setNoUsers: () => dispatch(actions.setNoUsers())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(UsersTable)