import { Paper, Avatar, ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router";
import { store } from "../store"



export default function SmallUserView() {
  const history = useHistory()
  return (
    <ButtonBase onClick={()=>history.push("/user-panel")} style={{width:'100%'}}>
        <Paper style={{width:'100%'}}>
            <div style={{backgroundColor:'#e7e7e7',height:'128px', paddingTop:'12px', textAlign:'center',width:'100%'}}>
                <Avatar src={store.getState().log.user.image} 
                style={{height:'60px',width:'60px',margin:'11px', marginLeft:'auto', marginRight:'auto',fontFamily:'calibri'}}>
                </Avatar>
                <span style={{display:'block',fontSize:'22px'}}>{store.getState().log.user.name}</span>
            </div>
        </Paper>
    </ButtonBase>
  );
}
