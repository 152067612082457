import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, useMediaQuery, useTheme } from "@material-ui/core"
import { useEffect, useState } from "react";
import { connect } from "react-redux"
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ChangeInfo from "./ChangeInfo";
import { serverUrl } from "../config/serverConfig";
import { useHistory } from "react-router";
import GeneratePdfButton from "./GeneratePdfButton";

const InfoTable = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [open,setOpen] = useState(false)
    const {orderId} = props
    const {linkEnabled} = props
    const [emails,setEmails] = useState([])
    const [phoneNumbers,setPhoneNumbers] = useState([])
    const [addresses,setAddresses] = useState([])
    const [orderProps,setOrderProps]=useState({})
    const [isLoading,setIsLoanding] = useState(true)
    const token='Bearer '.concat(props.jwt)
    const [refreshVar,setRefreshVar] = useState(0)
    const history = useHistory()
    
    useEffect(() => {
        async function getData() {
        await Promise.all([ 

            fetch(`${serverUrl}/orders/${orderId}/order-information`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json()),

            fetch(`${serverUrl}/phone-numbers/order?orderId=${orderId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json()),

            fetch(`${serverUrl}/emails/order?orderId=${orderId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json()),

            fetch(`${serverUrl}/addresses/order?orderId=${orderId}`,
            {method:'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                },
            }).then(response => response.json())
        ])
          .then(json => {
            console.log("to jest json: ",json);
            setIsLoanding(false)
           
            setAddresses(json[3])
            setEmails(json[2])
            setOrderProps(json[0])
            setPhoneNumbers(json[1])
            
            
          })
          .catch(error => {
            
          })
        }
        if (isLoading||refreshVar>0) {
          // if the result is not ready so you make the axios call
          getData();
          setRefreshVar(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[refreshVar])


    const handleClose = () => {
        setOpen(false)
    }

    const refresh = () => {
        console.log("będzie refresh")
        setRefreshVar(refreshVar+1)
    }

    const empty = () =>{
        return(<center><span style={{color:"red",fontSize:"1.2rem"}}>--</span></center>)
    }

    const handleRedirect = (orderId) =>{
        if(linkEnabled===true){
            history.push({
                pathname: '/all-orders',
                state: { orderId: orderId }
            })
        }
    }

    const handleRedirectCustomer = (customerId) =>{
        history.push({
            pathname: '/customers',
            state: { customerId: customerId }
        })
    }

    return(
        <>
        <table className="infoTable">
                    
                <tbody>

                        <tr>
                        <td align="center" style={{paddingTop:"0px",paddingBottom:"0px"}} colSpan={2}>
                            <Link 
                            className="infoTableLink"
                            style={{marginTop:"10px",display:"block",float:"left"}}
                            onClick={()=>handleRedirect(orderProps.orderId)}>
                                 {orderProps.orderCode}
                            </Link>
                            <GeneratePdfButton orderId={orderId} orderCode={orderProps.orderCode}/>
                            <Button 
                            style={{color:"#43A047",display:"block",float:"right"}}
                            onClick={()=>setOpen(true)} >
                                <EditRoundedIcon/>
                            </Button>
                        </td>
                        </tr>
                        <tr >
                        <td>Inwestor</td>
                        <td><Link
                            className="infoTableLink"
                            onClick={()=>handleRedirectCustomer(orderProps.customerId)}>
                                {orderProps.customerName}
                            </Link>
                        </td>
                        </tr>
                        <tr >
                        <td>Gmina</td>
                        <td>{orderProps.community}</td>
                        </tr>
                        <tr >
                        <td>Obręb</td>
                        <td>{orderProps.precinct}</td>
                        </tr>
                        <tr >
                        <td>Nr. Działki</td>
                        <td>{orderProps.plotNumber}</td>
                        </tr>
                        <tr >
                        <td>Nazwa projektu</td>
                        <td>{orderProps.projectName===""?empty():orderProps.projectName}</td>
                        </tr>
                        <tr>
                        <td rowSpan={phoneNumbers.length===0?1:phoneNumbers.length}>Telefon</td>
                        <td >{phoneNumbers.length===0?empty():phoneNumbers[0].phoneNumber}</td>
                        </tr>
                            {
                                phoneNumbers.map((item,index)=>{
                                    if(index!==0){
                                        return (<tr key={item.phoneNumberId}><td key={item.phoneNumberId} style={{fontWeight:"normal"}}><span key={item.phoneNumberId}>{item.phoneNumber}</span></td></tr>)
                                    }else{
                                        return null
                                    }
                                })
                            }
                        <tr>
                        <td rowSpan={addresses.length===0?1:addresses.length}>Adres koresp.</td>
                        <td >{addresses.length===0?empty():addresses[0].address}</td>
                        </tr>
                            {   
                                addresses.map((item,index)=>{
                                    if(index!==0){
                                        return (<tr key={item.addressId}><td key={item.addressId} style={{fontWeight:"normal"}}><span key={item.addressId}>{item.address}</span></td></tr>)
                                    }else{
                                        return null
                                    }
                                })
                            }
                        <tr>
                        <td rowSpan={emails.length===0?1:emails.length}>Email</td>
                        <td >{emails.length===0?empty():emails[0].email}</td>
                        </tr>
                            {
                                emails.map((item,index)=>{
                                    if(index!==0){
                                        return (<tr key={item.emailId}><td key={item.emailId} style={{fontWeight:"normal"}}><span key={item.emailId}>{item.email}</span></td></tr>)
                                    }else{
                                        return null
                                    }
                                })
                            }
                </tbody>
            
               </table> 
               <Dialog 
               maxWidth="sm"
               fullWidth={true}
               fullScreen={fullScreen} 
               open={open}
               onClose={handleClose}
              >
                <DialogTitle className="dialogHeader">Edycja danych w zleceniu - {orderProps.orderCode}</DialogTitle>
                <DialogContent className="dialogMid">
                  <ChangeInfo orderId={orderId} emails={emails} phoneNumbers={phoneNumbers} addresses={addresses} orderProps={orderProps} refresh={()=>refresh()}/>
                </DialogContent>
                <DialogActions className="dialogFooter">
                  <Button onClick={handleClose} color="primary">
                    Zakończ
                  </Button>
                </DialogActions>
              </Dialog>
              </>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(InfoTable)