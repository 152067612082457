import { Checkbox, TableCell, TableRow, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { connect } from "react-redux";
import actions from "../duck/actions";
import { useIsMount } from "../../departments/mountHook";

const ServiceRowGeol = (props) => {


    const [selected,setSelected] = useState(props.init.selected)
    const [priceUndefined,setPriceUndefined] = useState(props.init.undefinedPrice)
    const [price,setPrice] = useState(props.init.price)
    const {serviceInfoId} = props
    const {serviceName} = props
    const isMount = useIsMount();
    useEffect(()=>{
        if(!isMount){
            if(selected===false){
                props.removeService(serviceInfoId)
                setPriceUndefined(false)
                setPrice("")
            }else{
                props.addService({serviceId:serviceInfoId,serviceName:serviceName})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selected])

    useEffect(()=>{
        if(!isMount){
            const obj={
                price:price,
                undefinedPrice:priceUndefined,
                serviceId:serviceInfoId,
                serviceName:serviceName,
            }
            props.changeService(obj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[price,priceUndefined])

    const select = () => {
        setSelected(!selected)
    }

    const undefinedChange = () => {
        setPriceUndefined(!priceUndefined)
        setPrice("")
    }

    const changePrice = (price) => {
        setPrice(price)
        setPriceUndefined(false)
    }

    return(
        <TableRow selected={selected}>

            <TableCell align="center">
                <Checkbox checked={selected} onChange={()=>{select()}}/>
            </TableCell>

            <TableCell>
                {serviceName}
            </TableCell>

            <TableCell align="center">
                <TextField 
                disabled={selected===true?false:true}
                type="number" 
                value={price} 
                onChange={(e) => {changePrice(e.target.value)}}/>
            </TableCell>

            <TableCell align="center">
                <Checkbox 
                checked={priceUndefined}
                disabled={selected===true?false:true}
                color="primary" 
                icon={<HelpOutlineIcon/>} 
                onChange={()=>undefinedChange()}/>
            </TableCell>
        </TableRow>
    )
}

const mapDispatchToProps = (dispatch) => {
    return{
        addService: (obj) => dispatch(actions.addServiceGeol(obj)),
        removeService: (obj) => dispatch(actions.removeServiceGeol(obj)),
        changeService: (obj) => dispatch(actions.changeServiceGeol(obj)),
    }
}

const mapStateToProps = (state) =>{
    return{
        services:state.newOrder.servicesGeol,
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(ServiceRowGeol)