import { TableHead, useMediaQuery, useTheme } from "@material-ui/core"
import { TableBody } from "@material-ui/core"
import { TableRow } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { Dialog } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { TableCell } from "@material-ui/core"
import { TableContainer } from "@material-ui/core"
import { Table } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import convertToLocal from "../convertToLocal"
import NewCost from "./NewCost"
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from "@material-ui/core"
import { useSnackbar } from "notistack"
import DeleteDialog from "./DeleteDialog"
import EditDialog from "./EditDialog"


const sum = (costs) =>{
    let sum=0
    costs.forEach(row => {
        sum+=row.price
    });
    return sum
}

const OrdersCosts = ({orderId,jwt}) => {

    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    const [costs,setCosts] = useState([]) 
    const [deleteId,setDeleteId] = useState()
    const [open,setOpen] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [refresh,setRefresh] = useState(0)
    const { enqueueSnackbar } = useSnackbar() 
    const [deleteDialogOpen,setDeleteDialogOpen] = useState(false)
    const [editDialogOpen,setEditDialogOpen] = useState(false)
    const [cost,setCost] = useState()

    useEffect(() => {
        async function getData() {
            await fetch(`${serverUrl}/costs?orderId=${orderId}`,
            {method:'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                },
            })
            .then(response => response.json())
            .then(json => {
              console.log(json);
              setCosts(json);
              setIsLoading(false);
            })
            .catch(error => {
              console.log(error);
            })
        }
        if (isLoading||refresh>0) {getData()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const handleClose = () => {
        setOpen(false)
        setRefresh(refresh+1)
    }

    const handleDelete = async(costId) =>{
        await fetch(`${serverUrl}/costs/${costId}?orderId=${orderId}`,
        {method:'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
            setRefresh(refresh+1)
            console.log(response);
            if(response.status===200){
                enqueueSnackbar("Pomyslnie usunięto koszt",{variant: 'success',autoHideDuration: 2000})
            }else{
                enqueueSnackbar("UPS! Koszt nie został usunięty, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
            }
        })
        .catch(error => {
            enqueueSnackbar("UPS! Koszt nie został usunięty, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
            console.log(error);
        })
    }

    const handleEdit = (cost) => {
        setCost(cost)
        setEditDialogOpen(true)
    }

    const openDeleteDialog = (costId) => {
        setDeleteDialogOpen(true)
        setDeleteId(costId)
    }

    const closeDialogDelete = (confirmed) => {
        console.log(confirmed)
        if(confirmed===true){
            handleDelete(deleteId)
            setDeleteDialogOpen(false)
        }else{
            setDeleteDialogOpen(false)
        }
    }

    const handleCloseEdit = () => {
        setEditDialogOpen(false)
    }

    return(
        <>
        <TableContainer component={Paper}>
        <Table>
            <colgroup>
                <col style={{width:'2px'}}/>
                <col style={{width:'7px'}}/>
                <col style={{width:'8px'}}/>
                <col style={{width:'16px'}}/>
                <col style={{width:'6px'}}/>
                <col style={{width:'8px'}}/>
            </colgroup>
            <TableHead>
            <TableRow>
                <TableCell style={{backgroundColor:'#0750b5',fontSize:'1.1rem',color:'white'}}></TableCell>
                <TableCell style={{backgroundColor:'#0750b5',fontSize:'1.1rem',color:'white'}} align="left">Data</TableCell>
                <TableCell style={{backgroundColor:'#0750b5',fontSize:'1.1rem',color:'white'}} align="left">Użytkownik</TableCell>
                <TableCell style={{backgroundColor:'#0750b5',fontSize:'1.1rem',color:'white'}} align="left">Nazwa</TableCell>
                <TableCell style={{backgroundColor:'#0750b5',fontSize:'1.1rem',color:'white'}} align="center">Cena</TableCell>
                <TableCell style={{backgroundColor:'#0750b5',fontSize:'1.1rem',color:'white'}} align="center"></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {isLoading===true?<TableRow><TableCell colSpan={5}><CircularProgress/></TableCell></TableRow>
            :
            costs.length===0?<TableRow><TableCell colSpan={5}><Typography align='center'>Brak kosztów w tym zleceniu.</Typography></TableCell></TableRow>
            :
            costs.map((row,index) => (
                <TableRow key={row.costId}>
                <TableCell component="th" scope="row" style={{fontSize:'1.1rem'}}>{index+1}</TableCell>
                <TableCell align="left" style={{fontSize:'1.1rem'}}>{convertToLocal(row.entryDate).substr(0,16)}</TableCell>
                <TableCell align="left" style={{fontSize:'1.1rem'}}>{row.name+" "+row.surname}</TableCell>
                <TableCell align="left" style={{fontSize:'1.1rem'}}>{row.description}</TableCell>
                <TableCell align="center" style={{fontSize:'1.1rem'}}>{row.price}</TableCell>
                <TableCell align="center" style={{fontSize:'1.1rem'}}>
                    <IconButton onClick={()=>handleEdit(row)}>
                        <EditIcon style={{fontSize:'1.8rem',color:'#0750b5'}}/>
                    </IconButton>
                    <IconButton onClick={()=>openDeleteDialog(row.costId)}>
                        <DeleteForeverIcon style={{fontSize:'1.8rem',color:'red'}}/>
                    </IconButton>
                </TableCell>
                </TableRow>
            ))}
            <TableRow>
                <TableCell colSpan={4} style={{fontSize:'1.1rem'}}>
                    <Button 
                    size='large' 
                    style={{backgroundColor:'#43a047',color:'white'}} 
                    fullWidth={true}
                    onClick={()=>setOpen(true)}>dodaj nowy koszt</Button>
                </TableCell>
                <TableCell align="center" style={{fontSize:'1.1rem'}}>
                    <Typography style={{fontWeight:'bold',fontSize:'1.2rem'}}>{sum(costs)}</Typography>
                </TableCell>
            </TableRow>
            </TableBody>
        </Table>
        </TableContainer>

        <Dialog 
        maxWidth="md"
        fullWidth={true}
        fullScreen={fullScreen} 
        open={open}
        >
        <DialogTitle>Dodaj koszt:</DialogTitle>
        <DialogContent className="dialogMid">
        <NewCost close={handleClose} orderId={orderId}/>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">
            Zakończ
        </Button>
        </DialogActions>
        </Dialog>

        <DeleteDialog open={deleteDialogOpen} costId={deleteId} closeDialog={closeDialogDelete}/>
        <EditDialog open={editDialogOpen} close={handleCloseEdit} orderId={orderId} cost={cost} refresh={()=>{setRefresh(refresh+1)}}/>
        </>
    )
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(OrdersCosts)