import { ListItem } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { List } from "@material-ui/core"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { serverUrl } from "../config/serverConfig"



const OrdersList = ({jwt,customerId}) => {
    
    const [isLoading,setIsLoading] = useState(true)
    const token="Bearer ".concat(jwt)
    const [orders,setOrders] = useState([])
    const history = useHistory()

    const redirect = (orderId) => {
        history.push({
            pathname: '/all-orders',
            state: { orderId: orderId }
        })
    }

    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/customers/orders?customerId=${customerId}`,
          {method:'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
          })
          .then(response => response.json())
          .then(json => {
            console.log(json);
            setOrders(json);
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
          })
        }
        if (isLoading) {
          getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    return(
        <List component={Paper}>
            {isLoading?
            <ListItem>
                <CircularProgress></CircularProgress>
            </ListItem>
            :
            orders.map((order,index) =>(
                <ListItem key={index}>
                    <div>
                    <span>Dodano: </span>
                    <span>{order.entryDate.substr(0,10)}</span>
                    <Button 
                    variant="outlined" 
                    size="large"
                    color="primary"
                    style={{marginBottom:'6px'}}
                    key={index}
                    onClick={()=>redirect(order.orderId)}
                    fullWidth={true}>{order.orderCode}</Button>
                    </div>
                </ListItem>
            ))
            }
        </List>
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(OrdersList)