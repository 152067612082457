//sanitary installations
const ADD_FILTER_SANIT = 'ADD_FILTER_SANIT'
const DELETE_FILTER_SANIT = 'DELETE_FILTER_SANIT'
const RESET_FILTERS_SANIT = 'RESET_FILTERS_SANIT'
const ADD_GLOBAL_FILTER_SANIT = 'ADD_GLOBAL_FILTER_SANIT'
const ADD_SORTED_COLUMN_SANIT = 'ADD_SORTED_COLUMN_SANIT'
const SET_PAGE_SANIT = 'SET_PAGE_SANIT'
const SET_ON_PAGE_SANIT = 'SET_ON_PAGE_SANIT'

//typical projects
const ADD_FILTER_TYP = 'ADD_FILTER_TYP'
const DELETE_FILTER_TYP = 'DELETE_FILTER_TYP'
const RESET_FILTERS_TYP = 'RESET_FILTERS_TYP'
const ADD_GLOBAL_FILTER_TYP = 'ADD_GLOBAL_FILTER_TYP'
const ADD_SORTED_COLUMN_TYP = 'ADD_SORTED_COLUMN_TYP'
const SET_PAGE_TYP = 'SET_PAGE_TYP'
const SET_ON_PAGE_TYP = 'SET_ON_PAGE_TYP'

//individual projects
const ADD_FILTER_IND = 'ADD_FILTER_IND'
const DELETE_FILTER_IND = 'DELETE_FILTER_IND'
const RESET_FILTERS_IND = 'RESET_FILTERS_IND'
const ADD_GLOBAL_FILTER_IND = 'ADD_GLOBAL_FILTER_IND'
const ADD_SORTED_COLUMN_IND = 'ADD_SORTED_COLUMN_IND'
const SET_PAGE_IND = 'SET_PAGE_IND'
const SET_ON_PAGE_IND = 'SET_ON_PAGE_IND'

//geodesy
const ADD_FILTER_GEODESY = 'ADD_FILTER_GEODESY'
const DELETE_FILTER_GEODESY = 'DELETE_FILTER_GEODESY'
const RESET_FILTERS_GEODESY = 'RESET_FILTERS_GEODESY'
const ADD_GLOBAL_FILTER_GEODESY = 'ADD_GLOBAL_FILTER_GEODESY'
const ADD_SORTED_COLUMN_GEODESY = 'ADD_SORTED_COLUMN_GEODESY'
const SET_PAGE_GEODESY = 'SET_PAGE_GEODESY'
const SET_ON_PAGE_GEODESY = 'SET_ON_PAGE_GEODESY'

//geology
const ADD_FILTER_GEOLOGY = 'ADD_FILTER_GEOLOGY'
const DELETE_FILTER_GEOLOGY = 'DELETE_FILTER_GEOLOGY'
const RESET_FILTERS_GEOLOGY = 'RESET_FILTERS_GEOLOGY'
const ADD_GLOBAL_FILTER_GEOLOGY = 'ADD_GLOBAL_FILTER_GEOLOGY'
const ADD_SORTED_COLUMN_GEOLOGY = 'ADD_SORTED_COLUMN_GEOLOGY'
const SET_PAGE_GEOLOGY = 'SET_PAGE_GEOLOGY'
const SET_ON_PAGE_GEOLOGY = 'SET_ON_PAGE_GEOLOGY'

//conditions
const ADD_FILTER_COND = 'ADD_FILTER_COND'
const DELETE_FILTER_COND = 'DELETE_FILTER_COND'
const RESET_FILTERS_COND = 'RESET_FILTERS_COND'
const ADD_GLOBAL_FILTER_COND = 'ADD_GLOBAL_FILTER_COND'
const ADD_SORTED_COLUMN_COND = 'ADD_SORTED_COLUMN_COND'
const SET_PAGE_COND = 'SET_PAGE_COND'
const SET_ON_PAGE_COND = 'SET_ON_PAGE_COND'

//allOrders
const ADD_FILTER_ALL_ORDERS = 'ADD_FILTER_ALL_ORDERS'
const DELETE_FILTER_ALL_ORDERS = 'DELETE_FILTER_ALL_ORDERS'
const RESET_FILTERS_ALL_ORDERS = 'RESET_FILTERS_ALL_ORDERS'
const ADD_GLOBAL_FILTER_ALL_ORDERS = 'ADD_GLOBAL_FILTER_ALL_ORDERS'
const ADD_SORTED_COLUMN_ALL_ORDERS = 'ADD_SORTED_COLUMN_ALL_ORDERS'
const SET_PAGE_ALL_ORDERS = 'SET_PAGE_ALL_ORDERS'
const SET_ON_PAGE_ALL_ORDERS = 'SET_ON_PAGE_ALL_ORDERS'

//allServices
const ADD_FILTER_ALL_SERVICES = 'ADD_FILTER_ALL_SERVICES'
const DELETE_FILTER_ALL_SERVICES = 'DELETE_FILTER_ALL_SERVICES'
const RESET_FILTERS_ALL_SERVICES = 'RESET_FILTERS_ALL_SERVICES'
const ADD_GLOBAL_FILTER_ALL_SERVICES = 'ADD_GLOBAL_FILTER_ALL_SERVICES'
const ADD_SORTED_COLUMN_ALL_SERVICES = 'ADD_SORTED_COLUMN_ALL_SERVICES'
const SET_PAGE_ALL_SERVICES = 'SET_PAGE_ALL_SERVICES'
const SET_ON_PAGE_ALL_SERVICES = 'SET_ON_PAGE_ALL_SERVICES'

//customers
const ADD_FILTER_CUSTOMERS = 'ADD_FILTER_CUSTOMERS'
const DELETE_FILTER_CUSTOMERS = 'DELETE_FILTER_CUSTOMERS'
const RESET_FILTERS_CUSTOMERS = 'RESET_FILTERS_CUSTOMERS'
const ADD_GLOBAL_FILTER_CUSTOMERS = 'ADD_GLOBAL_FILTER_CUSTOMERS'
const ADD_SORTED_COLUMN_CUSTOMERS = 'ADD_SORTED_COLUMN_CUSTOMERS'
const SET_PAGE_CUSTOMERS = 'SET_PAGE_CUSTOMERS'
const SET_ON_PAGE_CUSTOMERS = 'SET_ON_PAGE_CUSTOMERS'

//history
const ADD_FILTER_HISTORY = 'ADD_FILTER_HISTORY'
const DELETE_FILTER_HISTORY = 'DELETE_FILTER_HISTORY'
const RESET_FILTERS_HISTORY = 'RESET_FILTERS_HISTORY'
const ADD_GLOBAL_FILTER_HISTORY = 'ADD_GLOBAL_FILTER_HISTORY'
const ADD_SORTED_COLUMN_HISTORY = 'ADD_SORTED_COLUMN_HISTORY'
const SET_PAGE_HISTORY = 'SET_PAGE_HISTORY'
const SET_ON_PAGE_HISTORY = 'SET_ON_PAGE_HISTORY'

//costs
const ADD_FILTER_COSTS = 'ADD_FILTER_COSTS'
const DELETE_FILTER_COSTS = 'DELETE_FILTER_COSTS'
const RESET_FILTERS_COSTS = 'RESET_FILTERS_COSTS'
const ADD_GLOBAL_FILTER_COSTS = 'ADD_GLOBAL_FILTER_COSTS'
const ADD_SORTED_COLUMN_COSTS = 'ADD_SORTED_COLUMN_COSTS'
const SET_PAGE_COSTS = 'SET_PAGE_COSTS'
const SET_ON_PAGE_COSTS = 'SET_ON_PAGE_COSTS'

//reportsPdf
const ADD_USER = 'ADD_USER'
const REMOVE_USER = 'REMOVE_USER'
const SET_START_DATE = 'SET_START_DATE'
const SET_END_DATE = 'SET_END_DATE'
const SET_ALL_USERS = 'SET_ALL_USERS'
const SET_NO_USERS = 'SET_NO_USERS'
const SET_START = 'SET_START'
const SET_END = 'SET_END'
const RESET_REPORTS_PDF = 'RESET_REPORTS_PDF'

const types = {

    //sanitary installations
    ADD_FILTER_SANIT,
    DELETE_FILTER_SANIT,
    RESET_FILTERS_SANIT,
    ADD_GLOBAL_FILTER_SANIT,
    ADD_SORTED_COLUMN_SANIT,
    SET_PAGE_SANIT,
    SET_ON_PAGE_SANIT,

    //typical projects
    ADD_FILTER_TYP,
    DELETE_FILTER_TYP,
    RESET_FILTERS_TYP,
    ADD_GLOBAL_FILTER_TYP,
    ADD_SORTED_COLUMN_TYP,
    SET_PAGE_TYP,
    SET_ON_PAGE_TYP,

    //individual projects
    ADD_FILTER_IND,
    DELETE_FILTER_IND,
    RESET_FILTERS_IND,
    ADD_GLOBAL_FILTER_IND,
    ADD_SORTED_COLUMN_IND,
    SET_PAGE_IND,
    SET_ON_PAGE_IND,

    //geodesy
    ADD_FILTER_GEODESY,
    DELETE_FILTER_GEODESY,
    RESET_FILTERS_GEODESY,
    ADD_GLOBAL_FILTER_GEODESY,
    ADD_SORTED_COLUMN_GEODESY,
    SET_PAGE_GEODESY,
    SET_ON_PAGE_GEODESY,

    //geology
    ADD_FILTER_GEOLOGY,
    DELETE_FILTER_GEOLOGY,
    RESET_FILTERS_GEOLOGY,
    ADD_GLOBAL_FILTER_GEOLOGY,
    ADD_SORTED_COLUMN_GEOLOGY,
    SET_PAGE_GEOLOGY,
    SET_ON_PAGE_GEOLOGY,

    //conditions
    ADD_FILTER_COND,
    DELETE_FILTER_COND,
    RESET_FILTERS_COND,
    ADD_GLOBAL_FILTER_COND,
    ADD_SORTED_COLUMN_COND,
    SET_PAGE_COND,
    SET_ON_PAGE_COND,

    //allOrders
    ADD_FILTER_ALL_ORDERS,
    DELETE_FILTER_ALL_ORDERS,
    RESET_FILTERS_ALL_ORDERS,
    ADD_GLOBAL_FILTER_ALL_ORDERS,
    ADD_SORTED_COLUMN_ALL_ORDERS,
    SET_PAGE_ALL_ORDERS,
    SET_ON_PAGE_ALL_ORDERS,

    //allOrders
    ADD_FILTER_ALL_SERVICES,
    DELETE_FILTER_ALL_SERVICES,
    RESET_FILTERS_ALL_SERVICES,
    ADD_GLOBAL_FILTER_ALL_SERVICES,
    ADD_SORTED_COLUMN_ALL_SERVICES,
    SET_PAGE_ALL_SERVICES,
    SET_ON_PAGE_ALL_SERVICES,

    //history
    ADD_FILTER_HISTORY,
    DELETE_FILTER_HISTORY,
    RESET_FILTERS_HISTORY,
    ADD_GLOBAL_FILTER_HISTORY,
    ADD_SORTED_COLUMN_HISTORY,
    SET_PAGE_HISTORY,
    SET_ON_PAGE_HISTORY,

    //history
    ADD_FILTER_CUSTOMERS,
    DELETE_FILTER_CUSTOMERS,
    RESET_FILTERS_CUSTOMERS,
    ADD_GLOBAL_FILTER_CUSTOMERS,
    ADD_SORTED_COLUMN_CUSTOMERS,
    SET_PAGE_CUSTOMERS,
    SET_ON_PAGE_CUSTOMERS,

    //costs
    ADD_FILTER_COSTS,
    DELETE_FILTER_COSTS,
    RESET_FILTERS_COSTS,
    ADD_GLOBAL_FILTER_COSTS,
    ADD_SORTED_COLUMN_COSTS,
    SET_PAGE_COSTS,
    SET_ON_PAGE_COSTS,

    //reportsPdf
    ADD_USER,
    REMOVE_USER,
    SET_START_DATE,
    SET_END_DATE,
    SET_ALL_USERS,
    SET_NO_USERS,
    SET_START,
    SET_END,
    RESET_REPORTS_PDF
}

export default types