import types from './types';

const setCustomerId = item => ({
    type: types.SET_CUSTOMER_ID, item
})
const setCustomerName = item => ({
    type: types.SET_CUSTOMER_NAME, item
})
const setCommunity = item => ({
    type: types.SET_COMMUNITY, item
})
const setCommunityId = item => ({
    type: types.SET_COMMUNITY_ID, item
})
const setPrecinct = item => ({
    type: types.SET_PRECINCT, item
})
const setPrecinctId = item => ({
    type: types.SET_PRECINCT_ID, item
})
const setPlotNumber = item => ({
    type: types.SET_PLOT_NUMBER, item
})
const setProjectName = item => ({
    type: types.SET_PROJECT_NAME, item
})
const addPhoneNumber = item => ({
    type: types.ADD_PHONE_NUMBER, item
})
const addEmail = item => ({
    type: types.ADD_EMAIL, item
})
const addAddress = item => ({
    type: types.ADD_ADDRESS, item
})
const removePhoneNumber = item => ({
    type: types.REMOVE_PHONE_NUMBER, item
})
const removeEmail = item => ({
    type: types.REMOVE_EMAIL, item
})
const removeAddress = item => ({
    type: types.REMOVE_ADDRESS, item
})
const setNet = item => ({
    type: types.SET_NET, item
})
const setDate = item => ({
    type: types.SET_DATE, item
})
const addComment = item => ({
    type: types.ADD_COMMENT, item
})
const resetForm = () => ({
    type: types.RESET_FORM
})
const setCustomCustomer = item => ({
    type: types.SET_CUSTOM_CUSTOMER, item
})
const setCustomerSet = item => ({
    type: types.SET_CUSTOMER_SET, item
})
const clearEmails = () => ({
    type: types.CLEAR_EMAILS
})
const clearAddresses = () => ({
    type: types.CLEAR_ADDRESSES
})
const clearPhoneNumbers = () => ({
    type: types.CLEAR_PHONE_NUMBERS
})
const clearNewEmails = () => ({
    type: types.CLEAR_NEW_EMAILS
})
const clearNewAddresses = () => ({
    type: types.CLEAR_NEW_ADDRESSES
})
const clearNewPhoneNumbers = () => ({
    type: types.CLEAR_NEW_PHONE_NUMBERS
})
const addNewPhoneNumber = item => ({
    type: types.ADD_NEW_PHONE_NUMBER, item
})
const addNewEmail = item => ({
    type: types.ADD_NEW_EMAIL, item
})
const addNewAddress = item => ({
    type: types.ADD_NEW_ADDRESS, item
})
const removeNewPhoneNumber = item => ({
    type: types.REMOVE_NEW_PHONE_NUMBER, item
})
const removeNewEmail = item => ({
    type: types.REMOVE_NEW_EMAIL, item
})
const removeNewAddress = item => ({
    type: types.REMOVE_NEW_ADDRESS, item
})
const addServiceGeod = item => ({
    type: types.ADD_SERVICE_GEOD, item
})
const removeServiceGeod = item => ({
    type: types.REMOVE_SERVICE_GEOD, item
})
const changeServiceGeod = item => ({
    type: types.CHANGE_SERVICE_GEOD, item
})
const addServiceIndi = item => ({
    type: types.ADD_SERVICE_INDI, item
})
const removeServiceIndi = item => ({
    type: types.REMOVE_SERVICE_INDI, item
})
const changeServiceIndi = item => ({
    type: types.CHANGE_SERVICE_INDI, item
})
const addServiceTypi = item => ({
    type: types.ADD_SERVICE_TYPI, item
})
const removeServiceTypi = item => ({
    type: types.REMOVE_SERVICE_TYPI, item
})
const changeServiceTypi = item => ({
    type: types.CHANGE_SERVICE_TYPI, item
})
const addServiceCond = item => ({
    type: types.ADD_SERVICE_COND, item
})
const removeServiceCond = item => ({
    type: types.REMOVE_SERVICE_COND, item
})
const changeServiceCond = item => ({
    type: types.CHANGE_SERVICE_COND, item
})
const addServiceSani = item => ({
    type: types.ADD_SERVICE_SANI, item
})
const removeServiceSani = item => ({
    type: types.REMOVE_SERVICE_SANI, item
})
const changeServiceSani = item => ({
    type: types.CHANGE_SERVICE_SANI, item
})
const addServiceGeol = item => ({
    type: types.ADD_SERVICE_GEOL, item
})
const removeServiceGeol = item => ({
    type: types.REMOVE_SERVICE_GEOL, item
})
const changeServiceGeol = item => ({
    type: types.CHANGE_SERVICE_GEOL, item
})
const setStep = item => ({
    type: types.SET_STEP, item
})
const setGeodesyOpen = item => ({
    type: types.SET_GEODESY_OPEN, item
})
const setTypicalProjectsOpen = item => ({
    type: types.SET_TYPICAL_PROJECTS_OPEN, item
})
const setIndividualProjectsOpen = item => ({
    type: types.SET_INDIVIDUAL_PROJECTS_OPEN, item
})
const setConditionsOpen = item => ({
    type: types.SET_CONDITIONS_OPEN, item
})
const setSanitaryInstallationsOpen = item => ({
    type: types.SET_SANITARY_INSTALLATIONS_OPEN, item
})
const setGeologyOpen = item => ({
    type: types.SET_GEOLOGY_OPEN, item
})
const setSupervisorGeod = item => ({
    type: types.SET_SUPERVISOR_GEOD, item
})
const setSupervisorGeol = item => ({
    type: types.SET_SUPERVISOR_GEOL, item
})
const setSupervisorCond = item => ({
    type: types.SET_SUPERVISOR_COND, item
})
const setSupervisorSani = item => ({
    type: types.SET_SUPERVISOR_SANI, item
})
const setSupervisorTypi= item => ({
    type: types.SET_SUPERVISOR_TYPI, item
})
const setSupervisorIndi = item => ({
    type: types.SET_SUPERVISOR_INDI, item
})




const actions = {
    setCustomerId,
    setCustomerName,
    setCommunity,
    setCommunityId,
    setPrecinct,
    setPrecinctId,
    setPlotNumber,
    setProjectName,
    addPhoneNumber,
    addEmail,
    addAddress,
    removePhoneNumber,
    removeEmail,
    removeAddress,
    setNet,
    setDate,
    addComment,
    resetForm,
    setCustomCustomer,
    setCustomerSet,
    clearAddresses,
    clearEmails,
    clearPhoneNumbers,
    clearNewAddresses,
    clearNewEmails,
    clearNewPhoneNumbers,
    addNewAddress,
    addNewEmail,
    addNewPhoneNumber,
    removeNewAddress,
    removeNewEmail,
    removeNewPhoneNumber,
    addServiceGeod,
    removeServiceGeod,
    changeServiceGeod,
    addServiceIndi,
    removeServiceIndi,
    changeServiceIndi,
    addServiceTypi,
    removeServiceTypi,
    changeServiceTypi,
    addServiceSani,
    removeServiceSani,
    changeServiceSani,
    addServiceCond,
    removeServiceCond,
    changeServiceCond,
    addServiceGeol,
    removeServiceGeol,
    changeServiceGeol,
    setStep,
    setGeodesyOpen,
    setGeologyOpen,
    setTypicalProjectsOpen,
    setIndividualProjectsOpen,
    setSanitaryInstallationsOpen,
    setConditionsOpen,
    setSupervisorGeod,
    setSupervisorGeol,
    setSupervisorIndi,
    setSupervisorTypi,
    setSupervisorCond,
    setSupervisorSani
}

export default actions