import moment from "moment";
import { store } from "../store";
import actions from "./duck/actions";
import { logOut } from "./logOut";


const timer = () => {

    
    const getTimeLeft = () => {
        const expDate=store.getState().log.exp
        const time=expDate-75-moment().unix()
        const hours=Math.floor(time/3600)
        const minutes=Math.floor((time-hours*3600)/60)
        const seconds=Math.floor((time-hours*3600-minutes*60))

        return{
            hours:hours,
            minutes:minutes,
            seconds:seconds
        }
    }

    const setTimer = setInterval(() => {
        const timeObject=getTimeLeft()
        if(timeObject.hours<0){
            store.dispatch(logOut())
            clearInterval(setTimer)
        }else{
            store.dispatch(actions.setTimeLeft(timeObject))
        }
    }, 1000);
}

export default timer