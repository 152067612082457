import moment from 'moment';

const securityCheck = (loggedIn,exp) => {

    //console.log("is now: ",moment().unix(), " > ", exp)
    //console.log(loggedIn,moment().unix()*1000)
    if(loggedIn===true&&moment().unix()<exp){
        console.log("jesteś zalogowany a token jest aktywny")
        return true;
    }
    if(loggedIn===false||moment().unix()>exp){
        console.log("token wygasł")
        return false;
    }
}
export default (securityCheck)