import { Button } from "@material-ui/core"
import { useDispatch } from "react-redux"
import actions from "../duck/actions";
import { getYearLeaves } from "../duck/operations";
import YearDialog from "./YearDialog";


const UserYearDialog = () => {

    const dispatch = useDispatch()

    const handleOpen = () => {
        dispatch(actions.setUserYearsOpenDialog(true))
        dispatch(getYearLeaves())
    }

    return(
        <>
        <Button variant="contained" style={{
            backgroundColor: "#0750b5",
            color:"white",
            marginLeft:15,
            marginRight:15,
            marginTop:4,
            marginBottom:4
        }} onClick={()=>handleOpen()}>
            dni w latach
        </Button>
        <YearDialog/>
        </>
    )
}

export default UserYearDialog