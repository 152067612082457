import { useDispatch, useSelector } from "react-redux"
import StatusSelect from "./StatusSelect"
import { changeLeaveStatusAllLeaves } from "../duck/operations"
import actions from "../duck/actions"


const StatusChanger = () => {

    const statusId = useSelector(state => state.leaves.allTableDialogStatusId)
    const leaveId = useSelector(state => state.leaves.allTableDialogId)
    const dispatch = useDispatch()
    const handleStatusChange = (newStatusId) => {
        //console.log("status change")
        dispatch(changeLeaveStatusAllLeaves(newStatusId,leaveId))
        dispatch(actions.setAllTableDialogStatusId(newStatusId))
    }

    return(
        <StatusSelect status={statusId} handleStatusChange={handleStatusChange}/>
    )
}
export default StatusChanger