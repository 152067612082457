import MyReportsCalendar from "./MyReportsCalendar"
import { Tabs,Tab,Container,Paper } from "@material-ui/core"
import { useState } from "react"
import { TabContext, TabPanel } from "@material-ui/lab"
import AllReportsCalendar from "./AllReportsCalendar"
import { RoleService } from "../RoleService"
import Information from "./Information"

const ReportsHome = () => {

    const [value,setValue] = useState(0)
    const handleChange = (event,val) =>{
        setValue(val)
    }
    const roleService = new RoleService()
    return(
        <Container component={Paper} style={{marginTop:'25px',minHeight:'80vh'}}>
            <TabContext value={value}>
                <Tabs
                    
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon tabs example"
                >
                    <Tab  label="Twoje raporty"/>
                    {roleService.hasOneOfRoles(["ADMIN","BOSS"])?<Tab  label="Wszystkie raporty"/>:null}
                </Tabs>
                <TabPanel hidden={value!==0} value={0} index={0} >
                {roleService.hasOneOfRoles(["REPORTS"])?<MyReportsCalendar/>:<Information/>}
                
                </TabPanel>
                {roleService.hasOneOfRoles(["ADMIN","BOSS"])?<TabPanel hidden={value!==1} value={1} index={1} >
                <AllReportsCalendar/>
                </TabPanel>:null}
            
            </TabContext>
        </Container>
    )
}

export default (ReportsHome)