import closeLeaveDialogs from './closeLeaveDialogs';
import actions from './duck/actions'


const logOut = () => dispatch => {
    console.log("wylogowano")
    dispatch(actions.logOut())
    closeLeaveDialogs(dispatch)
  };

export {logOut}