import { useDispatch, useSelector } from "react-redux"
import actions from "../duck/actions"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, Grid } from "@mui/material";
import StatusChanger from "./StatusChanger";
import ResponseInfoStatusChange from "./ResponseInfoStatusChange";

const EditDialog = () => {
    const open = useSelector(state => state.leaves.openAllTableDialog)
    const response = useSelector(state => state.leaves.changeStatusAllLeaves.response)
    const dispatch = useDispatch()

    const handleClose = () =>{
        dispatch(actions.setOpenAllTableDialog(false))
        dispatch(actions.resetChangeStatusAllLeaves())
    }
    
    return(
    <Dialog
    fullWidth={true}
    maxWidth='md'
    open={open}
    onClose={handleClose}
    >
    <DialogTitle>Edytuj status:</DialogTitle>
    <DialogContent style={{
        backgroundColor:'#e6e6e6',
        paddingTop:20,
    }}>
    <Grid container spacing={4} alignItems='center' justifyContent='center' style={{
        minHeight:200
    }}>
        <Grid xs={12} sm={12} md={8} lg={7} xl={6} item>
            <Paper>
                <StatusChanger/>
            </Paper>
        </Grid>
        <Grid xs={12} sm={12} md={10} lg={10} xl={10} item>
            <ResponseInfoStatusChange response={response}/>
        </Grid>
    </Grid>
       
    </DialogContent>
    <DialogActions>
    <Button onClick={handleClose}>Close</Button>
    </DialogActions>
    </Dialog>
    )
}

export default EditDialog