import { useEffect, useMemo } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { getAllLeaves } from "../duck/operations"
import NewRequestDialog from "../newRequest/NewRequestDialog"
import LeaveUserPanel from "../LeaveUserPanel"
import StatusBar from "../table/StatusBar"
import Button from "./Button"
import LeavesTable from "./LeavesTable"
import moment from "moment"
import FilterBar from "./FilterBar"
import actions from "../duck/actions"
import EditDialog from "./EditDialog"

const AllLeavesTable = () => {

    const dispatch = useDispatch()
    const leaves = useSelector(state => state.leaves.allLeaves)
    const isLoading = useSelector(state => state.leaves.loadingAllLeaves)
    const error = useSelector(state => state.leaves.fetchAllLeavesError)
    
    useEffect(() => {
        //console.log("pobranie danych w tabeli")
        dispatch(getAllLeaves())
    },[])

    const handleClick = () => {
        dispatch(actions.setOpenAllTableDialog(true))
    }

    //console.log('table rerender')

    const colGroup = useMemo(
        ()=>
            <colgroup>
            <col width={'5%'}/>
            <col width={'15%'}/>
            <col width={'10%'}/>
            <col width={'10%'}/>
            <col width={'3%'}/>
            <col width={'12%'}/>
            <col width={'20%'}/>
            <col width={'20%'}/>
            <col width={'5%'}/>
            </colgroup>
        ,
    []
    )
    

    const columns = useMemo(
        () => [
                {
                    Header: 'ID',
                    accessor: ((row)=>
                        row.leaveId
                    ),
                    id:1
                },
                {
                    Header: 'Data złożenia',
                    accessor: ((row)=>
                        moment.utc(row.submissionDate).local().format("YYYY-MM-DD HH:mm")
                    ),
                    id:2
                },
                {
                    Header: 'Data od:',
                    accessor: ((row)=>
                    moment.utc(row.startDate).local().format("YYYY-MM-DD")
                    ),
                    id:3
                },
                {
                    Header: 'Data do:',
                    accessor: ((row)=>
                    moment.utc(row.endDate).local().format("YYYY-MM-DD")
                    ),
                    id:4
                },
                {
                    Header:'Dni',
                    accessor: ((row)=>
                        <span style={{color:'#0c9c00',fontWeight:'bold'}}>{moment(row.endDate).diff(row.startDate,'days')+1}</span>
                    ),
                    id:5
                },
                {
                    Header: 'Osoba',
                    accessor: ((row)=>
                        <span style={{color:'#0c9c00',fontWeight:'bold'}}>{row.userName+' '+row.userSurname}</span>
                    ),
                    id:6
                },
                {
                    Header: 'Uwagi',
                    accessor: ((row)=>
                    row.comment
                    ),
                    id:7
                },
                {
                    Header: 'Status',
                    accessor: ((row)=>
                        <StatusBar statusId={row.statusId} statusName={row.statusName}/>
                    ),
                    id:8
                },
                {
                    Header: '',
                    accessor: ((row)=>
                        <Button onClick={handleClick} statusId={row.statusId} leaveId={row.leaveId}/>
                    ),
                    id:9
                }
            ],
        []
      )

     return(
        <LeaveUserPanel>
        <FilterBar/>
        <div style={{marginLeft:'auto',marginRight:'auto',overflowX:"auto",marginTop:20}}>
        <LeavesTable columns={columns} data={leaves} isLoading={isLoading} error={error} colGroup={colGroup} colQuant={9}/>
        <NewRequestDialog/>
        <EditDialog/>
        </div>
        </LeaveUserPanel>
     )
}

const mapStateToProps = (state) =>{
    return{
        leaves:state.leaves.allLeaves,
        isLoading:state.leaves.loadingAllLeaves
    }
}

export default connect(mapStateToProps,null)(AllLeavesTable)