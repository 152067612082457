import { CircularProgress } from "@material-ui/core"
import { Button } from "@mui/material"
import { useSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { requestLeave } from "../duck/operations"
import validCheck from "../helpers/formValidation"


const ConfirmButton = () => {
    const { enqueueSnackbar } = useSnackbar()
    const isLoading = useSelector(state=>state.leaves.form.isLoading)
    const handleConfirm = () => {
        const checkInfo = validCheck()
        if(checkInfo.valid){
            requestLeave(false,false)
            //dispatch(actions.setOpenNewRequestDialog(false))
            //dispatch(getUserLeaves())
            

        }else{
            enqueueSnackbar(checkInfo.info,{variant: 'warning',autoHideDuration: 4000})
        }
    }

    return (
        isLoading?
        <CircularProgress/>
        :
        <Button 
            size='large'
            variant='contained' 
            onClick={()=>{handleConfirm()}}
        >
            Wyślij zapytanie
        </Button>
        
    )
}

export default ConfirmButton