import { useEffect, useState } from "react"
import { connect } from "react-redux"
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import {  Grid,Button,Box, CircularProgress } from "@material-ui/core";
import { Paper } from "@mui/material";
import UsersTable from "./UsersTable";
import { serverUrl } from '../config/serverConfig';
import moment from "moment";
import ReportsPdf from "./ReportsPdf";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import actions from '../departments/duck/actions';
import { pl } from "date-fns/locale";

const ReportsPdfGenerator = (props) => {
    const [value, setValue] = useState([props.start, props.end]);
    const {jwt} = props
    const {usersList} = props
    const [isLoading,setIsLoading] = useState(false);
    const token="Bearer ".concat(jwt)
    async function getData() {
        await fetch(`${serverUrl}/reports/pdf?startDate=${moment(value[0]).utc().format("YYYY-MM-DD HH:mm:ss")}&endDate=${moment(value[1]).utc().format("YYYY-MM-DD HH:mm:ss")}`,
        {method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body:JSON.stringify({
                users:usersList
            })
        })
        .then(response => response.json())
        .then(json => {
            generatePdf(json,value[0],value[1]);
        })
        .catch(error => {
            console.log(error);
        })
    }

    useEffect(()=>{
        setValue([props.start,props.end])
    },[props.start,props.end])
    
    const generatePdf = async (data,start,end) => {
        console.log(data)
        const blob = await pdf((
            <ReportsPdf
                data={data}
                start={start}
                end={end}
            />
        )).toBlob();
        setIsLoading(false)
        saveAs(blob, "raporty-".concat(moment().format("YYYY-MM-DD").toString(),".pdf"));
    }

    return(
        <Grid container>
            <Grid item xl={7} lg={6} md={12} sm={12} xs={12}>
                <UsersTable/>
            </Grid>
            <Grid item xl={5} lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={2} alignItems='center' justify='center'>
                    <Grid item xs={12}>
                    <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns} >
                    <MobileDateRangePicker
                    components={Paper}
                    startText="Data"
                    endText="Data"
                    cancelText="Anuluj"
                    clearText="Wyczyść"
                    todayText="Dzisiaj"
                    toolbarTitle="Wybierz przedział czasu"
                    showDaysOutsideCurrentMonth={false}
                    showTodayButton={false}
                    clearable={true}
                    inputFormat='dd/MM/yyyy'
                    value={value}
                    onChange={(newValue) => {
                        props.setStart(newValue[0])
                        props.setEnd(newValue[1])
                    }}
                    renderInput={(startProps, endProps) => (
                        <Grid container justify='center' spacing={2} alignItems='center'>
                            <Grid item>
                                <TextField style={{backgroundColor:'white'}} {...startProps} />
                            </Grid>
                            <Grid item>
                                <Box> do </Box>
                            </Grid>
                            <Grid item>
                                <TextField style={{backgroundColor:'white'}} {...endProps} />
                            </Grid>
                        </Grid>
                    )}
                    />
                    </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={10} style={{paddingTop:40}}>
                    {
                        isLoading?<CircularProgress/>:
                        <Button
                        disabled={value[0]===null||value[1]===null||props.usersList.length<=0}
                        size='large'
                        fullWidth={true}
                        style={{backgroundColor:value[0]===null||value[1]===null||props.usersList.length<=0?'#9c9c9c':'#43a047',color:'white', topMargin:20,bottomMargin:20}} 
                        onClick={()=>getData()}>
                        WYGENERUJ
                        </Button>
                    }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
        usersList:state.filters.reportsPdf.usersList,
        start:state.filters.reportsPdf.startDate,
        end:state.filters.reportsPdf.endDate,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setStart: (item) => dispatch(actions.setStart(item)),
        setEnd: (item) => dispatch(actions.setEnd(item)),
        reset: () => dispatch(actions.resetReportsPdf())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ReportsPdfGenerator)