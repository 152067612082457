import { Accordion, AccordionDetails, AccordionSummary, TextField } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MomentUtils from '@date-io/moment';
import { serverUrl } from "../../config/serverConfig";
import ElectricitySubmissionHistoryTable from "./ElectricitySubmissionHistoryTable";

const DateInput = (props) => {
      const color1="red"
      const color2="green"
      const [color,setColor] = useState()
      //console.log(props.props.value,typeof(props.props.value))
      useEffect(()=>{
        if(props.props.value){
          setColor(color2)
        }else{
          setColor(color1)
        }
      },[props.props.value])
      
  return(<TextField 
      inputProps={{style:{textAlign:"center",fontSize:'1.2rem',fontFamily:'arial',color:color}}}
      size="small"
      variant="outlined"
      type="text"
      onClick={props.props.onClick}
      value={props.props.value}
      onChange={props.props.onChange}
      label="prąd - złożenie"
    ></TextField>)
}

function ElectricitySubmission(props) {
  
  const [refresh,setRefresh] = useState(0)
  const { enqueueSnackbar } = useSnackbar();
  const [value,setValue] = useState("");
  const [expanded,setExpanded] = useState(false)
  const token='Bearer '.concat(props.jwt)
  const [isLoading,setIsLoading] = useState(true)

  useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/orders/${props.orderId}/conditions-others-props`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        setIsLoading(false)
        setValue(json.electricitySubmission)
      })
      .catch(error => {
        
      })
    }
    if (isLoading||refresh>0) {
      getData()
      setRefresh(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  async function changeDate(value) {
    await fetch(`${serverUrl}/conditions/${props.conditionsId}/electricity-submission?date=${value}`,
    {method:'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
    })
    .then(response => response)
    .then(response => {
      if(response.status===200){
        enqueueSnackbar("Pomyślnie zmieniono datę złożenia - prąd",{variant: 'success',autoHideDuration: 2000})
      }else{
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
      }
    })
    .catch(error => {
      enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'error',autoHideDuration: 2000})
    })
  }


  function handleDateChange(date){
    let insert = ""
      if(date){
        insert = date.format('YYYY-MM-DD [00:00:00]').toString()
      }
      setValue(date)
      //console.log(date)
      changeDate(insert)
      setRefresh(refresh+1)
      //console.log("date: ",date.format('YYYY-MM-DD [00:00:00]').toString())
      //  *****NARAZIE ODŚWIEŻANIE TABELI JEST WYŁĄCZONE ALE MOŻNA WŁĄCZYĆ TU *****
      //props.onChange()
    }

    return(
        <Accordion expanded={expanded} style={{marginBottom:"15px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={()=>{setExpanded(!expanded)}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <MuiPickersUtilsProvider utils={MomentUtils}>
            <span style={{fontSize:'1.3rem',fontFamily:'arial',width:'60%',textAlign:'center',display:'flex',alignItems:'center'}}>
              PRĄD - złożenie
            </span>
            
              <div style={{width:'60%'}}>
                <DatePicker
                  style={{width:'100%'}}
                  clearable
                  variant="dialog"
                  inputVariant="outlined"
                  value={value}
                  placeholder="rrrr-mm-dd"
                  onChange={(date) => handleDateChange(date)}
                  format="yyyy-MM-DD"
                  TextFieldComponent={(props)=><DateInput props={props}></DateInput>}
                  cancelLabel="ANULUJ"
                  clearLabel="WYCZYŚĆ"
                  okLabel="ZAPISZ"
                />
              </div>
            </MuiPickersUtilsProvider>
        </AccordionSummary>
        <AccordionDetails>
            <ElectricitySubmissionHistoryTable key={refresh} orderId={props.orderId}/>
        </AccordionDetails>
        </Accordion>
    )
}

export default connect((state)=>({jwt:state.log.jwt}))(ElectricitySubmission)