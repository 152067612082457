import {Grid } from "@material-ui/core"
import React, { useEffect } from 'react'
import { connect } from "react-redux"
import CommentsSection from '../CommentsSection'
import '../style.css'
import ConditionsDate from "./ConditionsDate"
import RoadSubmission from "./RoadSubmission"
import ElectricitySubmission from "./ElectricitySubmission"
import WaterSubmission from "./WaterSubmission"
import RoadPickUp from "./RoadPickUp"
import ElectricityPickUp from "./ElectricityPickUp"
import WaterPickUp from "./WaterPickUp"
import InfoTable from "../../infoTable/InfoTable"
import ServiceInfo from "../ServiceInfo"
import Maps from "./Maps"

 function ConditionsDialog(props){

    const {orderId} = props
    const {serviceId} = props
    const {serviceInfoId} = props
    const {conditionsId} = props

    useEffect(() => {
        console.log(props)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return(
        <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
            <InfoTable orderId={orderId} linkEnabled={true}/>
            </Grid>
            <Grid item lg={5} xs={12}>
                <ServiceInfo 
                serviceId={serviceId} 
                serviceInfoId={serviceInfoId}
                onChange={props.onChange}>
                </ServiceInfo>
                <ConditionsDate
                orderId={orderId}
                onChange={props.onChange}
                serviceId={serviceId} 
                conditionsId={conditionsId}>
                </ConditionsDate>
                <RoadSubmission
                orderId={orderId}
                onChange={props.onChange}
                conditionsId={conditionsId}>
                </RoadSubmission>
                <RoadPickUp
                orderId={orderId}
                onChange={props.onChange}
                conditionsId={conditionsId}>
                </RoadPickUp>
                <ElectricitySubmission
                orderId={orderId}
                onChange={props.onChange}
                conditionsId={conditionsId}>
                </ElectricitySubmission>
                <ElectricityPickUp
                orderId={orderId}
                onChange={props.onChange}
                conditionsId={conditionsId}>
                </ElectricityPickUp>
                <WaterSubmission
                orderId={orderId}
                onChange={props.onChange}
                conditionsId={conditionsId}>
                </WaterSubmission>
                <WaterPickUp
                orderId={orderId}
                onChange={props.onChange}
                conditionsId={conditionsId}>
                </WaterPickUp>
                <Maps
                orderId={orderId}
                onChange={props.onChange}
                conditionsId={conditionsId}
                />
            </Grid>
            <Grid item lg={3} xs={12}>
                <CommentsSection 
                style={{width:'30%',float:'left'}}
                orderId={orderId} 
                onChange={props.onChange}>
                </CommentsSection>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(ConditionsDialog)