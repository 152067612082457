const green = '#2d9c00'
const yellow = '#ffab00'
const grey = '#c8c8c8'
const red = '#ec0000'

export {
    green,
    yellow,
    grey,
    red
}