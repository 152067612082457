
import { CircularProgress, Container, Grid } from "@material-ui/core"
import "./style.css"
import LockOpenIcon from '@material-ui/icons/LockOpen';
import logo from "../images/logo.png"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import jwtDecode from "jwt-decode"
import actions from "./duck/actions"
import securityCheck from "../mainComponents/securityCheck"
import { serverUrl } from "../config/serverConfig"
import { useState } from "react";

function LoginPage({logUser,loggedIn,exp}) {
    const { register, handleSubmit } = useForm()
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const [isLoading,setIsLoading] = useState(false)
    async function logIn(login,password){
        setIsLoading(true)
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: login, password:password })
        };
        await fetch(`${serverUrl}/authenticate`,requestOptions)
        .then(response => {
        if(response.status===200){
            enqueueSnackbar("Pomyślnie zalogowano",{variant: 'success',autoHideDuration: 2000})
            return(response.json())
        }else{
            enqueueSnackbar("Sprawdź czy dane logowania są poprawne",{variant: 'error',autoHideDuration: 2000})
        }
        setIsLoading(false)
        })
        .then(json =>{
            const loginObject = {
                loginObject:jwtDecode(json.jwt),
                jwt:json.jwt
            }
            logUser(loginObject)
            history.push("/")
        })
        .catch(error => {
        enqueueSnackbar("UPS! Coś poszło nie tak",{variant: 'warning',autoHideDuration: 3300})
        setIsLoading(false)
        })
    }

    const onSubmit = data =>{
        logIn(data.login,data.password)
    }

        
    return(
        <>
        {securityCheck(loggedIn,exp)===true?history.replace("/"):
        <div className="loginBox">
            <Grid className="mainGrid" container>
                <Grid item sx={12} sm={8} md={5} lg={3} xl={3} className="grid">
                    <div className="inputBox">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <img alt="logo" src={logo} className="logoImage"/>
                        
                        <input placeholder="Login" ref={register} name="login" className="loginInput"/>
                        
                        <input placeholder="Hasło" ref={register} type="password" name="password" className="loginInput"/>
                        <button disabled={isLoading} className="loginSubmit">
                            
                            {
                                isLoading?
                                <CircularProgress size={25} className="loadingSpiner"/>
                                :
                                <>
                                <span>Zaloguj</span>
                                <LockOpenIcon className="loginIcon"/>
                                </>
                            }
                            
                        </button>
                        </form>
                    </div>
                </Grid>
            </Grid>
            
        </div>}
        </>
    )
}

function mapDispatchToProps(dispatch) {
    return {
     logUser : (username,password) => dispatch(actions.login(username,password))
    }
}

function mapStateToProps(state) {
    return{
    exp: state.log.exp,
    loggedIn: state.log.loggedIn,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginPage)