import { DropzoneArea } from 'material-ui-dropzone';
import { useState } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { serverUrl } from '../config/serverConfig';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { post } from 'axios';
import { LinearProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { Paper } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 8,
    },
    colorPrimary: {
      backgroundColor: '#eeeeee',
    },
    bar: {
      borderRadius: 8,
      backgroundColor: '#0c9c00',
    },
}))(LinearProgress);

const SaveButton = withStyles({
    root: {
        marginTop:'15px',
        backgroundColor:'#0c9c00',
        color:'white',
        '&:hover':{
            backgroundColor:'#0c9c00c9'
        }
    },
    disabled:{
        backgroundColor:'lightgrey',
        color:'grey'
    }
})(Button)

const DropZone = ({categoryId,orderId,jwt,refresh,refreshList}) => {

    const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    }));
    const classes = useStyles();
    const [files,setFiles] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading,setIsLoading] = useState(false)
    const token="Bearer ".concat(jwt)
    const [progress,setProgress] = useState(0)
    const [key,setKey] = useState(1)


    const saveFile2 = async(file) =>{
        setIsLoading(true)
        const url = `${serverUrl}/file-system/file?categoryId=${categoryId}&orderId=${orderId}`;
        const config = {
            headers: {
                'Authorization':token,
                'content-type': 'multipart/form-data',
            },
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setProgress(percentCompleted)
            }
        }
        await post(url, file,config)
        .then(function (response) {
            if(response.status===200){
                enqueueSnackbar("Pomyslnie dodano plik",{variant: 'success',autoHideDuration: 2000})
                setFiles([])
            }else{
                enqueueSnackbar("UPS! Plik nie został dodany, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
            }
            setIsLoading(false)
            setKey(key+1)
            refresh()
            refreshList()
        }).catch(function (error) {
            console.log(error)
            enqueueSnackbar("UPS! Plik nie został dodany, coś poszło nie tak",{variant: 'error',autoHideDuration: 3000})
          setIsLoading(false)
        });
    }

    const handleFilesChange = (files) => {
        setFiles(files)
    }

    const handleDeleteFile = (file) => {
        let newFiles = []
        files.map((oldFile)=>(oldFile===file?null:newFiles.push(oldFile)))
        setFiles(newFiles)
    }

    const handleSaveFiles = () => {
        if(files!==undefined){
            files.forEach(file => {
                let formData= new FormData()
                formData.append('file',file)
                saveFile2(formData)
            });
        }
    }

    const handleDropReject = (rejectedFile, acceptedFiles, maxFileSize) => {
        let message = `Plik -  ${rejectedFile.name} został odrzucony. `;
        if (!acceptedFiles.includes(rejectedFile.type)) {
            message += 'Typ pliku nie jest wspierany. ';
        }
        if (rejectedFile.size > maxFileSize) {
            message += 'Rozmiar pliku jest za duży, limit wynosi ' + maxFileSize/1024/1024 + 'MB. ';
        }
        return message;
    }

    return(
    <>
        <DropzoneArea
        key={key}
        clearOnUnmount={true}
        maxFileSize={200000000}
        filesLimit={1}
        showPreviews={true}
        dropzoneText="Przeciągnij i upuść plik tutaj lub kliknij"
        onDrop={(files)=>handleFilesChange(files)}
        onDelete={(file)=>(handleDeleteFile(file))}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{container: { spacing: 1, direction: 'row' }}}
        previewChipProps={{classes: { root: classes.previewChip } }}
        previewText="Wybrane pliki"
        getFileLimitExceedMessage={(filesLimit)=>(`Przekroczono limit plików (${filesLimit})`)}
        getFileAddedMessage={(fileName)=>(`Dodano plik - ${fileName}`)}
        getFileRemovedMessage={(fileName)=>(`Usunieto plik - ${fileName}`)}
        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => handleDropReject(rejectedFile, acceptedFiles, maxFileSize)}
        />
        <SaveButton disabled={files.length===0} onClick={()=>handleSaveFiles()}>Prześlij plik</SaveButton>
        <Backdrop style={{zIndex:0}} open={isLoading}>
            {isLoading?
            <Paper style={{zIndex:2,width:'550px',padding:'25px',textAlign:'center',fontSize:'1.4rem'}}>
                <Typography style={{color:'#0750b5',fontSize:'1.5rem'}}>{progress}%</Typography>
                <BorderLinearProgress variant="determinate" value={progress} />
            
            </Paper>
            :null
            }
        </Backdrop>
    </>)
}
const mapStateToProps = (state) =>{
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(DropZone)