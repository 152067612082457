import {Grid } from "@material-ui/core"
import React from 'react'
import InfoTable from "../../infoTable/InfoTable"
import CommentsSection from '../CommentsSection'
import ServiceInfo from '../ServiceInfo'
import '../style.css'
import GeologyPickUp from "./GeologyPickUp"
import GeologySubmission from "./GeologySubmission"


 function GeologyDialog(props){
     
    const {orderId} = props
    const {serviceId} = props
    const {serviceInfoId} = props
    
    return(
        <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
                <InfoTable orderId={orderId} linkEnabled={true}/>
            </Grid>
            <Grid item lg={5} xs={12}>
                <ServiceInfo 
                serviceId={serviceId} 
                serviceInfoId={serviceInfoId}
                onChange={props.onChange}>
                </ServiceInfo>
                <GeologyPickUp
                orderId={orderId}
                onChange={props.onChange}>
                </GeologyPickUp>
                <GeologySubmission
                orderId={orderId}
                onChange={props.onChange}>
                </GeologySubmission>
            </Grid>
            <Grid item lg={3} xs={12}>
                <CommentsSection 
                style={{width:'30%',float:'left'}}
                orderId={orderId} 
                onChange={props.onChange}>
                </CommentsSection>
            </Grid>
        </Grid>
    )
}

export default (GeologyDialog)