

const colorFinder = (quantity,part) =>{

    const gap = Math.round(120/(quantity-1)) 
    let colors=[0]
    let counter=0
    for(let x=0;x<quantity-1;x++){
        counter=counter+gap
        colors.push(counter)
    }
    return ("hsl(".concat(colors[(part-1)]).concat("deg 100% 50% / 50%)"))
}

export default colorFinder