import { connect } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import securityCheck from "./securityCheck"


function PrivateRoute({loggedIn,canAccess,exp,component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          securityCheck(loggedIn,exp) ? (
            canAccess?<Component {...props} />:<Redirect to={{pathname: "/"}}/>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )
        }
      />
    );
  }

function mapStateToProps(state) {
    return{
    loggedIn: state.log.loggedIn,
    exp:state.log.exp,
    }
}
export default connect(mapStateToProps,null)(PrivateRoute)