import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core"


const CounterEvent = (props) => {

    //console.log(props)
    return(
        <Paper 
            style={{
            padding:'3px 5px 0px 5px',
            backgroundColor:props.event.reports===0?'rgb(0,0,0,0.4)':props.event.color,
            textAlign:'center',
            }}>
            <Typography style={{fontSize:'1.1rem'}}>{props.event.reports}/{props.event.users}</Typography> 
            
        </Paper>
    )
        
}

export default CounterEvent