import { Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import actions from "../duck/actions"
import AssessmentIcon from '@material-ui/icons/Assessment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { RoleService } from "../../RoleService"

const NavigationBar = ({add}) => {
    
    const history = useHistory()
    const dispatch = useDispatch()
    const roleService = new RoleService()
    const redirect = (path) => {
        history.push(path)
    }

    const openNew = () => {
        dispatch(actions.setOpenNewRequestDialog(true))
    }

    return(
        <>
        {
            roleService.hasOneOfRoles(["ADMIN","BOSS"])&&
            <Button style={{
                    backgroundColor: "#ffab00",
                    marginTop:4,
                    marginBottom:4,
                    marginRight:15,
                    marginLeft:15
                }} variant="contained" onClick={()=>redirect('/leave-timeline')}>
                <AssessmentIcon style={{
                    marginRight:10,
                    height:22
                }}/>Linia czasu
            </Button>
        }
        <Button style={{
                backgroundColor: "#0750b5",
                marginTop:4,
                marginBottom:4,
                marginRight:15,
                marginLeft:15
            }} variant="contained" onClick={()=>redirect('/leave-user-panel')}>Moje urlopy</Button>
        {
            roleService.hasOneOfRoles(["ADMIN","BOSS"])&&
            <Button style={{
                backgroundColor: "#0750b5",
                marginTop:4,
                marginBottom:4,
                marginRight:15,
                marginLeft:15
            }} variant="contained" onClick={()=>redirect('/all-leaves')}>Wszystkie urlopy</Button>
        }
        {add?
        <Button style={{
            backgroundColor: "#0c9c00",
            marginTop:4,
            marginBottom:4,
            marginRight:15,
            marginLeft:15
        }} variant="contained" onClick={()=>openNew()}>
        <AddBoxIcon style={{
            marginRight:10,
            height:22
        }}/>Nowy urlop
        </Button>
        :null}
        </>
    )
}

export default NavigationBar