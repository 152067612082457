import { Button, withStyles } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

const CustomButtonBlue = withStyles((theme) => ({
    root: {
      color:"white",
      backgroundColor: "#014fb6",
      width:"90%",
      marginLeft:"5%",
      marginRight:"5%",
      marginTop:"25px",
      paddingTop:"5px",
      paddingBottom:"5px",
      fontSize:"1.3rem",
      letterSpacing:"1px",
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  }))(Button);

  const CustomButtonGreen = withStyles((theme) => ({
    root: {
      color:"white",
      backgroundColor: "#43a047",
      width:"90%",
      marginLeft:"5%",
      marginRight:"5%",
      marginTop:"25px",
      paddingTop:"8px",
      paddingBottom:"8px",
      fontSize:"1.4rem",
      letterSpacing:"1px",
    '&:hover': {
        backgroundColor: '#48c34d',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  }))(Button);

  const CustomButtonRed = withStyles((theme) => ({
    root: {
      color:"white",
      backgroundColor: "#910000",
      width:"90%",
      marginLeft:"5%",
      marginRight:"5%",
      marginTop:"25px",
      paddingTop:"5px",
      paddingBottom:"5px",
      fontSize:"1.3rem",
      letterSpacing:"1px",
    '&:hover': {
        backgroundColor: '#be0000',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  }))(Button);

  const CustomButtonYellow = withStyles((theme) => ({
    root: {
      color:"white",
      backgroundColor: "#ffab00",
      width:"90%",
      marginLeft:"5%",
      marginRight:"5%",
      marginTop:"25px",
      paddingTop:"5px",
      paddingBottom:"5px",
      fontSize:"1.3rem",
      letterSpacing:"1px",
    '&:hover': {
        backgroundColor: '#ffc54f',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  }))(Button);

const MenuOption = ({label,variant,onClick}) => {
    if(variant==="blue"){return(<CustomButtonBlue onClick={onClick}>{label}</CustomButtonBlue>)}
    if(variant==="red"){return(<CustomButtonRed onClick={onClick}>{label}</CustomButtonRed>)}
    if(variant==="yellow"){return(<CustomButtonYellow onClick={onClick}>{label}</CustomButtonYellow>)}
    if(variant==="green"){return(<CustomButtonGreen onClick={onClick}><AddCircleOutlineRoundedIcon style={{marginRight:"10px",fontSize:"2.3rem"}}/>{label}</CustomButtonGreen>)}
}

export default(MenuOption)