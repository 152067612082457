import { Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import actions from "../duck/actions";
import NewPhoneNumber from "./NewPhoneNumber";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';

const mapStateToProps = (state) =>{
    return{
       customerId:state.newOrder.customerData.customerId,
       phoneNumbers:state.newOrder.customerData.phoneNumbers,
       newPhoneNumbers:state.newOrder.customerData.newPhoneNumbers,
       jwt:state.log.jwt
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return{
      addPhoneNumber: (obj) => dispatch(actions.addPhoneNumber(obj)),
      removePhoneNumber: (obj) => dispatch(actions.removePhoneNumber(obj)),
      removeNewPhoneNumber: (obj) => dispatch(actions.removeNewPhoneNumber(obj)),
    }
  }



const NewListOption = connect(mapStateToProps,mapDispatchToProps)((props) =>{

    const {phoneNumber} = props

    const handleDelete = () => { 
        props.removeNewPhoneNumber(phoneNumber)
        props.refresh()
    }

    return(
        <>
        <ListItem key={phoneNumber} style={{backgroundColor:"#d9ffda"}}>
        <ListItemIcon>
            <AddIcCallIcon style={{color:"#43a047"}}/>
        </ListItemIcon>
        <ListItemText id={phoneNumber} primary={phoneNumber}></ListItemText>
        <ListItemSecondaryAction style={{right:0}}>
            <Checkbox
            edge="end"
            checked={true}
            disabled={true}
            />
        <IconButton onClick={()=>{handleDelete()}}>
            <DeleteForeverIcon style={{color:"red",fontSize:"1.8rem"}}/>
        </IconButton>
        </ListItemSecondaryAction>
        
        </ListItem>
        <Divider style={{backgroundColor:"white",height:"5px"}}/>

        </>
    )

})


const CustomerNumbersListNew = (props) => {
    
    const [newNumbers,setNewNumbers] = useState(props.newPhoneNumbers)

    const refresh = () => {
        console.log(props.newPhoneNumbers)
        setNewNumbers([...props.newPhoneNumbers])
    }

    return (
        <Grid container justify="center"> 
            
            <Grid item xs={12} sm={12} md={11} lg={11} xl={10}>
            <Typography className="infoText" style={{color:"green",textAlign:"left"}}>Numery telefonów *</Typography>
            <List >
            {newNumbers.map((value) => {
                return (
                <NewListOption refresh={refresh} key={value} phoneNumber={value}/>
                );
            })}
            </List>

            <NewPhoneNumber refresh={refresh}/>
            </Grid>
        </Grid>

      );

}


  
  export default connect(mapStateToProps,mapDispatchToProps)(CustomerNumbersListNew)