import { Grid } from "@material-ui/core"
import "./style.css"
import PhoneNumberInput from "./PhoneNumberInput";
import AddressInput from "./AddressInput";
import EmailInput from "./EmailInput";
import NewPhoneNumber from "./NewPhoneNumber";
import NewAddress from "./NewAddress";
import NewEmail from "./NewEmail";
import CustomerNameInput from "./CustomerNameInput";
import CommunityInput from "./CommunityInput";
import PrecinctInput from "./PrecinctInput";
import PlotNumberInput from "./PlotNumberInput";
import ProjectNameInput from "./ProjectNameInput";

const ChangeInfo = (props) => {

    const {emails} = props
    const {phoneNumbers} = props
    const {addresses} = props
    const {orderProps} = props
    let counter = 0
    
    const clearCounter = () => {counter=0}
    
    return(
        <>
        <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomerNameInput customerName={orderProps.customerName} orderId={orderProps.orderId} customerId={orderProps.customerId} refresh={props.refresh}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CommunityInput community={orderProps.community} orderId={orderProps.orderId} refresh={props.refresh}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <PrecinctInput precinct={orderProps.precinct} orderId={orderProps.orderId} refresh={props.refresh}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <PlotNumberInput plotNumber={orderProps.plotNumber} orderId={orderProps.orderId} refresh={props.refresh}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginBottom:"10px"}}>
               <ProjectNameInput projectName={orderProps.projectName} orderId={orderProps.orderId} refresh={props.refresh}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               {phoneNumbers.map((item)=>{ 
                   //console.log(item)
                   counter++
                   return( <PhoneNumberInput quantity={phoneNumbers.length} refresh={props.refresh} counter={counter} phoneNumber={item.phoneNumber} phoneNumberId={item.phoneNumberId}  key={item.phoneNumberId} orderId={orderProps.orderId}/>)
                })}{clearCounter()}

                <NewPhoneNumber refresh={props.refresh} orderId={props.orderId}/>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               {addresses.map((item)=>{ 
                   counter++
                   return( <AddressInput quantity={addresses.length} refresh={props.refresh} counter={counter} address={item.address} addressId={item.addressId}  key={item.addressId} orderId={orderProps.orderId}/>)
                })}{clearCounter()}
                <NewAddress refresh={props.refresh} orderId={props.orderId}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               {emails.map((item)=>{ 
                   counter++
                   return( <EmailInput quantity={emails.length} refresh={props.refresh} counter={counter} email={item.email} emailId={item.emailId}  key={item.emailId}  orderId={orderProps.orderId}/>)
                })}{clearCounter()}
                <NewEmail refresh={props.refresh} orderId={props.orderId}/>
            </Grid>

        </Grid>
        </>
    )
} 

export default ChangeInfo