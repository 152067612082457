import { useSelector,useDispatch } from "react-redux"
import StatusSelect from "../allLeaves/StatusSelect"
import actions from "../duck/actions"

const AdminStatusSelect = () => {

    const dispatch = useDispatch()
    const status = useSelector(state=>state.leaves.form.statusId)

    const handleStatusChange = (status) => {
        dispatch(actions.setFormStatusId(status))
    }
    
    return (
        <StatusSelect
            status={status}
            handleStatusChange={handleStatusChange}
        />
    )

}

export default AdminStatusSelect