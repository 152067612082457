import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import actions from "../duck/actions"
import { getLeaveInfo } from '../duck/operations'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Table,TableCell, TableRow,TableBody, TableHead, Grid, CircularProgress, Paper } from "@mui/material";
import moment from "moment";
import StatusChanger from "./StatusChanger";
import { Typography } from "@material-ui/core";

const styleTableRight = {
    fontWeight:'bold',
    color:'green'
}

const PreviewDialog = () => {

    const open = useSelector(state => state.leaves.openTimelineInfoDialog)
    const info = useSelector(state => state.leaves.leaveDetails)
    const isLoading = useSelector(state => state.leaves.loadingTimelineInfo)
    const error = useSelector(state => state.leaves.fetchLeaveInfoError)
    const leaveId = useSelector(state => state.leaves.timelineDialogId)
    const dispatch = useDispatch()
    
    useEffect(()=>{
        if(leaveId!==null){
            dispatch(getLeaveInfo())
        }
    },[leaveId])

    const handleClose = () =>{
        dispatch(actions.setOpenTimelineInfoDialog(false))
        dispatch(actions.setTimelineDialogId(null))
        dispatch(actions.resetChangeStatusTimeline())
    }

    const loadingComponent = (innerValue) => {
        return(
            isLoading?<CircularProgress size={15}/>:(innerValue)
        )
    }
    
    return(
    <Dialog
    fullWidth={true}
    maxWidth='md'
    open={open}
    onClose={handleClose}
    >
    <DialogTitle>Okno urlopu</DialogTitle>
    <DialogContent style={{
        backgroundColor:'#e6e6e6',
        paddingTop:20,
    }}>
    <Grid container spacing={4}>
        <Grid xs={12} sm={12} md={8} lg={7} xl={6} item>
            {info?
                error?
                <Typography style={{
                    color:'grey'
                }}>
                    Wystąpił błąd wczytywania danych. :(
                </Typography>
                :
                <Table style={{
                    backgroundColor:'white',
                    borderBottom:'solid 2px #0750b5'
                }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{
                                backgroundColor:'#0750b5',
                                borderTopLeftRadius:5,
                                height:20,
                                width:'30%'
                            }}></TableCell>
                            <TableCell style={{
                                backgroundColor:'#0750b5',
                                borderTopRightRadius:5,
                                height:20,
                                width:'70%'
                            }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Pracownik:</TableCell>
                            <TableCell style={styleTableRight}>
                                {loadingComponent(info.userName+' '+info.userSurname)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Dni:</TableCell>
                            <TableCell style={styleTableRight}>
                                {loadingComponent(moment(info.endDate).diff(info.startDate,'days')+1)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Od:</TableCell>
                            <TableCell style={styleTableRight}>
                                {loadingComponent(moment.utc(info.startDate).local().format("YYYY-MM-DD HH:mm"))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Do:</TableCell>
                            <TableCell style={styleTableRight}>
                                {loadingComponent(moment.utc(info.endDate).local().format("YYYY-MM-DD HH:mm"))}  
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Wniosek z dnia:</TableCell>
                            <TableCell style={styleTableRight}>
                                {loadingComponent(moment.utc(info.submissionDate).local().format("YYYY-MM-DD HH:mm"))}  
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Uwagi:</TableCell>
                            <TableCell style={styleTableRight}>
                                {loadingComponent(info.comment)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            :
            null
            }
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={5} xl={6} item style={{
            textAlign:'center'
        }}>
            <Paper>
                <StatusChanger/>
            </Paper>
        </Grid>
    </Grid>
       
    </DialogContent>
    <DialogActions>
    <Button onClick={handleClose}>Close</Button>
    </DialogActions>
    </Dialog>
    )
}

export default PreviewDialog