import { PDFViewer } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { serverUrl } from '../config/serverConfig';
import ReportsPdf from './ReportsPdf';

const PdfViewer = ({orderId,jwt}) => {

    const [isLoading,setIsLoading] = useState(true)
    const token='Bearer '.concat(jwt)
    const [data,setData] = useState()
    const usersList = [1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
    useEffect(() => {
        async function getData() {
          await fetch(`${serverUrl}/reports/pdf?startDate=2021-08-30 22:00:00&endDate=2021-09-20 22:00:00`,
          {method:'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
              },
              body:JSON.stringify({
                  users:usersList
              })
          })
          .then(response => response.json())
          .then(json => {
            setIsLoading(false)
            setData(json)
          })
          .catch(error => {console.log(error)})
        }
        if(isLoading){getData()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    return(
        <>
        {isLoading===true?null:
        <PDFViewer style={{width:'1200px',height:'800px'}}>
            <ReportsPdf data={data}/>
        </PDFViewer>}
        </>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
}

export default connect(mapStateToProps,null)(PdfViewer)