import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { connect } from "react-redux"
import { serverUrl } from "../config/serverConfig"
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
const NewEmail = (props) => {

    const [expanded,setExpanded] = useState(false)
    const [newEmail,setNewEmail] = useState("")
    const { enqueueSnackbar } = useSnackbar()
    const token='Bearer '.concat(props.jwt)
    const [short,setShort] = useState(false)

    async function addEmail(email,orderId) {
        await fetch(`${serverUrl}/emails?email=${email}&orderId=${orderId}`,
        {method:'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
        })
        .then(response => response)
        .then(response => {
          if(response.status===200){
            enqueueSnackbar("Pomyślnie dodano email",{variant: 'success',autoHideDuration: 2000})
            props.refresh()
            setNewEmail("")
            setExpanded(false)
            //console.log(response)
          }else{
            enqueueSnackbar("UPS! Email nie został dodany",{variant: 'error',autoHideDuration: 2000})
            //console.log("bad ",response)
          }
        })
        .catch(error => {
          //console.log(error)
          enqueueSnackbar("UPS! Email nie został dodany",{variant: 'error',autoHideDuration: 2000})
        })
      }

      const emailValid = (email) =>{

        if(email.length===0){
          enqueueSnackbar("Email musi mieć długość od 4 do 255 znaków",{variant: 'warning',autoHideDuration: 4000})
        }else{
          let ats=0
          for (let x = 0; x < email.length; x++) {
            if(email.charAt(x)==="@"){ats++}
          }
          if(ats===1&&email.charAt(0)!=="@"){
            let afterAt = email.split('@')[1]
            let dots=0
            for (let x = 0; x < afterAt.length; x++) {
              if(afterAt.charAt(x)==="."){dots++}
              //console.log(afterAt.charAt(x))
            }
            //console.log(dots,afterAt.charAt(0),afterAt.charAt(afterAt.length-1))
            if(dots===1&&afterAt.charAt(0)!=="."&&afterAt.charAt(afterAt.length-1)!=="."){
              return true
            }else{
              enqueueSnackbar("Email musi zawierać jedną kropkę NIEbezpośrednio po małpie @",{variant: 'warning',autoHideDuration: 4000})
              return false
            }
          }else{
            enqueueSnackbar("Email musi zawierać jedną małpę @ i nie może ona znajdować się na początku",{variant: 'warning',autoHideDuration: 4000})
            return false
          }
        }
        
      }


    const handleAddEmail = () => {
        if(emailValid(newEmail)===true&&newEmail.length>=4&&newEmail.length<255){
            addEmail(encodeURIComponent(newEmail.trim()),props.orderId)
        }
      }

      
      const handleInputChange = (val) => {
        if(val.length<4){
            setShort(true)
        }else{
            setShort(false)
        }if(val.length<255){
          setNewEmail(val)
        }
      }

    return(
    <Accordion style={{marginTop:"10px",width:"60%",marginLeft:"10%"}} expanded={expanded} onChange={()=>{setExpanded(!expanded)}}>
    <AccordionSummary 
    expandIcon={<AlternateEmailIcon style={{color:"white",fontSize:"1.4rem"}} />}
    style={{backgroundColor:'#43a047',
    color:'white',
    fontSize:'0.875rem',
    fontWeight:"bold",
    fontFamily: "Roboto",
    lineHeight: "1.75",
    letterSpacing: "0.02857em",
    }}>
        DODAJ EMAIL
    </AccordionSummary>
    <AccordionDetails>
        <div>
        <TextField
            value={newEmail}
            style={{marginTop:"8px",marginBottom:"8px"}}
            label="Nowy email"
            variant="outlined"
            fullWidth={true}
            inputProps={{style:{fontSize:'1.2rem',fontFamily:'arial',color:short===true?"red":"#0750b5"}}}
            onChange={(e) => handleInputChange(e.target.value)}
        />
        
        <Button onClick={() => {handleAddEmail()}} style={{backgroundColor:"rgb(67, 160, 71)",color:'white'}} fullWidth={true}>Dodaj</Button>
        </div>
        
        
    </AccordionDetails>
    </Accordion>
    )
}

const mapStateToProps = (state) =>{
    //console.log(state.filters.sanitaryTable.sortedColumn)
    return{
        jwt:state.log.jwt,
    }
  }
export default connect(mapStateToProps,null)(NewEmail)