import {  Checkbox, Divider, Grid, IconButton, List, ListItem,  ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverUrl } from "../../config/serverConfig";
import actions from "../duck/actions";
import PhoneIcon from '@material-ui/icons/Phone';
import NewPhoneNumber from "./NewPhoneNumber";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';


const mapStateToProps = (state) =>{
    return{
       customerId:state.newOrder.customerData.customerId,
       phoneNumbers:state.newOrder.customerData.phoneNumbers,
       newPhoneNumbers:state.newOrder.customerData.newPhoneNumbers,
       jwt:state.log.jwt
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return{
      addPhoneNumber: (obj) => dispatch(actions.addPhoneNumber(obj)),
      removePhoneNumber: (obj) => dispatch(actions.removePhoneNumber(obj)),
      removeNewPhoneNumber: (obj) => dispatch(actions.removeNewPhoneNumber(obj)),
    }
  }

const ListOption = connect(mapStateToProps,mapDispatchToProps)((props) =>{

    const {phoneNumberId} = props.item
    const {phoneNumber} = props.item
    const {phoneNumbers} = props
    const [checked,setChecked] = useState(false)

    useEffect(()=>{
        phoneNumbers.forEach(element => {
            if(element.phoneNumberId===phoneNumberId){
                setChecked(true)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleChange = () => {
        if(checked!==true){
            props.addPhoneNumber({phoneNumberId:phoneNumberId,phoneNumber:phoneNumber})
        }else{
            props.removePhoneNumber(phoneNumberId)
        }
        setChecked(!checked)
    }

    return(
        <>
        <ListItem onClick={()=>handleChange()} key={phoneNumberId} button style={{backgroundColor:"#f5f5f5"}}>
        <ListItemIcon>
            <PhoneIcon style={{color:"#0750b5"}}/>
        </ListItemIcon>
        <ListItemText id={phoneNumberId} primary={phoneNumber}></ListItemText>
        <ListItemSecondaryAction>
            <Checkbox
            edge="end"
            onChange={()=>handleChange()}
            checked={checked}
            
        />
        </ListItemSecondaryAction>
        </ListItem>
        <Divider style={{backgroundColor:"white",height:"5px"}}/>

        </>
    )

})

const NewListOption = connect(mapStateToProps,mapDispatchToProps)((props) =>{

    const {phoneNumber} = props

    const handleDelete = () => { 
        props.removeNewPhoneNumber(phoneNumber)
        props.refresh()
    }

    return(
        <>
        <ListItem key={phoneNumber} style={{backgroundColor:"#d9ffda"}}>
        <ListItemIcon>
            <AddIcCallIcon style={{color:"#43a047"}}/>
        </ListItemIcon>
        <ListItemText id={phoneNumber} primary={phoneNumber}></ListItemText>
        <ListItemSecondaryAction style={{right:0}}>
            <Checkbox
            edge="end"
            checked={true}
            disabled={true}
            />
        <IconButton onClick={()=>{handleDelete()}}>
            <DeleteForeverIcon style={{color:"red",fontSize:"1.8rem"}}/>
        </IconButton>
        </ListItemSecondaryAction>
        
        </ListItem>
        <Divider style={{backgroundColor:"white",height:"5px"}}/>

        </>
    )

})


const CustomerNumbersList = (props) => {
    
    const [data,setServices] = useState([])
    const token="Bearer ".concat(props.jwt)
    const [newNumbers,setNewNumbers] = useState(props.newPhoneNumbers)

    console.log(newNumbers)

    useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/phone-numbers/customer?customerId=${props.customerId}`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
        console.log(json)
        setServices(json)
      })
      .catch(error => {
        console.log(error)
      })
    }
      getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.customerId])

    const refresh = () => {
        console.log(props.newPhoneNumbers)
        setNewNumbers([...props.newPhoneNumbers])
    }

    return (
        <Grid container justify="center"> 
            
            <Grid item xs={12} sm={12} md={11} lg={11} xl={10}>
            <Typography className="infoText" style={{color:"green",textAlign:"left"}}>Numery telefonów *</Typography>
            <List >
            {data.map((value) => {
                return (
                <ListOption key={value.phoneNumberId} item={value}/>
                );
            })}
            <Divider style={{backgroundColor:"white",height:"5px"}}/>
            {newNumbers.map((value) => {
                return (
                <NewListOption refresh={refresh} key={value} phoneNumber={value}/>
                );
            })}

            </List>

            <NewPhoneNumber refresh={refresh}/>
            </Grid>
        </Grid>

      );

}


  
  export default connect(mapStateToProps,mapDispatchToProps)(CustomerNumbersList)