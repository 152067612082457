import { AccordionSummary } from "@material-ui/core"
import { AccordionDetails } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { Accordion } from "@material-ui/core"
import { useState } from "react"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import convertToLocal from "../convertToLocal"
import DOMPurify from 'dompurify';

const ReportListElement = ({report}) =>{

    const [expanded,setExpanded] = useState(false)
    
    return(
        <Accordion expanded={expanded} onClick={()=>setExpanded(!expanded)}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
            <Typography style={{fontWeight:'bold'}}>{report.userName}</Typography>
            <Typography style={{marginLeft:'30px',color:'#0750b5'}}>
            {convertToLocal(report.entryDate)}
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                style={{
                    width:'100%',
                    padding:'15px',
                    backgroundColor:'#f6f6f6',
                    border:'solid #0750b582 1px',
                    borderRadius:'5px'
                }} onClick={(e)=>e.stopPropagation()}
                dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(stateToHTML(convertFromRaw(JSON.parse(report.content))))}}
                />
            </AccordionDetails>
        </Accordion>
    )
}
    
export default (ReportListElement)