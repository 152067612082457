import { Accordion, AccordionDetails, AccordionSummary, TextField, withStyles } from "@material-ui/core"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { connect } from "react-redux";
import actions from "../duck/actions";


const NewAccordionSummary = withStyles({
    root: {
      minHeight: 56,
      borderTopLeftRadius:"5px",
      borderTopRightRadius:"5px",
      backgroundColor:"#43a047",
      color:'white',
      fontSize:'1.2rem',
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(AccordionSummary);

const NewComment = (props) => {

    const handleCommentChange = (val) => {
        props.addComment(val)
    }

    return(
        <Accordion style={{marginBottom:"15px"}} expanded={true}>
          
            <NewAccordionSummary expandIcon={<AddCircleOutlineIcon style={{color:"white",fontSize:"2rem"}} />}>
                Dodaj komentarz
            </NewAccordionSummary>
            <AccordionDetails>
                <TextField
                    style={{marginTop:"15px",marginBottom:"15px"}}
                    label="Twój komentarz"
                    multiline
                    rows={6}
                    fullWidth={true}
                    variant="outlined"
                    value={props.comment}
                    onChange={(e)=>handleCommentChange(e.target.value)}
                />
            </AccordionDetails>
        </Accordion>    
    )
}

const mapStateToProps = (state) =>{
    return{
        comment:state.newOrder.comment,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addComment: (obj) => dispatch(actions.addComment(obj)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NewComment)
