import { Paper, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { red } from "../helpers/colors"
import moment from "moment"


const ResponseInfo = () => {
    const { enqueueSnackbar } = useSnackbar()
    const response = useSelector(state => state.leaves.form.response)


    useEffect(()=>{
        if(response!==null){
            if(response.canGetLeave===true){
                enqueueSnackbar("Zapytanie zostało wysłane",{variant: 'success',autoHideDuration: 4000})
            }
        }
    },[response])

    return(
        response!==null?
            !response.canGetLeave?
            <Paper style={{
                padding:8,
                borderWidth:2,
                borderColor:red,
                borderStyle:"solid",
                width:'100%',
                marginTop:15,
                marginBottom:15
            }}>
                <ErrorOutlineIcon style={{
                    color:red,
                    float:"left",
                    marginLeft:10,
                    marginRight:10,
                    height:'100%'
                }}/>
                <Typography style={{color:red}}>
                    {response.message}
                </Typography>
                {
                    response.collidingLeaves?
                        response.collidingLeaves.map(leave => (
                            <Typography style={{color:red}}>
                                - Urlop o ID {leave.leaveId} zaczyna się: {moment.utc(leave.startDate).local().format("YYYY-MM-DD")} i kończy: {moment.utc(leave.endDate).local().format("YYYY-MM-DD")}.
                            </Typography>
                        ))
                    :null
                }
            </Paper>
            :null
        :null
    )   
}

export default ResponseInfo