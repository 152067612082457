import { Font, StyleSheet, Text } from "@react-pdf/renderer";
import font from "../fonts/arial.ttf"

Font.register({
  family: "Arial",
  format: "truetype",
  src: font 
});
const styles = StyleSheet.create({
    main: {
        position:'absolute',
        display:'block',
        width:'20%',
        bottom:20,
        right:50,
    },
    hr:{
        backgroundColor:'black',
        height:1,
        width:'100%',
        marginBottom:4
    },
    text:{
        fontFamily:'Arial',
        fontSize:10,
        textAlign:'center'
    }
});

const SignBar = () => {
    return(
        <div style={styles.main}>
            <div style={styles.hr}/>
            <Text style={styles.text}>Podpis klienta</Text>
        </div>
    )
}

export default SignBar