import { Document, Page, Text, StyleSheet, Image, Font, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import logo from "../images/stamp.jpg";
import PdfInfoTable from './PdfInfoTable';
import PdfServicesTable from './PdfServicesTable';
import SignBar from './SignBar';
import font from "../fonts/arial.ttf"

Font.register({
  family: "Arial",
  format: "truetype",
  src: font 
});

const styles = StyleSheet.create({
    page: {
        display: "flex",
        width:'100%',
    },
    section: {
        width:'100%',
        display:'flex',
        fontSize:'12px'
    },
    stamp:{
        width:'33%',
        marginTop:'10px',
        marginBottom:'10px',
        display:'flex',
        alignSelf: 'center'
    },
    mainContainer:{
        width:'90%',
        height:'auto',
        padding:'10px',
        border:'solid 1px black'
    },
    textBox:{
        marginTop:'20px',
        width:"90%",
        marginLeft:'auto',
        marginRight:'auto',
        borderColor:'black',
        borderStyle:'solid',
        borderWidth:1,
        padding:10
    },
    text:{
        fontFamily:'Arial',
        fontSize:9
    }
});

const OrderCardPdf = ({data}) => {

    const [orderData,setOrderData] = useState(data)

    useEffect(()=>{
        setOrderData(data)
    },[data])
    return(
    
        <Document title={"KZ_207/2021"}>
            {(orderData===null||orderData===undefined)?null:
            <>
            <Page size="A4" style={styles.page}>
                <Image src={logo} style={styles.stamp}/>
                <div style={styles.section}>
                    <Text style={{marginLeft:'5%',paddingBottom:'5px',fontWeight:'bold',fontSize:'12'}}>Nr. zlec: {orderData.orderCode}</Text>
                </div>
                <PdfInfoTable data={data}/>
                <PdfServicesTable data={data}/>
                <SignBar/>
            </Page>
            <Page>
                <View style={styles.textBox}>
                    <Text style={styles.text}>Klauzula zgody na przetwarzanie danych osobowych zgodnej z ROD</Text>
                    <Text style={styles.text}>1. Wyrażam zgodę na przetwarzanie moich danych osobowych przez administratora danych firmę
                    Geoprestige Sp. z o.o. z siedzibą w Mogilnie, ul. Stanisława Moniuszki 1, numer KRS 0000610204 w celu
                    realizacji umowy</Text>
                    <Text style={styles.text}>2. Podaję dane osobowe dobrowolnie i oświadczam, że są one zgodne z prawdą.</Text>
                    <Text style={styles.text}>3. Zapoznałem(-am) się z treścią klauzuli informacyjnej, w tym z informacją o celu i sposobach
                    przetwarzania danych osobowych oraz prawie dostępu do treści swoich danych i prawie ich poprawiania,
                    usuwania oraz możliwości wniesienia skarg do PUODO.
                    </Text>
                </View>
            </Page>
            </>}
        </Document>
    )
}
 export default OrderCardPdf