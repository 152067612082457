import { useEffect, useRef, useState } from "react";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { TextField, Tooltip, Typography } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "../style.css"
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { serverUrl } from "../../config/serverConfig";
import actions from "../duck/actions";

const filter = createFilterOptions();

const CustomerInput = (props) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [refresh,setRefresh] = useState(0)
  const [isLoading,setIsLoading] = useState(true)
  const token="Bearer ".concat(props.jwt)
  const [valid,setValid] = useState(false)
  const inputRef = useRef()
  const [inputValue,setInputValue] = useState({customerId: props.customerId, customerName: props.customerName})
  
  useEffect(() => {
    if((props.customerId===null&&props.customerName!=="")||(props.customerName!==""&&props.CustomerId!==null)){
      setValid(true)
      setInputValue({customerId: props.customerId, customerName: props.customerName}) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setInputValue({customerId: props.customerId, customerName: props.customerName})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    async function getData() {
      await fetch(`${serverUrl}/customers/list`,
      {method:'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
      })
      .then(response => response.json())
      .then(json => {
          setIsLoading(false)
          setOptions(json)
      })
      .catch(error => {
        console.log(error);
      })
    }
    if (isLoading||refresh>0) {
      // if the result is not ready so you make the axios call
      //console.log("wyłączony stan ",skipPageResetRef)
      getData()
      setRefresh(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refresh])

  const addUser = (name) => {
    props.setCustomerName(name.substring(6))
    props.setCustomCustomer(true)
  }

  const selectUser = (customerId,name) => {
    props.setCustomerId(customerId)
    props.setCustomerName(name)
    props.setCustomCustomer(false)
  }

  const handleSelect = (val) => {
    if(val===undefined||val===null){
      //console.log("jest pusto")
      setValid(false)
      props.setCustomerId(null)
      props.setCustomerName("")
      props.setCustomCustomer(false)
      props.setCustomerSet(false)
      props.clearPhoneNumbers()
      props.clearEmails()
      props.clearAddresses()
      props.clearNewPhoneNumbers()
      props.clearNewEmails()
      props.clearNewAddresses()
    }else{
      if(val.customerId===null)
      {
        addUser(val.customerName)
        setValid(true)
        props.setCustomerSet(true)
      }else{
        selectUser(val.customerId,val.customerName)
        setValid(true)
        props.setCustomerSet(true)
        //console.log("user jest wybrany z listy")
      }
    }
  }

  
  //const validCheck = (val) => {
  //  console.log(val)
  //  if(val!==null){
  //      setInputValue(val.current.value)
  //  }
  //}

  return (
    <div className="inputContainer">
    <div className="iconContainerNew">
       <AccountCircleRoundedIcon className="icon"/>
    </div>
    <Autocomplete
      value={inputValue}
      freeSolo
      //onInputChange={(e)=>validCheck(e)}
      ref={inputRef}
      className="bigInput"
      id="asynchronous-demo"
      open={open}
      clearText="Resetuj"
      onChange={(e,val)=>{handleSelect(val)}}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.customerName === value.name}
      getOptionLabel={(option) => option.customerName}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Nazwa inwestora *"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
          
          filtered.push({
            customerId: null,
            customerName: `DODAJ ${params.inputValue}`,
          });
        }

        return filtered;
      }}
    />
    <div className="iconContainer">
      <Tooltip
      enterDelay={500}
      title={
        <>
        <Typography>
           Zacznij wpisywać dane klienta. Jeśli wyświetli się on w podpowiedziach wybierz go. Gdy jednak chcesz dodać nowego klienta użyj opcji DODAJ.
        </Typography>
        <Typography style={{color:"yellow"}}>
          Uważaj!
        </Typography>
        <Typography>
          Gdy chcesz zmienić klienta musisz na nowo wybrać kogoś z listy lub użyć opcji DODAJ. Edycja samej nazwy nic nie zmieni bez wybrania którejś z tych dwóch opcji
        </Typography>
        </>
      }>
      {inputValue.customerName===""?<HelpOutlineIcon className="icon" style={{color:"grey"}}/>:valid===true?<CheckCircleIcon className="icon" style={{color:"#43a047"}}/>:<CancelOutlinedIcon className="icon" style={{color:"red"}}/>}
      </Tooltip>
      
    </div>
    
  </div>
  );
}

const mapStateToProps = (state) =>{
  //console.log(state.filters.sanitaryTable.sortedColumn)
  return{
     customerId:state.newOrder.customerData.customerId,
     customerName:state.newOrder.customerData.customerName,
     customCustomer:state.newOrder.customerData.customCustomer,
     customerSet:state.newOrder.customerData.customerSet,
     jwt:state.log.jwt
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    setCustomerId : (obj) => dispatch(actions.setCustomerId(obj)),
    setCustomerName : (obj) => dispatch(actions.setCustomerName(obj)),
    setCustomCustomer: (obj) => dispatch(actions.setCustomCustomer(obj)),
    setCustomerSet: (obj) => dispatch(actions.setCustomerSet(obj)),
    clearPhoneNumbers: () => dispatch(actions.clearPhoneNumbers()),
    clearEmails: () => dispatch(actions.clearEmails()),
    clearAddresses: () => dispatch(actions.clearAddresses()),
    clearNewPhoneNumbers: () => dispatch(actions.clearNewPhoneNumbers()),
    clearNewEmails: () => dispatch(actions.clearNewEmails()),
    clearNewAddresses: () => dispatch(actions.clearNewAddresses()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CustomerInput)