import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PickersDay from '@mui/lab/PickersDay';
import endOfWeek from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import moment from 'moment';
import { pl } from "date-fns/locale";
import { addDays } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Grid } from '@material-ui/core';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const createDateRange = (date) => {
    return([moment(date).startOf('week').toDate(),moment(date).endOf('week').toDate()])
}

export default function WeekPicker({changeDate}) {
    const [value, setValue] = React.useState(new Date());
    const [format,setFormat] = React.useState("'".concat(moment().startOf('week').format("YYYY-MM-DD").toString()," - ",moment().endOf('week').format("YYYY-MM-DD").toString(),"'"))
    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
        if (!value) {
        return <PickersDay {...pickersDayProps} />;
        }

    const start = addDays(startOfWeek(value),+1);
    const end =addDays(endOfWeek(value),+1);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
};

    React.useEffect(()=>{
      changeDate(createDateRange(value))
    },[])

    const handleChange = (newValue) => {
        setValue(newValue);
        setFormat("'".concat(moment(newValue).startOf('week').format("YYYY-MM-DD").toString()," - ",moment(newValue).endOf('week').format("YYYY-MM-DD").toString(),"'"))
        changeDate(createDateRange(newValue))
        console.log(moment(newValue).startOf('week').format("YYYY-MM-DD"))
    }

    const addWeek = (val) => {
        const newValue = moment(value).add(val,'w').toDate()
        setValue(newValue)
        setFormat("'".concat(moment(newValue).startOf('week').format("YYYY-MM-DD").toString()," - ",moment(newValue).endOf('week').format("YYYY-MM-DD").toString(),"'"))
        changeDate(createDateRange(newValue))
      }


  return (
    <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        displayStaticWrapperAs="desktop"
        label="Tydzień"
        value={value}
        onChange={()=>{}}
        renderDay={renderWeekPickerDay}
        renderInput={(params) =>
            <Grid container justify='center' spacing={2} alignItems='center'>
                    <Grid item>
                        <IconButton onClick={()=>addWeek(-1)}>
                            <NavigateBeforeIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <TextField 
                        inputProps={{style: { textAlign: 'center' }}}
                        size='small' {...params} helperText={null}/>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={()=>addWeek(1)}>
                            <NavigateNextIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
             
            }
        inputFormat={format}
        onAccept={(val)=>handleChange(val)}
      />
    </LocalizationProvider>
  );
}
