import {Grid } from "@material-ui/core"
import React from 'react'
import '../departments/style.css'
import CustomerDataTable from "./CustomerDataTable"
import OrdersList from "./OrdersList"
import ServicesList from "./ServicesList"

 function CustomersDialog(props){
     
    const {customerId} = props
    
    return(
        <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
                <CustomerDataTable customerId={customerId}/>
            </Grid>
            <Grid item lg={5} xs={12}>
                <ServicesList customerId={customerId}/>
            </Grid>
            <Grid item lg={3} xs={12}>
                <OrdersList customerId={customerId}/> 
            </Grid>
        </Grid>
    )
}

export default (CustomersDialog)