import types from './types';

const login = item => ({
    type: types.LOGIN_USER, item
})

const logOut = () => ({
    type: types.LOGOUT_USER
})

const getJwt = () => ({
    type: types.GET_JWT
})

const setTimeLeft = (item) => ({
    type: types.SET_TIME_LEFT, item
})

const actions = {
    login,
    logOut,
    getJwt,
    setTimeLeft
}

export default actions