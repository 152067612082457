import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import './index.css';
import App from './mainComponents/App';
import {store} from './store';
import CacheBuster from 'react-cache-buster'
import { version } from '../package.json';
const Preloader = () =>{

  const [rehydrated,setRehydrated] = useState(false)

  useEffect(() => {
    persistStore(store,{},()=>{
      setRehydrated(true)
    })
  }, [])

  return(
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      //loadingComponent={<span>Ładowanko</span>} //If not pass, nothing appears at the time of new version check.
    >
    <React.Fragment>
    {rehydrated===true?
    <Provider store={store}>
      <SnackbarProvider maxSnack={5}>
      <div id='snackbarhelper'></div>
        <App/>
      </SnackbarProvider>
    </Provider>
    :
    null}
    </React.Fragment>
    </CacheBuster>
  )
}

ReactDOM.render(<Preloader/>,document.getElementById('root'));

