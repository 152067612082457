import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

export default function DialogUnPay(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (confirmed) => {
    props.closeDialog(confirmed)
  };

  useEffect(() => {
      console.log(props)
      setOpen(props.open)  
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            <ReportProblemOutlinedIcon style={{color:"#ff9800",fontSize:"2rem",display:"block",float:"left"}}/>
            {"Czy na pewno chcesz cofnąć rozliczenie?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Jeśli cofniesz rozliczenie usługi zapłacona kwota zostanie ustawiona na 0zł!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={()=>handleClose(false)} >
            ANULUJ
          </Button>
          <Button color="secondary" variant="contained" onClick={()=>handleClose(true)}  autoFocus>
            POTWIERDŹ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}